import { formatNumber } from "@helper/helper";
import { Button } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IoMenu } from "react-icons/io5";
import userAvatar from "@assets/image/user-avatar.png";
import avatar from "@assets/image/user-info.png";
import phone from "@assets/image/phone-info.png";
import BellIcon from "@assets/svg/Bell";
import { useAuth } from "provider/auth";
import { useNavigate } from "react-router-dom";
import { useOutsideClick } from "@hooks/useOutsideClick";
import phoneLogo from "@assets/image/phone-logo.png"

type HeaderProps = {
  handleToogleSidebar: () => void;
};

export const Header: React.FC<HeaderProps> = ({ handleToogleSidebar }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { onLogout, user } = useAuth();
  const [expandUserInfo, setExpandUserInfo] = useState(false);
  const accountBalance = 2031234;
  const go24Coin = 0.07;
  // const userName = "03251644832";
  const ref: any = useOutsideClick(() => setExpandUserInfo(false));

  return (
    <div className="bg-main-2 h-[64px] w-full flex flex-row items-center justify-between px-4">
      <div className="flex lg:hidden">
        <button
          onClick={handleToogleSidebar}
          className="lg:hidden  w-[24px] border-none p-0"
        >
          <IoMenu className="w-[24px] h-[24px] text-white" />
        </button>
        <div className="lg:hidden">
          <img src={phoneLogo} alt="" />
        </div>
      </div>
      <div className="hidden lg:flex flex-row gap-x-6 items-center">
        <button
          onClick={handleToogleSidebar}
          className=" w-[24px] border-none p-0"
        >
          <IoMenu className="w-[24px] h-[24px] text-white" />
        </button>
        {/* <div className="bg-yellow-2 rounded py-2 px-6 text-sm leading-normal">
          {t("header.AccountBalance")}:{" "}
          <span className="font-semibold">{formatNumber(accountBalance)}</span>
        </div>
        <button className="min-w-[100px] border-yellow-2 bg-main-2 text-yellow-2 font-semibold border leading-none py-2 px-6 rounded hover:!border-white hover:bg-yellow-2 hover:!text-white">
          {t("header.TopUp")}
        </button>
        <button className="min-w-[100px] border-yellow-2 bg-main-2 text-yellow-2 font-semibold border leading-none py-2 px-6 rounded hover:!border-white hover:bg-yellow-2 hover:!text-white">
          {t("header.Withdraw")}
        </button>
        <div className="rounded py-2 px-6 text-sm leading-none font-semibold uppercase text-yellow-2">
          {t("header.Go24Coin")}: {go24Coin.toLocaleString("vi-VN")}{" "}
          {t("header.Coin")}
        </div> */}
      </div>
      <div className="absolute right-0 flex flex-row items-center gap-x-2 mr-6">
        {/* <button className="border-none text-white px-1">
          <BellIcon className="w-[24px] h-[24px]" />
        </button> */}
        <div className="relative cursor-pointer">
          <div
            className="flex flex-row items-center gap-x-4 p-4 hover:bg-[#522981]"
            onClick={() => setExpandUserInfo(true)}
            ref={ref}
          >
            <img
              src={
                user?.imageUrl
                  ? `${process.env.REACT_APP_GO24_API}${user?.imageUrl}`
                  : userAvatar
              }
              className="w-8 h-8 rounded-full"
              alt="user-avatar"
            />
            <p className="hidden lg:block text-sm text-white">
              <span className="w-[120px] truncate">{user?.fullName}</span> -
              Partner
            </p>
          </div>
          {expandUserInfo && (
            <div className="absolute z-10 top-[4.1rem] right-0 bg-[#522981] p-3 text-white text-sm w-64">
              <div className="flex justify-center border-b py-2">
                <img
                  src={
                    user?.imageUrl
                      ? `${process.env.REACT_APP_GO24_API}${user?.imageUrl}`
                      : userAvatar
                  }
                  alt="avatar"
                  width={32}
                  height={32}
                  className="rounded-full"
                />
              </div>
              <div className="mt-2">
                <div className="flex gap-2 py-2">
                  <img src={avatar} width={24} height={24} alt="icon" />
                  <p className="font-semibold">{user?.fullName}</p>
                </div>
                <div className="flex gap-2 py-2">
                  <img src={phone} width={24} height={24} alt="icon" />
                  <p className="">{user?.phoneNumber}</p>
                </div>
              </div>
              <div className="mt-2 flex justify-between">
                <button
                  onClick={() => navigate("/account-setting")}
                  className="border-transparent text-sm border border-yellow-2 text-white bg-transparent leading-none p-2 rounded hover:!border-yellow-2 hover:bg-yellow-2 hover:!text-main-2 font-medium"
                >
                  Tài khoản
                </button>
                <button
                  className="border-transparent text-main-2 text-sm border border-yellow-2 bg-yellow-2 leading-none p-2 rounded hover:!border-yellow-2 hover:bg-transparent hover:!text-yellow-2 font-medium"
                  onClick={onLogout}
                >
                  {t("logout.Logout")}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
