import { Select } from "antd";
import styled from "styled-components";

export const StyledSelect = styled(Select)`
  width: 100%;
  .ant-select-selector {
    border: 0.5px solid #d9d9d9 !important;

    &:hover {
      border-color: #4096ff !important;
    }
  }
`;
