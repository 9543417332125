import { Geo } from "@models/Geo";
import {
  getDistrictToProvince,
  getDistricts,
  getProvince,
} from "@services/geo-services";
import { getDeliveryList } from "@services/shop-services";
import { Select, message } from "antd";
import { api } from "provider/api";
import { useLoading } from "provider/loading";
import { useEffect, useState } from "react";
import { IoCaretDown } from "react-icons/io5";

const DeliveryPriceFilter = ({
  setDeliveryPrices,
  page,
  setTotalPage,
  provinces,
  districts,
  setProvinces,
  setDistricts,
}: any) => {
  const { setLoading } = useLoading();
  const [deliveries, setDeliveries] = useState<any[]>([]);
  const [fromDistricts, setFromDistricts] = useState<Geo[]>([]);
  const [toDistricts, setToDistricts] = useState<Geo[]>([]);
  const [priceList, setPriceList] = useState<any[]>([]);
  const [fromProvinceId, setFromProvinceId] = useState<any>();
  const [fromDistrictId, setFromDistrictId] = useState<any>();
  const [toProvinceId, setToProvinceId] = useState<any>();
  const [toDistrictId, setToDistrictId] = useState<any>();
  const [priceListId, setPriceListId] = useState();
  const [partnerId, setPartnerId] = useState();
  const [refreshData, setRefreshData] = useState<number | undefined>(undefined);
  const [filteringFromProvinces, setFilteringFromProvinces] =
    useState(provinces);
  const [filteringToProvinces, setfilteringToProvinces] = useState([]);
  const [filteringFromDistricts, setFilteringFromDistricts] =
    useState(districts);
  const [filteringToDistricts, setFilteringToDistricts] = useState([]);

  const handleSearchFromProvinces = (newValue: string) => {
    const data = provinces.filter((ite: Geo) =>
      ite.name.toLowerCase().includes(newValue.toLowerCase())
    );
    setFilteringFromProvinces(data);
  };

  const handleSearchToProvinces = (newValue: string) => {
    const data = provinces.filter((ite: Geo) =>
      ite.name.toLowerCase().includes(newValue.toLowerCase())
    );
    setfilteringToProvinces(data);
  };

  const handleSearchFromDistricts = (newValue: string) => {
    const data = districts.filter((ite: Geo) =>
      ite.name.toLowerCase().includes(newValue.toLowerCase())
    );
    setFilteringFromDistricts(data);
  };

  const handleSearchToDistricts = (newValue: string) => {
    const data = districts.filter((ite: Geo) =>
      ite.name.toLowerCase().includes(newValue.toLowerCase())
    );
    setFilteringToDistricts(data);
  };

  const handleChangeFromProvince = async (id: number) => {
    if (fromDistrictId) {
      setFromDistrictId(undefined);
    }
    setFromProvinceId(id);
    const res = await getDistrictToProvince(id);
    if (res) setFilteringFromDistricts(res);
  };

  const handleChangeToProvince = async (id: number) => {
    if (toDistrictId) {
      setToDistrictId(undefined);
    }
    setToProvinceId(id);
    const res = await getDistrictToProvince(id);
    if (res) setFilteringToDistricts(res);
  };

  useEffect(() => {
    (async () => {
      const res = await getProvince();
      if (res) setProvinces(res);
      else {
        message.error("Không thể kết nối đến data tỉnh thành");
      }
      const resDistricts = await getDistricts();
      if (resDistricts) {
        setDistricts(resDistricts);
        setFromDistricts(resDistricts);
        setToDistricts(resDistricts);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const res = await api.get("delivery-price/price-list");
        if (res && res.data) {
          setPriceList(res.data?.response || []);
          setPriceListId(res?.data?.response[0].id);
        }
        const response = await getDeliveryList();
        if (response) {
          setDeliveries(response);
          setPartnerId(response[0].id);
        }
      } catch (e) {}
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (refreshData) {
        const payload = {
          page,
          limit: 10,
          partnerId,
          priceListId,
          fromProvinceId,
          fromDistrictId,
          toProvinceId,
          toDistrictId,
        };
        setLoading(true);
        const res = await api.get("delivery-price", { params: payload });
        if (res && res.data) {
          setDeliveryPrices(res.data?.response?.data);
          setTotalPage(res.data?.response?.meta?.pageCount || 1);
          setLoading(false);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, refreshData]);

  useEffect(() => {
    setFilteringFromProvinces(provinces);
    setfilteringToProvinces(provinces);
  }, [provinces]);

  useEffect(() => {
    setFilteringFromDistricts(districts);
    setFilteringToDistricts(districts);
  }, [districts]);

  return (
    <div className="mt-4 bg-white w-full py-2 px-2 md:px-4 rounded-lg shadow-lg xl:flex xl:gap-6 ">
      <div className="grid grid-cols-2 md:grid-cols-4 gap-6 w-full justify-between">
        <div className="md:col-span-2">
          <p className="font-semibold text-sm leading-none mb-2 text-start">
            Đối tác giao hàng
          </p>
          <Select
            className="w-full h-10 text-start first:pr-4"
            suffixIcon={<IoCaretDown className="text-lg" />}
            onChange={(value) => setPartnerId(value)}
            placeholder="Nhập đối tác giao hàng"
            value={deliveries.find((item) => item.id === partnerId)?.name}
          >
            {deliveries.map((option: any) => (
              <Select.Option key={option?.id} value={option.id}>
                {option?.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="md:col-span-2">
          <p className="font-semibold text-sm leading-none mb-2 text-start">
            Bảng giá
          </p>
          <Select
            className="w-full h-10 text-start first:pr-4"
            suffixIcon={<IoCaretDown className="text-lg" />}
            onChange={(value) => setPriceListId(value)}
            placeholder="Nhập bảng giá"
            value={priceList.find((item) => item.id === priceListId)?.name}
          >
            {priceList.map((option: any) => (
              <Select.Option key={option?.id} value={option.id}>
                {option?.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="">
          <p className="font-semibold text-sm leading-none mb-2 text-start">
            Tỉnh thành giao
          </p>
          <Select
            className="w-full h-10 text-start first:pr-4"
            suffixIcon={<IoCaretDown className="text-lg" />}
            onChange={(value) => handleChangeFromProvince(value)}
            placeholder="Nhập tỉnh thành giao"
            onSearch={handleSearchFromProvinces}
            showSearch
            filterOption={false}
          >
            {filteringFromProvinces.map((option: any) => (
              <Select.Option key={option?.id} value={option.id}>
                {option?.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="">
          <p className="font-semibold text-sm leading-none mb-2 text-start">
            Quận huyện giao
          </p>
          <Select
            className="w-full h-10 text-start first:pr-4"
            suffixIcon={<IoCaretDown className="text-lg" />}
            onChange={(value) => setFromDistrictId(value)}
            placeholder="Nhập quận huyện giao"
            value={
              fromDistricts.find((item: any) => item?.id === fromDistrictId)
                ?.name
            }
            onSearch={handleSearchFromDistricts}
            showSearch
            filterOption={false}
          >
            {filteringFromDistricts.map((option: any) => (
              <Select.Option key={option?.id} value={option.id}>
                {option?.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="">
          <p className="font-semibold text-sm leading-none mb-2 text-start">
            Tỉnh thành nhận
          </p>
          <Select
            className="w-full h-10 text-start first:pr-4"
            suffixIcon={<IoCaretDown className="text-lg" />}
            onChange={(value) => handleChangeToProvince(value)}
            placeholder="Nhập tỉnh thành nhận"
            onSearch={handleSearchToProvinces}
            showSearch
            filterOption={false}
          >
            {filteringToProvinces.map((option: any) => (
              <Select.Option key={option?.id} value={option.id}>
                {option?.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="">
          <p className="font-semibold text-sm leading-none mb-2 text-start">
            Quận huyện nhận
          </p>
          <Select
            className="w-full h-10 text-start first:pr-4"
            suffixIcon={<IoCaretDown className="text-lg" />}
            onChange={(value: any) => setToDistrictId(value)}
            placeholder="Nhập quận huyện nhận"
            value={
              toDistricts.find((item: any) => item?.id === toDistrictId)?.name
            }
            showSearch
            filterOption={false}
            onSearch={handleSearchToDistricts}
          >
            {filteringToDistricts.map((option: any) => (
              <Select.Option key={option?.id} value={option.id}>
                {option?.name}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
      <div className="mt-[22px] flex items-center h-10 w-40">
        <button
          className="!border-none rounded px-6 py-2 bg-main-2 text-sm font-medium leading-[24px] text-white hover:bg-main-4 flex justify-center items-center"
          onClick={() => setRefreshData(new Date().getTime())}
        >
          Tìm kiếm
        </button>
      </div>
    </div>
  );
};

export default DeliveryPriceFilter;
