import React, { useEffect } from "react";
import {
  Navigate,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Login } from "@pages/login";
import { ForgotPassword } from "@pages/login/ForgotPassword";
import { Layout } from "./layout";
import ShopManagement from "@pages/shop-management";
import AuthProvider, { useAuth } from "provider/auth";
import Overview from "@pages/overview";
import RequestsManagement from "@pages/requests-management";
import OrdersManagement from "@pages/order-management";
import TagsSetting from "@pages/tags-setting";
import ShopManagementTabs from "@components/shop-management-tabs";
import AccountDetails from "@pages/account-details";
import ShopRevenueReport from "@pages/shop-revenue-report";
import axios, { AxiosResponse } from "axios";
import { useLocalStorage } from "@hooks/useLocalStorage";
import { GET_ACCESS_TOKEN_TIME } from "constants/constants";
import AccountSetting from "@pages/account-setting";
import AccountInformation from "@pages/account-setting/AccountInfo";
import ChangePassword from "@pages/account-setting/ChangePassword";
import { api } from "provider/api";
import { ConfigProvider, message } from "antd";
import { useTranslation } from "react-i18next";
import DeliveryPrice from "@pages/master-data/delivery-price";
import LoadingProvider from "provider/loading";
import RoleBaseGuard from "provider/RoleBaseGuard";
import SubAccount from "@pages/master-data/sub-account";
import SubAccountDetails from "@pages/master-data/sub-account/SubAccountDetails";
import RoleSetting from "@pages/master-data/sub-account/RoleSetting";
import viVN from "antd/locale/vi_VN";

const ProtectedRoute = () => {
  const { setToken, user, setUser, onLogout } = useAuth();
  const { setItem } = useLocalStorage();
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      try {
        const userInfo = await api.get("/partner/me");
        if (userInfo) {
          const info = userInfo?.data?.response;
          setUser(info);
        }
      } catch (e) {
        message.error(t("common.SomethingError"));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timerId = setInterval(() => {
      const refresh = localStorage.getItem("refresh");
      const config = {
        headers: {
          Authorization: `Bearer ${refresh}`,
        },
      };
      axios
        .post(`${process.env.REACT_APP_API_URL}/auth/refresh`, {}, config)
        .then((res: AxiosResponse<any, any>) => {
          if (res) {
            const { accessToken } = res?.data;
            setToken(accessToken);
            setItem("token", accessToken);
          }
        })
        .catch((e) => {
          onLogout();
        });
    }, GET_ACCESS_TOKEN_TIME);
    return () => {
      clearInterval(timerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Layout />;
};

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: <ProtectedRoute />,
    children: [
      {
        path: "",
        element: (
          <RoleBaseGuard role="manageDashboard">
            <Overview />
          </RoleBaseGuard>
        ),
      },
      {
        path: "request-management",
        element: (
          <RoleBaseGuard role="manageRequest">
            <RequestsManagement />
          </RoleBaseGuard>
        ),
      },
      {
        path: "orders-management",
        element: (
          <RoleBaseGuard role="manageOrder">
            <OrdersManagement />
          </RoleBaseGuard>
        ),
      },

      {
        path: "tags-setting",
        element: (
          <RoleBaseGuard role="manageHashtag">
            <TagsSetting />
          </RoleBaseGuard>
        ),
      },
      {
        path: "shop-management",
        element: (
          <RoleBaseGuard role="manageShop">
            <ShopManagement />
          </RoleBaseGuard>
        ),
      },
      {
        path: "reset-password",
        element: <ForgotPassword />,
      },
      {
        path: "shop-management",
        element: (
          <RoleBaseGuard role="manageShop">
            <ShopManagementTabs />
          </RoleBaseGuard>
        ),
        children: [
          {
            path: "details/:id",
            element: <AccountDetails />,
          },
          {
            path: "revenue/:id",
            element: <ShopRevenueReport />,
          },
        ],
      },
      {
        path: "account-setting",
        element: <AccountSetting />,
        children: [
          {
            path: "",
            element: <AccountInformation />,
          },
          {
            path: "password",
            element: <ChangePassword />,
          },
        ],
      },
      {
        path: "delivery-price",
        element: (
          <RoleBaseGuard role="manageDeliveryPrice">
            <DeliveryPrice />
          </RoleBaseGuard>
        ),
      },
      {
        path: "sub-account",
        element: (
          <RoleBaseGuard role="manageSubAccount">
            <SubAccount />
          </RoleBaseGuard>
        ),
        children: [
          {
            path: "",
            element: <SubAccountDetails />,
          },
          {
            path: "role-setting",
            element: <RoleSetting />,
          },
        ],
      },
    ],
  },
]);

const NestedApp = () => {
  return <RouterProvider router={router} />;
};

const AppRouter: React.FC = () => {
  return (
    <ConfigProvider locale={viVN}>
      <LoadingProvider>
        <AuthProvider>
          <NestedApp />
        </AuthProvider>
      </LoadingProvider>
    </ConfigProvider>
  );
};

export default AppRouter;
