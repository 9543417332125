import { IPaginationResponse } from "@models/PaginationResponse";
import { api } from "provider/api";

export interface IRequestPayload {
  page: number;
  limit: number;
  from: string;
  to: string;
  status?: number | string;
  shopText?: string;
  orderText?: string;
  requestType?: string;
}

export interface IType {
  from: string;
  to: string;
}

interface IDataMessage {
  content: string;
}

export const getRequest = async ({
  page,
  limit,
  from,
  to,
  requestType,
  orderText,
  shopText,
  status,
}: IRequestPayload) => {
  const payload = {
    page,
    limit,
    from,
    to,
    requestType,
    orderText,
    shopText,
    status,
  };
  const res: IPaginationResponse = await api.get("/order-request", {
    params: payload,
  });

  if (res) {
    return {
      data: res.data.response.data,
      meta: res.data.response.meta,
    };
  }
  return undefined;
};

export const getRequestTypes = async (payload: any) => {
  const res = await api.get("/order-request/get/types", {
    params: payload,
  });

  if (res && res.data) {
    return res.data.response;
  }
  return undefined;
};

export const getRequestById = async (id: number,tableName:string) => {
  const res = await api.get(`/order-request/${id}/${tableName}`);

  if (res && res.data) {
    return res.data.response;
  }
  return undefined;
};

export const updateCommentByIdRequest = async (
  id: number,
  payload: any
) => {
  const res = await api.put(`/order-request/comment/${id}`, payload);

  if (res && res.data) {
    return res.data.response;
  }
  return undefined;
};

export const updateRequestById = async (id: number) => {
  const data = {};
  const res = await api.put(`/order-request/${id}`, data);

  if (res && res.data) {
    return res.data.response;
  }
  return undefined;
};
