import { ConfigProvider } from "antd";
import "./App.css";
import AppRouter from "./routes";

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "inherit",
        },
      }}
    >
      <div className="App">
        <AppRouter />
      </div>
    </ConfigProvider>
  );
}

export default App;
