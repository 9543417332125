import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { WrapperMessages } from "./styles";
import { Skeleton } from "antd";
import { useAuth } from "provider/auth";

interface IMessageProps {
  messages: any[];
  loading: boolean;
}

const Messages = ({ messages, loading }: IMessageProps) => {
  const { user } = useAuth();
  const isMyComment = (idComment: string) => {
    return idComment === user.id;
  };

  return (
    <WrapperMessages>
      <div className="w-full p-4 flex flex-col-reverse gap-4 overflow-y-auto h-[250px] lg:h-[400px]">
        {loading ? (
          <Skeleton />
        ) : (
          messages?.map((mess) => (
            <div
              className={`w-full flex items-end gap-2 message ${
                isMyComment(mess?.createdBy)
                  ? "my-message justify-end"
                  : "cus-message"
              }`}
            >
              {!isMyComment(mess?.createdBy) && (
                <img
                  src=""
                  alt=""
                  className="w-4 h-4 rounded-full object-cover"
                />
              )}
              <div
                className={`max-w-1/2 py-2.5 px-4 text-sm ${
                  isMyComment(mess?.createdBy)
                    ? "rounded-s-[14px] rounded-se-[48px] bg-blue-3 text-white"
                    : "bg-[#E6EBF5] rounded-ss-[48px] rounded-e-[24px]"
                }`}
              >
                {mess?.content}
              </div>
              {mess.isMyMess && (
                <IoCheckmarkCircleOutline className="w-4 h-4" />
              )}
            </div>
          ))
        )}
      </div>
    </WrapperMessages>
  );
};

export default Messages;
