import { Modal } from "antd";
import { AUTHORIZE } from "constants/constants";
import { roleTable } from "./RoleSetting";
import { StyledCheckbox } from "./styles";

const { NONE, READ, EDIT } = AUTHORIZE;

const RoleDetailsModal = ({
  open,
  onCancel,
  roleGroup,
  id,
  role,
}: {
  open: boolean;
  onCancel: any;
  roleGroup?: any;
  id?: any;
  role?: any;
}) => {
  const roleGroupDetails = role
    ? role
    : roleGroup?.find((item: any) => item?.id === id);

  return (
    <Modal
      title=""
      open={open}
      onCancel={onCancel}
      width={800}
      footer={null}
      centered
    >
      <div className="text-base font-semibold border-b pb-3">
        Chi tiết chức năng
      </div>
      <p className="my-3 text-sm text-black">
        <span className="font-semibold">Tên chức năng: </span>
        {roleGroupDetails?.name}
      </p>
      <div className="w-full shadow-lg overflow-auto">
        <div className="bg-gray-1 min-w-[700px] px-4 py-2 flex flex-row justify-between items-center text-sm font-semibold rounded-tl-lg rounded-tr-lg overflow-auto">
          <div className="w-[50px] md:w-[10%] text-left">.No</div>
          <div className="w-[200px] md:w-[30%] text-center">Tên chức năng</div>
          <div className="w-[150px] md:w-[20%] text-center">Không được xem</div>
          <div className="w-[150px] md:w-[20%] text-center">Chỉ được xem</div>
          <div className="w-[150px] md:w-[20%] text-center">Được sửa</div>
        </div>
        {roleGroupDetails?.organizations &&
          roleTable.map(({ key, value }, index) => (
            <div
              key={key}
              className={`min-w-[700px] flex justify-center px-4 py-3 border-b last:border-transparent border-gray-1 text-sm`}
            >
              <div className="w-[50px] md:w-[10%]">{index + 1}</div>
              <div className="w-[200px] md:w-[30%] text-center">{value}</div>
              <div className="w-[150px] md:w-[20%] text-center">
                <StyledCheckbox
                  checked={roleGroupDetails?.organizations[key] === NONE}
                />
              </div>
              <div className="w-[150px] md:w-[20%] text-center">
                <StyledCheckbox
                  checked={roleGroupDetails?.organizations[key] === READ}
                />
              </div>
              <div className="w-[150px] md:w-[20%] text-center">
                <StyledCheckbox
                  checked={roleGroupDetails?.organizations[key] === EDIT}
                />
              </div>
            </div>
          ))}
      </div>
    </Modal>
  );
};

export default RoleDetailsModal;
