import { IPaginationResponse } from "@models/PaginationResponse";
import { api } from "provider/api";

export interface IShopList {
  page: number;
  limit: number;
  from: string;
  to: string;
  dateType: string;
  hashtagId: string | number | undefined;
  statusKYC: string | undefined;
  userGroupId: string | undefined;
  lastOrderDate: string | undefined;
  searchText: string;
}

export const getShopList = async (payload: IShopList) => {
  const res: IPaginationResponse = await api.get("/partner/get-shops", {
    params: payload,
  });
  if (res && res.data.response) {
    return {
      data: res.data.response.data,
      meta: res.data.response.meta,
    };
  }
  return undefined;
};

export const getShopDetails = async (id: number | string) => {
  const res = await api.get(`partner/get-shop/${id}`);
  if (res && res?.data && res.data?.response) {
    return res.data?.response;
  }
  return undefined;
};

export const getDeliveryList = async () => {
  try {
    const res = await api.get("/partner/deliveries");
    if (res && res.data) return res.data.response;
    return undefined;
  } catch (e) {}
};

export const getUserGroup = async () => {
  try {
    const res = await api.get("/partner/user-group");
    if (res && res.data) return res.data.response;
    return undefined;
  } catch (e) {}
};

export const updateShopInfo = async (id: number, payload: any) => {
  const res = await api.put(`/partner/${id}/shop-info`, payload);
  return res;
};

export const resetPasswordForShop = async (id: number, payload: any) => {
  const res = await api.put(`/partner/${id}/reset-password`, payload);
  return res;
};
