import { Button, Form, Input, message } from "antd";
import { useTranslation } from "react-i18next";
import { StyledCreateAccountShopForm } from "./styles";
import { FaCaretUp, FaMinus } from "react-icons/fa6";
import CameraIcon from "@assets/image/shop-management/camera.svg";
import { api } from "provider/api";
import { useState } from "react";

type FieldType = {
  userName: string;
  email: string;
  fullName: string;
  password: string;
  phoneNumber: string;
  refferalPhoneNumber?: string;
};

const CreateAccountForm = ({
  handleCloseCreated,
  onRefreshData,
}: {
  handleCloseCreated: () => void;
  onRefreshData: () => void;
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const onSave = async (values: FieldType) => {
    try {
      setLoading(true);
      const res = await api.post("/partner/create-shop", {
        ...values,
      });
      if (res) {
        message.success(t("shopManagement.CreateShopAccountSuccessfully"));
        handleCloseCreated();
        onRefreshData();
        setLoading(false);
      }
    } catch (e: any) {
      console.log(e);
      message.error(
        e?.response?.data?.message[0]?.error || e?.response?.data?.message
      );
      setLoading(false);
    }
  };

  return (
    <div className="border-b border-gray-1 mb-6">
      <Form
        name="create-account-form"
        initialValues={{ remember: true }}
        onFinish={onSave}
        autoComplete="off"
        layout="vertical"
        className="w-full"
      >
        <StyledCreateAccountShopForm className="bg-white rounded-lg">
          <div className="flex justify-between px-4 py-2 border-b border-gray-1">
            <div className="flex gap-4 items-center">
              <div className="text-sm md:text-base text-black font-semibold">
                {t("shopManagement.CreateShopAccount")}
              </div>
              <FaCaretUp size={22} color="#373737" />
            </div>
            <button
              className="md:w-36 !border-none rounded px-2 md:px-6 py-1 md:py-2 bg-main-2 text-sm font-medium leading-[24px] text-white hover:bg-main-4 flex justify-center items-center"
              onClick={handleCloseCreated}
            >
              <span className="hidden md:block">{t("button.Collapse")}</span>
              <span className="block md:hidden">
                <FaMinus size={20} color="white" />
              </span>
            </button>
          </div>
          <div className="p-4">
            <div className="mt-4 flex flex-col md:flex-row gap-4 md:gap-6 items-center">
              <div className="w-full md:w-1/2 flex justify-center items-center">
                <img src={CameraIcon} className="w-48 h-48" alt="camera" />
              </div>
              <div className="mt-4 md:mt-0 w-full md:w-1/2">
                <Form.Item<FieldType> name="fullName">
                  <div className="flex flex-col items-start gap-3">
                    <p className="text-sm font-medium text-black">
                      {t("shopManagement.Fullname")}
                    </p>

                    <Input
                      className="rounded-lg w-full h-10 border-black border-[0.5px]"
                      placeholder={t("shopManagement.Fullname")}
                    />
                  </div>
                </Form.Item>
                <Form.Item<FieldType>
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: t("validate.ValidateEmail"),
                    },
                  ]}
                >
                  <div className="flex flex-col items-start gap-3">
                    <p className="text-sm font-medium text-black">Email</p>
                    <Input
                      className="rounded-lg w-full h-10 border-black border-[0.5px]"
                      placeholder="Email"
                    />
                  </div>
                </Form.Item>
              </div>
            </div>
            <div className="md:mt-4 flex flex-col md:flex-row md:gap-6">
              <div className="w-full md:w-1/2">
                <Form.Item<FieldType>
                  name="phoneNumber"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.reject(
                            t("validate.PleaseInputPhoneNumber")
                          );
                        }
                        const reg =
                          /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/g;
                        const isValid = reg.test(value);
                        if (isValid) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            t("validate.ValidatePhoneNumber")
                          );
                        }
                      },
                    }),
                  ]}
                >
                  <div className="flex flex-col items-start gap-3">
                    <p className="text-sm font-medium text-black">
                      Số điện thoại
                      <span className="text-red"> *</span>
                    </p>
                    <Input
                      className="rounded-lg w-full h-10 border-black border-[0.5px]"
                      placeholder="Số điện thoại"
                    />
                  </div>
                </Form.Item>
                <Form.Item<FieldType>
                  name="refferalPhoneNumber"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.resolve();
                        }
                        const reg =
                          /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/g;
                        const isValid = reg.test(value);
                        if (isValid) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            t("validate.ValidatePhoneNumber")
                          );
                        }
                      },
                    }),
                  ]}
                >
                  <div className="flex flex-col items-start gap-3">
                    <p className="text-sm font-medium text-black">
                      {t("shopManagement.Referrer")}
                    </p>
                    <Input
                      className="rounded-lg w-full h-10 border-black border-[0.5px]"
                      placeholder={t("shopManagement.Referrer")}
                    />
                  </div>
                </Form.Item>
              </div>
              <div className="w-full md:w-1/2">
                <Form.Item<FieldType>
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: t("validate.PleaseInputPassword"),
                    },
                    {
                      min: 6,
                      message: t("validate.MinPassword"),
                    },
                  ]}
                >
                  <div className="flex flex-col items-start gap-3">
                    <p className="text-sm font-medium text-black">
                      {t("shopManagement.Password")}
                      <span className="text-red"> *</span>
                    </p>
                    <Input.Password
                      className="rounded-lg w-full h-10 border-black border-[0.5px]"
                      placeholder={t("shopManagement.Password")}
                    />
                  </div>
                </Form.Item>
              </div>
            </div>
          </div>
        </StyledCreateAccountShopForm>
        <div className="flex justify-center gap-8 py-8">
          <div>
            <button
              className="border border-red rounded px-10 py-2 bg-white text-sm font-medium leading-[24px] text-red hover:!text-white hover:bg-red hover:!border-red flex justify-center items-center"
              onClick={handleCloseCreated}
            >
              {t("button.Cancel")}
            </button>
          </div>
          <Form.Item>
            <button
              className="!border-none rounded px-10 py-2 bg-main-2 text-sm font-medium leading-[24px] text-white hover:bg-main-4 flex justify-center items-center"
              type="submit"
              disabled={loading}
            >
              {t("button.Save")}
            </button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default CreateAccountForm;
