import { useTranslation } from "react-i18next";
import CameraIcon from "@assets/image/shop-management/camera.svg";
import { Button, Checkbox, Input, Select, message } from "antd";
import { SectionAccount } from "./SectionAccount";
import { ShopAccount } from "@models/ShopAccount";
import {
  getDeliveryList,
  getUserGroup,
  updateShopInfo,
} from "@services/shop-services";
import { useEffect, useRef, useState } from "react";
import { IoCaretDown } from "react-icons/io5";
import { StyledSelect } from "./styles";
import HashtagList from "@components/hashtag-list";
import {
  attachHashtagForShop,
  deleteHashtagForShop,
  getTagList,
} from "@services/hashtag-services";
import { AUTHORIZE, HASHTAG_TYPE } from "constants/constants";
import { Tag } from "@models/TagModel";
import ResetPasswordModal from "./ResetPasswordModal";
import { useOutsideClick } from "@hooks/useOutsideClick";
import iconDropdown from "@assets/image/caret-back.svg";
import { useAuth } from "provider/auth";
import { getSubAccounts } from "@services/sub-account-service";
import { api } from "provider/api";

const { SHOP } = HASHTAG_TYPE;
const { EDIT } = AUTHORIZE;

const SHOP_TYPE = [
  { key: 1, value: "Cá nhân" },
  { key: 2, value: "Shop" },
];

const AccountInfo = ({ shopInfo }: { shopInfo: ShopAccount | undefined }) => {
  const { t } = useTranslation();
  const { organizations, user } = useAuth();
  const [hashtagList, setHashtagList] = useState<Tag[]>([]);
  const [userGroups, setUserGroups] = useState<any[]>([]);
  const [deleveries, setDeliveries] = useState<any[]>([]);
  const [userGroupId, setUserGroupId] = useState<number | undefined>();
  const [priceListId, setPriceListId] = useState<number | undefined>();
  const [subPartnerId, setSubPartnerId] = useState<number | undefined>();
  const [customerSource, setCustomerSource] = useState("");
  const [productType, setProductType] = useState("");
  const [orderWeight, setOrderWeight] = useState("");
  const [allowedPartners, setAllowedPartners] = useState<any[]>([]);
  const [fullName, setFullName] = useState("");
  const [isUsed, setIsUsed] = useState(false);
  const [isEnableReferral, setIsEnableReferral] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [identifyCard, setIdentifyCard] = useState<string | undefined>("");
  const [referenceId, setreferenceId] = useState<string | undefined>();
  const [typeId, setTypeId] = useState<number | undefined>();
  const [openDeliveries, setOpenDeliveries] = useState(false);
  const [managementAdmin, setManagementAdmin] = useState<any[]>([]);
  const [priceList, setPriceList] = useState<any>([]);

  const handleClickOutside = () => {
    setOpenDeliveries(false);
  };
  const ref: any = useOutsideClick(handleClickOutside);

  const handeUpdateShopInfo = async () => {
    let payload = {
      fullName,
      isUsed,
      isEnableReferral,
      identifyCard,
      referenceId,
      typeId,
      userGroupId,
      priceListId,
      orderWeight,
      productType,
      customerSource,
      subPartnerId,
      allowedPartners: allowedPartners?.map((item) => item?.id).join(","),
    } as any;
    if (shopInfo?.email !== email) {
      payload = { ...payload, email };
    }
    if (shopInfo?.phoneNumber !== phoneNumber) {
      payload = { ...payload, phoneNumber };
    }
    if (shopInfo?.id) {
      try {
        const res = await updateShopInfo(shopInfo?.id, payload);
        if (res) {
          message.success("Cập nhật thành công");
        }
      } catch (e) {
        message.error("Cập nhật không thành công");
      }
    }
  };

  const handleSelectDelivery = (item: any) => {
    const check = allowedPartners.find((ite: any) => ite.id === item.id);
    if (check) {
      const list = allowedPartners.filter((ite: any) => ite.id !== item.id);
      setAllowedPartners(list);
    } else {
      setAllowedPartners([...allowedPartners, item]);
    }
  };

  const getManagementAdmin = () => {
    const admin = managementAdmin.find((item) => item.id === subPartnerId);
    if (admin) {
      return admin?.fullName || admin?.userName;
    }
    if (user?.id === subPartnerId) {
      return user?.fullName || user?.userName;
    }
  };

  const [openResetPassword, setOpenResetPassword] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const res = await getTagList({
          page: 1,
          limit: 50,
          type: SHOP,
        });
        if (res) {
          setHashtagList(res.data);
        }
      } catch (e) {}
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const res = await getUserGroup();
        if (res) {
          setUserGroups(res);
        }
        const resPrice = await api.get("delivery-price/price-list");
        if (resPrice && resPrice.data) {
          setPriceList(resPrice.data?.response || []);
        }
        const resDeliveries = await getDeliveryList();
        if (resDeliveries) {
          setDeliveries(resDeliveries);
          const array = shopInfo?.allowedPartners?.split(",");
          const list = [];
          for (const item of array || []) {
            for (const obj of resDeliveries) {
              if (item.trim() === obj.id?.toString()) {
                list.push(obj);
              }
            }
          }
          setAllowedPartners(list);
        }
      } catch (e) {}
    })();
  }, [shopInfo]);

  useEffect(() => {
    if (shopInfo) {
      setPriceListId(shopInfo?.priceListId);
      setUserGroupId(shopInfo?.userGroupId);
      setCustomerSource(shopInfo?.customerSource);
      setSubPartnerId(shopInfo?.subPartnerId);
      setProductType(shopInfo?.productType);
      setOrderWeight(shopInfo?.orderWeight);
      // setAllowedPartners(shopInfo?.allowedPartners);
      setFullName(shopInfo?.fullName);
      setIsUsed(shopInfo?.isUsed);
      setIsEnableReferral(shopInfo?.isEnableReferral);
      setPhoneNumber(shopInfo?.phoneNumber);
      setEmail(shopInfo?.email);
      setIdentifyCard(shopInfo?.identifyCard);
      setreferenceId(shopInfo?.referenceId);
      setTypeId(shopInfo?.typeId);
    }
  }, [shopInfo]);

  useEffect(() => {
    (async () => {
      try {
        const payload = {
          page: 1,
          limit: 100,
        };
        const res = await getSubAccounts(payload);
        if (res && res.data) {
          setManagementAdmin(res.data?.response?.data);
        }
      } catch (e) {}
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SectionAccount title={t("accountDetails.AccountInfo")}>
      <div className="rounded-lg shadow-lg bg-white">
        <div className="p-4 md:p-6">
          <div className="md:flex gap-7 items-center">
            <div className="w-full md:w-1/2 flex justify-center items-center">
              <img
                src={
                  shopInfo?.imageUrl
                    ? `${process.env.REACT_APP_GO24_API}${shopInfo?.imageUrl}`
                    : CameraIcon
                }
                className="w-48 h-48 rounded-full"
                alt="no-data"
              />
            </div>
            <div className="w-full md:w-1/2">
              <div className="flex flex-col items-start gap-3">
                <p className="text-sm font-medium text-black">
                  {t("accountDetails.Account")}
                </p>
                <Input
                  disabled
                  className="rounded-lg w-full h-10 border-black border-[0.5px]"
                  placeholder={t("common.NoData")}
                  value={shopInfo?.userName}
                />
              </div>
              <div className="mt-6 flex flex-col items-start gap-3">
                <p className="text-sm font-medium text-black">
                  {t("accountDetails.Password")}
                </p>
                <div className="relative w-full">
                  <Input
                    className="rounded-lg h-10 border-black border-[0.5px]"
                    value="*********"
                  />
                  {organizations["manageShop"] === EDIT && (
                    <div
                      className="absolute right-3 top-[10px] text-sm text-blue-2 cursor-pointer"
                      onClick={() => setOpenResetPassword(true)}
                    >
                      Cập nhật
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 md:mt-6 md:flex gap-7">
            <div className="w-full md:w-1/2">
              <div className="flex flex-col items-start gap-4">
                <p className="text-sm font-medium text-black">
                  {t("accountDetails.ShopName")}
                </p>
                <Input
                  className="rounded-lg w-full h-10 border-black border-[0.5px]"
                  placeholder="Nhập tên shop"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
              </div>
            </div>
            <div className="md:w-1/2 mt-3">
              <div className="h-full t flex items-end gap-2 md:gap-4 pb-3">
                <Checkbox
                  className="flex items-center gap-2"
                  checked={isUsed}
                  onChange={(e) => setIsUsed(e.target.checked)}
                >
                  <span className="text-[12px] md:text-[16px]">Hoạt động</span>
                </Checkbox>
                <Checkbox
                  className="flex items-center gap-2"
                  checked={isEnableReferral}
                  onChange={(e) => setIsEnableReferral(e.target.checked)}
                >
                  <span className="text-[12px] md:text-[16px]">
                    Cho phép thưởng hoa hồng
                  </span>
                </Checkbox>
              </div>
            </div>
          </div>
          <div className="mt-4 md:mt-6 md:flex gap-7">
            <div className="md:w-1/2">
              <div className="flex flex-col items-start gap-4">
                <p className="text-sm font-medium text-black">
                  {t("accountDetails.PhoneNumber")}
                </p>
                <Input
                  className="rounded-lg w-full h-10 border-black border-[0.5px]"
                  placeholder="Nhập số điện thoại"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
              <div className="mt-6 flex flex-col items-start gap-4">
                <p className="text-sm font-medium text-black">
                  {t("accountDetails.CCCD")}
                </p>
                <Input
                  className="rounded-lg w-full h-10 border-black border-[0.5px]"
                  placeholder="Nhập CMND / CCCD"
                  value={identifyCard}
                  onChange={(e) => setIdentifyCard(e.target.value)}
                />
              </div>
              <div className="mt-6 flex flex-col items-start gap-4">
                <p className="text-sm font-medium text-black">
                  {t("accountDetails.ShopType")}
                </p>
                <StyledSelect
                  value={SHOP_TYPE.find((item) => item.key === typeId)?.value}
                  className="w-full h-10 text-start first:pr-4"
                  suffixIcon={<IoCaretDown className="text-lg" />}
                  onChange={(e: any) => setTypeId(e)}
                  placeholder="Nhập loại shop"
                >
                  {SHOP_TYPE.map((type) => (
                    <Select.Option key={type.key} value={type?.key}>
                      {type?.value}
                    </Select.Option>
                  ))}
                </StyledSelect>
              </div>
              <div className="mt-6 flex flex-col items-start gap-4">
                <p className="text-sm font-medium text-black">
                  {t("accountDetails.OrderWeight")}
                </p>
                <Input
                  className="rounded-lg w-full h-10 border-black border-[0.5px]"
                  placeholder="Nhập khối lượng đơn hàng"
                  value={orderWeight}
                  onChange={(e) => setOrderWeight(e.target.value)}
                />
              </div>
              <div className="mt-6 flex flex-col items-start gap-4">
                <p className="text-sm font-medium text-black">
                  {t("accountDetails.Tag")}
                </p>
                <div className="flex flex-nowrap whitespace-nowrap items-center px-2 h-10 border-black border-[0.5px] w-full rounded-lg">
                  {shopInfo?.tags && (
                    <HashtagList
                      dataChooseHashtagList={shopInfo.tags}
                      hashtagList={hashtagList}
                      recordId={shopInfo?.id}
                      deleteService={deleteHashtagForShop}
                      addService={attachHashtagForShop}
                      page="manageShop"
                    />
                  )}
                </div>
              </div>
              <div className="mt-6 flex flex-col items-start gap-4">
                <p className="text-sm font-medium text-black">
                  {t("accountDetails.ManagementAdmin")}
                </p>
                <StyledSelect
                  value={getManagementAdmin()}
                  className="w-full h-10 text-start first:pr-4"
                  suffixIcon={<IoCaretDown className="text-lg" />}
                  onChange={(e: any) => setSubPartnerId(e)}
                  placeholder="Nhập admin quản lý"
                  disabled={user?.id === subPartnerId}
                >
                  {managementAdmin.map((admin) => (
                    <Select.Option key={admin.id} value={admin?.id}>
                      {admin?.fullName || admin?.userName}
                    </Select.Option>
                  ))}
                </StyledSelect>
              </div>
            </div>
            <div className="mt-4 md:mt-0 md:w-1/2">
              <div className="flex flex-col items-start gap-4">
                <p className="text-sm font-medium text-black">
                  {t("accountDetails.Email")}
                </p>
                <Input
                  className="rounded-lg w-full h-10 border-black border-[0.5px]"
                  placeholder="Nhập email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mt-6 flex flex-col items-start gap-4">
                <p className="text-sm font-medium text-black">
                  {t("accountDetails.Referrer")}
                </p>
                <Input
                  disabled
                  className="rounded-lg w-full h-10 border-black border-[0.5px]"
                  placeholder="Không có dữ liệu"
                  value={shopInfo?.referenceName}
                  // onChange={(e) => setreferenceId(e.target.value)}
                />
              </div>
              <div className="mt-6 flex flex-col items-start gap-4">
                <p className="text-sm font-medium text-black">
                  Bảng giá áp dụng
                </p>
                <StyledSelect
                  value={
                    priceList?.find((item: any) => item.id === priceListId)
                      ?.name
                  }
                  className="w-full h-10 text-start first:pr-4"
                  suffixIcon={<IoCaretDown className="text-lg" />}
                  onChange={(e: any) => {
                    setPriceListId(e);
                    const res = priceList?.find(
                      (item: any) => item.id === e
                    )?.userGroupId;
                    if (res) setUserGroupId(res);
                  }}
                  placeholder="Nhập bảng giá áp dụng"
                >
                  {priceList?.map((group: any) => (
                    <Select.Option key={group.id} value={group?.id}>
                      {group?.name}
                    </Select.Option>
                  ))}
                </StyledSelect>
              </div>
              <div className="mt-6 flex flex-col items-start gap-4">
                <p className="text-sm font-medium text-black">
                  {t("accountDetails.ProductType")}
                </p>
                <Input
                  className="rounded-lg w-full h-10 border-black border-[0.5px]"
                  placeholder="Nhập loại sản phẩm"
                  value={productType}
                  onChange={(e) => setProductType(e.target.value)}
                />
              </div>
              <div className="mt-6 flex flex-col items-start gap-4">
                <p className="text-sm font-medium text-black">
                  {t("accountDetails.CustomerSource")}
                </p>
                <Input
                  className="rounded-lg w-full h-10 border-black border-[0.5px]"
                  placeholder="Nhập nguồn khách hàng"
                  value={customerSource}
                  onChange={(e) => setCustomerSource(e.target.value)}
                />
              </div>
              <div className="mt-6 flex flex-col items-start gap-4">
                <p className="text-sm font-medium text-black">
                  Đối tác giao hàng
                </p>
                <div className="relative w-full" ref={ref}>
                  <Input
                    className="rounded-lg h-10 border-black border-[0.5px]"
                    placeholder="Nhập đối tác giao hàng"
                    value={allowedPartners?.map(
                      (item: any) => `${item?.code} - ${item?.name}`
                    )}
                    onFocus={() => setOpenDeliveries(true)}
                    // onBlur={() => setOpenDeliveries(false)}
                    // onChange={(e) => setCustomerSource(e.target.value)}
                  />
                  <div className="absolute right-3 top-[10px]">
                    <img src={iconDropdown} width={20} height={20} alt="icon" />
                  </div>
                  {openDeliveries && (
                    <div className="absolute left-0 top-14 z-10 w-full h-[200px] overflow-auto rounded-lg bg-white shadow-md">
                      <div className="flex flex-col items-start px-2">
                        {deleveries.map((item) => {
                          return (
                            <div
                              className="flex gap-2 justify-between py-2 px-2 text-sm cursor-pointer hover:bg-gray-2 w-full rounded-md"
                              onClick={() => handleSelectDelivery(item)}
                            >
                              <span>{`${item?.code} - ${item?.name}`}</span>
                              <Checkbox
                                checked={
                                  allowedPartners?.find(
                                    (obj: any) => obj?.id === item?.id
                                  )
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {organizations["manageShop"] === EDIT && (
            <div className="mt-6 flex justify-center">
              <button
                className="!border-none rounded px-6 py-2 bg-main-2 text-sm font-medium leading-[24px] text-white hover:bg-main-4 flex justify-center items-center"
                onClick={handeUpdateShopInfo}
              >
                Cập nhập
              </button>
            </div>
          )}
        </div>
      </div>
      {openResetPassword && (
        <ResetPasswordModal
          open={openResetPassword}
          onCancel={() => setOpenResetPassword(false)}
          id={shopInfo?.userId}
        />
      )}
    </SectionAccount>
  );
};

export default AccountInfo;
