import { api } from "provider/api";

export const getDashboardShopByUserId = async (id: number, payload: any) => {
  try {
    const res = await api.get(`/dashboard/by-shop/${id}`, {
      params: payload,
    });
    if (res && res.data) {
      return res.data.response;
    }
    return undefined;
  } catch (e) {
    return undefined;
  }
};
