import { Checkbox, Select } from "antd";
import styled from "styled-components";

export const StyledCheckbox = styled(Checkbox)`
  // .ant-checkbox-checked .ant-checkbox-inner {
  //   background: #510086 !important;
  //   border-color: #510086 !important;
  // }
`;

export const StyledSelect = styled(Select)`
  width: 100%;
  .ant-select-selector {
    border: 0.5px solid #000 !important;

    &:hover {
      border-color: #4096ff !important;
    }
  }
`;
