import { Popover } from "antd";
import styled from "styled-components";

export const StyledPopover = styled(Popover)`
  .ant-popover-content .ant-popover-inner {
    padding: 0px !important;
  }
`;

export const StyledOrderBlock = styled.div`
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.22) !important;
`;

export const StyledRequestBlock = styled.div`
  box-shadow: 0 2px 2px 2px rgba(0,0,0,.22)!important;
`;
