import bgLoginImage from "@assets/image/bg-login.png";
import loginBanner from "@assets/image/login-banner.png";
import { Button, Checkbox, Form, Input } from "antd";
import { useAuth } from "provider/auth";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";

type FieldType = {
  userName?: string;
  password?: string;
  remember?: string;
};

export const Login: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { token, onLogin } = useAuth();
  const [reCaptchaToken, setReCaptchaToken] = useState("");
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const onFinish = (values: any) => {
    setTimeout(async () => {
      const { userName, password } = values;
      const res = await onLogin({ userName, password, reCaptchaToken });
      if (!res) setRefreshReCaptcha((r) => !r);
    }, 500);
  };

  const onVerify = useCallback((token: string) => {
    setReCaptchaToken(token);
  }, []);

  useEffect(() => {
    if (token) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <div
      className="bg-cover bg-center h-[100vh] w-full justify-center items-center flex"
      style={{ backgroundImage: `url(${bgLoginImage})` }}
    >
      <div className="w-[700px] p-[50px] bg-bg-color rounded-xl">
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          className="w-full"
        >
          <h2 className="font-semibold text-center text-2xl pb-[40px]">
            {t("login.Login")}
          </h2>
          <div className="flex flex-col md:flex-row gap-4">
            <div className="w-full md:w-1/2 h-auto bg-cover bg-center">
              <img src={loginBanner} alt="login-banner" />
            </div>
            <div className="w-full md:w-1/2 h-auto">
              <Form.Item<FieldType>
                name="userName"
                className="mb-[18px]"
                rules={[
                  {
                    required: true,
                    message: t("login.PleaseInputYourUsername"),
                  },
                ]}
              >
                <div>
                  <h2 className="text-left font-bold">
                    {t("login.Account")} <span className="text-red">*</span>
                  </h2>
                  <Input
                    className="rounded"
                    placeholder={t("login.Username")}
                  />
                </div>
              </Form.Item>
              <Form.Item<FieldType>
                name="password"
                className="mb-[18px]"
                rules={[
                  {
                    required: true,
                    message: t("login.PleaseInputYourPassword"),
                  },
                ]}
              >
                <div>
                  <h2 className="text-left font-bold">
                    {t("login.Password")} <span className="text-red">*</span>
                  </h2>
                  <Input.Password
                    className="rounded"
                    placeholder={t("login.Password")}
                  />
                </div>
              </Form.Item>
              <div className="flex flex-row w-full justify-between">
                <Form.Item<FieldType>
                  name="remember"
                  valuePropName="checked"
                  className="text-left h-auto my-[10px] text-sm"
                >
                  <Checkbox>{t("login.RememberPassword")}</Checkbox>
                </Form.Item>
                <div className="flex flex-row items-center">
                  <a className="px-2 text-blue-2" href="/reset-password">
                    {t("login.ForgotPassword")}
                  </a>
                </div>
              </div>
              <Form.Item>
                <Button
                  className="bg-yellow-1 text-black text-[13px] text-bold hover:bg-gray-2 hover:!text-yellow-1 hover:!border-yellow-1"
                  htmlType="submit"
                // loading={loading}
                >
                  {t("login.Login")}
                </Button>
              </Form.Item>
            </div>
          </div>
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_RECAPCHA_SITE_KEY as string}
          >
            <GoogleReCaptcha
              onVerify={onVerify}
              refreshReCaptcha={refreshReCaptcha}
            />
          </GoogleReCaptchaProvider>
        </Form>
      </div>
    </div>
  );
};
