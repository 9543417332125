import { Section } from "@components/section/Section";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa6";
import { StyledTab } from "./styles";
import CreateAccountForm from "./CreateAccountForm";
import { useEffect, useState } from "react";
import ShopList from "./ShopList";
import { useAuth } from "provider/auth";
import { AUTHORIZE } from "constants/constants";

const { EDIT } = AUTHORIZE;

const ShopManagement = () => {
  const { t } = useTranslation();
  const { organizations } = useAuth();
  const [isCreated, setIsCreated] = useState<boolean>(false);
  const [refreshData, setRefreshData] = useState<number>(new Date().getTime());

  const handleOpenCreated = () => {
    setIsCreated(true);
  };

  const handleCloseCreated = () => {
    setIsCreated(false);
  };

  const onRefreshData = () => {
    setRefreshData(new Date().getTime());
  };

  useEffect(() => {
    document.title = "Quản lý shop - Go24";
  }, []);

  return (
    <div className="flex flex-col gap-y-8">
      <Section title={t("contentTitle.shopManagement.ShopManagement")}>
        {isCreated && (
          <CreateAccountForm
            handleCloseCreated={handleCloseCreated}
            onRefreshData={onRefreshData}
          />
        )}
        <div className="flex gap-8 items-center">
          <StyledTab className="flex items-center justify-between px-4 bg-white w-full rounded-lg">
            <div className="text-base text-black font-normal px-2 py-2 md:py-4 border-b border-main-1 cursor-pointer">
              {t("shopManagement.ShopList")}
            </div>
            {!isCreated && organizations["manageShop"] === EDIT && (
              <button
                className="!border-none rounded px-2 md:px-6 py-1 md:py-2 bg-main-2 text-sm font-medium leading-[24px] text-white hover:bg-main-4 flex gap-2 justify-center items-center"
                onClick={handleOpenCreated}
              >
                <FaPlus className="text-xl md:text-2xl" />
                <span className="hidden md:block">
                  {t("button.AddAccount")}
                </span>
              </button>
            )}
          </StyledTab>
        </div>
        <ShopList refreshData={refreshData} setRefreshData={setRefreshData} />
      </Section>
    </div>
  );
};

export default ShopManagement;
