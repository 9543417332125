import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ChangeMenuTabState {
  tab: number;
}
const initialState: ChangeMenuTabState = {
  tab: 0,
}

const changeMenuTabsSlice  = createSlice({
  name: 'changeMenutab',
  initialState,
  reducers: {
    setMenuTab: (state, action: PayloadAction<number>) => {
      state.tab = action.payload;
    } 
  }
});

export const { setMenuTab } = changeMenuTabsSlice.actions;
export default changeMenuTabsSlice.reducer;