import Pagination from "@components/pagination";
import { Section } from "@components/section/Section";
import {
  deleteSubAccount,
  getSubAccounts,
  updateSubAccount,
} from "@services/sub-account-service";
import { useEffect, useState } from "react";
import { FaCaretUp, FaMagnifyingGlass, FaMinus, FaPlus } from "react-icons/fa6";
import NoDataIcon from "@assets/image/no-data.png";
import { Input, Select, Switch, message } from "antd";
import TrashRegular from "@assets/image/account-details/TrashRegular.svg";
import SolarPenIcon from "@assets/image/account-details/solar_pen-bold.svg";
import { IoCaretDown } from "react-icons/io5";
import { getAuthorityGroup } from "@services/authority-service";
import CreateSubAccount from "./CreateSubAccount";
import userAvatar from "@assets/image/shop-management/camera.svg";
import RoleDetailsModal from "./RoleDetailsModal";
import { PAGE_SIZE } from "constants/constants";

export const managementAreaList = ["Miền Bắc", "Miền Trung", "Miền Nam"];

const SubAccountDetails = () => {
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [authorityGroupId, setAuthorityGroupId] = useState<string | undefined>(
    undefined
  );
  const [managementArea, setManagementArea] = useState<string | undefined>(
    undefined
  );
  const [isAddingSubAccount, setIsAddingSubAccount] = useState(false);
  const [subAccounts, setSubAccounts] = useState<any[]>([]);
  const [roleGroup, setRoleGroup] = useState<any[]>([]);
  const [refreshData, setRefreshData] = useState(new Date().getTime());
  const [choosingSubAccount, setChoosingSubAccount] = useState<any>(undefined);
  const [openRoleDetails, setOpenRoleDetails] = useState(false);

  const handleSearch = () => {
    setPage(1);
    setRefreshData(new Date().getTime());
  };

  const handleEdit = async (data: any, isActive = true) => {
    try {
      if (data?.phoneNumber) {
        delete data.phoneNumber;
      }
      const payload = isActive
        ? { ...data }
        : {
          id: data?.id,
          isUsed: data?.isUsed ? false : true,
        };
      const res = await updateSubAccount(payload);
      if (res && res.data) {
        isActive && message.success("Cập tài khoản thành công");
        setRefreshData(new Date().getTime());
        setIsAddingSubAccount(false);
      }
    } catch (e: any) {
      message.error(e?.response?.data?.error);
    }
  };

  const handleOpenAddingSubAccount = () => {
    setChoosingSubAccount(undefined);
    setIsAddingSubAccount(true);
  };

  const handleCloseAddingSubAccount = () => {
    setIsAddingSubAccount(false);
  };

  const handleEditSubAccount = (data: any) => {
    setChoosingSubAccount(data);
    setIsAddingSubAccount(true);
  };

  const handleOpenRoleDetails = (data: any) => {
    setChoosingSubAccount(data);
    setOpenRoleDetails(true);
  };

  const handleDeleteSubAccount = async (id: number) => {
    if (id) {
      try {
        const res = await deleteSubAccount(id);
        if (res && res.data) {
          message.success("Xoá tài khoản thành công");
          setRefreshData(new Date().getTime());
        }
      } catch (e) {
        message.error("Xoá tài khoản thất bại");
      }
    }
  };

  const onFreshData = () => {
    setPage(1);
    setRefreshData(new Date().getTime());
    setIsAddingSubAccount(false);
  };

  useEffect(() => {
    (async () => {
      try {
        const payload = {
          page,
          limit: 100,
        };
        const res = await getAuthorityGroup(payload);
        if (res && res.data) {
          const activeData = res.data?.response?.data?.filter(
            (item: any) => item?.isUsed
          );
          setRoleGroup(activeData);
        }
      } catch (e) { }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const payload = {
          page,
          limit: PAGE_SIZE,
          searchText,
          authorityGroupId,
          managementArea,
        };
        const res = await getSubAccounts(payload);
        if (res && res.data) {
          setSubAccounts(res.data?.response?.data);
          setTotalPage(res.data?.response?.meta?.pageCount || 1);
        }
      } catch (e) { }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, refreshData]);

  return (
    <div className="mt-4 flex flex-col gap-4">
      <div className="bg-white rounded-lg shadow-lg">
        <div className="w-full">
          <div className="flex items-center justify-between border-b-[0.5px] px-4 py-2">
            <div className="flex gap-3">
              <p className="text-sm md:text-base text-black font-semibold">
                Tạo tài khoản nhân viên
              </p>
              <FaCaretUp
                size={22}
                color="#373737"
                className={`${isAddingSubAccount ? "" : "rotate-180"}`}
              />
            </div>
            <div>
              {isAddingSubAccount ? (
                <button
                  className="!border-none rounded w-full px-4 py-2 bg-main-2 text-sm font-medium leading-[24px] text-white hover:bg-main-4 flex justify-center items-center"
                  onClick={handleCloseAddingSubAccount}
                >
                  <span className="block md:hidden">
                    <FaMinus size={20} color="white" />
                  </span>
                  <span className="hidden md:block">Thu gọn</span>
                </button>
              ) : (
                <button
                  className="!border-none rounded w-full px-4 py-2 bg-main-2 text-sm font-medium leading-[24px] text-white hover:bg-main-4 flex justify-center items-center"
                  onClick={handleOpenAddingSubAccount}
                >
                  <FaPlus className="text-[20px] md:mr-2" />
                  <span className="hidden md:block">Thêm tài khoản</span>
                </button>
              )}
            </div>
          </div>
          {isAddingSubAccount && (
            <CreateSubAccount
              {...{
                roleGroup,
                handleCloseAddingSubAccount,
                onFreshData,
                choosingSubAccount,
                handleEdit,
              }}
            />
          )}
        </div>
      </div>
      <Section title="Thông tin tài khoản">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 items-end w-full bg-white px-4 py-2 rounded-lg shadow-lg">
          <div className="flex flex-col justify-start">
            <p className="font-semibold text-sm leading-none mb-2 text-start">
              Tìm kiếm
            </p>
            <div className="relative">
              <Input
                className="h-10 px-10"
                placeholder="Tìm kiếm"
                name={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <div className="absolute top-[10px] left-3">
                <FaMagnifyingGlass className="text-lg text-[#BFBFBF]" />
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-start">
            <p className="font-semibold text-sm leading-none mb-2 text-start">
              Nhóm quyền
            </p>
            <Select
              className="rounded-lg w-full h-10 text-start"
              suffixIcon={<IoCaretDown className="text-lg" />}
              defaultValue="Tất cả"
              onChange={(e) => setAuthorityGroupId(e)}
            >
              <Select.Option value={undefined}>Tất cả</Select.Option>
              {roleGroup.map((group) => (
                <Select.Option key={group?.id} value={group?.id}>
                  {group?.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="flex flex-col justify-start">
            <p className="font-semibold text-sm leading-none mb-2 text-start">
              Khu vực
            </p>
            <Select
              className="rounded-lg w-full h-10 text-start"
              suffixIcon={<IoCaretDown className="text-lg" />}
              defaultValue="Tất cả"
              onChange={(e) => setManagementArea(e)}
            >
              <Select.Option value={undefined}>Tất cả</Select.Option>
              {managementAreaList.map((type) => (
                <Select.Option key={type} value={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          </div>
          <button
            className="!border-none rounded md:w-[180px] h-10 py-2 bg-main-2 text-sm font-medium leading-[24px] text-white hover:bg-main-4 flex justify-center items-center"
            onClick={handleSearch}
          >
            <FaMagnifyingGlass className="text-lg mr-2" />
            <span>Tìm kiếm</span>
          </button>
        </div>
      </Section>
      <div className="shadow-lg overflow-auto">
        <div className="bg-gray-1 min-w-[1370px] px-4 py-2 flex flex-row justify-between items-center text-sm font-semibold rounded-tl-lg rounded-tr-lg">
          <div className="w-[50px] text-left">.No</div>
          <div className="w-[150px]">Ảnh đại diện</div>
          <div className="w-[120px]">Số điện thoại</div>
          <div className="w-[200px]">Họ và tên</div>
          <div className="w-[200px]">Email</div>
          <div className="w-[200px]">Khu vực quản lý</div>
          <div className="w-[200px]">Tên nhóm quyền</div>
          <div className="w-[200px]">Hành động</div>
        </div>
        {subAccounts?.length === 0 ? (
          <div className="col-span-12 xl:col-span-7 py-6 flex items-center justify-center">
            <img
              src={NoDataIcon}
              alt="no-data"
              className="w-[180px] h-[162px] md:w-[250px] md:h-[225px]"
            />
          </div>
        ) : (
          subAccounts?.map((data: any, index: number) => (
            <div
              key={data?.id}
              className="px-4 py-3 min-w-[1370px] flex flex-row justify-between items-center text-sm border-b last:border-transparent border-gray-1"
            >
              <div className="w-[50px] text-left">
                {PAGE_SIZE * (page - 1) + index + 1}
              </div>
              <div className="w-[150px] flex justify-center">
                {data?.imageUrl ? (
                  <img
                    src={`${process.env.REACT_APP_GO24_API}${data?.imageUrl}`}
                    className="w-10 h-10"
                    alt="avatar"
                  />
                ) : (
                  <img src={userAvatar} className="w-10 h-10" alt="avatar" />
                )}
              </div>
              <div className="w-[120px]">{data?.phoneNumber}</div>
              <div className="w-[200px]">{data?.fullName}</div>
              <div className="w-[200px]">{data?.email}</div>
              <div className="w-[200px]">{data?.managementArea}</div>
              <div className="w-[200px]">
                {data?.authorityGroupId && (
                  <div
                    className="text-blue-2 cursor-pointer"
                    onClick={() => handleOpenRoleDetails(data)}
                  >
                    {
                      roleGroup?.find(
                        (role) => role.id === data.authorityGroupId
                      )?.name
                    }
                  </div>
                )}
              </div>
              <div className="w-[200px]">
                <div className="flex items-center justify-center gap-4">
                  <Switch
                    className={data?.isUsed ? "!bg-main-1" : "!bg-gray-1"}
                    value={data?.isUsed}
                    onChange={(e) => handleEdit(data, false)}
                  ></Switch>
                  <div onClick={() => handleDeleteSubAccount(data?.id)}>
                    <img
                      src={TrashRegular}
                      alt=""
                      className="w-5 h-5 cursor-pointer"
                    />
                  </div>
                  <div onClick={() => handleEditSubAccount(data)}>
                    <img
                      src={SolarPenIcon}
                      alt=""
                      className="w-6 h-6 cursor-pointer"
                    />
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      <Pagination {...{ page, setPage, totalPage }} />
      <RoleDetailsModal
        open={openRoleDetails}
        onCancel={() => {
          setChoosingSubAccount(undefined);
          setOpenRoleDetails(false);
        }}
        roleGroup={roleGroup}
        id={choosingSubAccount?.authorityGroupId}
      />
    </div>
  );
};

export default SubAccountDetails;
