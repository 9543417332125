import React from "react";

type SectionProps = {
  title?: string;
  children?: React.ReactNode
}
export const Section: React.FC<SectionProps> = ({ title, ...props }) => {
  return (
    <div className="w-full">
      {title && <h3 className="font-semibold  text-sm md:text-lg leading-none mb-4 text-start">{title}</h3>}
      {props.children}
    </div>
  );
}
