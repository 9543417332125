import { go24Api } from "provider/api";

export const addOrderRequest = async (payload: any) => {
  const res = await go24Api.post("/orderrequest/add", { input: payload });
  if (res && res.data) {
    return res.data;
  }
  return undefined;
};

export const calculateRedeliveryFee = async (payload: any) => {
  const res = await go24Api.post("/order/calculate-redelivery-fee", {
    input: payload,
  });
  if (res && res.data) {
    return res.data;
  }
  return undefined;
};

export const hashReturned = async (orderId: number) => {
  const res = await go24Api.post(`/order/has-returned/${orderId}`, {});
  return res;
};

export const getCalculateOrderAmount = async (order: any) => {
  const res = await go24Api.post(`/order/calculate-order-amount`, {
    input: order,
  });
  return res;
};
