import { formatNumber } from "@helper/helper";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { Wrapper } from "./styles";
import { useMemo } from "react";

interface ITableCol {
  width: number;
  minWidth?: number;
  title: string;
  dataIndex: string;
  key: string;
}

interface ITable {
  column: (t: TFunction<"translation", undefined>) => ITableCol[];
  row: any[];
}

const RevenueTable = ({ column, row }: ITable) => {
  const { t } = useTranslation();

  const calculateMinWidthTable = useMemo(() => {
    return (
      column(t).reduce((minWidth, col) => minWidth + Number(col.minWidth), 0) +
      column(t).length * 8 +
      16
    );
  }, []);

  const convertNumber = (value: string | number, index: number) => {
    switch (index) {
      case 0:
        return `${value?.toLocaleString("vi-VN")} đơn`;
      case 1:
        return formatNumber(value);
      case 2:
        return formatNumber(value);
      case 3:
        return formatNumber(value);
      case 4:
        return `${value}%`;
      case 5:
        return `${value}%`;
      default:
        return value;
    }
  };

  return (
    <Wrapper>
      <div className="mt-4 max-w-[100%] overflow-x-auto rounded-lg">
        <div
          className="bg-white"
          style={{
            minWidth: `${calculateMinWidthTable}px`,
          }}
        >
          <div className="bg-gray-1 px-4 py-[10px] flex flex-row justify-between items-center gap-2 text-sm font-semibold">
            {column(t).map((column, i) => (
              <div
                key={i}
                style={{
                  width: `${column.width}%`,
                  minWidth: `${column.minWidth}px`,
                }}
              >
                {column.title}
              </div>
            ))}
          </div>
          <div className="px-4 text-sm font-normal">
            {row.map((row: any) => (
              <div className="flex items-center w-full gap-2 border-b last:border-none py-3" key={Math.random()}>
                {column(t).map((column, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        width: `${column.width}%`,
                        minWidth: `${column.minWidth}px`,
                      }}
                    >
                      {convertNumber(row[column.dataIndex], i)}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default RevenueTable;
