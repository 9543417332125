import React, { useEffect, useState } from 'react';

type ElementSizeProps = {
  width: number,
  height: number
}

const useElementSize = (elementRef: React.RefObject<any>) => {
  const [elementSize, setElementSize] = useState<ElementSizeProps>({
    width: 0,
    height: 0
  });

  useEffect(() => {
    const handleResize = () => {
      if (elementRef.current) {
        setElementSize({
          width: elementRef.current.offsetWidth,
          height: elementRef.current.offsetHeight
        });
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [elementRef]);

  return elementSize;
};

export default useElementSize