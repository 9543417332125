import { useState, useContext, createContext } from "react";
import { api, go24Api } from "./api";
import { useLocalStorage } from "@hooks/useLocalStorage";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import { roleGroupInitial } from "constants/constants";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const { t } = useTranslation();
  const { setItem, getItem, removeItem } = useLocalStorage();
  const [token, setToken] = useState(getItem("token") || "");
  const [user, setUser] = useState(undefined);

  const handleLogin = async ({ userName, password, reCaptchaToken }) => {
    try {
      const go24Res = await go24Api.post("/Authen/login", {
        username: userName,
        password,
        isRememberMe: false,
        token: reCaptchaToken,
      });
      if (go24Res && go24Res.data?.code === "Success") {
        setItem("go24-token", go24Res?.data?.data?.accessToken);
      } else {
        message.error(go24Res?.data?.friendlyMessage);
        return false;
      }
      const res = await api.post("/auth/sign-in", {
        userName,
        password,
      });
      if (res && res?.data) {
        const token = res?.data?.accessToken;
        const refresh = res?.data?.refreshToken;
        setItem("token", token);
        setItem("refresh", refresh);
        setToken(token);
      }
    } catch (e) {
      message.error(t("login.WrongAccount"));
    }
  };

  const handleSignUp = async ({ fullName, phone, address, password }) => {
    try {
      const res = await api.post("/auth/sign-up", {
        fullName,
        address,
        phoneNumber: phone,
        password,
        ownerId: "65ab44cacf906f1e618f4a6e",
      });
      if (res && res?.data) {
        const token = res?.data?.accessToken;
        const refresh = res?.data?.refreshToken;
        setItem("token", token);
        setItem("refresh", refresh);
        setToken(token);
        return token;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleLogout = () => {
    setToken("");
    removeItem("token");
    removeItem("refresh");
    setUser();
    removeItem("user");
    removeItem("go24-token");
    window.location.pathname = "/login";
  };

  const value = {
    token,
    setToken,
    user,
    setUser,
    onSignUp: handleSignUp,
    onLogin: handleLogin,
    onLogout: handleLogout,
    organizations:
      (user?.authorityGroupDto &&
        JSON.parse(user?.authorityGroupDto?.organizationStr)) ||
      roleGroupInitial,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
