import React, { useState } from "react";
import logo from "@assets/image/menu-icons/Logo-go24.svg";
import shortLogo from "@assets/image/menu-icons/Logo-short.svg";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import overviewIcon from "@assets/image/menu-icons/generalIcon.png";
import requestManagementIcon from "@assets/image/menu-icons/requestManageIcon.png";
import orderManagementIcon from "@assets/image/menu-icons/mangeOrdersIcon.png";
import masterDataIcon from "@assets/image/menu-icons/master-data.svg";
import deliveryPriceIcon from "@assets/image/menu-icons/price-deliveries.svg";
import eployeeIcon from "@assets/image/menu-icons/employee.svg";
import shopsManagementIcon from "@assets/image/menu-icons/mangeShopIcon.png";
import settingTagsIcon from "@assets/image/menu-icons/settingsTagIcon.svg";
import settingAccountIcon from "@assets/image/menu-icons/settingsAccountIcon.png";
import { useLocation, useNavigate } from "react-router-dom";
import { FaCaretUp } from "react-icons/fa6";
import { IoMenu } from "react-icons/io5";
import { formatNumber } from "@helper/helper";
import { useOutsideClick } from "@hooks/useOutsideClick";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

type SidebarProps = {
  isOpen: boolean;
  handleToogleSidebar: () => void;
  setIsOpen: any;
};
type menuListProps = {
  id: number;
  menuIcon: string;
  menuName: string;
  url: string;
  childs?: menuListProps[];
};

const menuList: menuListProps[] = [
  {
    id: 0,
    menuIcon: overviewIcon,
    menuName: "menuList.Overview",
    url: "/",
  },
  {
    id: 1,
    menuIcon: requestManagementIcon,
    menuName: "menuList.RequestsManagement",
    url: "/request-management",
  },
  {
    id: 2,
    menuIcon: orderManagementIcon,
    menuName: "menuList.OrdersManagement",
    url: "/orders-management",
  },
  {
    id: 3,
    menuIcon: masterDataIcon,
    menuName: "Master data",
    url: "#",
    childs: [
      {
        id: 0,
        menuIcon: deliveryPriceIcon,
        menuName: "Giá giao",
        url: "/delivery-price",
      },
      {
        id: 1,
        menuIcon: eployeeIcon,
        menuName: "Nhân viên",
        url: "/sub-account",
      },
    ],
  },
  {
    id: 4,
    menuIcon: shopsManagementIcon,
    menuName: "menuList.ShopsManagement",
    url: "/shop-management",
  },
  {
    id: 5,
    menuIcon: settingTagsIcon,
    menuName: "menuList.TagsSetting",
    url: "/tags-setting",
  },
  {
    id: 6,
    menuIcon: settingAccountIcon,
    menuName: "Cài đặt tài khoản",
    url: "/account-setting",
  },
];

export const Sidebar: React.FC<SidebarProps> = ({
  handleToogleSidebar,
  isOpen,
  setIsOpen,
}) => {
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1024px)" });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const ref: any = useOutsideClick(() => {
    // if (isOpen) {
    //   setIsOpen(false);
    // }
  });
  const [activeChild, setActiveChild] = useState("");
  const accountBalance = 2031234;

  const handleNavigate = (item: menuListProps) => {
    if (item.menuName !== activeChild) setActiveChild("");
    if (item?.childs) {
      if (activeChild === "") setActiveChild(item?.menuName);
      if (!isDesktopOrLaptop) return;
    } else {
      if (!isDesktopOrLaptop) {
        setTimeout(() => {
          setIsOpen(false);
        }, 300);
      }
    }
  };

  const handleNavigateChild = () => {
    if (!isDesktopOrLaptop) {
      setTimeout(() => {
        setIsOpen(false);
      }, 300);
    }
  };

  return (
    <div
      className={`bg-main-2 h-full pt-6 absolute top-0 z-10 flex flex-col ${
        isOpen ? " block tran" : " hidden lg:block"
      } `}
      style={{ width: isOpen ? "230px" : "100px" }}
      ref={ref}
    >
      <div className="transition-all delay-100 duration-200 ease-in-out overflow-hidden flex flex-col h-[80vh]">
        <div className="flex items-center justify-center w-full mb-4">
          <div
            className={`w-[100px] cursor-pointer ${
              isOpen ? "w-[166px] mb-3" : "px-4"
            } transition-all duration-200 ease-in-out`}
            onClick={() => navigate("/")}
          >
            <img
              src={logo}
              className={isOpen ? "block" : "hidden"}
              alt="go24-logo"
            />
            <img
              src={shortLogo}
              className={!isOpen ? "block" : "hidden"}
              alt="go24-logo"
            />
          </div>
          <button
            onClick={handleToogleSidebar}
            className=" w-[24px] border-none p-0 lg:hidden"
          >
            <IoMenu className="w-[24px] h-[24px] text-white" />
          </button>
        </div>
        <div className="flex flex-col flex-1">
          {menuList.map((menuItem) => (
            <Link
              key={menuItem.id}
              to={menuItem.url}
              onClick={() => handleNavigate(menuItem)}
            >
              <Tooltip title={!isOpen && t(menuItem.menuName)}>
                <div
                  className={`flex flex-row px-[23.5px] py-[16px] hover:bg-yellow-1 transition duration-150 cursor-pointer ${
                    ((menuItem.url &&
                      location.pathname.includes(menuItem.url) &&
                      menuItem.url !== "/") ||
                      (location.pathname === "/" && menuItem.url === "/") ||
                      activeChild === menuItem.menuName) &&
                    "bg-yellow-1"
                  } ${!isOpen && "justify-center"}`}
                >
                  <div className="flex justify-center items-center min-w-[30px] max-w-[30px] h-[25px]">
                    <img
                      src={menuItem.menuIcon}
                      className="object-fill"
                      alt="menu-icon"
                    />
                  </div>
                  <div
                    className={`flex flex-row w-full items-center text-base ml-4 text-white transition-all duration-200 ease-in-out opacity-0 text-nowrap ${
                      isOpen ? "delay-100 opacity-100" : "hidden"
                    }`}
                  >
                    <p>{t(menuItem.menuName)}</p>
                  </div>
                  {menuItem?.childs && isOpen && (
                    <div
                      className={`transition ${
                        activeChild === menuItem.menuName ? "" : "rotate-180"
                      }`}
                    >
                      <FaCaretUp size={22} color="white" />
                    </div>
                  )}
                </div>
                {activeChild === menuItem.menuName &&
                  isOpen &&
                  menuItem?.childs?.map((item) => (
                    <Link to={item.url} key={item.id}>
                      <div
                        className={`flex flex-row px-[23.5px] py-[16px] bg-main-3 hover:bg-yellow-2 transition duration-150 cursor-pointer ${
                          item?.url &&
                          location.pathname.includes(item?.url) &&
                          "bg-yellow-2"
                        } justify-center`}
                        onClick={() => handleNavigateChild()}
                      >
                        <div className="flex justify-center items-center min-w-[30px] max-w-[30px] h-[25px]">
                          <img
                            src={item.menuIcon}
                            className="object-fill"
                            alt="menu-icon"
                          />
                        </div>
                        <div
                          className={`flex flex-row w-full items-center text-[16px] ml-4 text-white transition-all duration-200 ease-in-out text-nowrap`}
                        >
                          <p>{t(item.menuName)}</p>
                        </div>
                      </div>
                    </Link>
                  ))}
              </Tooltip>
            </Link>
          ))}
        </div>
        {/* <div className="px-3 lg:hidden">
          <div className="flex mb-2">
            <button className="mr-2 min-w-[100px] border-yellow-2 bg-main-2 text-yellow-2 font-semibold border leading-none py-2 px-6 rounded hover:!border-white hover:bg-yellow-2 hover:!text-white">
              {t("header.TopUp")}
            </button>
            <button className="min-w-[100px] border-yellow-2 bg-main-2 text-yellow-2 font-semibold border leading-none py-2 px-6 rounded hover:!border-white hover:bg-yellow-2 hover:!text-white">
              {t("header.Withdraw")}
            </button>
          </div>

          <div className="bg-yellow-2 rounded py-2 px-6 text-sm leading-normal">
            {t("header.AccountBalance")}:{" "}
            <span className="font-semibold">
              {formatNumber(accountBalance)}
            </span>
          </div>
        </div> */}
      </div>
    </div>
  );
};
