import { ShopLocation } from "@models/ShopLocation";
import { api } from "provider/api";

export const getShopLocationList = async (shopId: number) => {
  try {
    const payload = {
      page: 1,
      limit: 25,
      shopId,
    };
    const res = await api.get("/shop-location", { params: payload });
    if (res && res.data) {
      return res.data?.response?.data;
    }
    return undefined;
  } catch (e) {
    return undefined;
  }
};

export const createShopLocation = async (payload: any) => {
  const res = await api.post("/shop-location", payload);
  if (res && res.data) {
    return res.data;
  }
  return undefined;
};

export const updateShopLocation = async (payload: ShopLocation) => {
  const res = await api.put(`/shop-location/${payload.id}`, payload);
  if (res && res.data) {
    return res.data;
  }
  return undefined;
};

export const deleteShopLocation = async (id: number) => {
  const res = await api.delete(`/shop-location/${id}`);
  if (res && res.data) {
    return res.data;
  }
  return undefined;
};
