import { Select } from "antd";
import styled from "styled-components";

export const StyledSelect = styled(Select)`
  width: 100%;
  .ant-select-selector {
    border: 0.5px solid #d9d9d9 !important;

    &:hover {
      border-color: #4096ff !important;
    }
  }
`;

export const CustomForm = styled.div`
  height: 80%;
  .ant-form-item {
    margin-bottom: 0.625rem;
  }
  .ant-input-outlined[disabled] {
    background-color: transparent;
    cursor: default;
  }
  .ant-input-number-input-wrap {
    height: 100%;
    input {
      height: 100%;
    }
  }
`;