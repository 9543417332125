import {
  formatDate,
  formatNumber,
  renderCODStatus,
  renderOrderPaidStatus,
  renderOrderStatus,
} from "@helper/helper";
import OrderDetails from "@pages/order-management/OrderDetails";
import OrderTracking from "@pages/order-management/OrderTracking";
import { getDeliveryList } from "@services/shop-services";
import {
  AUTHORIZE,
  DATE_FORMAT,
  ORDER_STATUS,
  roleGroupInitial,
} from "constants/constants";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SendRequestModal from "./SendRequestModal";
import { getOrderDetails } from "@services/order-service";
import OrderRequest from "@pages/order-management/OrderRequest";
import EditOrderModal from "@pages/order-management/EditOrderModal";
import RequestModal from "@pages/order-management/RequestModal";
import { useAuth } from "provider/auth";

interface IRequestDetails {
  request: any;
  handleCancel: () => void;
  setRefreshData: any;
}

const { EDIT } = AUTHORIZE;

const RequestDetails = ({
  request,
  handleCancel,
  setRefreshData,
}: IRequestDetails) => {
  const { t } = useTranslation();
  const { organizations } = useAuth();
  const [openOrderTracking, setOpenOrderTracking] = useState(false);
  const [deliveries, setDeliveries] = useState<any[]>([]);
  const [openOrderDetails, setOpenOrderDetails] = useState(false);
  const [openSendingRequest, setOpenSendingRequest] = useState(false);
  const [orderDetails, setOrderDetails] = useState<any>();
  const [openRequest, setOpenRequest] = useState<any>("");
  const [openEditOrder, setOpenEditOrder] = useState(false);

  const statusAlowEdit = [
    ORDER_STATUS.WaitingConfirm.code,
    ORDER_STATUS.WaitingTakeOrder.code,
    ORDER_STATUS.Delivering.code,
    ORDER_STATUS.PickedUp.code,
    ORDER_STATUS.Transporting.code,
    ORDER_STATUS.Stored.code,
    ORDER_STATUS.DeliveryFailed.code,
  ];

  const allowEditOrder = (id: string) => {
    return statusAlowEdit.includes(id);
  };

  useEffect(() => {
    (async () => {
      const res = await getDeliveryList();
      setDeliveries(res);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (request?.orderId) {
        const res = await getOrderDetails(request?.orderId);
        if (res) {
          setOrderDetails(res);
        }
      }
    })();
  }, [request?.orderId]);

  return (
    <div className="overflow-x-auto text-sm text-left rounded-lg bg-gray-1 shadow-lg">
      <div className="flex border-b flex-wrap border-transparent lg:border-gray-2 justify-between items-end md:items-start pr-2 p-4 md:gap-x-2 text-sm leading-none ">
        <div className="flex flex-col gap-y-2 w-1/2 md:w-auto  border-b-[2px] md:border-0 pb-4">
          <p className="font-semibold text-blue-4 w-full break-words">
            {orderDetails?.code}
          </p>
          <p className="font-semibold text-black-1 w-full">
            {orderDetails?.shopUsername}
          </p>
          <p className="font-semibold w-full">
            {orderDetails?.shopPhoneNumber}
          </p>
          <p className="w-full">
            {formatDate(orderDetails?.createdDate, DATE_FORMAT)}
          </p>
        </div>
        <div className="flex flex-col gap-y-2 w-1/2 md:w-auto border-b-[2px] md:border-0 text-end md:text-start pb-4 items-end md:items-start">
          <p className="font-semibold text-black-1 w-full">
            {orderDetails?.fullName}
          </p>
          <p className="w-full font-semibold">{orderDetails?.phoneNumber}</p>
        </div>
        <div className="flex flex-col gap-y-2 w-1/2 md:w-auto  border-b-[2px] md:border-0 py-4 md:py-0">
          <p className="font-semibold text-blue-4 w-full break-words">
            {orderDetails?.deliveryCode
              ? orderDetails?.deliveryCode
              : `(${t("RequestsManagement.NotCreateOrder")})`}
          </p>
          <p className="font-semibold text-red-3 w-full">
            {
              deliveries.find(
                (item) => item.id === orderDetails?.partnerDeliveryId
              )?.name
            }
          </p>
        </div>
        <div className="flex flex-col gap-1 text-end md:text-center w-1/2 md:w-auto border-b-[2px] md:border-0 py-4 md:py-0">
          <p className="font-semibold text-black-1 w-full">
            {t(
              `orderStatus.${renderOrderStatus(
                orderDetails?.statusId?.toString()
              )}`
            )}
          </p>
          {(orderDetails?.statusId === 3 || orderDetails?.statusId === 12) && (
            <p className="w-full">
              {formatDate(orderDetails?.deliveredDate, DATE_FORMAT)}
            </p>
          )}
          {(orderDetails?.statusId === 5 || orderDetails?.statusId === 13) && (
            <p className="w-full">
              {formatDate(orderDetails?.returnedDate, DATE_FORMAT)}
            </p>
          )}
        </div>
        <div className="md:text-center w-1/2 md:w-auto mt-2 md:mt-0 py-4 border-b-[2px] md:border-0 md:py-0">
          <p className="font-semibold text-black-1 w-full">
            {t(
              `orderPaidStatus.${renderOrderPaidStatus(
                orderDetails?.paidStatusId?.toString()
              )}`
            )}
          </p>
          {orderDetails?.paidStatusId === 3 && (
            <p className="w-full">
              {formatDate(orderDetails?.paidDate, DATE_FORMAT)}
            </p>
          )}
        </div>
        <div className="text-end md:text-center w-1/2 md:w-auto mt-2 py-4 border-b-[2px] md:border-0 md:py-0 md:mt-0">
          <p className="font-semibold text-black-1 w-full">
            {t(
              `CODStatus.${renderCODStatus(
                orderDetails?.codStatusId?.toString()
              )}`
            )}
          </p>
          {orderDetails?.codStatusId === 2 && (
            <p className="w-full">
              {formatDate(orderDetails?.paidpaidCODDate, DATE_FORMAT)}
            </p>
          )}
        </div>
      </div>
      <div className="flex flex-wrap gap-y-4 justify-between items-end pl-4 pr-2 md:p-4">
        <div className="flex items-centers md:gap-12 flex-wrap w-full lg:w-auto border-b-[2px] md:border-0 pb-2 md:justify-between">
          <div className="flex flex-row gap-x-2 w-full md:w-auto">
            <div className="flex flex-col gap-y-1 w-1/2 md:w-[300px]">
              <p className="font-semibold text-black-1 w-full">
                {t("table.tableContent.ShopOrderCode")}
              </p>
              <p className="text-black-1 w-full">
                {t("table.tableContent.TotalRevenue")}:{" "}
                {formatNumber(orderDetails?.totalTakenCustomerAmount)}
              </p>
            </div>
            <div className="flex flex-col gap-y-1 justify-end w-1/2 text-end md:w-auto">
              <p className="text-black-1 w-full">
                {t("table.tableContent.PaymentToShop")}:{" "}
                <span className="font-semibold text-red-3">
                  {formatNumber(orderDetails?.totalPaidShopAmount)}
                </span>
              </p>
            </div>
          </div>
          <div className="w-full md:w-auto md:flex text-center md:items-end">
            <button onClick={() => setOpenOrderTracking(true)}>
              <span className="text-blue-2">
                {t("RequestsManagement.ViewItineraryOrder")}
              </span>
            </button>
          </div>
        </div>
        <div className="flex gap-2 w-full lg:w-auto items-center justify-center pb-2">
          <button
            className="h-8 flex justify-center items-center px-4 rounded bg-main-3 text-white"
            onClick={() => setOpenOrderDetails(true)}
          >
            {t("table.tableButton.Details")}
          </button>
          {allowEditOrder(orderDetails?.statusId?.toString()) &&
            organizations["manageRequest"] === EDIT && (
              <button
                className="h-8 flex justify-center items-center px-4 rounded bg-main-3 text-white"
                onClick={() => setOpenEditOrder(true)}
              >
                Sửa
              </button>
            )}
          {orderDetails && organizations["manageRequest"] === EDIT && (
            <OrderRequest
              order={orderDetails}
              openRequest={openRequest}
              setOpenRequest={setOpenRequest}
              sendingRequest={() => {}}
              editOrderFunc={() => {
                setOrderDetails({ ...orderDetails, isRenew: true });
                setOpenEditOrder(true);
              }}
              customButton
            />
          )}
        </div>
      </div>
      {openOrderTracking && (
        <OrderTracking
          open={openOrderTracking}
          onCancel={() => setOpenOrderTracking(false)}
          order={{
            id: request?.orderId,
            deliveryCode: request?.deliveryCode,
            partnerId: request?.PartnerDeliveryId,
            statusId: request?.statusId,
          }}
        />
      )}
      {openOrderDetails && (
        <OrderDetails
          open={openOrderDetails}
          onCancel={() => setOpenOrderDetails(false)}
          id={request?.orderId}
        />
      )}
      {openSendingRequest && (
        <SendRequestModal
          open={openSendingRequest}
          onCancel={() => setOpenSendingRequest(false)}
          order={request}
        />
      )}
      {openEditOrder && (
        <EditOrderModal
          open={openEditOrder}
          onCancel={() => setOpenEditOrder(false)}
          order={orderDetails}
          onRefreshData={() => {
            handleCancel();
            setRefreshData(new Date().getTime());
          }}
        />
      )}
      {openRequest?.value && (
        <RequestModal
          type={openRequest}
          onCancel={() => setOpenRequest("")}
          order={orderDetails}
        />
      )}
    </div>
  );
};

export default RequestDetails;
