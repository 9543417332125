import { Button, Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

type FieldType = {
  phonenumber?: string;
};

type PhoneNumberFromProps = {
  handleChangeStep: () => void;
}

export const PhoneNumberForm: React.FC<PhoneNumberFromProps> = ({handleChangeStep}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const onFinish = (values: any) => {
    console.log(form, values);
  };

  return (
    <Form
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      autoComplete="off"
      layout='vertical'
      className="w-full"
    >
      <Form.Item<FieldType>
        name="phonenumber"
        className='mb-[18px]'
        rules={[{ required: true, message: t("login.PleaseInputYourPhoneNumber") }]}
      >
        <div>
          <h2 className="text-left font-bold">{t("login.ShopPhoneNumber")} <span className='text-red'>*</span></h2>
          <Input className="rounded" placeholder={t("login.PhoneNumber")} />
        </div>
      </Form.Item>
      <Form.Item className='mb-1'>
        <Button 
          className="bg-yellow-1 text-black text-[13px] text-bold hover:bg-gray-2 hover:!text-yellow-1 hover:!border-yellow-1 w-full" 
          htmlType="submit"
          onClick={handleChangeStep}
          >
          {t("login.Next")}
        </Button>
      </Form.Item>
    </Form>
  );
}
