import { api } from "provider/api";

export const getSubAccounts = async (payload: any) => {
  const res = await api.get("/partner/get-sub-accounts", { params: payload });
  if (res) {
    return res;
  }
};

export const createSubAccount = async (payload: any) => {
  const res = await api.post("/partner/create-sub-account", payload);
  if (res) {
    return res;
  }
};

export const updateSubAccount = async (payload: any) => {
  const res = await api.put(
    `/partner/${payload?.id}/sub-account-info`,
    payload
  );
  if (res) {
    return res;
  }
};

export const deleteSubAccount = async (id: number) => {
  const res = await api.delete(`/partner/${id}/sub-account`);
  if (res) {
    return res;
  }
};
