import { TFunction } from "i18next";

export const RevenueColumn = (t: TFunction<"translation", undefined>) => [
  {
    title: t("ShopRevenueReport.TotalOrder"),
    dataIndex: "totalOrder",
    key: "totalOrder",
    width: 15,
    minWidth: 150,
  },
  {
    title: t("ShopRevenueReport.TotalDeliveryServiceFee"),
    dataIndex: "totalDeliveryServiceFee",
    key: "totalDeliveryServiceFee",
    width: 20,
    minWidth: 250,
  },
  {
    title: t("ShopRevenueReport.TotalCOD"),
    dataIndex: "totalCOD",
    key: "totalCOD",
    width: 15,
    minWidth: 150,
  },
  {
    title: t("ShopRevenueReport.TotalCODAdvance"),
    dataIndex: "totalCODAdvance",
    key: "totalCODAdvance",
    width: 15,
    minWidth: 150,
  },
  {
    title: t("ShopRevenueReport.PartiallyDeliveredRatio"),
    dataIndex: "partiallyDeliveredRatio",
    key: "partiallyDeliveredRatio",
    width: 20,
    minWidth: 200,
  },
  {
    title: t("ShopRevenueReport.RefundRatio"),
    dataIndex: "refundRatio",
    key: "refundRatio",
    width: 15,
    minWidth: 150,
  },
];

export const RevenueRow = [
  {
    totalOrder: 100000,
    totalDeliveryServiceFee: 30000000,
    totalCOD: 20000000,
    totalCODAdvance: 15000000,
    partiallyDeliveredRatio: 20,
    refundRatio: 10,
  },
];
