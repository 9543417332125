import { DatePicker, Input, Select, message } from "antd";
import { PAGE_SIZE, STATUS_KYC } from "constants/constants";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { IoCalendarNumberOutline, IoCaretDown } from "react-icons/io5";
import { StyledContentTableWrapper, StyledFilterWrapper } from "./styles";
import { shopListColumns } from "./dataSources";
// import { useAuth } from "provider/auth";
import {
  Dispatch,
  KeyboardEvent,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { getShopList, getUserGroup } from "@services/shop-services";
import Pagination from "@components/pagination";
import { ShopAccount } from "@models/ShopAccount";
import ShopItem from "./ShopItem";
import { Tag } from "@models/TagModel";
import { getTagList } from "@services/hashtag-services";
import TagIcon from "@assets/svg/Tag";
import NoDataIcon from "@assets/image/no-data.png";

import { HASHTAG_TYPE } from "constants/constants";

const { SHOP } = HASHTAG_TYPE;

const ShopList = ({
  refreshData,
  setRefreshData,
}: {
  refreshData: number;
  setRefreshData: Dispatch<SetStateAction<number>>;
}) => {
  const { t } = useTranslation();
  const dateFormat = "YYYY-MM-DD";
  const [shopList, setShopList] = useState<ShopAccount[]>([]);
  const [from, setFrom] = useState<any>(
    dayjs(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format(
      dateFormat
    )
  );
  const [to, setTo] = useState<any>(dayjs(new Date()).format(dateFormat));
  const [hashtag, setHashtag] = useState<number | string>();
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [hashtagList, setHashtagList] = useState<Tag[]>([]);
  const [selectedShop, setSelectedShop] = useState<ShopAccount | undefined>();
  const [searchText, setSearchText] = useState("");
  const [statusKYC, setStatusKYC] = useState<string | undefined>(undefined);
  const [userGroups, setUserGroups] = useState<any[]>([]);
  const [userGroupId, setUserGroupId] = useState<string | undefined>(undefined);
  const [lastOrderDate, setLastOrderDate] = useState(undefined);

  const handleFilterShopList = () => {
    setPage(1);
    setRefreshData(new Date().getTime());
  };

  const handleEnterSearchText = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      setPage(1);
      setRefreshData(new Date().getTime());
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await getTagList({
          page: 1,
          limit: 50,
          type: SHOP,
        });
        if (res) {
          const activeTagList = res.data?.filter((item: any) => item?.isActive);
          setHashtagList(activeTagList);
        }
      } catch (e) {}
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const res = await getUserGroup();
        if (res) {
          setUserGroups(res);
        }
      } catch (e) {}
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const payload = {
          page,
          limit: PAGE_SIZE,
          from,
          to,
          dateType: "createdDate",
          hashtagId: hashtag,
          statusKYC,
          userGroupId,
          lastOrderDate,
          searchText,
        };
        const res = await getShopList(payload);
        if (res) {
          setShopList(res.data);
          setTotalPage(res.meta.pageCount || 1);
        }
      } catch (e) {
        setShopList([]);
        setTotalPage(1);
        message.error("Lỗi hệ thống, vui lòng thử lại");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, refreshData]);

  return (
    <div className="flex flex-col items-start">
      <div className="mt-4 flex gap-4 bg-white w-full py-2 px-4 rounded-lg shadow-lg">
        <div className="grid grid-cols-2 md:grid-cols-3 gap-2 lg:gap-4 w-full">
          <div className="w-full">
            <p className="font-semibold text-sm leading-none mb-2 text-start">
              {t("filter.fieldTitle.From")}
            </p>
            <DatePicker
              defaultValue={dayjs(from, dateFormat)}
              format={dateFormat}
              className="w-full h-10 px-4"
              suffixIcon={
                <IoCalendarNumberOutline className="text-2xl text-black" />
              }
              onChange={(date, dateString: any) => {
                setFrom(dateString);
              }}
              disabledDate={(current) => {
                let customDate = dayjs(to).format("YYYY-MM-DD");
                return current && current > dayjs(customDate, "YYYY-MM-DD");
              }}
              allowClear={false}
            />
          </div>
          <div className="w-full">
            <p className="font-semibold text-sm leading-none mb-2 text-start">
              {t("filter.fieldTitle.To")}
            </p>
            <DatePicker
              defaultValue={dayjs(to, dateFormat)}
              format={dateFormat}
              className="w-full h-10 px-4"
              suffixIcon={
                <IoCalendarNumberOutline className="text-2xl text-black" />
              }
              onChange={(date, dateString: any) => {
                setTo(dateString);
              }}
              disabledDate={(current) => {
                let customDate = dayjs(from).format("YYYY-MM-DD");
                return current && current < dayjs(customDate, "YYYY-MM-DD");
              }}
              allowClear={false}
            />
          </div>
          <div className="w-full">
            <p className="font-semibold text-sm leading-none mb-2 text-start">
              {t("filter.fieldTitle.Hasgtag")}
            </p>
            <Select
              defaultValue={t("filter.fieldContent.All")}
              className="w-full h-10 text-start first:pr-4"
              suffixIcon={<IoCaretDown className="text-lg" />}
              onChange={(value) => setHashtag(value)}
            >
              <Select.Option value={undefined}>
                {t("filter.fieldContent.All")}
              </Select.Option>
              {hashtagList.map((hashtag) => (
                <Select.Option key={hashtag.id} value={hashtag.id}>
                  <div className="flex gap-2 items-center py-1">
                    <div>
                      <TagIcon
                        className="w-4 h-4"
                        style={{ color: hashtag.colorCode }}
                      />
                    </div>
                    <p className="truncate">{hashtag.hashtag}</p>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="w-full">
            <p className="font-semibold text-sm leading-none mb-2 text-start">
              Từ khoá
            </p>
            <Input
              className="rounded-lg w-full h-10 border-[0.5px]"
              placeholder="Mã khách hàng, họ tên, số điện thoại"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => handleEnterSearchText(e)}
            />
          </div>
          <div className="w-full">
            <p className="font-semibold text-sm leading-none mb-2 text-start">
              Trạng thái KYC
            </p>
            <Select
              defaultValue={t("filter.fieldContent.All")}
              className="w-full h-10 text-start first:pr-4"
              suffixIcon={<IoCaretDown className="text-lg" />}
              onChange={(value) => setStatusKYC(value)}
            >
              <Select.Option value={undefined}>
                {t("filter.fieldContent.All")}
              </Select.Option>
              {STATUS_KYC.map((status) => (
                <Select.Option key={status.id} value={status.id}>
                  {status.value}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="w-full">
            <p className="font-semibold text-sm leading-none mb-2 text-start">
              Nhóm khách hàng
            </p>
            <Select
              defaultValue={t("filter.fieldContent.All")}
              className="w-full h-10 text-start first:pr-4"
              suffixIcon={<IoCaretDown className="text-lg" />}
              onChange={(value) => setUserGroupId(value)}
            >
              <Select.Option value={undefined}>
                {t("filter.fieldContent.All")}
              </Select.Option>
              {userGroups.map((group) => (
                <Select.Option key={group?.id} value={group?.id}>
                  {group?.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="w-full">
            <p className="font-semibold text-sm leading-none mb-2 text-start">
              Shop lên đơn gần nhất
            </p>
            <DatePicker
              format={dateFormat}
              className="w-full h-10 px-4"
              suffixIcon={
                <IoCalendarNumberOutline className="text-2xl text-black" />
              }
              onChange={(date, dateString: any) => {
                setLastOrderDate(dateString);
              }}
            />
            <div>
              <div className="w-20 h-1"></div>
            </div>
          </div>
          <div className="md:hidden w-full p-1 flex items-end justify-center mx-auto">
            <div className="h-10 w-20">
              <button
                className=" mt-auto !border-none rounded px-6 py-2 bg-main-2 text-sm font-medium leading-[24px] text-white hover:bg-main-4 flex justify-center items-center"
                onClick={handleFilterShopList}
              >
                {t("button.View")}
              </button>
            </div>
          </div>
        </div>
        <div className="hidden mt-[22px] md:flex justify-center mx-auto">
          <div className="h-10 w-20">
            <button
              className=" mt-auto !border-none rounded px-6 py-2 bg-main-2 text-sm font-medium leading-[24px] text-white hover:bg-main-4 flex justify-center items-center"
              onClick={handleFilterShopList}
            >
              {t("button.View")}
            </button>
          </div>
        </div>
      </div>
      <div className="mt-5 w-[100%]">
        <div className="rounded-lg bg-white lg:!min-w-none">
          <div className="relative overflow-auto">
            <div className="min-w-[1880px] rounded-t-lg bg-gray-1 px-4 py-[9.5px] flex flex-row justify-between items-center text-sm font-semibold w-full">
              {shopListColumns(t).map((column, i) => (
                <div key={i}>
                  <div style={{ width: `${column.width}px` }}>
                    {column.title}
                  </div>
                </div>
              ))}
            </div>
            {shopList.length > 0 && (
              <StyledContentTableWrapper className="w-full min-w-[1880px]">
                {shopList.map((item) => (
                  <ShopItem
                    key={item.id}
                    {...{
                      item,
                      hashtagList,
                      setRefreshData,
                    }}
                  />
                ))}
              </StyledContentTableWrapper>
            )}
            {shopList.length === 0 && (
              <div className="sticky left-0 flex justify-center items-center h-[calc(100vh-523px)] w-full">
                <img
                  src={NoDataIcon}
                  alt="no-data"
                  className="w-[180px] h-[162px] md:w-[250px] md:h-[225px]"
                />
              </div>
            )}
          </div>
        </div>
        <Pagination {...{ page, setPage, totalPage }} />
      </div>
    </div>
  );
};

export default ShopList;
