import { TFunction } from "i18next";

export const shopListColumns = (t: TFunction<"translation", undefined>) => [
  {
    title: "No.",
    dataIndex: "no",
    key: "no",
    width: 50,
  },
  {
    title: "Hành động",
    dataIndex: "customerCode",
    key: "customerCode",
    width: 140,
  },
  {
    title: t("shopManagement.CustomerCode"),
    dataIndex: "customerCode",
    key: "customerCode",
    width: 140,
  },
  {
    title: t("shopManagement.Name"),
    dataIndex: "name",
    key: "name",
    width: 180,
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    width: 180,
  },
  {
    title: t("shopManagement.PhoneNumber"),
    dataIndex: "phone",
    key: "phone",
    width: 180,
  },
  {
    title: t("shopManagement.KYCStatus"),
    dataIndex: "KYCStatus",
    key: "KYCStatus",
    width: 140,
  },
  {
    title: t("shopManagement.CustomerGroup"),
    dataIndex: "customerGroup",
    key: "customerGroup",
    width: 150,
  },
  {
    title: t("shopManagement.FirstCreatedOrderDate"),
    dataIndex: "firstCreatedOrderDate",
    key: "firstCreatedOrderDate",
    width: 200,
  },
  {
    title: t("shopManagement.LastestCreatedOrderDate"),
    dataIndex: "lastestCreatedOrderDate",
    key: "lastestCreatedOrderDate",
    width: 200,
  },
  {
    title: t("shopManagement.RefundRate"),
    dataIndex: "refundRate",
    key: "refundRate",
    width: 120,
  },
  {
    title: t("shopManagement.AccountBalance"),
    dataIndex: "accountBalance",
    key: "accountBalance",
    width: 120,
  },
];
