import styled from "styled-components";

export const StyledTab = styled.div`
  box-shadow: -2px -2px 4px 4px #d1d1d126;
`;

export const StyledCreateAccountShopForm = styled.div`
  box-shadow: -2px -2px 4px 4px #d1d1d126;
`;

export const StyledFilterWrapper = styled.div`
  box-shadow: -2px -2px 4px 4px #d1d1d126;
`;

export const StyledContentTableWrapper = styled.div`
  box-shadow: -2px -2px 4px 4px #d1d1d126;
`;
