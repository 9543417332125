import * as React from "react"
const ColorPaletIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={200}
    height={200}
    viewBox=" 0 0 200 200"
    fill="none"
    {...props}
  >
    <path
      fill="url(#a)"
      d="M145.833 100a12.498 12.498 0 0 1-12.5-12.5 12.497 12.497 0 0 1 12.5-12.5 12.499 12.499 0 0 1 8.839 21.339 12.499 12.499 0 0 1-8.839 3.661Zm-25-33.333a12.502 12.502 0 0 1-12.5-12.5 12.5 12.5 0 1 1 12.5 12.5Zm-41.666 0a12.5 12.5 0 1 1 0-25 12.5 12.5 0 0 1 0 25Zm-25 33.333a12.5 12.5 0 1 1 0-25 12.5 12.5 0 0 1 0 25ZM100 25a75 75 0 0 0 0 150 12.501 12.501 0 0 0 12.5-12.5c0-3.25-1.25-6.167-3.25-8.333-1.917-2.25-3.167-5.167-3.167-8.334a12.499 12.499 0 0 1 12.5-12.5h14.75A41.667 41.667 0 0 0 175 91.667C175 54.833 141.417 25 100 25Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={25}
        x2={200}
        y1={104.167}
        y2={104.167}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FC5B5B" />
        <stop offset={0.271} stopColor="#B89E43" />
        <stop offset={0.49} stopColor="#35812F" />
        <stop offset={0.729} stopColor="#178291" />
        <stop offset={0.865} stopColor="#2F1F94" />
        <stop offset={1} stopColor="#3414F4" />
      </linearGradient>
    </defs>
  </svg>
)
export default ColorPaletIcon
