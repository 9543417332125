import { Select } from "antd";
import styled from "styled-components";

export const StyleAccountDetailsWrapper = styled.div`
  box-shadow: -2px -2px 4px 4px #d1d1d126;
`;

export const StylePickupAddressWrapper = styled.div`
  box-shadow: -2px -2px 4px 4px #d1d1d126;
`;

export const StyleTaxCodeListWrapper = styled.div`
  box-shadow: -2px -2px 4px 4px #d1d1d126;
`;

export const StyleBankInfoListWrapper = styled.div`
  box-shadow: -2px -2px 4px 4px #d1d1d126;
`;

export const StyleRegisterCODProfileWrapper = styled.div`
  box-shadow: -2px -2px 4px 4px #d1d1d126;
`;

export const StyleCODTransportersWrapper = styled.div`
  box-shadow: -2px -2px 4px 4px #d1d1d126;
`;

export const StyleSettingCODTransportersWrapper = styled.div`
  box-shadow: -2px -2px 4px 4px #d1d1d126;
`;

export const StyleModalPickupAddress = styled.div``;

export const StyleSwitch = styled.div`
  .ant-switch-handle {
    width: 16px;
    height: 16px;
  }
`;

export const StyleSelect = styled.div`
  width: 100%;
  .ant-select-selector {
    border: 0.5px solid #000 !important;

    &:hover {
      border-color: #4096ff !important;
    }
  }
`;

export const StyledSelect = styled(Select)`
  width: 100%;
  .ant-select-selector {
    border: 0.5px solid #000 !important;

    &:hover {
      border-color: #4096ff !important;
    }
  }
`;
