import { TFunction } from "i18next";

export const pickupAddressColumn = (t: TFunction<"translation", undefined>) => [
  {
    title: "STT",
    dataIndex: "stt",
    key: "stt",
    width: 3,
    minWidth: 50,
  },
  {
    title: t("accountDetails.StoreName"),
    dataIndex: "name",
    key: "name",
    width: 15,
    minWidth: 180,
  },
  {
    title: t("accountDetails.PhoneNumber"),
    dataIndex: "phoneNumber",
    key: "phoneNumber",
    width: 11,
    minWidth: 150,
  },
  {
    title: t("accountDetails.Address"),
    dataIndex: "address",
    key: "address",
    width: 44,
    minWidth: 200,
  },
  {
    title: t("accountDetails.Action"),
    dataIndex: "action",
    key: "action",
    width: 14,
    minWidth: 150,
  },
];

export const PickupAddressRow = [
  {
    stt: "1",
    storeName: "Shop Phúc Thảo Minh",
    contactPerson: "Nguyễn Văn Tiến",
    phoneNumber: "0353094435",
    address:
      "20 Đường 34 - Khu đô thị Vạn Phúc - Thành phố Thủ Đức - Thành phố Hồ Chí Minh",
  },
  {
    stt: "2",
    storeName: "Shop Phúc Thảo Minh",
    contactPerson: "Nguyễn Văn Tiến",
    phoneNumber: "0353094435",
    address:
      "20 Đường 34 - Khu đô thị Vạn Phúc - Thành phố Thủ Đức - Thành phố Hồ Chí Minh",
  },
  {
    stt: "3",
    storeName: "Shop Phúc Thảo Minh",
    contactPerson: "Nguyễn Văn Tiến",
    phoneNumber: "0353094435",
    address:
      "20 Đường 34 - Khu đô thị Vạn Phúc - Thành phố Thủ Đức - Thành phố Hồ Chí Minh",
  },
];

export const taxCodeColumn = (t: TFunction<"translation", undefined>) => [
  {
    title: t("accountDetails.UnitName"),
    dataIndex: "vatCompanyName",
    key: "vatCompanyName",
    width: 10,
    minWidth: 125,
  },
  {
    title: t("accountDetails.TaxCode"),
    dataIndex: "vatTaxCode",
    key: "vatTaxCode",
    width: 10,
    minWidth: 125,
  },
  {
    title: t("accountDetails.PhoneNumber"),
    dataIndex: "vatPhoneNumber",
    key: "vatPhoneNumber",
    width: 10,
    minWidth: 125,
  },
  {
    title: "Email",
    dataIndex: "vatEmail",
    key: "vatEmail",
    width: 15,
    minWidth: 185,
  },
  {
    title: t("accountDetails.Address"),
    dataIndex: "vatAddress",
    key: "vatAddress",
    width: 20,
    minWidth: 250,
  },
  {
    title: t("accountDetails.ExportElectronicInvoiceEveryMonth"),
    dataIndex: "vatIsEveryMonth",
    key: "vatIsEveryMonth",
    width: 18,
    minWidth: 200,
  },
];

export const TaxCodeRow = [
  {
    stt: 1,
    unitName: "Nguyễn Văn Tiến",
    taxCode: "2902188829",
    phoneNumber: "0353094435",
    email: "NguyenVanTien@gmail.com",
    address:
      "20 Đường 34 - Khu đô thị Vạn Phúc - Thành phố Thủ Đức - Thành phố Hồ Chí Minh",
    exportElectronicInvoiceEveryMonth: true,
  },
  {
    stt: 2,
    unitName: "Nguyễn Văn Tiến",
    taxCode: "2902188829",
    phoneNumber: "0353094435",
    email: "NguyenVanTien@gmail.com",
    address:
      "20 Đường 34 - Khu đô thị Vạn Phúc - Thành phố Thủ Đức - Thành phố Hồ Chí Minh",
    exportElectronicInvoiceEveryMonth: true,
  },
  {
    stt: 3,
    unitName: "Nguyễn Văn Tiến",
    taxCode: "2902188829",
    phoneNumber: "0353094435",
    email: "NguyenVanTien@gmail.com",
    address:
      "20 Đường 34 - Khu đô thị Vạn Phúc - Thành phố Thủ Đức - Thành phố Hồ Chí Minh",
    exportElectronicInvoiceEveryMonth: true,
  },
];

export const bankInfoColumn = (t: TFunction<"translation", undefined>) => [
  {
    title: "STT",
    dataIndex: "stt",
    key: "stt",
    width: 5,
    minWidth: 50,
  },
  {
    title: "Tên ngân hàng",
    dataIndex: "bankName",
    key: "bankName",
    width: 27,
    minWidth: 200,
  },
  {
    title: "Tên viết tắt",
    dataIndex: "bankShortName",
    key: "bankShortName",
    width: 27,
    minWidth: 150,
  },
  {
    title: t("accountDetails.AccountName"),
    dataIndex: "accountName",
    key: "accountName",
    width: 27,
    minWidth: 200,
  },
  {
    title: t("accountDetails.AccountNumber"),
    dataIndex: "accountNumber",
    key: "accountNumber",
    width: 27,
    minWidth: 200,
  },
  // {
  //   title: t("accountDetails.Action"),
  //   dataIndex: "action",
  //   key: "action",
  //   width: 14,
  //   minWidth: 150,
  // },
];

export const codTransportersColumn = [
  {
    title: "No.",
    dataIndex: "no",
    key: "no",
    width: 10,
    minWidth: 50,
  },
  {
    title: "",
    dataIndex: "name",
    key: "name",
    width: 15,
    minWidth: 200,
  },
  {
    title: "GHN",
    dataIndex: "GHN",
    key: "GHN",
    width: 15,
    minWidth: 200,
  },
  {
    title: "GHTK",
    dataIndex: "GHTK",
    key: "GHTK",
    width: 15,
    minWidth: 200,
  },
  {
    title: "Ninjavan",
    dataIndex: "Ninjavan",
    key: "Ninjavan",
    width: 15,
    minWidth: 200,
  },
  {
    title: "Best",
    dataIndex: "Best",
    key: "Best",
    width: 15,
    minWidth: 200,
  },
  {
    title: "JT",
    dataIndex: "JT",
    key: "JT",
    width: 15,
    minWidth: 200,
  },
  {
    title: "Tiki",
    dataIndex: "Tiki",
    key: "Tiki",
    width: 15,
    minWidth: 200,
  },
  {
    title: "Lex",
    dataIndex: "Lex",
    key: "Lex",
    width: 15,
    minWidth: 200,
  },
];

export const CODTransportersRow = [
  {
    no: "1",
    noName: "Active NVC",
    ninjavan: true,
    GHN: true,
    GHTK: true,
    best: true,
    JT: false,
  },
  {
    no: "2",
    noName: "Hạn mức ứng tối đa",
    ninjavan: "0",
    GHN: "0",
    GHTK: "0",
    best: "0",
    JT: "0",
  },
];

export const settingCodTransportersColumn = (
  t: TFunction<"translation", undefined>
) => [
  {
    title: "No.",
    dataIndex: "no",
    key: "no",
    width: 10,
    minWidth: 50,
  },
  {
    title: "NoName",
    dataIndex: "noName",
    key: "noName",
    width: 15,
    minWidth: 200,
  },
  {
    title: "Ninjavan",
    dataIndex: "ninjavan",
    key: "ninjavan",
    width: 15,
    minWidth: 200,
  },
  {
    title: "GHN",
    dataIndex: "GHN",
    key: "GHN",
    width: 15,
    minWidth: 200,
  },
  {
    title: "GHTK",
    dataIndex: "GHTK",
    key: "GHTK",
    width: 15,
    minWidth: 200,
  },
  {
    title: "Best",
    dataIndex: "best",
    key: "best",
    width: 15,
    minWidth: 200,
  },
  {
    title: "JT",
    dataIndex: "JT",
    key: "JT",
    width: 15,
    minWidth: 200,
  },
];

export const settingCODTransportersRow = [
  {
    no: "1",
    noName: "<Đã lấy hàng>",
    key: "pickedUpCodPercent",
    status: 8,
  },
  {
    no: "2",
    noName: "<Đang trung chuyển>",
    key: "deliveringCodPercent",
    status: 9,
  },
  {
    no: "3",
    noName: "<Đã nhập kho giao>",
    key: "storedCodPercent",
    status: 10,
  },
  {
    no: "4",
    noName: "<Đang trên xe giao>",
    key: "transportingCodPercent",
    status: 2,
  },
  {
    no: "5",
    noName: "<Đang giao hàng>/<Đã giao hàng 1 phần>",
    key: "deliveredCodPercent",
    status: 3,
  },
];
