import { formatNumber } from "@helper/helper";
import { getVouchers } from "@services/order-service";
import { Modal, message } from "antd";
import { useEffect, useState } from "react";

const columns2 = [
  {
    title: "Mã giảm giá",
  },
  {
    title: "Tên chương trình",
  },
  {
    title: "Trị giá",
  },
  {
    title: "Hành động",
  },
];

const VoucherCodeModal = ({ open, onCancel, onChoosingVoucher }: any) => {
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const res = await getVouchers();
        if (res) {
          setData(res?.data);
        }
      } catch (e) {
        message.error("Không thể lấy mã giảm giá");
      }
    })();
  }, []);

  return (
    <Modal
      title=""
      open={open}
      onCancel={onCancel}
      footer={null}
      centered
      width={900}
    >
      <div className="text-base font-semibold border-b pb-3">
        Chọn mã giảm giá
      </div>
      <div className="mt-4 max-w-[100%] overflow-x-auto">
        <div className="rounded-lg overflow-hidden bg-white shadow-lg">
          <div className="bg-gray-1 px-4 py-[10px] flex flex-row justify-between items-center text-sm font-semibold">
            {columns2.map((column, i) => (
              <div key={i} className="w-[200px] text-center">
                {column.title}
              </div>
            ))}
          </div>
          {data?.map((item: any, index: number) => (
            <div
              key={index}
              className={`px-4 py-[10px] flex flex-row justify-between items-center text-sm text-center border-b`}
            >
              <div className="w-[200px]">{item?.code}</div>
              <div className="w-[200px]">{item?.name}</div>
              <div className="w-[200px]">{`Giảm ${formatNumber(
                item?.value
              )} VND`}</div>
              <div className="w-[200px]">
                <button
                  className="bg-main-2 hover:bg-main-4 text-wrap text-white px-2 py-1 rounded-md"
                  onClick={() => onChoosingVoucher(item)}
                >
                  Chọn
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default VoucherCodeModal;
