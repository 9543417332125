import { ShopAccount } from "@models/ShopAccount";
import { Tag } from "@models/TagModel";
import {
  attachHashtagForShop,
  deleteHashtagForShop,
} from "@services/hashtag-services";
import dayjs from "dayjs";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import CurrencyFormat from "react-currency-format";
import HashtagList from "@components/hashtag-list";
import { Link } from "react-router-dom";
import { AUTHORIZE, STATUS_KYC } from "constants/constants";
import { IoCopyOutline } from "react-icons/io5";
import { Input, message } from "antd";
import { updateShopInfo } from "@services/shop-services";
import { FaRegCircleCheck, FaRegCircleXmark } from "react-icons/fa6";
import { useAuth } from "provider/auth";

const { EDIT } = AUTHORIZE;

interface IShopItem {
  item: ShopAccount;
  hashtagList: Tag[];
  setRefreshData: Dispatch<SetStateAction<number>>;
}

const ShopItem = ({ item, hashtagList, setRefreshData }: IShopItem) => {
  const { organizations } = useAuth();
  const [isEdit, setIsEdit] = useState(false);
  const [note, setNote] = useState<string>(item?.note || "");
  const ref: any = useRef();

  const handleCopyPhoneNumber = (phoneNumber: string) => {
    if (phoneNumber) {
      navigator.clipboard.writeText(phoneNumber);
      message.success("Copy số điện thoại thành công");
    }
  };

  const handleUpdateNote = async () => {
    setIsEdit(false);
    if (item?.note !== note) {
      try {
        const payload = { note };
        const res = await updateShopInfo(item?.id, payload);
        if (res && res.data) {
          message.success("Cập nhật ghi chú thành công");
          setRefreshData(new Date().getTime());
        }
      } catch (e) {
        message.error("Cập nhật ghi chú thất bại");
      }
    }
  };

  return (
    <div className="flex flex-col gap-2 px-4 py-3 border-b-[0.5px] border-gray-1 hover:bg-gray-2">
      <div className="flex justify-between text-sm w-full">
        <div style={{ width: "50px" }} className="truncate px-1">
          {item.id}
        </div>
        <Link to={`details/${item.id}`} target="_blank">
          <div style={{ width: "140px" }} className="truncate px-1 text-blue-2">
            Xem chi tiết
          </div>
        </Link>
        <div style={{ width: "140px" }} className="truncate px-1">
          {item.code}
        </div>
        <div style={{ width: "180px" }} className="truncate px-1">
          {item.fullName}
        </div>
        <div style={{ width: "180px" }} className="truncate px-1">
          {item.email}
        </div>
        <div
          style={{ width: "180px" }}
          className="flex justify-center items-center gap-2 truncate px-1"
        >
          <div>{item.phoneNumber}</div>
          <div
            className="cursor-pointer"
            onClick={() => handleCopyPhoneNumber(item.phoneNumber)}
          >
            <IoCopyOutline fontSize="18px" color="#BFBFBF" />
          </div>
        </div>
        <div style={{ width: "140px" }} className="truncate px-1">
          {STATUS_KYC.find((ite) => ite.id === item?.statusKYC)?.value}
        </div>
        <div style={{ width: "150px" }} className="truncate px-1">
          {item.userGroupName}
        </div>
        <div style={{ width: "200px" }} className="truncate px-1">
          {item.firstOrderDate
            ? dayjs(item.firstOrderDate).format("DD/MM/YYYY")
            : null}
        </div>
        <div style={{ width: "200px" }} className="truncate px-1">
          {item.lastOrderDate
            ? dayjs(item.lastOrderDate).format("DD/MM/YYYY")
            : null}
        </div>
        <div style={{ width: "120px" }} className="truncate px-1">
          {item.orderReturnRate}%
        </div>
        <div style={{ width: "120px" }} className="truncate px-1">
          <CurrencyFormat
            value={item.VNDCCoin}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
          />
        </div>
      </div>
      <HashtagList
        dataChooseHashtagList={item?.tags}
        hashtagList={hashtagList}
        recordId={item?.id}
        deleteService={deleteHashtagForShop}
        addService={attachHashtagForShop}
        page="manageShop"
      />
      <div className="w-fit">
        {isEdit ? (
          <div className="flex gap-1">
            <Input
              value={note}
              onChange={(e) => setNote(e.target.value)}
              onBlur={handleUpdateNote}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleUpdateNote();
                }
              }}
              className="w-80"
              ref={ref}
            />
            <button className="p-1" onClick={handleUpdateNote}>
              <FaRegCircleCheck className="text-base" />
            </button>
            <button className="p-1" onClick={() => setIsEdit(false)}>
              <FaRegCircleXmark className="text-base" />
            </button>
          </div>
        ) : organizations["manageShop"] === EDIT ? (
          <div
            className="text-sm px-[10px] py-[3px] rounded-3xl w-fit bg-blue-4 text-white cursor-pointer"
            onClick={() => {
              setIsEdit(true);
              setTimeout(() => {
                if (ref && ref?.current) {
                  ref?.current?.focus();
                }
              }, 300);
            }}
          >
            {item?.note ? `Ghi chú: ${item?.note}` : "Thêm ghi chú"}
          </div>
        ) : item?.note ? (
          <div className="text-sm px-[10px] py-[3px] rounded-3xl w-fit bg-blue-4 text-white cursor-pointer">
            {item?.note ? `Ghi chú: ${item?.note}` : ""}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ShopItem;
