import bgLoginImage from '@assets/image/bg-login.png';
import loginBanner from '@assets/image/login-banner.png';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PhoneNumberForm } from './PhoneNumberForm';
import { ResetPasswordForm } from './ResetPasswordForm';

export const ForgotPassword: React.FC = () => {
  const [step, setStep] = useState<number>(0);
  const { t } = useTranslation();

  return (
    <div className="bg-cover bg-center h-[100vh] w-full justify-center items-center flex" style={{ backgroundImage: `url(${bgLoginImage})` }}>
      <div className="w-[700px] p-[50px] bg-bg-color rounded-xl">
        <h2 className="font-semibold text-center text-2xl pb-[40px]">{t("login.ResetPassword")}</h2>
        <div className="flex flex-row gap-4">
          <div className="w-1/2 h-auto bg-cover bg-center">
            <img src={loginBanner} alt="login-banner" />
          </div>
          <div className="w-1/2 h-auto">
            {step === 0 && <PhoneNumberForm  handleChangeStep={() => setStep(1)}/>}
            {step === 1 && <ResetPasswordForm />}
            <p className="text-center text-sm">{t("login.GoBack")} <a className="text-blue-2 lowercase" href='/login'>{t("login.Login")}</a></p>
          </div>
        </div>
      </div>
    </div>
  );
}
