import { BankAccount } from "@models/BankAccount";
import { api } from "provider/api";

interface IBankAccount {
  page: number;
  limit: number;
  shopId: number;
}

export const getBankAccountList = async (payload: IBankAccount) => {
  const res = await api.get("/bank-account", { params: payload });
  if (res && res.data) {
    return {
      data: res.data.response.data,
      meta: res.data.response.meta,
    };
  }
  return undefined;
};

export const createBankAccount = async ({
  shopId,
  bankEntityId,
  accountName,
  accountNumber,
  isDefault,
}: BankAccount) => {
  const payload = {
    shopId,
    bankEntityId,
    accountName,
    accountNumber,
    isDefault,
  };
  const res = await api.post("/bank-account", payload);
  if (res && res.data) {
    return res.data;
  }
  return undefined;
};

export const updateBankAccount = async ({
  id,
  shopId,
  bankEntityId,
  accountName,
  accountNumber,
  isDefault,
  isUsed
}: BankAccount) => {
  const payload = {
    shopId,
    bankEntityId,
    accountName,
    accountNumber,
    isDefault,
    isUsed,
  };
  const res = await api.put(`/bank-account/${id}`, payload);
  if (res && res.data) {
    return res.data;
  }
  return undefined;
};

export const deleteBankAccount = async (id: number) => {
  const res = await api.delete(`/bank-account/${id}`);
  if (res && res.data) {
    return res.data;
  }
  return undefined;
};

export const getBankList = async () => {
  const res = await api.get("/bank-account/list-bank/gets");
  if (res && res.data?.response) {
    return res.data?.response;
  }
  return undefined;
};
