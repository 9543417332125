import { api } from "provider/api";

interface IOverview {
  from: string;
  to: string;
  dateType: string;
}

export const getDashboardOverview = async (payload: any) => {
  try {
    const res = await api.get("dashboard/overview", { params: payload });
    if (res && res.data) {
      return res.data.response;
    }
    return undefined;
  } catch (e) {}
};

export const getRevenueByPartnerReport = async (from: string, to: string, dateType: string) => {
  try {
    const res = await api.get("dashboard/by-delivery-partner", {
      params: { from, to, dateType },
    });
    if (res && res.data) {
      return res.data.response;
    }
    return undefined;
  } catch (e) {}
};

export const getRevenueByShopReport = async (from: string, to: string, dateType: string) => {
  try {
    const res = await api.get("dashboard/by-shops", {
      params: { from, to, dateType },
    });
    if (res && res.data) {
      return res.data.response;
    }
    return undefined;
  } catch (e) {}
};
