import { Section } from "@components/section/Section";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Outlet } from "react-router-dom";

const TAB_ITEMS = [
  { label: "Thông tin tài khoản", path: "/account-setting" },
  { label: "Mật khẩu", path: "/account-setting/password" },
];

const AccountSetting = () => {
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    document.title = "Cài đặt tài khoản - Go24";
  }, []);

  return (
    <div className="flex flex-col gap-y-8">
      <Section title="Cài đặt tài khoản">
        <div className="flex gap-8 items-center">
          <div className="flex items-center bg-white w-full rounded-lg gap-5 px-4 shadow-md">
            {TAB_ITEMS.map(({ label, path }) => (
              <Link to={path} key={path}>
                <div
                  className={`text-base text-black font-normal px-2 py-4 border-b hover:border-main-1 cursor-pointer ${
                    pathname === path ? "border-main-1" : "border-transparent"
                  }`}
                  key={label}
                >
                  {label}
                </div>
              </Link>
            ))}
          </div>
        </div>
        <Outlet />
      </Section>
    </div>
  );
};

export default AccountSetting;
