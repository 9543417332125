import {
  formatDate,
  formatNumber,
  renderOrderStatus,
  renderRequestStatus,
} from "@helper/helper";
import { Button } from "antd";
import { AUTHORIZE, DATE_FORMAT, roleGroupInitial } from "constants/constants";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tag } from "@models/TagModel";
import HashtagList from "@components/hashtag-list";
import {
  addHashtagForRequest,
  deleteHashtagForRequest,
} from "@services/hashtag-services";
import { checkingGoodsDefault } from "@pages/order-management/EditOrderModal";
import { StyledRequestBlock } from "../../components/block/styles";
import { useAuth } from "provider/auth";

const pickWorkShiftList = [
  {
    id: 1,
    name: "Ca sáng",
  },
  {
    id: 2,
    name: "Ca trưa",
  },
  {
    id: 3,
    name: "Ca tối",
  },
];

const orderTypes = [
  {
    id: 0,
    name: "Tiêu chuẩn",
  },
  {
    id: 1,
    name: "Dễ vỡ",
  },
  {
    id: 2,
    name: "Giao hàng 1 phần",
  },
  {
    id: 7,
    name: "Nông sản/thực phẩm khô",
  },
];

const { EDIT } = AUTHORIZE;

type RequestBlockProps = {
  data?: any;
  tagList?: Tag[];
  showDetailsFunc?: () => void;
  commentFunc?: any;
  isRequest?: boolean;
};
export const RequestBlock: React.FC<RequestBlockProps> = ({
  data,
  tagList,
  showDetailsFunc,
  commentFunc,
  isRequest = true,
}) => {
  const { t } = useTranslation();
  const { organizations } = useAuth();
  const [isAddressChanged, setIsAddressChanged] = useState(false);
  const [isProductChanged, setIsProductChanged] = useState(false);
  const [orderItems, setOrderItems] = useState("");
  const [orderChangeItems, setOrderChangeItems] = useState("");

  const getDeliveryInstruction = (typeShowItem: number) => {
    const deliveryInstruction = checkingGoodsDefault.filter(
      (e) => e.id === typeShowItem
    )[0];
    if (deliveryInstruction === undefined) {
      return "";
    }
    return deliveryInstruction.title;
  };

  const getPickWorkShift = (id: number) => {
    const pickWorkShift = pickWorkShiftList.filter((e) => e.id === id)[0];
    if (pickWorkShift === undefined) {
      return "";
    }
    return pickWorkShift.name;
  };

  const getOrderType = (id: number) => {
    const orderType = orderTypes.filter((e) => e.id === id)[0];
    if (orderType === undefined) {
      return "";
    }
    return orderType.name;
  };

  useEffect(() => {
    if (
      data?.orderObj &&
      data?.orderChangedObj &&
      data?.orderObj?.items &&
      data?.orderChangedObj?.items
    ) {
      if (
        data?.orderObj?.items.length !== data?.orderChangedObj?.items.length
      ) {
        setIsProductChanged(true);
      } else {
        data?.orderObj?.items?.forEach((e: any) => {
          if (
            !data?.orderChangedObj?.items?.find(
              (i: any) => i?.id === e?.id && i?.quantity === e?.quantity
            )
          ) {
            setIsProductChanged(true);
          }
        });
      }
      if (isProductChanged) {
        let items = "";
        let changeItems = "";
        data?.orderObj?.items?.forEach((e: any) => {
          items += e?.name + ": " + e?.quantity + "; ";
        });
        data?.orderChangedObj?.items.forEach((e: any) => {
          changeItems += e?.name + ": " + e?.quantity + "; ";
        });
        setOrderItems(items);
        setOrderChangeItems(changeItems);
      }
    }

    if (!data?.orderChangedObj || !data?.orderObj) {
      setIsAddressChanged(false);
    } else if (
      data?.orderChangedObj?.address !== data?.orderObj?.address ||
      data?.orderChangedObj?.provinceId !== data?.orderObj?.provinceId ||
      data?.orderChangedObj?.districtId !== data?.orderObj?.districtId ||
      data?.orderChangedObj?.wardId !== data?.orderObj?.wardId
    ) {
      setIsAddressChanged(true);
    } else {
      if (
        !data?.orderChangedObj?.addressLevel4Id &&
        data?.orderObj?.addressLevel4Id
      ) {
        setIsAddressChanged(false);
      } else if (
        data?.orderChangedObj?.addressLevel4Id !==
        data?.orderObj?.addressLevel4Id
      ) {
        setIsAddressChanged(true);
      }
    }
  }, []);

  return (
    <StyledRequestBlock className="text-sm overflow-hidden rounded-lg bg-white text-black-1">
      <div className="">
        <div className="flex flex-row gap-4 justify-between items-center border-b border-gray-2 font-semibold leading-none py-2.5 px-4">
          <h4 className="w-1/2 lg:w-1/4 flex justify-start">
            {t("table.tableTitle.GeneralInformation")}
          </h4>
          <div className="w-1/2 lg:w-1/4 text-start">
            {data?.orderObj && data?.orderChangedObj && (
              <h4 className="">
                {t("table.tableTitle.PreRequestInformation")}
              </h4>
            )}
          </div>
          <div className="hidden lg:block w-1/4">
            {data?.orderObj && data?.orderChangedObj && (
              <h4 className="text-left">
                {t("table.tableTitle.InformationNeedsUpdating")}
              </h4>
            )}
          </div>
          <div className=" hidden lg:block w-1/4 text-left">
            <h4 className="">Thông tin xử lý</h4>
          </div>
        </div>
        <div className="p-4 flex flex-wrap flex-row gap-4 lg:gap-0 justify-between leading-none text-start">
          <div className="w-[46%] lg:w-1/4 flex flex-col gap-y-1">
            <p className="w-full">
              {formatDate(data?.createdDate, DATE_FORMAT)}
            </p>
            <p className="w-full font-semibold">{data?.requestType}</p>
            <p
              className={`w-full font-semibold ${isRequest ? "text-blue-4 cursor-pointer " : ""
                }`}
              onClick={showDetailsFunc}
            >
              {data?.code}
            </p>
            <p className="w-full font-semibold">{data?.deliveryCode}</p>
            <p className="w-full font-semibold">
              {t(
                `orderStatus.${renderOrderStatus(String(data?.statusId || 0))}`
              )}
            </p>
          </div>
          {data?.requestMessage ? (
            <div className="flex w-1/2 font-semibold">
              <p>
                Ghi chú:
                <span className="text-[#dd4b39]">{` ${data?.requestMessage}`}</span>
              </p>
            </div>
          ) : (
            <>
              {data?.orderObj && data?.orderChangedObj && (
                <div className="w-[48%] lg:w-1/4 flex flex-col gap-y-1 text-right">
                  {data?.orderChangedObj?.shopLocationId !==
                    data?.orderObj?.shopLocationId && (
                      <div className="flex gap-1 flex-wrap text-end">
                        <p>Địa chỉ lấy hàng:</p>
                        <p className="text-red-4">{`${data?.orderObj?.shopLocationName} ${data?.orderObj?.shopLocationAddress}`}</p>
                      </div>
                    )}
                  {data?.orderChangedObj?.fullName !==
                    data?.orderObj?.fullName && (
                      <div className="flex gap-1 flex-wrap">
                        <p>Họ tên người nhận:</p>
                        <p className="text-red-4">{data?.orderObj?.fullName}</p>
                      </div>
                    )}
                  {data?.orderChangedObj?.phoneNumber !==
                    data?.orderObj?.phoneNumber && (
                      <div className="flex gap-1 flex-wrap">
                        <p>Số điện thoại:</p>
                        <p className="text-red-4">
                          {data?.orderObj?.phoneNumber}
                        </p>
                      </div>
                    )}
                  {isAddressChanged && (
                    <div className="flex gap-1 flex-wrap">
                      <p>Địa chỉ giao hàng:</p>
                      <p className="text-red-4">{data?.orderObj?.address}</p>
                    </div>
                  )}
                  {isProductChanged && (
                    <div className="flex gap-1 flex-wrap">
                      <p>Sản phẩm:</p>
                      <p className="text-red-4">{orderItems}</p>
                    </div>
                  )}
                  {data?.orderChangedObj?.Type !== data?.orderObj?.Type && (
                    <div className="font-semibold">
                      <p>Loại sản phẩm:</p>
                      <p className="text-red-4">
                        {getOrderType(data?.orderObj?.Type)}
                      </p>
                    </div>
                  )}
                  {data?.orderChangedObj?.weight !== data?.orderObj?.weight && (
                    <div className="flex gap-1 flex-wrap">
                      <p>Khối lượng:</p>
                      <p className="text-red-4">{data?.orderObj?.weight} Kg</p>
                    </div>
                  )}
                  {data?.orderChangedObj?.length !== data?.orderObj?.length && (
                    <div className="flex gap-1 flex-wrap">
                      <p>Dài:</p>
                      <p className="text-red-4">{data?.orderObj?.length} Cm</p>
                    </div>
                  )}
                  {data?.orderChangedObj?.width !== data?.orderObj?.width && (
                    <div className="flex gap-1 flex-wrap">
                      <p>Rộng:</p>
                      <p className="text-red-4">{data?.orderObj?.width} Cm</p>
                    </div>
                  )}
                  {data?.orderChangedObj?.height !== data?.orderObj?.height && (
                    <div className="flex gap-1 flex-wrap">
                      <p>Cao:</p>
                      <p className="text-red-4">{data?.orderObj?.height} Cm</p>
                    </div>
                  )}
                  {data?.orderChangedObj?.insurranceAmount !==
                    data?.orderObj?.insurranceAmount && (
                      <div className="flex gap-1 flex-wrap">
                        <p>Tiền bảo hiểm:</p>
                        <p className="text-red-4">
                          {formatNumber(data?.orderObj?.insurranceAmount)}
                        </p>
                      </div>
                    )}
                  {data?.orderChangedObj?.shopCode &&
                    data?.orderChangedObj?.shopCode !==
                    data?.orderObj?.shopCode && (
                      <div className="flex gap-1 flex-wrap">
                        <p>Mã đơn shop:</p>
                        <p className="text-red-4">{data?.orderObj?.shopCode}</p>
                      </div>
                    )}
                  {data?.orderChangedObj?.note !== data?.orderObj?.note && (
                    <div className="flex gap-1 flex-wrap">
                      <p>Ghi chú:</p>
                      <p className="text-red-4">{data?.orderObj?.note}</p>
                    </div>
                  )}
                  {data?.orderChangedObj?.collectMoney !==
                    data?.orderObj?.collectMoney && (
                      <div className="flex gap-1 flex-wrap">
                        <p>Tiền thu hộ:</p>
                        <p className="text-red-4">
                          {formatNumber(data?.orderObj?.collectMoney)}
                        </p>
                      </div>
                    )}
                  {data?.orderChangedObj?.totalTakenCustomerAmount !==
                    data?.orderObj?.totalTakenCustomerAmount && (
                      <div className="flex gap-1 flex-wrap">
                        <p>Tổng thu người nhận:</p>
                        <p className="text-red-4">
                          {formatNumber(data?.orderObj?.totalTakenCustomerAmount)}
                        </p>
                      </div>
                    )}
                  {data?.orderChangedObj?.totalPaidShopAmount !==
                    data?.orderObj?.totalPaidShopAmount && (
                      <div className="flex gap-1 flex-wrap">
                        <p>Thanh toán cho shop:</p>
                        <p className="text-red-4">
                          {formatNumber(data?.orderObj?.totalPaidShopAmount)}
                        </p>
                      </div>
                    )}
                  {data?.orderChangedObj?.typeShowItem !==
                    data?.orderObj?.typeShowItem && (
                      <div className="flex gap-1 flex-wrap">
                        <p>Hướng dẫn giao hàng:</p>
                        <p className="text-red-4">
                          {getDeliveryInstruction(data?.orderObj?.typeShowItem)}
                        </p>
                      </div>
                    )}
                  {data?.orderChangedObj?.isCustomerPaid !==
                    data?.orderObj?.isCustomerPaid && (
                      <div className="flex gap-1 flex-wrap">
                        <p>Khách hàng trả phí:</p>
                        <p className="text-red-4">
                          {data?.orderObj?.isCustomerPaid ? "true" : "false"}
                        </p>
                      </div>
                    )}
                  {data?.orderChangedObj?.partialDeliverySelection !==
                    data?.orderObj?.partialDeliverySelection && (
                      <div className="flex gap-1 flex-wrap">
                        <p>Giao hàng 1 phần chọn sản phẩm:</p>
                        <p className="text-red-4">
                          {data?.orderObj?.partialDeliverySelection
                            ? "true"
                            : "false"}
                        </p>
                      </div>
                    )}
                  {data?.orderChangedObj?.partialDeliveryReturn !==
                    data?.orderObj?.partialDeliveryReturn && (
                      <div className="flex gap-1 flex-wrap">
                        <p>Giao hàng 1 phần đổi trả hàng:</p>
                        <p className="text-red-4">
                          {data?.orderObj?.partialDeliveryReturn
                            ? "true"
                            : "false"}
                        </p>
                      </div>
                    )}
                  {data?.orderChangedObj?.callbackShop !==
                    data?.orderObj?.callbackShop && (
                      <div className="flex gap-1 flex-wrap">
                        <p>
                          Gọi shop khi khách không nhận hàng, không liên lạc được,
                          sai thông tin:
                        </p>
                        <p className="text-red-4">
                          {data?.orderObj?.callbackShop ? "true" : "false"}
                        </p>
                      </div>
                    )}
                  {data?.orderChangedObj?.notDeliveredCollection !==
                    data?.orderObj?.notDeliveredCollection ||
                    (data?.orderChangedObj?.notDeliveredFee !==
                      data?.orderObj?.notDeliveredFee && (
                        <div className="flex gap-1 flex-wrap">
                          <p>Không giao được thu</p>
                          <p className="text-red-4">{`${data?.orderObj?.notDeliveredCollection} ${data?.orderObj?.notDeliveredFee}`}</p>
                        </div>
                      ))}
                  {data?.orderChangedObj?.isSealedProduct !==
                    data?.orderObj?.isSealedProduct && (
                      <div className="flex gap-1 flex-wrap">
                        <p>Hàng nguyên hộp</p>
                        <p className="text-red-4">
                          {data?.orderObj?.isSealedProduct ? "true" : "false"}
                        </p>
                      </div>
                    )}
                  {data?.orderChangedObj?.isDocument !==
                    data?.orderObj?.isDocument && (
                      <div className="flex gap-1 flex-wrap">
                        <p>Thư tín, tài liệu</p>
                        <p className="text-red-4">
                          {data?.orderObj?.isDocument ? "true" : "false"}
                        </p>
                      </div>
                    )}
                  {data?.orderChangedObj?.isFragileItem !==
                    data?.orderObj?.isFragileItem && (
                      <div className="flex gap-1 flex-wrap">
                        <p>Hàng hóa dễ vỡ</p>
                        <p className="text-red-4">
                          {data?.orderObj?.isFragileItem ? "true" : "false"}
                        </p>
                      </div>
                    )}
                  {data?.orderChangedObj?.isAgriculturalItem !==
                    data?.orderObj?.isAgriculturalItem && (
                      <div className="flex gap-1 flex-wrap">
                        <p>Nông sản/thực phẩm khô</p>
                        <p className="text-red-4">
                          {data?.orderObj?.isAgriculturalItem ? "true" : "false"}
                        </p>
                      </div>
                    )}
                  {data?.orderChangedObj?.pickWorkShift !==
                    data?.orderObj?.pickWorkShift && (
                      <div className="flex gap-1 flex-wrap">
                        <p>Thời gian lấy hàng</p>
                        <p className="text-red-4">
                          {getPickWorkShift(data?.orderObj?.pickWorkShift)}
                        </p>
                      </div>
                    )}
                </div>
              )}
              {data?.orderObj && data?.orderChangedObj && (
                <div className="w-full lg:w-[24%] flex flex-col gap-y-1 border-t border-gray-2">
                  <div className="w-full font-semibold lg:hidden py-2">
                    {data?.orderObj && data?.orderChangedObj && (
                      <h4 className="text-left">
                        {t("table.tableTitle.InformationNeedsUpdating")}
                      </h4>
                    )}
                  </div>
                  {data?.orderChangedObj?.shopLocationId !==
                    data?.orderObj?.shopLocationId && (
                      <div className="flex gap-1 flex-wrap">
                        <p>Địa chỉ lấy hàng:</p>
                        <p className="text-red-4">{`${data?.orderChangedObj?.shopLocationName} ${data?.orderChangedObj?.shopLocationAddress}`}</p>
                      </div>
                    )}
                  {data?.orderChangedObj?.fullName !==
                    data?.orderObj?.fullName && (
                      <div className="flex gap-1 flex-wrap">
                        <p>Họ tên người nhận:</p>
                        <p className="text-red-4">
                          {data?.orderChangedObj?.fullName}
                        </p>
                      </div>
                    )}
                  {data?.orderChangedObj?.phoneNumber !==
                    data?.orderObj?.phoneNumber && (
                      <div className="flex gap-1 flex-wrap">
                        <p>Số điện thoại:</p>
                        <p className="text-red-4">
                          {data?.orderChangedObj?.phoneNumber}
                        </p>
                      </div>
                    )}
                  {isAddressChanged && (
                    <div className="flex gap-1 flex-wrap">
                      <p>Địa chỉ giao hàng:</p>
                      <p className="text-red-4">
                        {data?.orderChangedObj?.address}
                      </p>
                    </div>
                  )}
                  {isProductChanged && (
                    <div className="flex gap-1 flex-wrap">
                      <p>Sản phẩm:</p>
                      <p className="text-red-4">{orderChangeItems}</p>
                    </div>
                  )}
                  {data?.orderChangedObj?.Type !== data?.orderObj?.Type && (
                    <div className="flex gap-1 flex-wrap">
                      <p>Loại sản phẩm:</p>
                      <p className="text-red-4">
                        {getOrderType(data?.orderChangedObj?.Type)}
                      </p>
                    </div>
                  )}
                  {data?.orderChangedObj?.weight !== data?.orderObj?.weight && (
                    <div className="flex gap-1 flex-wrap">
                      <p>Khối lượng:</p>
                      <p className="text-red-4">
                        {data?.orderChangedObj?.weight} Kg
                      </p>
                    </div>
                  )}
                  {data?.orderChangedObj?.length !== data?.orderObj?.length && (
                    <div className="flex gap-1 flex-wrap">
                      <p>Dài:</p>
                      <p className="text-red-4">
                        {data?.orderChangedObj?.length} Cm
                      </p>
                    </div>
                  )}
                  {data?.orderChangedObj?.width !== data?.orderObj?.width && (
                    <div className="flex gap-1 flex-wrap">
                      <p>Rộng:</p>
                      <p className="text-red-4">
                        {data?.orderChangedObj?.width} Cm
                      </p>
                    </div>
                  )}
                  {data?.orderChangedObj?.height !== data?.orderObj?.height && (
                    <div className="flex gap-1 flex-wrap">
                      <p>Cao:</p>
                      <p className="text-red-4">
                        {data?.orderChangedObj?.height} Cm
                      </p>
                    </div>
                  )}
                  {data?.orderChangedObj?.insurranceAmount !==
                    data?.orderObj?.insurranceAmount && (
                      <div className="flex gap-1 flex-wrap">
                        <p>Tiền bảo hiểm:</p>
                        <p className="text-red-4">
                          {formatNumber(data?.orderChangedObj?.insurranceAmount)}
                        </p>
                      </div>
                    )}
                  {data?.orderChangedObj?.shopCode &&
                    data?.orderChangedObj?.shopCode !==
                    data?.orderObj?.shopCode && (
                      <div className="flex gap-1 flex-wrap">
                        <p>Mã đơn shop:</p>
                        <p className="text-red-4">
                          {data?.orderChangedObj?.shopCode}
                        </p>
                      </div>
                    )}
                  {data?.orderChangedObj?.note !== data?.orderObj?.note && (
                    <div className="flex gap-1 flex-wrap">
                      <p>Ghi chú:</p>
                      <p className="text-red-4">
                        {data?.orderChangedObj?.note}
                      </p>
                    </div>
                  )}
                  {data?.orderChangedObj?.collectMoney !==
                    data?.orderObj?.collectMoney && (
                      <div className="flex gap-1 flex-wrap">
                        <p>Tiền thu hộ:</p>
                        <p className="text-red-4">
                          {formatNumber(data?.orderChangedObj?.collectMoney)}
                        </p>
                      </div>
                    )}
                  {data?.orderChangedObj?.totalTakenCustomerAmount !==
                    data?.orderObj?.totalTakenCustomerAmount && (
                      <div className="flex gap-1 flex-wrap">
                        <p>Tổng thu người nhận:</p>
                        <p className="text-red-4">
                          {formatNumber(
                            data?.orderChangedObj?.totalTakenCustomerAmount
                          )}
                        </p>
                      </div>
                    )}
                  {data?.orderChangedObj?.totalPaidShopAmount !==
                    data?.orderObj?.totalPaidShopAmount && (
                      <div className="flex gap-1 flex-wrap">
                        <p>Thanh toán cho shop:</p>
                        <p className="text-red-4">
                          {formatNumber(
                            data?.orderChangedObj?.totalPaidShopAmount
                          )}
                        </p>
                      </div>
                    )}
                  {data?.orderChangedObj?.typeShowItem !==
                    data?.orderObj?.typeShowItem && (
                      <div className="flex gap-1 flex-wrap">
                        <p>Hướng dẫn giao hàng:</p>
                        <p className="text-red-4">
                          {getDeliveryInstruction(
                            data?.orderChangedObj?.typeShowItem
                          )}
                        </p>
                      </div>
                    )}
                  {data?.orderChangedObj?.isCustomerPaid !==
                    data?.orderObj?.isCustomerPaid && (
                      <div className="flex gap-1 flex-wrap">
                        <p>Khách hàng trả phí:</p>
                        <p className="text-red-4">
                          {data?.orderChangedObj?.isCustomerPaid
                            ? "true"
                            : "false"}
                        </p>
                      </div>
                    )}
                  {data?.orderChangedObj?.partialDeliverySelection !==
                    data?.orderObj?.partialDeliverySelection && (
                      <div className="flex gap-1 flex-wrap">
                        <p>Giao hàng 1 phần chọn sản phẩm:</p>
                        <p className="text-red-4">
                          {data?.orderChangedObj?.partialDeliverySelection
                            ? "true"
                            : "false"}
                        </p>
                      </div>
                    )}
                  {data?.orderChangedObj?.partialDeliveryReturn !==
                    data?.orderObj?.partialDeliveryReturn && (
                      <div className="flex gap-1 flex-wrap">
                        <p>Giao hàng 1 phần đổi trả hàng:</p>
                        <p className="text-red-4">
                          {data?.orderChangedObj?.partialDeliveryReturn
                            ? "true"
                            : "false"}
                        </p>
                      </div>
                    )}
                  {data?.orderChangedObj?.callbackShop !==
                    data?.orderObj?.callbackShop && (
                      <div className="flex gap-1 flex-wrap">
                        <p>
                          Gọi shop khi khách không nhận hàng, không liên lạc được,
                          sai thông tin:
                        </p>
                        <p className="text-red-4">
                          {data?.orderChangedObj?.callbackShop ? "true" : "false"}
                        </p>
                      </div>
                    )}
                  {data?.orderChangedObj?.notDeliveredCollection !==
                    data?.orderObj?.notDeliveredCollection ||
                    (data?.orderChangedObj?.notDeliveredFee !==
                      data?.orderObj?.notDeliveredFee && (
                        <div className="flex gap-1 flex-wrap">
                          <p>Không giao được thu</p>
                          <p className="text-red-4">{`${data?.orderChangedObj?.notDeliveredCollection} ${data?.orderChangedObj?.notDeliveredFee}`}</p>
                        </div>
                      ))}
                  {data?.orderChangedObj?.isSealedProduct !==
                    data?.orderObj?.isSealedProduct && (
                      <div className="flex gap-1 flex-wrap">
                        <p>Hàng nguyên hộp</p>
                        <p className="text-red-4">
                          {data?.orderChangedObj?.isSealedProduct
                            ? "true"
                            : "false"}
                        </p>
                      </div>
                    )}
                  {data?.orderChangedObj?.isDocument !==
                    data?.orderObj?.isDocument && (
                      <div className="flex gap-1 flex-wrap">
                        <p>Thư tín, tài liệu</p>
                        <p className="text-red-4">
                          {data?.orderChangedObj?.isDocument ? "true" : "false"}
                        </p>
                      </div>
                    )}
                  {data?.orderChangedObj?.isFragileItem !==
                    data?.orderObj?.isFragileItem && (
                      <div className="flex gap-1 flex-wrap">
                        <p>Hàng hóa dễ vỡ</p>
                        <p className="text-red-4">
                          {data?.orderChangedObj?.isFragileItem
                            ? "true"
                            : "false"}
                        </p>
                      </div>
                    )}
                  {data?.orderChangedObj?.isAgriculturalItem !==
                    data?.orderObj?.isAgriculturalItem && (
                      <div className="flex gap-1 flex-wrap">
                        <p>Nông sản/thực phẩm khô</p>
                        <p className="text-red-4">
                          {data?.orderChangedObj?.isAgriculturalItem
                            ? "true"
                            : "false"}
                        </p>
                      </div>
                    )}
                  {data?.orderChangedObj?.pickWorkShift !==
                    data?.orderObj?.pickWorkShift && (
                      <div className="flex gap-1 flex-wrap">
                        <p>Thời gian lấy hàng</p>
                        <p className="text-red-4">
                          {getPickWorkShift(data?.orderChangedObj?.pickWorkShift)}
                        </p>
                      </div>
                    )}
                </div>
              )}
            </>

          )}
          <div className="w-full lg:w-1/4 flex items-center lg:flex-col lg:gap-y-2 border-t border-gray-2">
            <div className="flex flex-col gap-y-1 w-3/5 lg:w-full">
              <div className="w-full font-semibold lg:hidden py-2">
                {data?.orderObj && data?.orderChangedObj && (
                  <h4 className="text-left">
                    {t("table.tableTitle.InformationNeedsUpdating")}
                  </h4>
                )}
              </div>
              <p className="w-full">
                <span className="font-semibold">
                  {t("table.tableContent.Status")}:
                </span>{" "}
                {data?.requestStatus}
              </p>
              <p className="w-full">
                <span className="font-semibold">
                  {t("table.tableContent.Sender")}:
                </span>{" "}
                {data?.sender}
              </p>
              {data?.handler && (
                <div className="w-full flex gap-1 flex-wrap">
                  <p className="font-semibold">Người thực hiện:</p>
                  <p>{data?.handler}</p>
                </div>
              )}
              {data?.requestStatus === "Đang xử lý" && (
                <p className="w-full">
                  <span className="font-semibold">Thời gian xác nhận:</span>{" "}
                  {formatDate(data?.confirmedTime, DATE_FORMAT)}
                </p>
              )}
              {data?.requestStatus === "Chấp nhận" && (
                <p className="w-full">
                  <span className="font-semibold">Thời gian duyệt:</span>{" "}
                  {formatDate(data?.approvedTime, DATE_FORMAT)}
                </p>
              )}
              {data?.requestStatus === "Từ chối" && (
                <div className="w-full">
                  <p>
                    <span className="font-semibold">Thời gian từ chối:</span>{" "}
                    <span>{formatDate(data?.rejectedTime, DATE_FORMAT)}</span>
                  </p>
                </div>
              )}
              {data?.rejectedReason && (
                <p className="w-full">
                  <span className="font-semibold">Lý do:</span>{" "}
                  {data?.rejectedReason}
                </p>
              )}
            </div>
            {organizations["manageRequest"] === EDIT && isRequest && (
              <div className="flex gap-2">
                <Button
                  className="bg-gray-1 w-[100px] p-2 height-[auto] leading-none hover:!border-main-3 hover:!text-white hover:!bg-main-3"
                  onClick={() => commentFunc(data.id)}
                >
                  {t(`table.tableButton.Comment`)}
                </Button>
              </div>
            )}
          </div>
        </div>
        {tagList && (
          <div className="ml-4 pb-4">
            <HashtagList
              dataChooseHashtagList={data?.tags}
              hashtagList={tagList}
              recordId={data?.id}
              deleteService={deleteHashtagForRequest}
              addService={addHashtagForRequest}
              tableName={data?.tableName}
              page="manageRequest"
            />
          </div>
        )}
      </div>
    </StyledRequestBlock>
  );
};
