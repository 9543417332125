import { Button, Input } from "antd";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { IoCaretBackSharp, IoCaretForwardSharp } from "react-icons/io5";
import { useDebounce } from "use-debounce";

const Pagination = ({
  page,
  setPage,
  totalPage,
}: {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  totalPage: number;
}) => {
  const [temPage, setTemPage] = useState(page);
  const ref: any = useRef();
  const [pageDebounce] = useDebounce(temPage, 1000);
  const handleChangePagination = (value: any) => {
    if (parseInt(value) < 1) {
      setTemPage(1);
      return;
    }
    if (parseInt(value) > totalPage) {
      setTemPage(totalPage);
      return;
    }
    setTemPage(parseInt(value));
  };

  useEffect(() => {
    if (!isNaN(temPage)) {
      setPage(temPage);
      ref.current.blur();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageDebounce]);

  useEffect(() => {
    if (temPage !== page) setTemPage(page);
  }, [page]);

  return (
    <div className=" flex flex-row justify-center w-full mt-6">
      <div className="flex flex-row items-center gap-x-4">
        <button
          className="!border-none p-2 h-[auto]"
          onClick={() => page > 1 && setPage((prev) => prev - 1)}
        >
          <IoCaretBackSharp className="text-xl md:text-2xl" />
        </button>
        <p className="font-semibold text-sm md:text-base">
          Page {page} of {totalPage}
        </p>
        <button
          className="!border-none p-2 h-[auto]"
          onClick={() => page < totalPage && setPage((prev) => prev + 1)}
        >
          <IoCaretForwardSharp className="text-xl md:text-2xl" />
        </button>
        <div>
          <Input
            ref={ref}
            className="py-2 px-1 leading-none w-14 h-[32px] !border-black text-sm md:text-base text-center [-moz-appearance:_textfield] [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none"
            type="number"
            // min={1}
            max={totalPage}
            value={temPage}
            onChange={(e) => handleChangePagination(e.target.value)}
            required
          />
        </div>
      </div>
    </div>
  );
};

export default Pagination;
