import { Section } from "@components/section/Section";
import DeliveryPriceFilter from "./DeliveryPriceFilter";
import { useEffect, useState } from "react";
import DeliveryPriceList from "./DeliveryPriceList";
import Pagination from "@components/pagination";
import { Geo } from "@models/Geo";

const DeliveryPrice = () => {
  const [deliveryPrices, setDeliveryPrices] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [provinces, setProvinces] = useState<Geo[]>([]);
  const [districts, setDistricts] = useState<Geo[]>([]);

  useEffect(()=>{
    document.title = "Giá giao - Go24";
  },[]);

  return (
    <div className="flex flex-col gap-y-8">
      <Section title="Giá giao">
        <div className="flex gap-8 items-center">
          <div className="flex items-center justify-between px-4 bg-white w-full rounded-lg shadow-lg">
            <div className="text-base text-black font-normal px-2 py-4 border-b border-main-1 cursor-pointer">
              Quản lý bảng giá
            </div>
          </div>
        </div>
        <DeliveryPriceFilter
          {...{
            page,
            setTotalPage,
            setDeliveryPrices,
            provinces,
            districts,
            setProvinces,
            setDistricts,
          }}
        />
        <DeliveryPriceList {...{ deliveryPrices, provinces, districts }} />
        <Pagination {...{ page, setPage, totalPage }} />
      </Section>
    </div>
  );
};

export default DeliveryPrice;
