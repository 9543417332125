import { Popconfirm } from "antd";
import styled from "styled-components";

export const WrapperShadowStyle = styled.div`
  box-shadow: -2px -2px 4px 4px #d1d1d126;
`;

export const WrapperShadowDetailRequestStyle = styled.div`
  box-shadow: -1px -1px 4px 4px rgba(209, 209, 209, 0.5);
`;

export const WrapperMessages = styled.div`
  .cus-message {
    img {
      opacity: 0;
    }
  }

  .my-message + .cus-message {
    img {
      opacity: 1;
    }
  }

  .message:nth-child(1) {
    img {
      opacity: 1;
    }
  }
`;
