import { hashReturned } from "@services/send-request";
import { Popover, message } from "antd";
import { DeliveryPartners, ORDER_STATUS } from "constants/constants";
import { useEffect, useState } from "react";

const requestList = [
  { text: "Giao lại", value: "re-delivery" },
  { text: "Duyệt hoàn", value: "return" },
  { text: "Xuất giao đơn mới", value: "re-new" },
  { text: "Hẹn ngày lấy / giao / trả", value: "order-reschedule" },
  { text: "Push lấy / giao / trả", value: "order-push" },
  { text: "Khiếu nại", value: "complain" },
  { text: "Xác nhận đã trả", value: "has-returned" },
];

const OrderRequest = ({
  order,
  openRequest,
  setOpenRequest,
  sendingRequest,
  editOrderFunc,
  customButton,
}: any) => {
  const [isShow, setIsShow] = useState(false);

  const handleShow = () => {
    setIsShow((pre) => !pre);
    sendingRequest(order);
  };

  const isDisabledItem = (requestType: string, item: any) => {
    const statusId = item.statusId.toString();
    switch (requestType) {
      case "return":
        if (
          statusId === ORDER_STATUS.PickedUp.code ||
          statusId === ORDER_STATUS.Transporting.code ||
          statusId === ORDER_STATUS.Stored.code ||
          statusId === ORDER_STATUS.Delivering.code ||
          statusId === ORDER_STATUS.DeliveryFailed.code
        ) {
          return false;
        }
        break;
      case "re-delivery": {
        if (statusId === ORDER_STATUS.DeliveryFailed.code) {
          return false;
        }
        break;
      }
      case "re-new": {
        if (
          item.partnerDeliveryId === DeliveryPartners.GHTK ||
          item.partnerDeliveryId === DeliveryPartners.GHTK2 ||
          item.partnerDeliveryId === DeliveryPartners.GHTK3
        ) {
          if (
            statusId === ORDER_STATUS.PickedUp.code ||
            statusId === ORDER_STATUS.Transporting.code ||
            statusId === ORDER_STATUS.Stored.code ||
            statusId === ORDER_STATUS.Delivering.code ||
            statusId === ORDER_STATUS.PartiallyReturning.code ||
            statusId === ORDER_STATUS.DeliveryFailed.code
          ) {
            return false;
          }
        }
        break;
      }
      case "order-reschedule":
      case "order-push":
      case "complain":
        return false;
      case "has-returned":
        if (
          statusId === ORDER_STATUS.WaitingReturn.code ||
          statusId === ORDER_STATUS.PartiallyReturning.code ||
          statusId === ORDER_STATUS.Returned.code ||
          statusId === ORDER_STATUS.PartiallyReturned.code ||
          statusId === ORDER_STATUS.PartiallyDelivered.code
        ) {
          return false;
        }
        break;
      default:
        break;
    }

    return true;
  };

  const onReDelivery = (item: any) => {
    setOpenRequest(item);
  };
  const onComplain = (item: any) => {
    setOpenRequest(item);
  };
  const onReschedule = (item: any) => {
    setOpenRequest(item);
  };
  const onRenew = (item: any) => {
    setIsShow(false);
    editOrderFunc(true);
  };

  const onReturn = (item: any) => {
    const statusId = order.statusId.toString();
    if (
      statusId !== ORDER_STATUS.PickedUp.code &&
      statusId !== ORDER_STATUS.Transporting.code &&
      statusId !== ORDER_STATUS.Stored.code &&
      statusId !== ORDER_STATUS.Delivering.code &&
      statusId !== ORDER_STATUS.DeliveryFailed.code
    ) {
      message.error("Trạng thái không khả dụng");
      return;
    }
    setOpenRequest(item);
  };

  const onHashReturned = async (item: any) => {
    if (order.hasReturned) {
      message.error("Đơn hàng đã hoàn trả");
      return;
    }
    try {
      const res = await hashReturned(order.id);
      if (res) {
        message.success("Cập nhật thành công");
        setIsShow(false);
        return;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onExecuteSendRequest = (item: any, order: any) => {
    switch (item?.value) {
      case "return":
        onReturn(item);
        break;
      case "re-delivery":
        onReDelivery(item);
        break;
      case "re-new":
        onRenew(item);
        break;
      case "order-reschedule":
        onReschedule(item);
        break;
      case "order-push":
        setOpenRequest(item);
        break;
      case "complain":
        onComplain(item);
        break;
      case "has-returned":
        onHashReturned(item);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (openRequest?.value) {
      setIsShow(false);
    }
  }, [openRequest]);

  return (
    <Popover
      title=""
      trigger="click"
      open={isShow}
      onOpenChange={handleShow}
      placement="bottomRight"
      arrow={false}
      content={
        <div className="">
          <p className="text-black text-base font-semibold py-2 border-b px-4">
            Chọn yêu cầu
          </p>
          <div className="mt-3 flex flex-col">
            {requestList.map((item) => (
              <div key={item.value} className="px-4 py-1">
                <button
                  className={`bg-gray-2 hover:bg-gray-1 px-2 py-1 rounded text-sm ${
                    isDisabledItem(item.value, order) && "opacity-50"
                  }`}
                  disabled={isDisabledItem(item.value, order)}
                  onClick={() => onExecuteSendRequest(item, order)}
                >
                  {item.text}
                </button>
              </div>
            ))}
          </div>
          <div className="w-1 h-3"></div>
        </div>
      }
    >
      {customButton ? (
        <button className="h-8 flex justify-center items-center px-4 rounded bg-main-3 text-white">
          Gửi yêu cầu
        </button>
      ) : (
        <button className="bg-gray-1 rounded text-xs md:text-sm p-2 lg:min-w-[100px] h-[auto] leading-none hover:!border-main-3 hover:!text-white hover:bg-main-3">
          Gửi yêu cầu
        </button>
      )}
    </Popover>
  );
};

export default OrderRequest;
