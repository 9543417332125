import { Button, Input, message } from "antd";
import { useAuth } from "provider/auth";
import { useEffect, useState } from "react";
import CameraIcon from "@assets/image/shop-management/camera.svg";
import { api, go24Api } from "provider/api";
import { phoneRegExp, validateEmail } from "@helper/helper";
import ChangePhoneNumber from "./ChangePhoneNumber";

const AccountInformation = () => {
  const { user, setUser } = useAuth();
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [otpId, setOtpId] = useState("");

  const handleUpdatePhoneNumber = async () => {
    try {
      const res = await go24Api.post("/me/otp", {
        phoneNumber: user?.phoneNumber,
        key: user?.phoneNumber,
        type: 3,
        data: "",
      });
      if (res && res?.data) {
        setOtpId(res?.data?.data);
      }
    } catch (e) { }
    setOpenChangePassword(true);
  };

  const validateInfoForm = () => {
    if (!fullName) {
      message.error("Họ và tên là bắt buộc");
      return false;
    }
    if (!phoneNumber) {
      message.error("Số điện thoại là bắt buộc");
      return false;
    }
    if (!phoneRegExp.test(phoneNumber)) {
      message.error("Số điện thoại không đúng định dạng");
      return false;
    }
    if (!email) {
      message.error("Email là bắt buộc");
      return false;
    }
    if (!validateEmail(email)) {
      message.error("Email không đúng định dạng");
      return false;
    }
    return true;
  };

  const handleUpdateInfo = async () => {
    if (!validateInfoForm()) {
      return;
    }
    const payload = {
      fullName,
      phoneNumber,
      email,
    };
    try {
      const res = await api.put(`/partner/update`, payload);
      if (res) {
        message.success("Cập nhật thành công");
        const info = res?.data?.response;
        setUser(info);
      }
    } catch (e) {
      message.error("Cập nhật thất bại");
    }
  };

  useEffect(() => {
    if (user) {
      const { fullName, phoneNumber, email } = user;
      setFullName(fullName);
      setPhoneNumber(phoneNumber);
      setEmail(email);
    }
  }, [user]);

  return (
    <div>
      <div className="mt-4 rounded-lg shadow-md bg-white p-6">
        <div className="flex flex-col md:flex-row gap-6">
          <div className="flex justify-center items-center md:w-1/2">
            <img
              src={
                user?.imageUrl
                  ? `${process.env.REACT_APP_GO24_API}${user?.imageUrl}`
                  : CameraIcon
              }
              className="w-48 h-48 rounded-full"
              alt="no-data"
            />
          </div>
          <div className="md:w-1/2">
            <div className="flex flex-col items-start gap-3">
              <p className="text-sm font-medium text-black">
                Họ và tên<span className="text-red"> *</span>
              </p>
              <Input
                className="rounded-lg w-full h-10 border-black border-[0.5px]"
                placeholder="Nhập họ và tên"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </div>
            <div className="mt-6 flex flex-col items-start gap-3">
              <p className="text-sm font-medium text-black">
                Số điện thoại<span className="text-red"> *</span>
              </p>
              <div className="relative w-full">
                <Input
                  className="rounded-lg w-full h-10 border-black border-[0.5px]"
                  value={phoneNumber}
                  disabled
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="Nhập số điện thoại"
                />
                <div
                  className="absolute top-[10px] right-4 text-blue-2 cursor-pointer text-sm"
                  onClick={handleUpdatePhoneNumber}
                >
                  Cập nhật
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6 flex md:flex-row flex-col gap-6">
          <div className="w-full md:w-1/2">
            <div className="flex flex-col items-start gap-3">
              <p className="text-sm font-medium text-black">
                Email<span className="text-red"> *</span>
              </p>
              <Input
                className="rounded-lg w-full h-10 border-black border-[0.5px]"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Nhập email"
              />
            </div>
          </div>
          <div className="w-full md:w-1/2">
            <div className="flex flex-col items-start gap-3">
              <p className="text-sm font-medium text-black">Khu vực quản lý</p>
              <Input
                disabled
                className="rounded-lg w-full h-10 border-black border-[0.5px]"
                value={user?.managementArea}
              />
            </div>
          </div>
        </div>
      </div>
      <button
        className="w-40 h-10 !border-none rounded mt-4 px-6 py-2 bg-main-2 text-base font-medium text-white hover:bg-main-4"
        onClick={handleUpdateInfo}
      >
        Cập nhật
      </button>
      {openChangePassword && (
        <ChangePhoneNumber
          open={openChangePassword}
          onCancel={() => setOpenChangePassword(false)}
          otpId={otpId}
          setOtpId={setOtpId}
        />
      )}
    </div>
  );
};

export default AccountInformation;
