import { Section } from "@components/section/Section";
import React from "react";
import { WrapperShadowStyle } from "./styles";
import { REQUEST_TYPE } from "constants/constants";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { IRequestType } from ".";

interface IFilterRequestTypes {
  requestTypes: IRequestType[];
  filterType: string;
  handleChangeFilter: (code: any) => void;
}

const FilterRequestTypes = ({
  requestTypes,
  filterType,
  handleChangeFilter,
}: IFilterRequestTypes) => {
  const { t } = useTranslation();

  const getRequestTypeCount = (code: any) => {
    const findType = requestTypes.find((type) => type.requestType === code);
    if (findType && Object.entries(findType)) {
      return findType.requestTypeCount;
    }
    return 0;
  };

  return (
    <Section>
      <WrapperShadowStyle>
        <div className="flex flex-row flex-wrap gap-x-3 gap-y-3 rounded bg-white py-4 px-4 w-full">
          {Object.values(REQUEST_TYPE).map((status) => (
            <Button
              key={status.key}
              className={`${
                filterType === status.code
                  ? "bg-main-3 text-white border-main-3"
                  : "bg-gray-1"
              } text-sm p-2 height-[auto] leading-none hover:!border-main-3 hover:!text-white hover:!bg-main-3`}
              onClick={() => handleChangeFilter(status.code)}
            >
              {t(`requestType.${status.key}`)} (
              <>{getRequestTypeCount(status.code)}</> )
            </Button>
          ))}
        </div>
      </WrapperShadowStyle>
    </Section>
  );
};

export default FilterRequestTypes;
