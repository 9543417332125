import {
  formatDate,
  formatNumber,
  renderCODStatus,
  renderOrderPaidStatus,
  renderOrderStatus,
} from "@helper/helper";
import {
  attachHashtagForOrder,
  deleteHashtagForOrder,
} from "@services/hashtag-services";
import { Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyledOrderBlock } from "../../components/block/styles";
import { AUTHORIZE, DeliveryPartners, ORDER_STATUS } from "constants/constants";
import HashtagList from "@components/hashtag-list";
import OrderRequest from "./OrderRequest";
import { Link } from "react-router-dom";
import { useAuth } from "provider/auth";

const { EDIT } = AUTHORIZE;

type OrderBlockProps = {
  data: any;
  isChecked: boolean;
  onCheckFunc: () => void;
  showDetailsFunc: () => void;
  editOrderFunc: any;
  trackingOrderFunc: () => void;
  hashtagList: any;
  openRequest: any;
  setOpenRequest: any;
  sendingRequest: any;
  deliveries: any[];
};

const DATE_FORMAT = "DD/MM/YYYY";

export const OrderBlock: React.FC<OrderBlockProps> = ({
  data,
  isChecked,
  onCheckFunc,
  showDetailsFunc,
  editOrderFunc,
  trackingOrderFunc,
  hashtagList,
  openRequest,
  setOpenRequest,
  sendingRequest,
  deliveries,
}) => {
  const { organizations } = useAuth();
  const { t } = useTranslation();
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  const statusAlowEdit = [
    ORDER_STATUS.WaitingConfirm.code,
    ORDER_STATUS.WaitingTakeOrder.code,
    ORDER_STATUS.Delivering.code,
    ORDER_STATUS.PickedUp.code,
    ORDER_STATUS.Transporting.code,
    ORDER_STATUS.Stored.code,
    ORDER_STATUS.DeliveryFailed.code,
  ];

  const allowEditOrder = (id: string) => {
    return statusAlowEdit.includes(id);
  };

  useEffect(() => {
    const statusId = data?.statusId?.toString();
    let timeLeft: number = 0;
    if (
      statusId === ORDER_STATUS.DeliveryFailed.code &&
      data?.deliveryStatusDate
    ) {
      if (
        data?.partnerDeliveryId === DeliveryPartners.GHN ||
        data?.partnerDeliveryId === DeliveryPartners.GHN2 ||
        data?.partnerDeliveryId === DeliveryPartners.GHTK ||
        data?.partnerDeliveryId === DeliveryPartners.GHTK2 ||
        data?.partnerDeliveryId === DeliveryPartners.GHTK3
      ) {
        timeLeft = 70 * 60 * 60 * 1000;
      } else if (data?.partnerDeliveryId === DeliveryPartners.Ninjavan) {
        timeLeft = 24 * 60 * 60 * 1000;
      } else {
        timeLeft = 0;
      }
      timeLeft =
        timeLeft -
        (new Date().getTime() - new Date(data?.deliveryStatusDate).getTime());
      timeLeft = timeLeft < 0 ? 0 : timeLeft;
      const interval = setInterval(() => {
        if (timeLeft > 0) {
          timeLeft = timeLeft - 1000;
          timeLeft = timeLeft < 0 ? 0 : timeLeft;

          setHours(Math.floor(timeLeft / 1000 / 60 / 60));
          setMinutes(
            Math.floor((timeLeft - hours * 60 * 60 * 1000) / 1000 / 60)
          );
        }
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, []);

  return (
    <StyledOrderBlock className="flex flex-row bg-white rounded-md overflow-hidden">
      <div className="overflow-x-auto w-full">
        <div className="flex flex-col w-full text-start leading-normal">
          <div className="flex">
            <div className="hidden lg:flex justify-center items-center px-4">
              <Checkbox
                className="scale-150"
                checked={isChecked}
                onClick={onCheckFunc}
              />
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 w-full lg:border-b border-gray-1 justify-between items-start gap-y-3 p-2 lg:py-4 gap-x-2 text-sm leading-none">
              <div className="grid grid-cols-2 justify-between lg:justify-normal gap-x-2 pb-3 lg:pb-0 border-b border-b-gray-1 lg:border-none">
                <div className="flex gap-2">
                  <Checkbox
                    className="lg:hidden scale-[1.2]"
                    checked={isChecked}
                    onClick={onCheckFunc}
                  />
                  <div className="flex flex-col gap-y-2">
                    <p className="font-semibold text-blue-4 w-full break-words">
                      {data?.code}
                    </p>
                    {/* <p className="font-semibold text-black-1 w-full">
                      {data?.shopUsername}
                    </p> */}
                    <p className="font-semibold w-full">
                      {data?.shopPhoneNumber}
                    </p>
                    <p className="w-full">
                      {formatDate(data?.createdDate, DATE_FORMAT)}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col items-end gap-y-2">
                  <p className="font-semibold text-black-1 lg:w-full">
                    {data?.fullName}
                  </p>
                  <p className="lg:w-full font-semibold">{data?.phoneNumber}</p>
                </div>
              </div>
              <div className="grid grid-cols-2 justify-between lg:justify-normal gap-x-2 pb-3 lg:pb-0 border-b border-b-gray-1 lg:border-none">
                <div className="flex flex-col gap-y-2">
                  {data?.deliveryCode ? (
                    <Link
                      to={`${
                        deliveries.find(
                          (item) => item.id === data.partnerDeliveryId
                        )?.url
                      }${data.deliveryCode}`}
                      target="_blank"
                    >
                      <p className="font-semibold text-blue-4 w-full break-words">
                        {data?.deliveryCode
                          ? data?.deliveryCode
                          : `(${t("RequestsManagement.NotCreateOrder")})`}
                      </p>
                    </Link>
                  ) : (
                    <p className="font-semibold text-blue-4 w-full break-words">
                      {t("RequestsManagement.NotCreateOrder")}
                    </p>
                  )}
                  <p className="font-semibold text-red-3 w-full">
                    {data?.deliveryName}
                  </p>
                </div>
                <div className="text-right lg:text-center">
                  <p className="font-semibold text-black-1 w-full">
                    {t(
                      `orderStatus.${renderOrderStatus(
                        data?.statusId?.toString()
                      )}`
                    )}
                  </p>
                  {(data?.statusId === 3 || data?.statusId === 12) && (
                    <p className="mt-1 w-full">
                      {formatDate(data?.deliveredDate, DATE_FORMAT)}
                    </p>
                  )}
                  {(data?.statusId === 5 || data?.statusId === 13) && (
                    <p className="mt-1 w-full">
                      {formatDate(data?.returnedDate, DATE_FORMAT)}
                    </p>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-2 justify-between lg:justify-normal gap-x-2 pb-3 lg:pb-0 border-b border-b-gray-1 lg:border-none">
                <div className="lg:text-center">
                  <p className="font-semibold text-black-1 w-full">
                    {t(
                      `orderPaidStatus.${renderOrderPaidStatus(
                        data?.paidStatusId?.toString()
                      )}`
                    )}
                  </p>
                  {data?.paidStatusId === 3 && (
                    <p className="mt-1 w-full">
                      {formatDate(data?.paidDate, DATE_FORMAT)}
                    </p>
                  )}
                </div>
                <div className="text-right lg:text-center">
                  <p className="font-semibold text-black-1 w-full">
                    {t(
                      `CODStatus.${renderCODStatus(
                        data?.codStatusId?.toString()
                      )}`
                    )}
                  </p>
                  {data?.codStatusId === 2 && (
                    <p className="mt-1 w-full">
                      {formatDate(data?.paidpaidCODDate, DATE_FORMAT)}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full">
            <div className="flex flex-col lg:flex-row justify-between items-start pl-2 lg:pl-14 pr-2 lg:pt-4 pb-2 gap-x-2 text-sm leading-normal">
              <div className="flex w-full flex-col lg:flex-row gap-x-2">
                <div className="flex flex-col lg:gap-y-1 w-full lg:w-[300px] pb-2 lg:pb-0 border-b border-b-gray-1 lg:border-none">
                  <p className="font-semibold text-black-1 w-full">
                    {t("table.tableContent.ShopOrderCode")}
                  </p>
                  <p className="text-black-1 w-full">
                    {t("table.tableContent.TotalRevenue")}:{" "}
                    {formatNumber(data?.totalTakenCustomerAmount)}
                  </p>
                </div>
                <div className="flex flex-col py-2 lg:py-0 gap-y-1 justify-end pb-2 lg:pb-0 border-b border-b-gray-1 lg:border-none">
                  <p className="text-black-1 w-full">
                    {t("table.tableContent.PaymentToShop")}:{" "}
                    <span className="font-semibold text-red-3">
                      {formatNumber(data?.totalPaidShopAmount)}
                    </span>
                  </p>
                </div>
              </div>
              <div className="pt-2 lg:pt-0 relative h-full w-full flex flex-row justify-center lg:justify-end gap-x-2 pb-2 lg:pb-0 border-b border-b-gray-1 lg:border-none">
                <button
                  className="bg-gray-1 rounded text-xs md:text-sm p-2 md:min-w-[100px] h-[auto] leading-none hover:!border-main-3 hover:!text-white hover:bg-main-3"
                  onClick={showDetailsFunc}
                >
                  {t("table.tableButton.Details")}
                </button>
                {allowEditOrder(data?.statusId?.toString()) &&
                  organizations["manageOrder"] === EDIT && (
                    <button
                      className="bg-gray-1 rounded text-xs md:text-sm p-2 md:min-w-[100px] h-[auto] leading-none hover:!border-main-3 hover:!text-white hover:bg-main-3"
                      onClick={() => editOrderFunc(false)}
                    >
                      {t("table.tableButton.EditOrder")}
                    </button>
                  )}
                {organizations["manageOrder"] === EDIT && (
                  <OrderRequest
                    order={data}
                    openRequest={openRequest}
                    setOpenRequest={setOpenRequest}
                    sendingRequest={sendingRequest}
                    editOrderFunc={editOrderFunc}
                  />
                )}
                {data?.statusId !== 0 && (
                  <button
                    className="bg-gray-1 rounded text-xs md:text-sm p-2 md:min-w-[100px] h-[auto] leading-none hover:!border-main-3 hover:!text-white hover:bg-main-3"
                    onClick={trackingOrderFunc}
                  >
                    {t("table.tableButton.TrackingOrder")}
                  </button>
                )}
              </div>
            </div>
            {data?.note && (
              <div className="flex gap-1 px-2 pb-1 text-sm">
                <p className="font-medium">{t("RequestsManagement.Note")}:</p>
                <p>{data?.note}</p>
              </div>
            )}
            {data?.statusId === 6 && (
              <div className="px-2 pb-1 text-sm text-red">
                Đơn hàng không giao được hàng chờ shop check:{" "}
                <span className="font-semibold">{`${hours} giờ ${minutes} phút`}</span>
              </div>
            )}
          </div>
          <div className="pb-2 lg:pb-4 pl-2 lg:pl-14">
            <HashtagList
              dataChooseHashtagList={data?.tags}
              hashtagList={hashtagList}
              recordId={data?.id}
              deleteService={deleteHashtagForOrder}
              addService={attachHashtagForOrder}
              page="manageOrder"
            />
          </div>
        </div>
      </div>
    </StyledOrderBlock>
  );
};
