export const ORDER_STATUS = {
  All: {
    code: "total",
    key: "All",
  },
  WaitingConfirm: {
    code: "0",
    key: "WaitingConfirm",
  },
  WaitingTakeOrder: {
    code: "1",
    key: "WaitingTakeOrder",
  },
  PickedUp: {
    code: "8",
    key: "PickedUp",
  },
  Transporting: {
    code: "9",
    key: "Transporting",
  },
  Stored: {
    code: "10",
    key: "Stored",
  },
  Delivering: {
    code: "2",
    key: "Delivering",
  },
  Deliveried: {
    code: "3",
    key: "Deliveried",
  },
  PartiallyDelivered: {
    code: "12",
    key: "PartiallyDelivered",
  },
  DeliveryFailed: {
    code: "6",
    key: "DeliveryFailed",
  },
  WaitingReturn: {
    code: "4",
    key: "WaitingReturn",
  },
  PartiallyReturning: {
    code: "11",
    key: "PartiallyReturning",
  },
  Returned: {
    code: "5",
    key: "Returned",
  },
  PartiallyReturned: {
    code: "13",
    key: "PartiallyReturned",
  },
  Canceled: {
    code: "-1",
    key: "Canceled",
  },
  Incident: {
    code: "7",
    key: "Incident",
  },
  Reimbursement: {
    code: "-2",
    key: "Reimbursement",
  },
  ReimbursementDelivery: {
    code: "-3",
    key: "ReimbursementDelivery",
  },
};

export const REQUEST_TYPE = {
  Total: {
    key: "Total",
    code: "total",
  },
  Edit: {
    key: "Edit",
    code: "edit",
  },
  ReDelivery: {
    key: "ReDelivery",
    code: "re-delivery",
  },
  PickupAppointment: {
    key: "PickupAppointment",
    code: "reschedule-pickup",
  },
  Complain: {
    key: "Complain",
    code: "complain",
  },
  RescheduleDelivering: {
    key: "RescheduleDelivering",
    code: "reschedule-delivering",
  },
  Delivery: {
    key: "Delivery",
    code: "push-delivering",
  },
  PushReturn: {
    key: "PushReturn",
    code: "push-return",
  },
  Reschedule: {
    key: "Reschedule",
    code: "reschedule",
  },
  Pickup: {
    key: "Pickup",
    code: "push-pickup",
  },
  Renew: {
    key: "Renew",
    code: "renew",
  },
  Return: {
    key: "Return",
    code: "return",
  },
};

export const ORDER_PAID_STATUS = {
  All: {
    code: "total",
    key: "All",
  },
  None: {
    code: "0",
    key: "None",
  },
  NotPaid: {
    code: "1",
    key: "NotPaid",
  },
  WaitingPaid: {
    code: "2",
    key: "WaitingPaid",
  },
  Done: {
    code: "3",
    key: "Done",
  },
};

export const COD_STATUS = {
  All: {
    code: "total",
    key: "All",
  },
  None: {
    code: "0",
    key: "None",
  },
  WaittingCOD: {
    code: "1",
    key: "WaitingCOD",
  },
  Done: {
    code: "2",
    key: "CODDone",
  },
};

export const DELIVERY_STATUS = {
  All: {
    code: "total",
    key: "All",
  },
  None: {
    code: "0",
    key: "None",
  },
  DelayPickup: {
    code: "1",
    key: "DelayPickup",
  },
  DelayDelivery: {
    code: "2",
    key: "DelayDelivery",
  },
  WeightChanged: {
    code: "3",
    key: "WeightChanged",
  },
  ReturnPartPackage: {
    code: "4",
    key: "ReturnPartPackage",
  },
  RetryDelivery: {
    code: "5",
    key: "RetryDelivery",
  },
  Delivered: {
    code: "8",
    key: "Delivered",
  },
};

export const FILTER_DELIVERY_STATUS = {
  All: {
    code: "total",
    key: "All",
  },
  DelayPickup: {
    code: "1",
    key: "DelayPickup",
  },
  DelayDelivery: {
    code: "2",
    key: "DelayDelivery",
  },
  WeightChanged: {
    code: "3",
    key: "WeightChanged",
  },
  ReturnPartPackage: {
    code: "4",
    key: "ReturnPartPackage",
  },
  RetryDelivery: {
    code: "5",
    key: "RetryDelivery",
  },
};

export const PAYER = {
  ShopPaysFees: {
    code: 0,
    key: "ShopPaysFees",
  },
  CustomerPaysFees: {
    code: 1,
    key: "CustomerPaysFees",
  },
};

export const PRINT_TYPE = {
  Printed: {
    code: 1,
    key: "Printed",
  },
  NotPrintedYet: {
    code: 0,
    key: "NotPrintedYet",
  },
};

export const REQUEST_STATUS_TYPE = {
  All: {
    code: undefined,
    key: "All",
  },
  PendingProcessing: {
    code: 0,
    key: "PendingProcessing",
  },
  Processed: {
    code: 1,
    key: "Processed",
  },
};

export const ADDED_SERVICE = {
  PartiallyDelivery: {
    code: 1,
    key: "PartiallyDelivery",
  },
  Fragile: {
    code: 2,
    key: "Fragile",
  },
  AgriculturalProductsFoodstuffs: {
    code: 3,
    key: "AgriculturalProducts/Foodstuffs",
  },
  CorrespondenceDocuments: {
    code: 4,
    key: "Correspondence/Documents",
  },
  OriginalBoxedGoods: {
    code: 5,
    key: "OriginalBoxedGoods",
  },
};

export const FILTER_REQUEST_TYPE = {
  Total: {
    code: undefined,
    key: "Total",
  },
  ReDelivery: {
    code: 0,
    key: "ReDelivery",
  },
  Return: {
    code: 1,
    key: "Return",
  },
  Reschedule: {
    code: 2,
    key: "Reschedule",
  },
  Renew: {
    code: 3,
    key: "Renew",
  },
  Edit: {
    code: 4,
    key: "Edit",
  },
};

export const DATE_TYPE = [
  "createdDate",
  "pickupDate",
  "deliveredDate",
  "paidDate",
  "paidCODDate",
  "returnedDate",
  "confirmDate",
  "deliveredReturnedDate",
  "waitingPaidDate",
];

export const DELIVERY_AREA = {
  Provincial: {
    code: 1,
    key: "Provincial",
  },
  InlandRegion: {
    code: 2,
    key: "InlandRegion",
  },
  Interregional: {
    code: 3,
    key: "Interregional",
  },
};

export const RETURN_TYPE = {
  NotComfirmYet: {
    code: 1,
    key: "NotComfirmYet",
  },
  Confirmed: {
    code: 2,
    key: "Confirmed",
  },
};

export const DATE_FORMAT = "DD/MM/YYYY - HH:mm:ss";

export const GET_ACCESS_TOKEN_TIME = 300000;

export const PAGE_SIZE = 5;

export const REQUEST_STATUS = {
  All: { code: 0, key: "All" },
  Accept: { code: 1, key: "Accept" },
  Rejected: { code: 2, key: "Rejected" },
  WaitProgress: { code: 3, key: "WaitProgress" },
  Progressing: { code: 4, key: "Progressing" },
};

export const DASHBOARD_DATE_TYPE = {
  day: { code: "day", key: "Day" },
  week: { code: "week", key: "Week" },
  month: { code: "month", key: "Month" },
  quarter: { code: "quarter", key: "Quarter" },
  year: { code: "year", key: "Year" },
};

export const ORDER_TYPE = [
  {
    id: 0,
    name: "Tiêu chuẩn",
  },
  {
    id: 1,
    name: "Dễ vỡ",
  },
  {
    id: 2,
    name: "Giao hàng 1 phần",
  },
  {
    id: 7,
    name: "Nông sản/thực phẩm khô",
  },
];

export const HASHTAG_TYPE = {
  ORDER: 1,
  REQUEST: 2,
  SHOP: 3,
};

export const STATUS_KYC = [
  {
    id: 1,
    value: "Chưa KYC",
  },
  {
    id: 2,
    value: "Chờ duyệt KYC",
  },
  {
    id: 3,
    value: "Đã xác minh",
  },
  {
    id: 4,
    value: "Từ chối KYC",
  },
];

export const STATUS_COD = [
  {
    id: 1,
    value: "Chưa duyệt hồ sơ",
  },
  {
    id: 2,
    value: "Đã duyệt hồ sơ",
  },
  {
    id: 3,
    value: "Từ chối",
  },
  {
    id: 4,
    value: "Huỷ",
  },
];

export enum DeliveryPartners {
  Ninjavan = 1,
  JetLink = 2,
  GHN = 3,
  GHTK = 4,
  TVC = 5,
  JT = 6,
  VTP = 7,
  VTPPTN = 8,
  DHL = 9,
  VNPOST = 10,
  BESTEXPRESS = 11,
  GHTK2 = 12,
  GHN2 = 13,
  GHTK3 = 14,
  TIKI = 17,
  LEX = 18,
}

export enum ResultCode {
  FailMsg = -2,
  Fail = -1,
  Success = 0,
  Warning = 1,
  error404 = -11,
  CODError = 10,
  CSError = 11,
  CODAndCSError = 21,
  GHNUpdateError = -3,
}

export const PERIOD_TYPE = [
  { key: "date", value: "Theo ngày" },
  { key: "week", value: "Theo tuần" },
  { key: "month", value: "Theo tháng" },
  { key: "quarter", value: "Theo quý" },
  { key: "year", value: "Theo năm" },
];

export const REPORT_DATE_TYPE = [
  { key: "paidDate", value: "Ngày đối soát" },
  { key: "createdDate", value: "Ngày tạo đơn" },
  { key: "pickupDate", value: "Ngày lấy hàng thành công" },
];

export const AUTHORIZE = {
  EDIT: 1,
  READ: 2,
  NONE: 3,
};

const { NONE } = AUTHORIZE;

export const roleGroupInitial = {
  manageSubAccount: NONE,
  manageDashboard: NONE,
  manageShop: NONE,
  manageRequest: NONE,
  manageOrder: NONE,
  manageHashtag: NONE,
  manageDeliveryPrice: NONE,
};
