import { useTranslation } from "react-i18next";
import { SectionAccount } from "./SectionAccount";
import { useEffect, useState } from "react";
import { settingCODTransportersRow } from "./dataSources";

const SettingCODTransporters = ({ shopInfo }: { shopInfo: any }) => {
  const { t } = useTranslation();
  const [shopCod, setShopCod] = useState<any[]>([]);
  const [codTransportersRow, setCodTransportersRow] = useState<any[]>(
    settingCODTransportersRow
  );

  useEffect(() => {
    if (shopInfo?.shopCodDetails?.length > 0) {
      const listCodPartner = [];
      for (const partner of shopInfo?.shopCodDetails || []) {
        listCodPartner.push({
          deliveryPartnerCode: partner?.deliveryPartnerCode,
          deliveredCodPercent: partner?.deliveredCodPercent,
          transportingCodPercent: partner?.transportingCodPercent,
          storedCodPercent: partner?.storedCodPercent,
          deliveringCodPercent: partner?.deliveringCodPercent,
          pickedUpCodPercent: partner?.pickedUpCodPercent,
        });
      }
      setShopCod(listCodPartner);
    }
  }, [shopInfo?.shopCodDetails]);

  useEffect(() => {
    if (shopInfo?.canCODStatus) {
      const list = [];
      for (const item of settingCODTransportersRow) {
        for (const object of shopInfo?.canCODStatus) {
          if (item.status === object) {
            list.push(item);
          }
        }
      }
      setCodTransportersRow(list);
    }
  }, [shopInfo?.canCODStatus]);

  return (
    <SectionAccount
      title={t("accountDetails.SettingCODAdvanceByTransportersStatus")}
    >
      <div className="p-4 md:p-6 bg-white rounded-lg shadow-lg">
        {shopCod?.length === 0 ? (
          <div>Không có dữ liệu</div>
        ) : (
          <div className="max-w-[100%] overflow-x-auto text-sm shadow-lg">
            <div className="min-w-[1300px] rounded-tl-lg rounded-tr-lg bg-gray-1 px-4 py-[10px] flex flex-row justify-between items-center font-semibold">
              <div className="w-[250px]"></div>
              {shopCod.map((column) => (
                <div key={column?.deliveryPartnerCode} className="w-[150px]">
                  {column?.deliveryPartnerCode}
                </div>
              ))}
            </div>
            {codTransportersRow.map((item) => (
              <div
                className={`min-w-[1300px] px-4 py-[10px] flex flex-row justify-between items-center border-b`}
                key={item.key}
              >
                <div className="w-[250px]">{item.noName}</div>
                {shopCod.map((column) => (
                  <div className="w-[150px]" key={column?.deliveryPartnerCode}>
                    {column[item.key]}% / đơn
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
      </div>
    </SectionAccount>
  );
};

export default SettingCODTransporters;
