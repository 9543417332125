import { Tag } from "@models/TagModel";
import { Popover } from "antd";
import { useEffect, useState } from "react";
import { ScrollStyle } from "./styles";
import TagIcon from "@assets/svg/Tag";
import { FaCircleXmark } from "react-icons/fa6";
import { useAuth } from "provider/auth";
import { AUTHORIZE } from "constants/constants";

const { EDIT } = AUTHORIZE;

interface IPopoverHashtag {
  recordId: number;
  hashtagList: Tag[];
  dataChooseHashtagList: Tag[];
  deleteService: any;
  addService: any;
  tableName?: string;
  page: string;
}

const HashtagList = ({
  dataChooseHashtagList,
  hashtagList,
  recordId,
  deleteService,
  addService,
  tableName,
  page,
}: IPopoverHashtag) => {
  const { organizations } = useAuth();
  const [isShow, setIsShow] = useState<boolean>(false);
  const [chooseHashtagList, setChooseHashtagList] = useState<Tag[]>([]);

  useEffect(() => {
    if (dataChooseHashtagList) {
      setChooseHashtagList(dataChooseHashtagList);
    }
  }, [dataChooseHashtagList]);

  const handleShow = () => {
    setIsShow((pre) => !pre);
  };

  const chooseHashtag = (idTag: number) =>
    chooseHashtagList?.findIndex((tag) => tag?.id === idTag) !== -1;

  const handleUpdateHashtag = async (e: any, tag: Tag) => {
    e.stopPropagation();
    try {
      const checkTag = chooseHashtagList.findIndex((t) => t.id === tag.id);
      const payload = { id: tag.id, targetId: recordId, tableName };
      if (checkTag !== -1) {
        const res = await deleteService(payload);
        if (res) {
          const newHashtagList = [...chooseHashtagList];
          newHashtagList.splice(checkTag, 1);
          setChooseHashtagList(newHashtagList);
          return;
        }
      }
      const res = await addService(payload);
      if (res) {
        setChooseHashtagList([...chooseHashtagList, tag]);
      }
      return;
    } catch (error) { }
  };

  return (
    <div className="flex flex-wrap md:flex-nowrap justify-start items-center gap-2 overflow-auto">
      {chooseHashtagList?.map((tag) => (
        <div key={tag.id}>
          <div
            className="flex items-center gap-[6px] rounded-3xl py-1 px-[10px] w-fit"
            style={{ background: tag.colorCode }}
          >
            <p className="text-sm leading-4 text-white">{tag.hashtag}</p>
            {organizations[page] === EDIT && (
              <div
                className="cursor-pointer"
                onClick={(e: any) => handleUpdateHashtag(e, tag)}
              >
                <FaCircleXmark fontSize="16px" color="white" />
              </div>
            )}
          </div>
        </div>
      ))}
      {organizations[page] === EDIT && (
        <div onClick={(e) => e.stopPropagation()}>
          <Popover
            title=""
            trigger="click"
            open={isShow}
            onOpenChange={handleShow}
            placement="bottomLeft"
            arrow={false}
            content={
              <div className="p-0.5 w-[250px]">
                <ScrollStyle className="h-[200px] overflow-y-auto rounded bg-white">
                  {hashtagList?.map((tag) => (
                    <div key={tag.id}>
                      <div
                        className={`flex gap-3 items-center rounded !py-1 !lg:py-2 hover:bg-gray-1/50 cursor-pointer px-1 ${chooseHashtag(tag.id) && "bg-gray-1"
                          }`}
                        onClick={(e) => handleUpdateHashtag(e, tag)}
                      >
                        <TagIcon
                          className="w-4 h-4"
                          style={{ color: tag.colorCode }}
                        />
                        <p className="truncate">{tag?.hashtag}</p>
                      </div>
                      <div className="w-full h-[0.2px] my-[0.5px] bg-gray-300"></div>
                    </div>
                  ))}
                </ScrollStyle>
              </div>
            }
          >
            <button className="rounded-2xl flex justify-center items-center text-sm leading-4 text-yellow-1 border py-[3px] px-[10px] border-yellow-1">
              Thêm mới
            </button>
          </Popover>
        </div>
      )}
    </div>
  );
};

export default HashtagList;
