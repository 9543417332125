import { Input, Select, message } from "antd";
import { useTranslation } from "react-i18next";
import CameraIcon from "@assets/image/shop-management/camera.svg";
import { useEffect, useState } from "react";
import { IoCaretDown } from "react-icons/io5";
import { StyledSelect } from "./styles";
import { managementAreaList } from "./SubAccountDetails";
import { phoneRegExp, validateEmail } from "@helper/helper";
import { createSubAccount } from "@services/sub-account-service";
import ResetPasswordModal from "./ResetPasswordModal";
import ResetPhoneNumber from "./ResetPhoneNumberModal";

const CreateSubAccount = ({
  roleGroup,
  handleCloseAddingSubAccount,
  onFreshData,
  choosingSubAccount,
  handleEdit,
}: any) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [authorityGroupId, setAuthorityGroupId] = useState<string | undefined>(
    roleGroup[0]?.id || undefined
  );
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [managementArea, setManagementArea] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [password, setPassword] = useState("");
  const [openResetPassword, setOpenResetPassword] = useState(false);
  const [openResetPhoneNumber, setOpenResetPhoneNumber] = useState(false);

  const validation = () => {
    if (!phoneNumber) {
      message.error("Số điện thoại là bắt buộc");
      return false;
    }
    if (!phoneRegExp.test(phoneNumber)) {
      message.error("Số điện thoại không đúng định dạng");
      return false;
    }
    if (!password) {
      message.error("Mật khẩu là bắt buộc");
      return false;
    }
    if (password.length < 8) {
      message.error("Mật khẩu tối thiểu 8 ký tự");
      return false;
    }
    if (email && !validateEmail(email)) {
      message.error("Email không đúng định dạng");
      return false;
    }
    if (!authorityGroupId) {
      message.error("Nhóm quyền là bắt buộc");
      return false;
    }
    return true;
  };

  const editingValidation = () => {
    if (email && !validateEmail(email)) {
      message.error("Email không đúng định dạng");
      return false;
    }
    return true;
  };

  const onSave = async () => {
    try {
      if (!choosingSubAccount?.id) {
        if (!validation()) {
          return;
        }
        const payload = {
          fullName,
          phoneNumber,
          password,
          email,
          authorityGroupId,
          managementArea,
        };
        const res = await createSubAccount(payload);
        if (res && res.data) {
          message.success("Tạo nhân viên thành công");
          onFreshData();
        }
        return;
      }
      if (!editingValidation()) {
        return;
      }
      const payload =
        email !== choosingSubAccount?.email
          ? {
              id: choosingSubAccount?.id,
              email,
              fullName,
              authorityGroupId,
              managementArea,
            }
          : {
              id: choosingSubAccount?.id,
              fullName,
              authorityGroupId,
              managementArea,
            };
      handleEdit(payload);
    } catch (e: any) {
      message.error(e?.response?.data?.error);
    }
  };

  useEffect(() => {
    if (choosingSubAccount) {
      setFullName(choosingSubAccount?.fullName);
      setPhoneNumber(choosingSubAccount?.phoneNumber);
      setEmail(choosingSubAccount?.email);
      setManagementArea(choosingSubAccount?.managementArea);
      setAuthorityGroupId(choosingSubAccount?.authorityGroupId);
    }
  }, [choosingSubAccount]);

  return (
    <div className="border-b border-gray-1 mb-6">
      <div className="bg-white rounded-lg">
        <div className="p-4">
          <div className="mt-4 flex flex-col md:flex-row gap-4 md:gap-6 items-center">
            <div className="w-full md:w-1/2 flex justify-center items-center">
              <img src={CameraIcon} className="w-48 h-48" alt="camera" />
            </div>
            <div className="w-full md:w-1/2">
              <div className="flex flex-col items-start gap-3">
                <p className="text-sm font-medium text-black">
                  Họ tên nhân viên
                </p>
                <Input
                  className="rounded-lg w-full h-10 border-black border-[0.5px]"
                  placeholder="Họ tên nhân viên"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
              </div>
              <div className="mt-4 flex flex-col items-start gap-3">
                <p className="text-sm font-medium text-black">
                  Số điện thoại
                  <span className="text-red"> *</span>
                </p>
                <div className="relative w-full">
                  <Input
                    className="rounded-lg w-full h-10 border-black border-[0.5px]"
                    placeholder="Số điện thoại"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    disabled={choosingSubAccount?.id}
                  />
                  {choosingSubAccount?.id && (
                    <div
                      className="absolute top-[10px] right-4 text-blue-2 cursor-pointer text-sm"
                      onClick={() => setOpenResetPhoneNumber(true)}
                    >
                      Cập nhật
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 flex flex-col md:flex-row gap-6">
            <div className="w-full md:w-1/2">
              <div className="flex flex-col items-start gap-3">
                <p className="text-sm font-medium text-black">
                  Password
                  <span className="text-red"> *</span>
                </p>
                <div className="relative w-full">
                  {choosingSubAccount?.id ? (
                    <Input
                      className="rounded-lg w-full h-10 border-black border-[0.5px]"
                      placeholder={`${
                        choosingSubAccount?.id ? "********" : "Password"
                      }`}
                      value={password}
                      type="text"
                      onChange={(e) => setPassword(e.target.value)}
                      disabled={choosingSubAccount?.id}
                    />
                  ) : (
                    <Input.Password
                      className="rounded-lg w-full h-10 border-black border-[0.5px]"
                      placeholder={`${
                        choosingSubAccount?.id ? "********" : "Password"
                      }`}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  )}
                  {choosingSubAccount?.id && (
                    <div
                      className="absolute top-[10px] right-4 text-blue-2 cursor-pointer text-sm"
                      onClick={() => setOpenResetPassword(true)}
                    >
                      Cập nhật
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-4 flex flex-col items-start gap-3">
                <p className="text-sm font-medium text-black">
                  Nhóm quyền
                  <span className="text-red"> *</span>
                </p>
                <StyledSelect
                  className="rounded-lg w-full h-10 text-start border-black"
                  suffixIcon={
                    <IoCaretDown className="text-lg text-black" />
                  }
                  value={
                    roleGroup?.find(
                      (item: any) => item?.id === authorityGroupId
                    )?.name
                  }
                  onChange={(e: any) => setAuthorityGroupId(e)}
                >
                  <Select.Option value={undefined}>Tất cả</Select.Option>
                  {roleGroup.map((group: any) => (
                    <Select.Option key={group?.id} value={group?.id}>
                      {group?.name}
                    </Select.Option>
                  ))}
                </StyledSelect>
              </div>
            </div>
            <div className="w-full md:w-1/2">
              <div className="flex flex-col items-start gap-3">
                <p className="text-sm font-medium text-black">Email</p>
                <Input
                  className="rounded-lg w-full h-10 border-black border-[0.5px]"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mt-4 flex flex-col items-start gap-3">
                <p className="text-sm font-medium text-black">
                  Khu vực quản lý
                </p>
                <StyledSelect
                  className="rounded-lg w-full h-10 text-start border-black"
                  suffixIcon={
                    <IoCaretDown className="text-lg text-black" />
                  }
                  value={
                    managementAreaList?.find(
                      (item) => item === managementArea
                    ) || "Tất cả"
                  }
                  onChange={(e: any) => setManagementArea(e)}
                >
                  <Select.Option value={undefined}>Tất cả</Select.Option>
                  {managementAreaList.map((type) => (
                    <Select.Option key={type} value={type}>
                      {type}
                    </Select.Option>
                  ))}
                </StyledSelect>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center gap-8 py-8">
        <div>
          <button
            className="border border-red rounded px-10 py-2 bg-white text-sm font-medium leading-[24px] text-red hover:!text-white hover:bg-red hover:!border-red flex justify-center items-center"
            onClick={handleCloseAddingSubAccount}
          >
            {t("button.Cancel")}
          </button>
        </div>
        <button
          className="!border-none rounded px-10 py-2 bg-main-2 text-sm font-medium leading-[24px] text-white hover:bg-main-4 flex justify-center items-center"
          onClick={onSave}
          disabled={loading}
        >
          {t("button.Save")}
        </button>
      </div>
      {openResetPassword && (
        <ResetPasswordModal
          open={openResetPassword}
          onCancel={() => setOpenResetPassword(false)}
          id={choosingSubAccount?.id}
        />
      )}
      {openResetPhoneNumber && (
        <ResetPhoneNumber
          open={openResetPhoneNumber}
          onCancel={() => setOpenResetPhoneNumber(false)}
          id={choosingSubAccount?.id}
        />
      )}
    </div>
  );
};

export default CreateSubAccount;
