import { formatNumber } from "@helper/helper";
import { deliveryPriceColumn } from "./dataSources";
import NoDataIcon from "@assets/image/no-data.png";

const DeliveryPriceList = ({
  deliveryPrices,
  provinces,
  districts,
}: {
  deliveryPrices: any[];
  provinces: any[];
  districts: any[];
}) => {
  const getProvinceNameById = (id: number | undefined) => {
    const find = provinces?.find((item) => item?.id === id);
    if (find) {
      return find?.name;
    } else return "";
  };

  const getDistrictNameById = (id: number | undefined) => {
    const find = districts?.find((item) => item?.id === id);
    if (find) {
      return find?.name;
    } else return "";
  };

  return (
    <div className="mt-5 rounded-lg bg-white lg:!min-w-none shadow-lg">
      <div className="relative overflow-auto">
        <div className="min-w-[8406px] rounded-t-lg bg-gray-1 px-4 py-[9.5px] flex flex-row justify-between items-center text-sm font-semibold w-full">
          {deliveryPriceColumn.map((column, i) => (
            <div key={i}>
              <div style={{ width: `${column.width}px` }}>{column.title}</div>
            </div>
          ))}
        </div>
        <div className="w-full min-w-[8406px]">
          {deliveryPrices?.map((item: any) => (
            <div
              key={item?.id}
              className="flex py-3 border-b-[0.5px] px-4 text-sm"
            >
              <div>
                <div className="w-[140px]">{item?.priceListName}</div>
              </div>
              <div>
                <div className="w-[550px]">{item?.name}</div>
              </div>
              <div>
                <div className="w-[150px]">
                  {getProvinceNameById(item?.fromProvinceId)}
                </div>
              </div>
              <div>
                <div className="w-[180px]">
                  {getDistrictNameById(item?.fromDistrictId)}
                </div>
              </div>
              <div>
                <div className="w-[150px]">
                  {getProvinceNameById(item?.toProvinceId)}
                </div>
              </div>
              <div>
                <div className="w-[180px]">
                  {getDistrictNameById(item?.toDistrictId)}
                </div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.price1)}</div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.price2)}</div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.price3)}</div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.price4)}</div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.price5)}</div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.price6)}</div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.price7)}</div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.price8)}</div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.price9)}</div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.price10)}</div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.price11)}</div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.price12)}</div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.price13)}</div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.price14)}</div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.price15)}</div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.price16)}</div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.price17)}</div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.price18)}</div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.price19)}</div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.price20)}</div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.price21)}</div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.price22)}</div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.price23)}</div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.price24)}</div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.price25)}</div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.price26)}</div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.price27)}</div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.price28)}</div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.price29)}</div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.price30)}</div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.price31)}</div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.price32)}</div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.price33)}</div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.price34)}</div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.price35)}</div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.price36)}</div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.price37)}</div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.price38)}</div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.price39)}</div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.price40)}</div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.price41)}</div>
              </div>
              <div>
                <div className="w-[140px]">
                  {formatNumber(item?.increateAmountByKG)}
                </div>
              </div>
              <div>
                <div className="w-[220px]">{item?.increateWeightCount}</div>
              </div>
              <div>
                <div className="w-[200px]">
                  {formatNumber(item?.increateAmountByKG2)}
                </div>
              </div>
              <div>
                <div className="w-[200px]">
                  {formatNumber(item?.threstHold2)}
                </div>
              </div>
              <div>
                <div className="w-[200px]">{item?.increateWeightCount2}</div>
              </div>
              <div>
                <div className="w-[200px]">{formatNumber(item?.minWeight)}</div>
              </div>
              <div>
                <div className="w-[140px]">{formatNumber(item?.maxWeight)}</div>
              </div>
            </div>
          ))}
        </div>
        {deliveryPrices?.length === 0 && (
          <div className="sticky left-0 flex justify-center items-center h-[calc(100vh-450px)] md:h-[calc(100vh-523px)] w-full">
            <img
              src={NoDataIcon}
              alt="no-data"
              className="w-[180px] h-[162px] md:w-[250px] md:h-[225px]"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DeliveryPriceList;
