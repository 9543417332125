import {
  getOrderDetails,
  getOrderTracking,
  getTracking,
} from "@services/order-service";
import { Modal, Spin, message } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import NoDataIcon from "@assets/image/no-data.png";
import { formatDate } from "@helper/helper";
import { DATE_FORMAT, ORDER_STATUS } from "constants/constants";
import { useTranslation } from "react-i18next";

const OrderTracking = ({ open, onCancel, order }: any) => {
  const { t } = useTranslation();
  const [orderTracking, setOrderTracking] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState<any>();

  useEffect(() => {
    (async () => {
      if (order?.id && open) {
        const res = await getOrderDetails(order?.id);
        if (res) {
          setOrderDetails(res);
        }
      }
    })();
  }, [order?.id, open]);

  useEffect(() => {
    (async () => {
      if (order && open) {
        try {
          setLoading(true);
          const payload = {
            orderId: order?.id,
            deliveryCode: order?.deliveryCode,
            partnerId: order?.partnerDeliveryId,
          };
          const res = await getOrderTracking(payload);
          if (res && res?.code !== "Fail") {
            setOrderTracking(res?.data);
            setLoading(false);
          } else {
            // message.error(res?.errorMessage);
            const tracking = await getTracking(order?.id);
            setLoading(false);
            if (tracking) {
              setOrderTracking({ trackingLogs: tracking });
            }
          }
        } catch (e: any) {
          message.error(
            "Có lỗi trong quá trình lấy thông tin hành trình đơn từ đơn vị vận chuyển."
          );
          setLoading(false);
        }
      }
    })();
  }, [order, open]);

  return (
    <Modal
      title=""
      open={open}
      onCancel={onCancel}
      width={1000}
      footer={null}
      centered
    >
      <div className="text-base font-semibold border-b pb-3">
        Tra cứu đơn hàng
      </div>
      <div className="max-h-[500px] md:max-h-[700px] overflow-auto mb-4">
        <div className="mt-4 md:px-4 md:flex justify-between">
          <div>
            <div className="flex gap-1">
              <span className="font-semibold">Mã vận đơn:</span>
              <span>{order?.deliveryCode}</span>
            </div>
            <div className="flex gap-1">
              <span className="font-semibold">Ngày lấy hàng:</span>
              {(orderTracking?.pickupDate || orderDetails?.pickupDate) && (
                <span>
                  {formatDate(
                    orderTracking?.pickupDate || orderDetails?.pickupDate,
                    DATE_FORMAT
                  )}
                </span>
              )}
            </div>
            <div className="flex gap-1">
              <span className="font-semibold">Tên tài xế lấy hàng:</span>
              <span>{orderTracking?.pickupPerson}</span>
            </div>
            <div className="flex gap-1">
              <span className="font-semibold">Số tài xế lấy hàng:</span>
              <span>{orderTracking?.pickupPhoneNumber}</span>
            </div>
          </div>
          <div>
            <div className="flex gap-1">
              <span className="font-semibold">Trạng thái hiện tại:</span>
              <span>
                {t(
                  `orderStatus.${
                    Object.values(ORDER_STATUS).find(
                      (item) => item.code === order?.statusId?.toString()
                    )?.key
                  }`
                )}
              </span>
            </div>
            <div className="flex gap-1">
              <span className="font-semibold">Ngày giao hàng:</span>
              {(orderTracking?.deliveryDate || orderDetails?.deliveredDate) && (
                <span>
                  {formatDate(
                    orderTracking?.deliveryDate || orderDetails?.deliveredDate,
                    DATE_FORMAT
                  )}
                </span>
              )}
            </div>
            <div className="flex gap-1">
              <span className="font-semibold">Tên tài xế giao hàng:</span>
              <span>{orderTracking?.deliveryPerson}</span>
            </div>
            <div className="flex gap-1">
              <span className="font-semibold">Số tài xế giao hàng:</span>
              <span>{orderTracking?.deliveryPhoneNumber}</span>
            </div>
          </div>
        </div>
        {orderDetails?.failedReason?.length && (
          <div className="mt-4 md:mx-4 shadow rounded-t-lg overflow-hidden">
            <div className="bg-gray-1 px-4 py-[10px] flex flex-row justify-between items-center text-sm font-semibold">
              <div className="w-[30%]">Thời gian</div>
              <div className="w-[70%]">Lý do deplay</div>
            </div>
            {orderDetails?.failedReason?.map((item: any, index: number) => (
              <div
                key={item?.id}
                className={`px-4 py-[10px] flex flex-row justify-between items-center text-sm
              border-b ${
                orderTracking?.failedReason?.length - 1 === index
                  ? "border-transparent"
                  : "border-gray-1"
              }`}
              >
                <div className="w-[30%]">
                  {formatDate(item?.createdDate, DATE_FORMAT)}
                </div>
                <div className="w-[70%]">{item?.Reason}</div>
              </div>
            ))}
          </div>
        )}
        <div className="mt-4 pb-4">
          <p className="font-semibold">Chi tiết hành trình đơn</p>
          <div className="mt-4 md:mx-4 shadow rounded-t-lg overflow-hidden overflow-x-auto">
            <div className="bg-gray-1 px-4 py-[10px] flex flex-row justify-between items-center text-sm font-semibold min-w-[400px]">
              <div className="w-[20%] mr-4 md:mr-0">Thời gian</div>
              <div className="w-[60%]">Nội dung hành trình đơn</div>
              <div className="w-[20%]">Hình ảnh</div>
            </div>
            {loading ? (
              <div className="flex justify-center w-full py-10">
                <Spin />
              </div>
            ) : (
              <div className="min-w-[400px]">
                {orderTracking?.trackingLogs?.length > 0 ? (
                  orderTracking?.trackingLogs?.map(
                    (item: any, index: number) => (
                      <div
                        key={item?.id}
                        className={`px-4 py-[10px] flex flex-row justify-between items-center text-sm
              border-b ${
                orderTracking?.trackingLogs?.length - 1 === index
                  ? "border-transparent"
                  : "border-gray-1"
              }`}
                      >
                        <div className="w-[20%] mr-4 md:mr-0">
                          {formatDate(item?.createdDate, DATE_FORMAT)}
                        </div>
                        <div
                          className="w-[60%]"
                          dangerouslySetInnerHTML={{
                            __html: item?.shippingStatus,
                          }}
                        ></div>
                        <div className="w-[20%]">{item?.imageUrl}</div>
                      </div>
                    )
                  )
                ) : (
                  <div className="w-full py-6 flex items-center justify-center">
                    <img
                      src={NoDataIcon}
                      alt="no-data"
                      className="w-[180px] h-[162px]"
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default OrderTracking;
