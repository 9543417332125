import { Result } from "antd";
import { ReactNode } from "react";
import { useAuth } from "./auth";
import { AUTHORIZE } from "constants/constants";

const { NONE } = AUTHORIZE;

const RoleBaseGuard = ({
  role,
  children,
}: {
  role: string;
  children: ReactNode;
}) => {
  const { organizations } = useAuth();
  const active = organizations[role] !== NONE ? true : false;

  if (active) return <>{children}</>;

  return (
    <Result
      status="403"
      title="403"
      subTitle="Bạn không có quyền truy cập vào trang này."
    />
  );
};

export default RoleBaseGuard;
