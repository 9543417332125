import { Input } from "antd";
import AttachIcon from "@assets/image/request-management/attach.svg";
import SendMessIcon from "@assets/image/request-management/SendMess.svg";
import HappyIcon from "@assets/image/request-management/HappyIcon.svg";
import { IoCloseCircleOutline } from "react-icons/io5";
import { KeyboardEvent, useState } from "react";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import { WrapperShadowStyle } from "./styles";

export interface IDataMessage {
  inputStr: string;
}

interface IInputComment {
  handleSendComment: ({ inputStr }: IDataMessage) => void;
}

const InputMessage = ({ handleSendComment }: IInputComment) => {
  const [inputStr, setInputStr] = useState("");
  const [showPicker, setShowPicker] = useState(false);
  const [files, setFiles] = useState<any>([]);

  const onEmojiClick = (e: any) => {
    const sym = "0x" + e.unified;
    const emoji = String.fromCodePoint(Number(sym));
    setInputStr(inputStr + emoji);
  };

  const handleShowEmoji = (e: any) => {
    e.stopPropagation();
    setShowPicker((val) => !val);
  };

  const handleOnChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputStr(e.target.value);
  };

  const handleSend = () => {
    const data = { inputStr };
    handleSendComment(data);
    setInputStr("");
    files.map((file: any) => {
      if (file?.preview) {
        URL.revokeObjectURL(file.preview);
      }
    });
    setFiles([]);
  };

  const handleOnChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      let f: any = e.target.files;
      let fileList: any = [];
      for (const file of f) {
        fileList.push({ file, preview: URL.createObjectURL(file) });
      }
      setFiles([...files, ...fileList]);
    }
  };

  const handleRemoveFile = (index: number) => {
    if (!files[index]) return;
    URL.revokeObjectURL(files[index].preview);
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  const handleEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case "Enter":
        const dataCmt = { inputStr };
        setInputStr("");
        handleSendComment(dataCmt);
        files.map((file: any) => {
          if (file?.preview) {
            URL.revokeObjectURL(file.preview);
          }
        });
        setFiles([]);
        break;

      default:
        break;
    }
  };

  return (
    <WrapperShadowStyle>
      <div className="w-full px-6 py-4">
        {files.length > 0 && (
          <ul className="flex gap-3 mb-3 w-full">
            {files.map((file: any, index: number) => (
              <li key={index} className="h-14 relative">
                <img src={file.preview} alt="" className="h-full rounded-md" />
                <span
                  className="absolute -top-1.5 -right-1.5 border pb-0.5 cursor-pointer border-none"
                  onClick={() => handleRemoveFile(index)}
                >
                  <IoCloseCircleOutline className="text-[20px] text-red-1" />
                </span>
              </li>
            ))}
          </ul>
        )}
        <div className="border border-black rounded-2xl px-4 py-2 flex gap-4">
          {/* <button className="w-8 h-8 overflow-hidden flex justify-center items-center relative">
            <div className="cursor-pointer">
              <img src={AttachIcon} alt="" />
            </div>
            <input
              type="file"
              multiple
              className="w-full h-full opacity-0 absolute cursor-pointer file:cursor-pointer"
              accept="image/*"
              onChange={handleOnChangeFile}
            />
          </button> */}
          <Input
            className="text-sm !outline-none border-none focus:border-none focus:shadow-none flex-1 p-0"
            value={inputStr}
            onChange={handleOnChangeInput}
            onKeyDown={handleEnter}
          />

          <div className="relative flex items-center">
            <button>
              <img src={HappyIcon} alt="" onClick={handleShowEmoji} />
            </button>
            {showPicker && (
              <div className="absolute -translate-y-full -translate-x-full -top-2 left-[90px] md:left-full z-[1] shadow">
                <Picker
                  data={data}
                  className={""}
                  previewPosition={"none"}
                  onEmojiSelect={onEmojiClick}
                  onClickOutside={() => setShowPicker(false)}
                />
              </div>
            )}
          </div>
          <button onClick={handleSend}>
            <img src={SendMessIcon} alt="" />
          </button>
        </div>
      </div>
    </WrapperShadowStyle>
  );
};

export default InputMessage;
