import { useTranslation } from "react-i18next";
import { StyledHeaderWrapper, Wrapper } from "./styles";
import { Button, Checkbox, DatePicker, Select } from "antd";
import dayjs from "dayjs";
import { IoCalendarNumberOutline, IoCaretDown } from "react-icons/io5";
import chartBg from "@assets/image/chart-bg.svg";
import { useEffect, useMemo, useRef, useState } from "react";
import useElementSize from "@hooks/useElementWidth";
import Chart from "react-apexcharts";
import PersonIcon from "@assets/image/revenue-report/Person.svg";
import FindDollarIcon from "@assets/image/revenue-report/FindDollar.svg";
import StatisticIcon from "@assets/image/revenue-report/Statistic.svg";
import RevenueTable from "./RevenueTable";
import { RevenueColumn } from "./dataSources";
import { useAuth } from "provider/auth";
import { getDashboardShopByUserId } from "@services/shop-revenue-service";
import { useNavigate, useParams } from "react-router-dom";
import { getShopDetails } from "@services/shop-services";
import { ShopAccount } from "@models/ShopAccount";
import { formatNumber } from "@helper/helper";
import NoDataIcon from "@assets/image/no-data.png";
import { PAGE_SIZE, PERIOD_TYPE, REPORT_DATE_TYPE } from "constants/constants";
import { Section } from "@components/section/Section";
import CurrencyFormat from "react-currency-format";
import Pagination from "@components/pagination";
import {
  getRevenueByPartnerReport,
  getRevenueByShopReport,
} from "@services/dashboard-services";

const deliveryRevue = "Phí vận chuyển";
const totalProduct = "Giá trị hàng hoá";

const chartData = {
  options: {
    chart: {
      type: "line",
      toolbar: {
        show: false,
      },
    },
    // legend: {
    //   show: false,
    // },
    stroke: {
      width: [0, 1],
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1, 3],
      formatter: function (value: number) {
        return formatNumber(value);
      },
    },
    labels: [],
    xaxis: {
      type: "category",
    },
    yaxis: [
      {
        seriesName: deliveryRevue,
        labels: {
          formatter: function (value: number) {
            return formatNumber(value);
          },
        },
      },
      {
        seriesName: deliveryRevue,
        labels: {
          formatter: function (value: number) {
            return formatNumber(value);
          },
        },
      },
      {
        seriesName: totalProduct,
        labels: {
          formatter: function (value: number) {
            return formatNumber(value);
          },
        },
        opposite: true,
      },
      {
        seriesName: totalProduct,
        labels: {
          formatter: function (value: number) {
            return formatNumber(value);
          },
        },
      },
    ],
  },
};

const ShopRevenueReport = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const chartRef = useRef(null);
  const chartSize = useElementSize(chartRef);
  const dateFormat = "YYYY-MM-DD";

  const [dashboard, setDashboard] = useState<any>({});
  const [refreshData, setRefreshData] = useState(new Date().getTime());
  const [shopInfo, setShopInfo] = useState<ShopAccount>();
  const [dateType, setDateType] = useState("paidDate");
  const [period, setPeriod] = useState("day");
  const [deliveryReport, setDeliveryReport] = useState<any>([]);
  const [from, setFrom] = useState<any>(
    dayjs(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format(
      dateFormat
    )
  );
  const [to, setTo] = useState<any>(dayjs(new Date()).format(dateFormat));

  const columns1 = [
    {
      title: t("table.tableTitle.TransportationCompany"),
      dataIndex: "delyveryPartner",
      key: "delyveryPartner",
    },
    {
      title: t("table.tableTitle.TotalNumberOfOrders"),
      dataIndex: "totalNumberOfOrders",
      key: "totalNumberOfOrders",
    },
    {
      title: t("table.tableTitle.TotalShippingCost"),
      dataIndex: "totalShippingCost",
      key: "totalShippingCost",
    },
    {
      title: t("table.tableTitle.CostPrice"),
      dataIndex: "costPrice",
      key: "costPrice",
    },
    {
      title: "Lợi nhuận",
      dataIndex: "revenue",
      key: "revenue",
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        if (id) {
          const payload = { dateType, period, from, to };
          const res = await getDashboardShopByUserId(Number(id), payload);
          if (res) {
            const formatData = {
              labels: res?.chartDeliveryValue?.map((item: any) => item?.key),
              datasets: [
                {
                  name: deliveryRevue,
                  type: "column",
                  data: res?.chartDeliveryValue?.map(
                    (item: any) => item?.value
                  ),
                  color: "#71CA00",
                },
                {
                  name: "Line 1",
                  type: "line",
                  data: res?.chartDeliveryValue?.map(
                    (item: any) => item?.value
                  ),
                  color: "#71CA00",
                },
                {
                  name: totalProduct,
                  type: "column",
                  data: res?.chartRevenue?.map((item: any) => item?.value),
                  color: "#FCC400",
                },
                {
                  name: "Line 2",
                  type: "line",
                  data: res?.chartRevenue?.map((item: any) => item?.value),
                  color: "#FCC400",
                },
              ],
            };
            const sorted = res?.dashboardByDeliveryPartnerDto?.sort(
              (a: any, b: any) => {
                if (a?.deliveryPartnerName < b?.deliveryPartnerName) {
                  return -1;
                }
                if (a?.deliveryPartnerName > b?.deliveryPartnerName) {
                  return 1;
                }
                return 0;
              }
            );
            setDeliveryReport(sorted);
            setDashboard({ ...res, chart: formatData });
          }
        }
      } catch (error) {
        navigate("/shop-management");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData, id]);

  useEffect(() => {
    document.title = "Báo cáo doanh thu shop - Go24";
  }, []);

  useEffect(() => {
    (async () => {
      if (id) {
        try {
          const res = await getShopDetails(id);
          if (res) {
            setShopInfo(res);
          }
        } catch (e) { }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className="mt-4 flex flex-col items-start">
      <StyledHeaderWrapper className="bg-white rounded-lg px-3 py-3 text-sm w-full">
        <div className="flex gap-1">
          <p>{t("ShopRevenueReport.CustomerCode")} :</p>
          <p className="mr-2 font-semibold">{shopInfo?.code}</p>
          <p>{t("ShopRevenueReport.Name")} :</p>
          <p className="font-semibold">{shopInfo?.fullName}</p>
        </div>
      </StyledHeaderWrapper>
      <StyledHeaderWrapper className="mt-4 md:flex gap-6 w-full items-end bg-white py-2 px-4 rounded-lg mb-5">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 items-center w-full flex-wrap">
          <div className="w-full">
            <p className="font-semibold text-sm leading-none mb-2 text-start">
              {t("ShopRevenueReport.ReportType")}
            </p>
            <Select
              defaultValue="Theo ngày"
              className="w-full h-10 text-start first:pr-4"
              suffixIcon={<IoCaretDown className="text-lg" />}
              onChange={(value) => setPeriod(value)}
            >
              {PERIOD_TYPE.map((type) => (
                <Select.Option key={type.key} value={type.key}>
                  {type.value}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="w-full flex flex-col justify-start">
            <p className="font-semibold text-sm leading-none mb-2 text-start">
              Loại ngày
            </p>
            <Select
              className="rounded-lg w-full h-10 text-start"
              suffixIcon={<IoCaretDown className="text-lg" />}
              onChange={(value: any) => setDateType(value)}
              defaultValue="paidDate"
            >
              {REPORT_DATE_TYPE.map((type) => (
                <Select.Option key={type.key} value={type.key}>
                  {type.value}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="w-full">
            <p className="font-semibold text-sm leading-none mb-2 text-start">
              {t("filter.fieldTitle.From")}
            </p>
            <DatePicker
              defaultValue={dayjs(from, dateFormat)}
              format={dateFormat}
              className="w-full h-10 px-4"
              suffixIcon={
                <IoCalendarNumberOutline className="text-2xl text-black" />
              }
              onChange={(date, dateString: any) => {
                setFrom(dateString);
              }}
              disabledDate={(current) => {
                let customDate = dayjs(to).format("YYYY-MM-DD");
                return current && current > dayjs(customDate, "YYYY-MM-DD");
              }}
              allowClear={false}
            />
          </div>
          <div className="w-full">
            <p className="font-semibold text-sm leading-none mb-2 text-start">
              {t("filter.fieldTitle.To")}
            </p>
            <DatePicker
              defaultValue={dayjs(to, dateFormat)}
              format={dateFormat}
              className="w-full h-10 px-4"
              suffixIcon={
                <IoCalendarNumberOutline className="text-2xl text-black" />
              }
              onChange={(date, dateString: any) => {
                setTo(dateString);
              }}
              disabledDate={(current) => {
                let customDate = dayjs(from).format("YYYY-MM-DD");
                return current && current < dayjs(customDate, "YYYY-MM-DD");
              }}
              allowClear={false}
            />
          </div>
        </div>
        <div className="mt-4 flex justify-center items-center h-10">
          <button
            className="!border-none rounded px-6 py-2 bg-main-2 text-sm font-medium leading-[24px] text-white hover:bg-main-4 flex justify-center items-center"
            onClick={() => setRefreshData(new Date().getTime())}
          >
            {t("button.View")}
          </button>
        </div>
      </StyledHeaderWrapper>
      <div className="flex flex-wrap gap-6 justify-between w-full mb-8">
        <Wrapper className="bg-[#E5F5FF] rounded-xl flex justify-center items-center gap-14 h-[128px] w-full md:w-[31%]">
          <img src={FindDollarIcon} alt="" />
          <div className="flex flex-col items-center gap-4">
            <span className="text-2xl font-semibold text-main-1">
              {formatNumber(dashboard?.totalOrder)}
            </span>
            <p>{t("ShopRevenueReport.TotalOrder")}</p>
          </div>
        </Wrapper>
        <Wrapper className="bg-[#FFEFE7] rounded-xl flex flex-col md:flex-row justify-center items-center h-[150px] md:h-[128px] w-[46%] md:w-[31%]">
          <img src={StatisticIcon} alt="" />
          <div className="flex flex-col items-center gap-2 md:gap-4">
            <span className="text-2xl font-semibold text-main-1">
              {`${formatNumber(dashboard?.totalCOD)} vnđ`}
            </span>
            <p>{t("ShopRevenueReport.TotalValueCOD")}</p>
          </div>
        </Wrapper>
        <Wrapper className="bg-[#EBFEEF] rounded-xl flex flex-col md:flex-row justify-center items-center md:gap-14 h-[150px] md:h-[128px] w-[46%] md:w-[31%]">
          <img src={PersonIcon} alt="" />
          <div className="flex flex-col items-center gap-2 md:gap-4">
            <span className="text-2xl font-semibold text-main-1">
              {`${formatNumber(dashboard?.totalPaidCOD)} vnđ`}
            </span>
            <p>{t("ShopRevenueReport.TotalCODAdvance")}</p>
          </div>
        </Wrapper>
      </div>
      <div className="flex flex-col w-full gap-0.5">
        <div className="flex items-center justify-between">
          <p className="text-lg font-semibold">
            {t("ShopRevenueReport.RevenueReportChart")}
          </p>
        </div>
      </div>
      <div
        className="col-span-12 xl:col-span-7 flex w-full items-center min-h-[450px] relative"
        ref={chartRef}
      >
        <div className="absolute w-full h-full z-0">
          <img
            src={chartBg}
            className="object-fill w-full h-full"
            alt="chartBg"
          />
        </div>
        {dashboard?.chart && (
          <div>
            <Chart
              options={
                {
                  ...chartData.options,
                  labels: dashboard.chart.labels,
                } as any
              }
              series={dashboard.chart.datasets}
              type="line"
              width={chartSize.width}
              height={chartSize.height}
            />
          </div>
        )}
      </div>
      <div className="mt-4 mb-8 w-full">
        <div className="text-lg font-semibold text-left">
          {t("ShopRevenueReport.ShopRevenueReport")}
        </div>
        <RevenueTable
          column={RevenueColumn}
          row={[
            {
              totalOrder: dashboard?.totalOrder,
              totalDeliveryServiceFee: dashboard?.totalDeliveryFee,
              totalCOD: dashboard?.totalCOD,
              totalCODAdvance: dashboard?.totalPaidCOD,
              partiallyDeliveredRatio: dashboard?.partialDeliveryRate,
              refundRatio: dashboard?.returnedRate,
            },
          ]}
        />
      </div>
      <Section title={t("contentTitle.overview.CarrierspecificRevenueReport")}>
        <div className="max-w-[100%] overflow-x-auto shadow-lg">
          <div
            className="rounded-lg overflow-hidden bg-white lg:!min-w-none list"
            style={{ minWidth: 160 * columns1.length + 32 }}
          >
            <div className="bg-gray-1 px-4 py-[10px] flex flex-row justify-between items-center text-sm font-semibold rounded-tl-lg rounded-tr-lg">
              {columns1.map((column, i) => (
                <div key={i} className="w-[150px]">
                  {column.title}
                </div>
              ))}
            </div>
            {deliveryReport?.map((data: any, index: number) => (
              <div
                key={data?.deliveryPartnerId}
                className="px-4 py-[10px] flex flex-row justify-between items-center text-sm border-b last:border-transparent border-gray-1"
              >
                <div className="w-[150px]">{data?.deliveryPartnerName}</div>
                <div className="w-[150px]">
                  {formatNumber(data?.totalOrder)}
                </div>
                <div className="w-[150px]">
                  {formatNumber(data?.totalDeliveryFee)}
                </div>
                <div className="w-[150px]">{formatNumber(data?.costPrice)}</div>
                <div className="w-[150px]">{formatNumber(data?.revenue)}</div>
              </div>
            ))}
          </div>
        </div>
      </Section>
    </div>
  );
};

export default ShopRevenueReport;
