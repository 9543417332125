import { phoneRegExp } from "@helper/helper";
import { updateSubAccount } from "@services/sub-account-service";
import { Input, Modal, message } from "antd";
import { useState } from "react";

const ResetPhoneNumber = ({
  open,
  onCancel,
  id,
}: {
  open: boolean;
  onCancel: any;
  id: number | undefined;
}) => {
  const [phoneNumber, setPhoneNumber] = useState("");

  const validateUpdateForm = () => {
    if (!phoneNumber) {
      message.error("Số điện thoại là bắt buộc");
      return false;
    }
    if (!phoneRegExp.test(phoneNumber)) {
      message.error("Số điện thoại không đúng định dạng");
      return false;
    }
    return true;
  };

  const onSave = async () => {
    if (!validateUpdateForm()) return;
    try {
      const payload = {
        phoneNumber,
        id,
      };
      if (id) {
        const res = await updateSubAccount(payload);
        if (res) {
          message.success("Cập nhật thành công");
          onCancel();
        }
      }
    } catch (e: any) {
      message.error(e?.response?.data?.error || "Cập nhật thất bại");
    }
  };

  return (
    <Modal
      title=""
      open={open}
      onCancel={onCancel}
      width={600}
      footer={null}
      centered
    >
      <div className="text-base font-semibold border-b pb-3">
        Cập nhật số điện thoại
      </div>
      <div className="mt-4 flex flex-col gap-6">
        <div className="flex flex-col items-start gap-3">
          <p className="text-sm font-medium text-black">
            Số điện thoại<span className="text-red"> *</span>
          </p>
          <Input
            className="rounded-lg w-full h-10 border-black border-[0.5px]"
            placeholder="Nhập số điện thoại"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </div>
      </div>
      <div className="mt-4 flex justify-center">
        <button
          className="!border-none rounded px-6 py-2 bg-main-2 text-sm font-medium leading-[24px] text-white hover:bg-main-4 flex justify-center items-center"
          onClick={onSave}
        >
          Cập nhật
        </button>
      </div>
    </Modal>
  );
};

export default ResetPhoneNumber;
