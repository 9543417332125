import changeMenuTabs from '@reducers/changeMenuTabs';
import counterReducer from '@reducers/counterReducer';
import { configureStore } from '@reduxjs/toolkit';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    changeMenuTab: changeMenuTabs,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;