export const deliveryPriceColumn = [
  {
    title: "Tên bảng giá",
    dataIndex: "vatCompanyName",
    key: "vatCompanyName",
    width: 140,
    minWidth: 125,
  },
  {
    title: "Tên gợi nhớ",
    dataIndex: "vatTaxCode",
    key: "vatTaxCode",
    width: 550,
    minWidth: 125,
  },
  {
    title: "Tỉnh thành giao",
    dataIndex: "vatPhoneNumber",
    key: "vatPhoneNumber",
    width: 150,
    minWidth: 125,
  },
  {
    title: "Quận huyện giao",
    dataIndex: "vatEmail",
    key: "vatEmail",
    width: 180,
    minWidth: 185,
  },
  {
    title: "Tỉnh thành nhận",
    dataIndex: "vatAddress",
    key: "vatAddress",
    width: 150,
    minWidth: 250,
  },
  {
    title: "Quận huyện nhận",
    dataIndex: "",
    key: "",
    width: 180,
    minWidth: 200,
  },
  {
    title: "Giá từ 0 - 0.3 KG",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Giá từ 0.3 - 0.5 KG",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Giá từ 0.5 - 1 KG",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Giá từ 1 - 1.5 KG",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Giá từ 1.5 - 2 KG",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Giá từ 2 - 2.5 KG",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Giá từ 2.5 - 3 KG",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Giá từ 3 - 3.5 KG",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Giá từ 3.5 - 4 KG",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Giá từ 4 - 4.5 KG",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Giá từ 4.5 - 5 KG",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Giá từ 5 - 5.5 KG",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Giá từ 5.5 - 6 KG",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Giá từ 6 - 6.5 KG",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Giá từ 6.5 - 7 KG",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Giá từ 7 - 7.5 KG",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Giá từ 7.5 - 8 KG",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Giá từ 8 - 8.5 KG",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Giá từ 8.5 - 9 KG",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Giá từ 9 - 9.5 KG",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Giá từ 9.5 - 10 KG",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Giá từ 10 - 10.5 KG",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Giá từ 10.5 - 11 KG",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Giá từ 11 - 11.5 KG",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Giá từ 11.5 - 12 KG",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Giá từ 12 - 12.5 KG",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Giá từ 12.5 - 13 KG",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Giá từ 13 - 13.5 KG",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Giá từ 13.5 - 14 KG",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Giá từ 14 - 14.5 KG",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Giá từ 14.5 - 15 KG",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Giá từ 15 - 15.5 KG",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Giá từ 15.5 - 16 KG",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Giá từ 16 - 16.5 KG",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Giá từ 16.5 - 17 KG",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Giá từ 17 - 17.5 KG",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Giá từ 17.5 - 18 KG",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Giá từ 18 - 18.5 KG",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Giá từ 18.5 - 19 KG",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Giá từ 19 - 19.5 KG",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Giá từ 19.5 - 20 KG",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Giá cước tăng thêm",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
  {
    title: "Khối lượng tăng thêm",
    dataIndex: "",
    key: "",
    width: 220,
    minWidth: 200,
  },
  {
    title: "Giá Cước tăng thêm (2)",
    dataIndex: "",
    key: "",
    width: 200,
    minWidth: 200,
  },
  {
    title: "Ngưỡng tăng thêm (2)",
    dataIndex: "",
    key: "",
    width: 200,
    minWidth: 200,
  },
  {
    title: "Khối lượng tăng thêm (2)",
    dataIndex: "",
    key: "",
    width: 200,
    minWidth: 200,
  },
  {
    title: "Khối lượng tối thiểu",
    dataIndex: "",
    key: "",
    width: 200,
    minWidth: 200,
  },
  {
    title: "Khối lượng tối đa",
    dataIndex: "",
    key: "",
    width: 140,
    minWidth: 200,
  },
];
