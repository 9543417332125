import ColorPaletIcon from "@assets/svg/ColorPalet";
import TagIcon from "@assets/svg/Tag";
import Pagination from "@components/pagination";
import { Section } from "@components/section/Section";
import { Tag } from "@models/TagModel";
import {
  createTag,
  deleteTag,
  getTagList,
  updateTag,
} from "@services/hashtag-services";
import { Button, ColorPicker, Input, Switch, message } from "antd";
import { AUTHORIZE, PAGE_SIZE } from "constants/constants";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FaPlus,
  FaPen,
  FaRegCircleCheck,
  FaRegCircleXmark,
  FaRegTrashCan,
  FaMinus
} from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import NoDataIcon from "@assets/image/no-data.png";
import { useAuth } from "provider/auth";

const subtabs = [
  {
    id: 1,
    name: "Nhãn dán đơn hàng",
  },
  {
    id: 2,
    name: "Nhãn dán yêu cầu",
  },
  {
    id: 3,
    name: "Nhãn dán shop",
  },
];

const { EDIT } = AUTHORIZE;

const TagsSetting: React.FC = () => {
  const { organizations } = useAuth();
  const [activeSubTab, setActiveSubTab] = useState<number>(1);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isAddNew, setIsAddNew] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [tagsData, setTagsData] = useState<Tag[]>([]);
  const [refreshData, setRefreshData] = useState(new Date().getTime());
  const [choosedTag, setChoosedTag] = useState<Tag | undefined>(undefined);

  const { t } = useTranslation();

  const handleChangeActiveState = (tag: Tag) => {
    const updateTag = {
      ...tag,
      isActive: tag.isActive ? false : true,
    };
    handleUpdateTag(updateTag, false);
  };

  const validatation = (tag: Tag) => {
    if (!tag?.hashtag) {
      message.error("Tên nhãn là bắt buộc");
      return false;
    }
    return true;
  };

  const handleCreateNewTag = async () => {
    if (choosedTag) {
      try {
        const res = await createTag({
          hashtag: choosedTag.hashtag,
          colorCode: choosedTag.colorCode || "#CCCC",
          type: activeSubTab,
        });
        if (res && res?.data) {
          message.success(t("tag.CreateTagSuccessfully"));
          setChoosedTag(undefined);
          setIsAddNew(false);
          setRefreshData(new Date().getTime());
        }
      } catch (e) {
        message.error(t("tag.CreateTagFailed"));
      }
    } else {
      message.error("Tên nhãn là bắt buộc");
    }
  };

  const handleUpdateTag = async (tag = choosedTag, reload = true) => {
    if (tag) {
      if (!validatation(tag)) {
        return;
      }
      try {
        const res = await updateTag(tag);
        if (res && res?.status === 200) {
          setChoosedTag(undefined);
          setRefreshData(new Date().getTime());
          if (reload) {
            message.success(t("tag.UpdateTagSuccessfully"));
          }
        }
      } catch (e) {
        message.error(t("tag.UpdateTagFailed"));
      }
    }
  };

  const handleDeleteTag = async (id: number) => {
    if (id) {
      try {
        const res = await deleteTag(id);
        if (res && res?.status === 200) {
          message.success(t("tag.DeleteTagSuccessfully"));
          setRefreshData(new Date().getTime());
        }
      } catch (e) {
        message.error(t("tag.DeleteTagFailed"));
      }
    }
  };

  const handleAddNewTag = () => {
    if (choosedTag) {
      setChoosedTag(undefined);
    }
    setIsAddNew(true);
  };

  const handleEditTag = (tag: Tag) => {
    if (isAddNew) {
      setIsAddNew(false);
    }
    setIsEdit(true);
    setChoosedTag(tag);
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await getTagList({
          page,
          limit: PAGE_SIZE,
          type: activeSubTab,
        });
        if (res) {
          setTagsData(res.data);
          setTotalPage(res.meta.pageCount || 1);
        }
      } catch (e) { }
    })();
  }, [page, refreshData, activeSubTab]);

  useEffect(() => {
    document.title = "Cài đặt nhãn dán - Go24";
  }, []);

  return (
    <div className="flex flex-col">
      <Section title={t("contentTitle.tagsSetting.TagsSetting")}>
        <div className="flex flex-row rounded px-4 justify-between bg-white shadow-md mb-8 w-full overflow-x-auto">
          {/* Parent div for scrolling */}
          <div className="flex flex-row gap-8 min-w-max overflow-x-auto no-scrollbar">
            {subtabs.map((subtab) => (
              <div
                className={`flex h-full py-2 justify-center items-center cursor-pointer transition-all ease-in-out duration-300 border-b border-solid 
        ${activeSubTab === subtab.id
                    ? "border-main-1"
                    : "hover:border-main-2 border-transparent"
                  }`}
                key={subtab.id}
                onClick={() => {
                  setActiveSubTab(subtab.id);
                  setIsAddNew(false);
                  setPage(1);
                  setChoosedTag(undefined);
                }}
              >
                {subtab.name}
              </div>
            ))}
          </div>
          {organizations["manageHashtag"] === EDIT ? (
            <div className="md:min-w-[180px] py-2 ml-6">
              {!isAddNew ? (
                <button
                  className="!border-none rounded w-full px-2 md:px-4 py-2 bg-main-2 text-sm font-medium leading-[24px] text-white hover:bg-main-4 flex justify-center items-center"
                  // icon={<FaPlus className="text-2xl" />}
                  onClick={handleAddNewTag}
                >
                  <FaPlus className="text-[20px] md:mr-2" />
                  <span className="hidden md:block">{t("button.AddNewTag")}</span>
                </button>
              ) : (
                <button
                  className="!border-none rounded w-full px-2 md:px-4 py-2 bg-main-2 text-sm font-medium leading-[24px] text-white hover:bg-main-4 flex justify-center items-center"
                  onClick={() => setIsAddNew(false)}
                >
                  <FaMinus className="text-[20px] md:mr-2" />
                  <span className="hidden md:block"> {t("button.Collapse")}</span>
                </button>
              )}
            </div>
          ) : (
            <div className="w-[180px] h-14"></div>
          )}
        </div>
      </Section>
      <Section>
        <div className="rounded-lg overflow-x-auto shadow-lg">
          <div className="flex flex-row items-center gap-x-2 bg-gray-1 font-semibold leading-[18px] w-fit md:w-full">
            <div className="w-full p-4 text-start">
              <p className="min-w-[100px]">{t("table.tableTitle.TagName")}</p>
            </div>
            <div>
              <p className="w-[200px] p-4">{t("table.tableTitle.Color")}</p>
            </div>
            <div>
              <p className="w-[200px] p-4">
                {t("table.tableTitle.ActiveState")}
              </p>
            </div>
            <div>
              <p className="w-[200px] p-4">{t("table.tableTitle.Details")}</p>
            </div>
          </div>
          <div className="h-[315px]">
            <div
              className="flex flex-row justify-between  items-center gap-x-2 w-full border-t border-gray-1"
              style={{ height: isAddNew && !choosedTag?.id ? "auto" : 0, overflow: isAddNew && !choosedTag?.id ? "" : "hidden" }}
            >
              <div className="min-w-[130px] w-full p-4 text-start">
                <Input
                  className="!text-[#000] border-[0.5px] border-gray-1"
                  value={choosedTag?.hashtag}
                  onChange={(e) =>
                    setChoosedTag((preValue: any) => ({
                      ...preValue,
                      hashtag: e.target.value,
                    }))
                  }
                />
              </div>
              <div>
                <div className="w-[200px] p-4 flex flex-row justify-center items-center">
                  <ColorPicker
                    format="hex"
                    value={choosedTag?.colorCode}
                    onChange={(value) =>
                      setChoosedTag((preValue: any) => ({
                        ...preValue,
                        colorCode: value?.toHexString(),
                      }))
                    }
                  >
                    <button>
                      <ColorPaletIcon className="w-[24px] h-[24px]" />
                    </button>
                  </ColorPicker>
                  <TagIcon
                    className="w-[24px] h-[24px]"
                    style={{ color: choosedTag?.colorCode }}
                  />
                </div>
              </div>
              <div>
                <div className="w-[200px] p-4">
                  <Switch className="!bg-main-1" checked={true}></Switch>
                </div>
              </div>
              <div>
                <div className="w-[200px] p-4 flex flex-row gap-x-4 justify-center">
                  <button className="p-1" onClick={handleCreateNewTag}>
                    <FaRegCircleCheck className="text-[20px]" />
                  </button>
                  <button className="p-1" onClick={() => setIsAddNew(false)}>
                    <FaRegCircleXmark className="text-[20px]" />
                  </button>
                </div>
              </div>
            </div>
            {tagsData?.length === 0 && !isAddNew ? (
              <div className="w-full h-full py-6 flex items-center justify-center">
                <img
                  src={NoDataIcon}
                  alt="no-data"
                  className="w-[180px] h-[162px] md:w-[250px] md:h-[225px]"
                />
              </div>
            ) : (
              tagsData.map((tag, i) => (
                <div
                  className="flex flex-row items-center gap-x-2 w-fit md:w-full border-b border-gray-1"
                  key={tag.id}
                >
                  <div className="w-full p-4 text-start">
                    <Input
                      className={`!text-[#000] px-0 min-w-[100px]`}
                      variant={
                        choosedTag?.id === tag?.id ? undefined : "borderless"
                      }
                      value={
                        choosedTag?.id === tag?.id
                          ? choosedTag.hashtag
                          : tag.hashtag
                      }
                      disabled={!(choosedTag?.id === tag?.id && isEdit)}
                      onChange={(e) =>
                        setChoosedTag((preValue: any) => ({
                          ...preValue,
                          hashtag: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div>
                    <div className="w-[200px] p-4 flex flex-row justify-center items-center">
                      <ColorPicker
                        format="hex"
                        value={tag.colorCode}
                        disabled={tag.id !== choosedTag?.id}
                        onChange={(value) =>
                          setChoosedTag((preValue: any) => ({
                            ...preValue,
                            colorCode: value?.toHexString(),
                          }))
                        }
                      >
                        <button disabled={tag.id !== choosedTag?.id}>
                          <ColorPaletIcon className="w-[24px] h-[24px]" />
                        </button>
                      </ColorPicker>
                      <TagIcon
                        className="w-[24px] h-[24px]"
                        style={{ color: tag.colorCode }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="w-[200px] p-4">
                      <Switch
                        className={tag.isActive ? "!bg-main-1" : "!bg-gray-1"}
                        checked={tag.isActive}
                        onClick={() => handleChangeActiveState(tag)}
                        disabled={organizations["manageHashtag"] !== EDIT}
                      ></Switch>
                    </div>
                  </div>
                  <div>
                    <div className="w-[200px] p-4 flex flex-row gap-x-4 justify-center">
                      {isEdit && choosedTag?.id === tag?.id ? (
                        <button
                          className="p-1"
                          onClick={() => handleUpdateTag()}
                          disabled={organizations["manageHashtag"] !== EDIT}
                        >
                          <FaRegCircleCheck className="text-[20px]" />
                        </button>
                      ) : (
                        <button
                          className="p-1"
                          onClick={() => {
                            handleEditTag(tag);
                          }}
                          disabled={organizations["manageHashtag"] !== EDIT}
                        >
                          <FaPen className="text-[20px]" />
                        </button>
                      )}
                      <button
                        className="p-1"
                        onClick={() => handleDeleteTag(tag?.id)}
                        disabled={organizations["manageHashtag"] !== EDIT}
                      >
                        <FaRegTrashCan className="text-[20px]" />
                      </button>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </Section>
      <Pagination {...{ page, setPage, totalPage }} />
    </div>
  );
};

export default TagsSetting;
