import { Button, Input, Modal, message } from "antd";
import { api, go24Api } from "provider/api";
import { useAuth } from "provider/auth";
import { useEffect, useState } from "react";

const ChangePassword = () => {
  const { user, onLogout } = useAuth();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [renewPassword, setRenewPassword] = useState("");
  const [openOtp, setOpenOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [countdown, setCountdown] = useState(300);
  const [otpId, setOtpId] = useState("");

  const validateUpdateForm = () => {
    if (!oldPassword) {
      message.error("Mật khẩu cũ là bắt buộc");
      return false;
    }
    if (!newPassword) {
      message.error("Mật khẩu mới là bắt buộc");

      return false;
    }
    if (renewPassword.length < 8) {
      message.error("Mật khẩu tối thiểu 8 ký tự");

      return false;
    }
    if (!renewPassword) {
      message.error("Nhập lại mật khẩu mới là bắt buộc");

      return false;
    }
    if (newPassword !== renewPassword) {
      message.error("Nhập lại mật khẩu không trùng khớp");
      return false;
    }
    return true;
  };

  const handleUpdatePassword = async () => {
    if (!validateUpdateForm()) return;
    try {
      const res = await go24Api.post("/me/otp", {
        phoneNumber: user?.phoneNumber,
        key: user?.phoneNumber,
        type: 3,
        data: "",
      });
      if (res && res?.data) {
        setOtpId(res?.data?.data);
        setOpenOtp(true);
      }
    } catch (e) {
      message.error("Gửi OTP thất bại");
    }
  };

  const handleSendOTP = async () => {
    if (!otp) {
      message.error("OTP là bắt buộc");
      return false;
    }
    try {
      const res = await api.post("/otp/confirm", {
        otpId,
        otp,
        key: user?.phoneNumber,
        type: 3,
      });
      if (res && res?.data?.status === 200) {
        const payload = {
          oldPassword,
          newPassword,
          confirmPassword: renewPassword,
        };
        const res = await api.put(`/partner/${user?.id}/password`, payload);
        if (res) {
          message.success("Cập nhật thành công");
          setTimeout(() => {
            onLogout();
          }, 300);
        }
      } else {
        message.error(res?.data?.error);
      }
    } catch (e: any) {
      message.error("Cập nhật thất bại");
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (openOtp) {
        setCountdown((current) => {
          if (current > 0) {
            return current - 1;
          }
          setOpenOtp(false);
          return 0;
        });
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [openOtp]);

  return (
    <div>
      <div className="mt-4 rounded-lg shadow-md bg-white p-6">
        <div className="flex flex-col gap-6 md:w-1/2">
          <div className="flex flex-col items-start gap-3">
            <p className="text-sm font-medium text-black">
              Mật khẩu cũ<span className="text-red"> *</span>
            </p>
            <Input.Password
              className="rounded-lg w-full h-10 border-black border-[0.5px]"
              placeholder="Nhập mật khẩu cũ"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </div>
          <div className="flex flex-col items-start gap-3">
            <p className="text-sm font-medium text-black">
              Mật khẩu mới<span className="text-red"> *</span>
            </p>
            <Input.Password
              className="rounded-lg w-full h-10 border-black border-[0.5px]"
              placeholder="Nhập mật khẩu mới"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className="flex flex-col items-start gap-3">
            <p className="text-sm font-medium text-black">
              Nhập lại mật khẩu mới<span className="text-red"> *</span>
            </p>
            <Input.Password
              className="rounded-lg w-full h-10 border-black border-[0.5px]"
              placeholder="Nhập lại mật khẩu mới"
              value={renewPassword}
              onChange={(e) => setRenewPassword(e.target.value)}
            />
          </div>
        </div>
      </div>
      <button
        className="w-40 h-10 !border-none rounded mt-4 px-6 py-2 bg-main-2 text-base font-medium text-white hover:bg-main-4"
        onClick={handleUpdatePassword}
      >
        Cập nhật
      </button>
      <Modal
        title=""
        open={openOtp}
        onCancel={() => setOpenOtp(false)}
        width={600}
        footer={null}
        centered
      >
        <div className="text-base font-semibold border-b pb-3">
          Xác thực số điện thoại
        </div>
        <div className="mt-4">
          <div className="flex flex-col items-start gap-3">
            <p className="text-sm font-medium text-black">
              OTP<span className="text-red"> *</span>
            </p>
            <Input
              className="rounded-lg w-full h-10 border-[0.5px]"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder="Nhập mã OTP"
            />
          </div>
          <div className="mt-2 flex justify-center">{`Còn lại ${countdown} giây`}</div>
          <div className="mt-4 flex gap-4 justify-center items-center">
            <button
              className="h-10 !border-none rounded px-6 py-2 bg-main-2 text-base font-medium text-white hover:bg-main-4"
              onClick={handleSendOTP}
            >
              Xác nhận
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ChangePassword;
