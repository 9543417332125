import { phoneRegExp } from "@helper/helper";
import { Input, Modal, message } from "antd";
import { api, go24Api } from "provider/api";
import { useAuth } from "provider/auth";
import { useEffect, useState } from "react";

const ChangePhoneNumber = ({ open, onCancel, otpId, setOtpId }: any) => {
  const [step, setStep] = useState(1);
  const [otp, setOtp] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countdown, setCountdown] = useState(300);
  const { user, setUser, onLogout } = useAuth();

  const handleSendOTP = async () => {
    if (!otp) {
      message.error("OTP là bắt buộc");
      return false;
    }
    if (step === 1) {
      try {
        const res = await api.post("/otp/confirm", {
          otpId,
          otp,
          key: user?.phoneNumber,
          type: 3,
        });
        if (res && res?.data?.status === 200) {
          setStep(2);
          setOtp("");
        } else {
          message.error(res?.data?.error);
        }
      } catch (e) {}
    }
    if (step === 3) {
      try {
        const res = await api.post("/otp/confirm", {
          otpId,
          otp,
          key: phoneNumber,
          type: 3,
        });
        if (res && res?.data?.status === 200) {
          const resUpdate = await api.put(`/partner/update/phone-number`, {
            phoneNumber,
          });
          if (resUpdate && resUpdate?.data?.status === 200) {
            message.success("Cập nhật thành công");
            const info = resUpdate?.data?.response;
            setUser(info);
            onCancel();
            onLogout();
          } else {
            message.error(resUpdate?.data?.error);
          }
        } else {
          message.error(res?.data?.error);
        }
      } catch (e: any) {
        message.error(e?.response?.data?.error);
      }
    }
  };

  const updatePhoneNumber = async () => {
    if (!phoneNumber) {
      message.error("Số điện thoại là bắt buộc");
      return;
    }
    if (!phoneRegExp.test(phoneNumber)) {
      message.error("Số điện thoại không đúng định dạng");
      return false;
    }
    try {
      const res = await go24Api.post("/me/otp", {
        phoneNumber: phoneNumber,
        key: phoneNumber,
        type: 3,
        data: "",
      });
      if (res && res?.data) {
        setStep(3);
        setCountdown(300);
        setOtpId(res?.data?.data);
      }
    } catch (e) {}
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (step === 1 || step === 3) {
        setCountdown((current) => {
          if (current > 0) {
            return current - 1;
          }
          onCancel();
          return 0;
        });
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [step]);

  return (
    <Modal
      title=""
      open={open}
      onCancel={onCancel}
      width={600}
      footer={null}
      centered
    >
      <div className="text-base font-semibold border-b pb-3">
        {step === 2 ? "Cập nhật số điện thoại" : "Xác thực số điện thoại"}
      </div>
      {(step === 1 || step === 3) && (
        <div className="mt-4">
          <div className="flex flex-col items-start gap-3">
            <p className="text-sm font-medium text-black">
              OTP<span className="text-red"> *</span>
            </p>
            <Input
              className="rounded-lg w-full h-10 border-[0.5px]"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder="Nhập mã OTP"
            />
          </div>
          <div className="mt-2 flex justify-center">{`Còn lại ${countdown} giây`}</div>
          <div className="mt-4 flex gap-4 justify-center items-center">
            {step === 3 && (
              <button
                className="h-10 border border-red rounded px-10 py-2 bg-white text-sm font-medium text-red hover:!text-white hover:bg-red hover:!border-red flex justify-center items-center"
                onClick={() => {
                  setStep(2);
                  setOtp("");
                }}
              >
                Quay lại
              </button>
            )}
            <button
              className="h-10 !border-none rounded px-6 py-2 bg-main-2 text-base font-medium text-white hover:bg-main-4"
              onClick={handleSendOTP}
            >
              Xác nhận
            </button>
          </div>
        </div>
      )}
      {step === 2 && (
        <div className="mt-4">
          <div className="flex flex-col items-start gap-3">
            <p className="text-sm font-medium text-black">
              Số điện thoại<span className="text-red"> *</span>
            </p>
            <Input
              className="rounded-lg w-full h-10 border-[0.5px]"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="Nhập số điện thoại"
            />
          </div>
          <div className="flex justify-center">
            <button
              className="h-10 !border-none rounded mt-4 px-6 py-2 bg-main-2 text-base font-medium text-white hover:bg-main-4"
              onClick={updatePhoneNumber}
            >
              Xác nhận
            </button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ChangePhoneNumber;
