import { useTranslation } from "react-i18next";
import { pickupAddressColumn } from "./dataSources";
import { IoAdd } from "react-icons/io5";
import Table from "./Table";
import { useEffect, useState } from "react";
import ShopLocationForm from "./ShopLocationForm";
import { ShopLocation } from "@models/ShopLocation";
import {
  deleteShopLocation,
  getShopLocationList,
  updateShopLocation,
} from "@services/shop-location-service";
import { useParams } from "react-router-dom";
import {
  getAddressLevelFourById,
  getDistrictById,
  getProvinceById,
  getWardById,
} from "@services/geo-services";
import { message } from "antd";
import { useAuth } from "provider/auth";
import { AUTHORIZE } from "constants/constants";
import { FaPlus } from "react-icons/fa6";

const { EDIT } = AUTHORIZE;

const PickupAddressList = () => {
  const { t } = useTranslation();
  const { organizations } = useAuth();
  const params = useParams();
  const { id } = params;
  const [open, setOpen] = useState<boolean>(false);
  const [shopLocarions, setShopLocations] = useState<ShopLocation[]>([]);
  const [selectedShopLocation, setSelectedShopLocation] =
    useState<ShopLocation>();
  const [refreshData, setRefreshData] = useState(new Date().getTime());
  const [provinceDisplay, setProvinceDisplay] = useState();
  const [districtDisplay, setDistrictDisplay] = useState();
  const [wardDisplay, setWardDisplay] = useState();
  const [addressLevelFourDisplay, setAddressLevelFourDisplay] = useState();

  const handleOnClickEdit = async (item: ShopLocation) => {
    setSelectedShopLocation(item);
    const province = await getProvinceById(item.provinceId);
    setProvinceDisplay(province);
    const district = await getDistrictById(item.districtId);
    setDistrictDisplay(district);
    const ward = await getWardById(item.wardId);
    setWardDisplay(ward);
    const address = await getAddressLevelFourById(item.levelFourId);
    setAddressLevelFourDisplay(address);
    setOpen(true);
  };

  const handleActive = async (item: ShopLocation) => {
    try {
      delete item.stt;
      const res = await updateShopLocation({
        ...item,
        isUsed: item?.isUsed ? false : true,
      });
      if (res) {
        message.success(t("shopLocation.UpdateShopLocationSuccessfully"));
        setOpen(false);
        setRefreshData(new Date().getTime());
      }
    } catch (e) {
      message.error(t("shopLocation.UpdateShopLocationFailed"));
    }
  };

  const handleDelete = async (item: ShopLocation) => {
    if (item?.id) {
      try {
        const res = await deleteShopLocation(item?.id);
        if (res) {
          message.success(t("shopLocation.DeleteShopLocationSuccessfully"));
          setRefreshData(new Date().getTime());
        }
      } catch (e) {
        message.error(t("shopLocation.DeleteShopLocationFailed"));
      }
    }
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
    setSelectedShopLocation(undefined);
    setProvinceDisplay(undefined);
    setDistrictDisplay(undefined);
    setWardDisplay(undefined);
    setAddressLevelFourDisplay(undefined);
  };

  useEffect(() => {
    (async () => {
      if (id) {
        const res = await getShopLocationList(Number(id));
        if (res) {
          const formatRes = res.map((item: any, index: number) => ({
            ...item,
            stt: index + 1,
          }));
          setShopLocations(formatRes);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData]);

  return (
    <div>
      <div className="flex gap-7 items-center ">
        <div className="w-1/2 flex justify-start font-medium">
          {t("accountDetails.PickupAddress")}
        </div>
        <div className="w-1/2 flex justify-end">
          {organizations["manageShop"] === EDIT && (
            <button
              className="p-2 md:p-0 md:w-[180px] md:h-10 bg-blue-3 text-white flex justify-center items-center gap-4 rounded hover:bg-main-4"
              onClick={showModal}
            >
              <FaPlus className="text-xl md:text-2xl" />
              <span className="hidden md:block">{t("button.AddAddress")}</span>
            </button>
          )}
        </div>
      </div>
      {shopLocarions.length > 0 ? (
        <Table
          column={pickupAddressColumn}
          row={shopLocarions}
          onEditAction={handleOnClickEdit}
          onActiveAction={handleActive}
          onDeleteAction={handleDelete}
          disableAction={organizations["manageShop"] !== EDIT}
        />
      ) : (
        <p className="mt-4">{t("common.NoData")}</p>
      )}
      {open && (
        <ShopLocationForm
          {...{
            open,
            handleCancel,
            selectedShopLocation,
            setSelectedShopLocation,
            setRefreshData,
          }}
        />
      )}
    </div>
  );
};

export default PickupAddressList;
