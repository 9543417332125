import { Tag } from "@models/TagModel";
import { api } from "provider/api";
import { HASHTAG_TYPE } from "constants/constants";

const { ORDER, REQUEST, SHOP } = HASHTAG_TYPE;

interface ITagList {
  page: number;
  limit: number;
  type?: number;
}

export const getTagList = async (payload: ITagList) => {
  const res = await api.get("/hashtag", { params: payload });
  if (res) {
    return {
      data: res.data.response.data,
      meta: res.data.response.meta,
    };
  }
  return undefined;
};

export const createTag = async ({
  hashtag,
  colorCode,
  type,
}: {
  hashtag: string;
  colorCode: string;
  type?: number;
}) => {
  const payload = {
    hashtag,
    colorCode,
    type,
  };
  const res = await api.post("hashtag", payload);
  if (res) {
    return res;
  }
  return undefined;
};

export const updateTag = async (payload: Tag) => {
  const res = await api.put(`hashtag/${payload.id}`, payload);
  if (res) {
    return res;
  }
  return undefined;
};

export const deleteTag = async (id: number) => {
  const res = await api.delete(`hashtag/${id}`);
  if (res) {
    return res;
  }
  return undefined;
};

export const attachHashtagForShop = async ({
  id,
  targetId,
}: {
  id: number;
  targetId: number;
}) => {
  const payload = {
    id,
    targetId: targetId,
    type: SHOP,
  };
  const res = await api.post("/hashtag/add", payload);
  if (res) {
    return res;
  }
  return undefined;
};

export const attachHashtagForOrder = async ({
  id,
  targetId,
}: {
  id: number;
  targetId: number;
}) => {
  const payload = {
    id,
    targetId: Number(targetId),
    type: ORDER,
  };
  const res = await api.post("/hashtag/add", payload);
  if (res) {
    return res;
  }
  return undefined;
};

export const deleteHashtagForShop = async ({
  id,
  targetId,
}: {
  id: number;
  targetId: number;
}) => {
  const payload = {
    id,
    targetId,
    type: SHOP,
  };
  const res = await api.post("/hashtag/remove", payload);
  if (res) {
    return res;
  }
  return undefined;
};

export const addHashtagForRequest = async ({
  id,
  targetId,
  tableName,
}: {
  id: number;
  targetId: number;
  tableName: string;
}) => {
  const payload = {
    id,
    targetId,
    type: REQUEST,
    tableName,
  };
  const res = await api.post("/hashtag/add", payload);
  if (res && res.data) {
    return res.data.response;
  }
  return undefined;
};

export const deleteHashtagForRequest = async ({
  id,
  targetId,
  tableName,
}: {
  id: number;
  targetId: number;
  tableName?: string;
}) => {
  const payload = {
    id,
    targetId,
    type: REQUEST,
    tableName,
  };
  const res = await api.post("/hashtag/remove", payload);
  if (res && res.data) {
    return res.data.response;
  }
  return undefined;
};

export const deleteHashtagForOrder = async ({
  id,
  targetId,
}: {
  id: number;
  targetId: number;
}) => {
  const payload = {
    id,
    targetId: Number(targetId),
    type: ORDER,
  };
  const res = await api.post("/hashtag/remove", payload);
  if (res) {
    return res;
  }
  return undefined;
};
