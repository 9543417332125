import { api } from "provider/api";

export const getAuthorityGroup = async (payload: any) => {
  const res = await api.get("/authority-group", { params: payload });
  if (res) {
    return res;
  }
};

export const createAuthorityGroup = async (payload: any) => {
  const res = await api.post("/authority-group", payload);
  if (res) {
    return res;
  }
};

export const updateAuthorityGroup = async (payload: any) => {
  const res = await api.put(`/authority-group/${payload?.id}`, payload);
  if (res) {
    return res;
  }
};

export const deleteAuthorityGroup = async (id: number) => {
  const res = await api.delete(`/authority-group/${id}`);
  if (res) {
    return res;
  }
};
