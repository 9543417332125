import Pagination from "@components/pagination";
import { Input, Switch, message } from "antd";
import { AUTHORIZE, PAGE_SIZE, roleGroupInitial } from "constants/constants";
import { useEffect, useState } from "react";
import { FaPlus, FaMagnifyingGlass, FaMinus } from "react-icons/fa6";
import { StyledCheckbox } from "./styles";
import {
  createAuthorityGroup,
  deleteAuthorityGroup,
  getAuthorityGroup,
  updateAuthorityGroup,
} from "@services/authority-service";
import NoDataIcon from "@assets/image/no-data.png";
import TrashRegular from "@assets/image/account-details/TrashRegular.svg";
import SolarPenIcon from "@assets/image/account-details/solar_pen-bold.svg";
import RoleDetailsModal from "./RoleDetailsModal";

const { NONE, READ, EDIT } = AUTHORIZE;

export const roleTable = [
  { key: "manageDashboard", value: "Báo cáo doanh thu" },
  { key: "manageShop", value: "Quản lý shop" },
  { key: "manageRequest", value: "Quản lý yêu cầu" },
  { key: "manageOrder", value: "Quản lý đơn hàng" },
  { key: "manageHashtag", value: "Quản lý nhãn dán" },
  { key: "manageDeliveryPrice", value: "Giá giao" },
];

const RoleSetting = () => {
  const [isAddingRole, setIsAddingRole] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [roleSettings, setRoleSettings] = useState<any[]>([]);
  const [roleName, setRoleName] = useState("");
  const [roleGroup, setRoleGroup] = useState<any>(roleGroupInitial);
  const [refreshData, setRefreshData] = useState(new Date().getTime());
  const [choosingRoleGroup, setChoosingRoleGroup] = useState<any>();
  const [openRoleDetails, setOpenRoleDetails] = useState(false);

  const handleAddingRole = () => {
    setIsAddingRole(true);
  };

  const handleCloseAddingRole = () => {
    setIsAddingRole(false);
  };

  const onChangeRoleGroup = (name: string, value: number) => {
    setRoleGroup((currentValue: any) => ({ ...currentValue, [name]: value }));
  };

  const handleSearch = () => {
    setPage(1);
    setRefreshData(new Date().getTime());
  };

  const validation = () => {
    if (!roleName) {
      message.error("Tên nhóm quyền là bắt buộc");
      return false;
    }
    return true;
  };

  const getFunctionDetails = (data: any) => {
    let result = "";
    let count = 0;
    for (const item of roleTable) {
      if (data[item.key] !== NONE) {
        count++;
        if (result === "") result += item.value;
        else if (result && count <= 2) result += ` / ${item.value}`;
      }
    }
    if (count > 2) {
      return result + ` (+${count - 2})`;
    }
    if (!result) {
      return "Không có chức năng";
    }
    return result;
  };

  const handleChoosingRole = (data: any) => {
    setChoosingRoleGroup(data);
    setIsAddingRole(true);
  };

  const handleDelete = async (data: any) => {
    try {
      const res = await deleteAuthorityGroup(data?.id);
      if (res && res.data) {
        message.success("Xoá nhóm quyền thành công");
        setRefreshData(new Date().getTime());
      }
    } catch (e: any) {
      message.error(e?.response?.data?.error);
    }
  };

  const handleCancel = () => {
    setChoosingRoleGroup(undefined);
    setIsAddingRole(false);
    setRoleName("");
    setRoleGroup(roleGroupInitial);
  };

  const handleEdit = async (data: any, isActive = true) => {
    try {
      const res = await updateAuthorityGroup({
        ...data,
        isUsed: isActive ? data?.isUsed : data?.isUsed ? false : true,
      });
      if (res && res.data) {
        isActive && message.success("Cập nhật quyền thành công");
        handleCancel();
        setRefreshData(new Date().getTime());
      }
    } catch (e: any) {
      message.error(e?.response?.data?.error);
    }
  };

  const onSave = async () => {
    if (!validation()) {
      return;
    }
    if (!choosingRoleGroup?.id) {
      try {
        const payload = {
          name: roleName,
          organizations: roleGroup,
        };
        const res = await createAuthorityGroup(payload);
        if (res && res.data) {
          message.success("Tạo nhóm quyền thành công");
          handleCancel();
          setRefreshData(new Date().getTime());
        }
      } catch (e: any) {
        message.error(e?.response?.data?.error);
      }
    } else {
      handleEdit({
        ...choosingRoleGroup,
        name: roleName,
        organizations: roleGroup,
      });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const payload = {
          page,
          limit: PAGE_SIZE,
          name: searchText,
        };
        const res = await getAuthorityGroup(payload);
        if (res && res.data) {
          setRoleSettings(res.data?.response?.data);
          setTotalPage(res.data?.response?.meta?.pageCount || 1);
        }
      } catch (e) {}
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, refreshData]);

  useEffect(() => {
    if (choosingRoleGroup) {
      setRoleName(choosingRoleGroup?.name);
      setRoleGroup(choosingRoleGroup?.organizations);
    }
  }, [choosingRoleGroup]);

  return (
    <div>
      <div className="mt-4 bg-white rounded-lg shadow-lg">
        <div className="w-full">
          <div className="flex items-center justify-between border-b-[0.5px] px-4 py-2">
            <div className="flex gap-3">
              <p className="text-sm md:text-base text-black font-semibold">
                Cài đặt nhóm quyền
              </p>
            </div>
            <div>
              {isAddingRole ? (
                <button
                  className="!border-none rounded w-full px-6 py-2 bg-main-2 text-sm font-medium leading-[24px] text-white hover:bg-main-4 flex justify-center items-center"
                  onClick={handleCloseAddingRole}
                >
                  <span className="block md:hidden">
                    <FaMinus size={20} color="white" />
                  </span>
                  <span className="hidden md:block">Thu gọn</span>
                </button>
              ) : (
                <button
                  className="!border-none rounded w-full px-4 py-2 bg-main-2 text-sm font-medium leading-[24px] text-white hover:bg-main-4 flex justify-center items-center"
                  onClick={handleAddingRole}
                >
                  <FaPlus className="text-[20px] md:mr-2" />
                  <span className="hidden md:block">Thêm nhóm quyền</span>
                </button>
              )}
            </div>
          </div>
          {isAddingRole && (
            <div className="mt-4 flex flex-col gap-3 items-start px-4 border-b-[0.5px]">
              <p className="text-sm text-black font-semibold">Tên nhóm quyền</p>
              <Input
                className="h-10 px-4 rounded-lg md:w-96"
                placeholder="Nhập tên nhóm quyền"
                value={roleName}
                onChange={(e) => setRoleName(e.target.value)}
              />
              <p className="text-sm text-black font-semibold">
                Tuỳ chọn chức năng tài khoản
              </p>
              <div className="w-full shadow-lg overflow-auto">
                <div className="bg-gray-1 min-w-[700px] px-4 py-2 flex flex-row justify-between items-center text-sm font-semibold rounded-tl-lg rounded-tr-lg overflow-auto">
                  <div className="w-[50px] md:w-[10%] text-left">.No</div>
                  <div className="w-[200px] md:w-[30%]">Tên chức năng</div>
                  <div className="w-[150px] md:w-[20%]">Không được xem</div>
                  <div className="w-[150px] md:w-[20%]">Chỉ được xem</div>
                  <div className="w-[150px] md:w-[20%]">Được sửa</div>
                </div>
                {roleTable.map(({ key, value }, index) => (
                  <div
                    key={key}
                    className={`min-w-[700px] flex px-4 py-3 border-b last:border-transparent border-gray-1 text-sm`}
                  >
                    <div className="w-[50px] md:w-[10%] text-left">{index + 1}</div>
                    <div className="w-[200px] md:w-[30%]">{value}</div>
                    <div className="w-[150px] md:w-[20%]">
                      <StyledCheckbox
                        checked={roleGroup[key] === NONE}
                        onChange={(e) => onChangeRoleGroup(key, NONE)}
                      />
                    </div>
                    <div className="w-[150px] md:w-[20%]">
                      <StyledCheckbox
                        checked={roleGroup[key] === READ}
                        onChange={(e) => onChangeRoleGroup(key, READ)}
                      />
                    </div>
                    <div className="w-[150px] md:w-[20%]">
                      <StyledCheckbox
                        checked={roleGroup[key] === EDIT}
                        onChange={(e) => onChangeRoleGroup(key, EDIT)}
                      />
                    </div>
                  </div>
                ))}
                <div className="flex justify-center gap-8 py-6">
                  <div>
                    <button
                      className="border border-red rounded px-10 h-10 bg-white text-sm font-medium leading-[24px] text-red hover:!text-white hover:bg-red hover:!border-red flex justify-center items-center"
                      onClick={handleCancel}
                    >
                      Huỷ
                    </button>
                  </div>
                  <button
                    className="!border-none rounded px-10 h-10 bg-main-2 text-sm font-medium leading-[24px] text-white hover:bg-main-4 flex justify-center items-center"
                    onClick={onSave}
                  >
                    Lưu
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="p-4">
          <p className="text-sm md:text-base text-black font-semibold text-left">
            Danh sách nhóm quyền & Chức năng
          </p>
          <div className="mt-4 flex gap-3">
            <div className="relative">
              <Input
                className="h-10 px-10 md:w-96"
                placeholder="Tìm kiếm"
                name={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <div className="absolute top-[10px] left-3">
                <FaMagnifyingGlass className="text-lg text-[#BFBFBF]" />
              </div>
            </div>
            <button
              className="!w-32 !border-none rounded px-2 md:px-4 py-2 bg-main-2 text-sm font-medium leading-[24px] text-white hover:bg-main-4 flex justify-center items-center"
              onClick={handleSearch}
            >
              <FaMagnifyingGlass className="text-lg md:mr-2" />
              <span>Tìm kiếm</span>
            </button>
          </div>
          <div className="mt-4 shadow-lg overflow-auto">
            <div className="min-w-[600px] bg-gray-1 px-4 py-2 flex flex-row justify-between items-center text-sm font-semibold rounded-tl-lg rounded-tr-lg">
              <div className="w-[50px] md:w-[10%] text-left">.No</div>
              <div className="w-[200px] md:w-[35%]">Tên nhóm quyền</div>
              <div className="w-[200px] md:w-[35%]">Chi tiết chắc năng</div>
              <div className="w-[150px] md:w-[20%]">Chi tiết</div>
            </div>
            {roleSettings?.length === 0 ? (
              <div className="col-span-12 xl:col-span-7 py-6 flex items-center justify-center">
                <img
                  src={NoDataIcon}
                  alt="no-data"
                  className="w-[180px] h-[162px] md:w-[250px] md:h-[225px]"
                />
              </div>
            ) : (
              roleSettings?.map((data: any, index: number) => (
                <div
                  key={data?.id}
                  className="min-w-[600px] px-4 py-3 flex flex-row justify-between items-center text-sm border-b last:border-transparent border-gray-1"
                >
                  <div className="w-[50px] md:w-[10%] text-left">
                    {PAGE_SIZE * (page - 1) + index + 1}
                  </div>
                  <div className="w-[200px] md:w-[35%]">{data?.name}</div>
                  <div className="w-[200px] md:w-[35%]">
                    <div>{getFunctionDetails(data?.organizations)}</div>
                    {getFunctionDetails(data?.organizations) !==
                      "Không có chức năng" && (
                      <div
                        className="text-red cursor-pointer"
                        onClick={() => {
                          setChoosingRoleGroup(data);
                          setOpenRoleDetails(true);
                        }}
                      >
                        Xem chi tiết
                      </div>
                    )}
                  </div>
                  <div className="w-[150px] md:w-[20%]">
                    <div className="flex items-center justify-center gap-4">
                      <Switch
                        className={data?.isUsed ? "!bg-main-1" : "!bg-gray-1"}
                        value={data?.isUsed}
                        onChange={(e) => handleEdit(data, false)}
                      ></Switch>
                      <div onClick={() => handleDelete(data)}>
                        <img
                          src={TrashRegular}
                          alt=""
                          className="w-5 h-5 cursor-pointer"
                        />
                      </div>
                      <div onClick={() => handleChoosingRole(data)}>
                        <img
                          src={SolarPenIcon}
                          alt=""
                          className="w-6 h-6 cursor-pointer"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
      <Pagination {...{ page, setPage, totalPage }} />
      {openRoleDetails && (
        <RoleDetailsModal
          open={openRoleDetails}
          onCancel={() => {
            setChoosingRoleGroup(undefined);
            setOpenRoleDetails(false);
          }}
          role={choosingRoleGroup}
        />
      )}
    </div>
  );
};

export default RoleSetting;
