import { Button, Form, Input } from "antd";
import { useTranslation } from "react-i18next";


type FieldTypeProps = {
  optCode?: string;
  password?: string;
  retypePassword?: string;
};

export const ResetPasswordForm: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    console.log(form, values);
  };

  return (
    <Form
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      autoComplete="off"
      layout='vertical'
      className="w-full"
    >
      <Form.Item<FieldTypeProps>
        name="optCode"
        className='mb-[18px]'
        rules={[{ required: true, message: t("login.PleaseInputYourOPTCode") }]}
      >
        <div>
          <h2 className="text-left font-bold">{t("login.OTPCode")} <span className='text-red'>*</span></h2>
          <Input className="rounded" placeholder={t("login.OTPCode")} />
        </div>
      </Form.Item>
      <Form.Item<FieldTypeProps>
        name="password"
        className='mb-[18px]'
        rules={[{ required: true, message: t("login.PleaseInputYourPassword") }]}
      >
        <div>
          <h2 className="text-left font-bold">{t("login.Password")} <span className='text-red'>*</span></h2>
          <Input.Password className="rounded" placeholder={t("login.Password")} />
        </div>
      </Form.Item>
      <Form.Item<FieldTypeProps>
        name="retypePassword"
        className='mb-[18px]'
        rules={[{ required: true, message: t("login.PleaseInputYourPassword") }]}
      >
        <div>
          <h2 className="text-left font-bold">{t("login.RetypePassword")} <span className='text-red'>*</span></h2>
          <Input.Password className="rounded" placeholder={t("login.RetypePassword")} />
        </div>
      </Form.Item>
      <Form.Item className='mb-1'>
        <Button className="bg-yellow-1 text-black text-[13px] text-bold hover:bg-gray-2 hover:!text-yellow-1 hover:!border-yellow-1 w-full" htmlType="submit">
          {t("login.ComfirmPassword")}
        </Button>
      </Form.Item>
    </Form>
  );
}
