import { useTranslation } from "react-i18next";
import { taxCodeColumn } from "./dataSources";
import TickSquareIcon from "@assets/image/account-details/tick-square.svg";
import Table from "./Table";
import React from "react";
import { ShopAccount } from "@models/ShopAccount";

export interface IActionComponent {
  handleOnClickEdit: any;
}

export interface IExportElectronicInvoiceEveryMonth {
  data: any;
}

const ExportElectronicInvoiceEveryMonth: React.FC<
  IExportElectronicInvoiceEveryMonth
> = ({ data }) => {
  return (
    <div className="flex items-center justify-evenly">
      {data && <img src={TickSquareIcon} alt="" />}
    </div>
  );
};

const TaxCodeList = ({ shopInfo }: { shopInfo: ShopAccount | undefined }) => {
  const { t } = useTranslation();
  const row = [
    {
      vatCompanyName: shopInfo?.vatCompanyName || t("common.NoData"),
      vatTaxCode: shopInfo?.vatTaxCode || t("common.NoData"),
      vatAddress: shopInfo?.vatAddress || t("common.NoData"),
      vatEmail: shopInfo?.vatEmail || t("common.NoData"),
      vatPhoneNumber: shopInfo?.vatPhoneNumber || t("common.NoData"),
      vatIsEveryMonth: shopInfo?.vatIsEveryMonth || false,
    },
  ];

  return (
    <div>
      <div className="flex gap-7 items-center ">
        <div className="w-1/2 flex justify-start font-medium">
          {t("accountDetails.TaxCode")}
        </div>
      </div>
      <Table
        column={taxCodeColumn}
        row={row}
        vatIsEveryMonth={
          <ExportElectronicInvoiceEveryMonth data={row[0].vatIsEveryMonth} />
        }
      />
    </div>
  );
};

export default TaxCodeList;
