import { Section } from "@components/section/Section";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import Chart from "react-apexcharts";
import { BlockBannerLG } from "@components/block/BlockBannerLg";
import bannerImage1 from "@assets/image/frame-1.svg";
import bannerImage2 from "@assets/image/frame-2.svg";
import chartBg from "@assets/image/chart-bg.svg";
import useElementSize from "@hooks/useElementWidth";
import { DatePicker, Select } from "antd";
import { IoCalendarNumberOutline, IoCaretDown } from "react-icons/io5";
import {
  getDashboardOverview,
  getRevenueByPartnerReport,
  getRevenueByShopReport,
} from "@services/dashboard-services";
import { StyledSelect } from "./styled";
import { formatNumber } from "@helper/helper";
import NoDataIcon from "@assets/image/no-data.png";
import Pagination from "@components/pagination";
import { PAGE_SIZE, PERIOD_TYPE, REPORT_DATE_TYPE } from "constants/constants";
import { Link } from "react-router-dom";

const deliveryRevue = "Doanh thu vận chuyển";
const totalProduct = "Tổng giá trị hàng hoá";

const chartData = {
  options: {
    chart: {
      type: "line",
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: [0, 1],
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1, 3],
      formatter: function (value: number) {
        return formatNumber(value);
      },
    },
    labels: [],
    xaxis: {
      type: "category",
    },
    yaxis: [
      {
        seriesName: deliveryRevue,
        labels: {
          formatter: function (value: number) {
            return formatNumber(value);
          },
        },
      },
      {
        seriesName: deliveryRevue,
        labels: {
          formatter: function (value: number) {
            return formatNumber(value);
          },
        },
      },
      {
        seriesName: totalProduct,
        labels: {
          formatter: function (value: number) {
            return formatNumber(value);
          },
        },
        opposite: true,
      },
      {
        seriesName: totalProduct,
        labels: {
          formatter: function (value: number) {
            return formatNumber(value);
          },
        },
      },
    ],
  },
};

const Overview: React.FC = () => {
  const chartRef = useRef(null);
  const chartSize = useElementSize(chartRef);
  const dateFormat = "YYYY-MM-DD";
  const { t } = useTranslation();
  const [overviewData, setOverviewData] = useState<any>();
  const [shopFrom, setShopFrom] = useState<any>(
    dayjs(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format(
      dateFormat
    )
  );
  const [shopDateType, setShopDateType] = useState("paidDate");
  const [shopTo, setShopTo] = useState<any>(
    dayjs(new Date()).format(dateFormat)
  );
  const [deliveryFrom, setDeliveryFrom] = useState<any>(
    dayjs(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format(
      dateFormat
    )
  );
  const [deliveryTo, setDeliveryTo] = useState<any>(
    dayjs(new Date()).format(dateFormat)
  );
  const [deliveryReport, setDeliveryReport] = useState<any>([]);
  const [shopReport, setShopReport] = useState<any>([]);
  const [totalShop, setTotalShop] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [refreshShopData, setRefreshShopData] = useState(new Date().getTime());
  const [refreshDeliveryData, setRefreshDeliveryData] = useState(
    new Date().getTime()
  );
  const [dateType, setDateType] = useState("paidDate");
  const [period, setPeriod] = useState("day");
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const handleChangeDateType = (value: string) => {
    setPeriod(value);
  };

  const handleChangeReportDateType = (value: string) => {
    setDateType(value);
  };

  const columns1 = [
    {
      title: t("table.tableTitle.TransportationCompany"),
      dataIndex: "delyveryPartner",
      key: "delyveryPartner",
    },
    {
      title: t("table.tableTitle.TotalNumberOfOrders"),
      dataIndex: "totalNumberOfOrders",
      key: "totalNumberOfOrders",
    },
    {
      title: t("table.tableTitle.TotalShippingCost"),
      dataIndex: "totalShippingCost",
      key: "totalShippingCost",
    },
    {
      title: t("table.tableTitle.CostPrice"),
      dataIndex: "costPrice",
      key: "costPrice",
    },
    {
      title: "Lợi nhuận",
      dataIndex: "revenue",
      key: "revenue",
    },
  ];

  const columns2 = [
    {
      title: t("table.tableTitle.Username"),
      dataIndex: "username",
      key: "username",
    },
    {
      title: t("table.tableTitle.ShopName"),
      dataIndex: "shopname",
      key: "shopname",
    },
    {
      title: t("table.tableTitle.TotalNumberOfOrders"),
      dataIndex: "totalNumberOfOrders",
      key: "totalNumberOfOrders",
    },
    {
      title: t("table.tableTitle.TotalShippingCost"),
      dataIndex: "totalShippingCost",
      key: "totalShippingCost",
    },
    {
      title: t("table.tableTitle.CostPrice"),
      dataIndex: "costPrice",
      key: "costPrice",
    },
    {
      title: "Lợi nhuận",
      dataIndex: "revenue",
      key: "revenue",
    },
  ];

  useEffect(() => {
    (async () => {
      const payload = {
        from: deliveryFrom,
        to: deliveryTo,
        dateType,
        period,
      };
      const res = await getDashboardOverview(payload);
      if (res) {
        const formatData = {
          labels: res?.chartDeliveryValue?.map((item: any) => item?.key),
          datasets: [
            {
              name: deliveryRevue,
              type: "column",
              data: res?.chartDeliveryValue?.map((item: any) => item?.value),
              color: "#71CA00",
            },
            {
              name: "Line 1",
              type: "line",
              data: res?.chartDeliveryValue?.map((item: any) => item?.value),
              color: "#71CA00",
            },
            {
              name: totalProduct,
              type: "column",
              data: res?.chartRevenue?.map((item: any) => item?.value),
              color: "#FCC400",
            },
            {
              name: "Line 2",
              type: "line",
              data: res?.chartRevenue?.map((item: any) => item?.value),
              color: "#FCC400",
            },
          ],
        };
        setOverviewData({ ...res, chart: formatData });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDeliveryData]);

  useEffect(() => {
    (async () => {
      const res = await getRevenueByPartnerReport(
        deliveryFrom,
        deliveryTo,
        dateType
      );
      if (res) {
        const sorted = res.sort((a: any, b: any) => {
          if (a?.deliveryPartnerName < b?.deliveryPartnerName) {
            return -1;
          }
          if (a?.deliveryPartnerName > b?.deliveryPartnerName) {
            return 1;
          }
          return 0;
        });
        setDeliveryReport(sorted);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDeliveryData]);

  useEffect(() => {
    (async () => {
      const res = await getRevenueByShopReport(shopFrom, shopTo, shopDateType);
      if (res) {
        const sorted = res.sort((a: any, b: any) => {
          if (a?.userName < b?.userName) {
            return -1;
          }
          if (a?.userName > b?.userName) {
            return 1;
          }
          return 0;
        });
        const totalOrder = sorted?.reduce(
          (sum: number, currentItem: any) => sum + currentItem?.totalOrder,
          0
        );
        let sum = 0;
        sorted?.forEach((item: any) => {
          if (item?.totalOrder > 0) {
            sum = sum + 1;
          }
        });
        setTotalShop(sum);
        setShopReport(sorted);
        setTotalOrders(totalOrder);
        setTotalPage(Math.ceil(res?.length / PAGE_SIZE) || 1);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshShopData]);

  useEffect(() => {
    document.title = "Tổng quan - Go24";
  }, []);

  return (
    <div className="flex flex-col gap-y-4 lg:gap-y-8">
      <Section title={t("contentTitle.overview.TransportationRevenueReport")}>
        <div className="flex flex-col md:flex-row rounded-lg gap-x-6 bg-white py-2 px-4 w-full shadow-md shadow-[#d1d1d126] mb-4">
          <div className="grid grid-cols-2 md:grid-cols-4 w-full gap-y-3 gap-x-4 lg:gap-x-6">
            <div className="flex flex-col justify-start">
              <p className="font-semibold text-sm leading-none mb-2 text-start">
                {t("filter.fieldTitle.From")}
              </p>
              <DatePicker
                defaultValue={dayjs(deliveryFrom, dateFormat)}
                format={dateFormat}
                className="h-10 px-4"
                suffixIcon={
                  <IoCalendarNumberOutline className="text-2xl text-black" />
                }
                onChange={(date, dateString) => {
                  setDeliveryFrom(dateString);
                }}
                disabledDate={(current) => {
                  let customDate = dayjs(deliveryTo).format("YYYY-MM-DD");
                  return current && current > dayjs(customDate, "YYYY-MM-DD");
                }}
                allowClear={false}
              />
            </div>
            <div className="flex flex-col justify-start">
              <p className="font-semibold text-sm leading-none mb-2 text-start">
                {t("filter.fieldTitle.To")}
              </p>
              <DatePicker
                defaultValue={dayjs(deliveryTo, dateFormat)}
                format={dateFormat}
                className="h-10 px-4"
                suffixIcon={
                  <IoCalendarNumberOutline className="text-2xl text-black" />
                }
                onChange={(date, dateString) => {
                  setDeliveryTo(dateString);
                }}
                disabledDate={(current) => {
                  let customDate = dayjs(deliveryFrom).format("YYYY-MM-DD");
                  return current && current < dayjs(customDate, "YYYY-MM-DD");
                }}
                allowClear={false}
              />
            </div>
            <div className="flex flex-col justify-start">
              <p className="font-semibold text-sm leading-none mb-2 text-start">
                Chu kỳ
              </p>
              <StyledSelect
                className="rounded-lg w-full h-10 text-start"
                suffixIcon={<IoCaretDown className="text-lg" />}
                onChange={(value: any) => handleChangeDateType(value)}
                defaultValue="Ngày"
              >
                {PERIOD_TYPE.map((type) => (
                  <Select.Option key={type.key} value={type.key}>
                    {type.value}
                  </Select.Option>
                ))}
              </StyledSelect>
            </div>
            <div className="flex flex-col justify-start">
              <p className="font-semibold text-sm leading-none mb-2 text-start">
                Loại ngày
              </p>
              <StyledSelect
                className="rounded-lg w-full h-10 text-start"
                suffixIcon={<IoCaretDown className="text-lg" />}
                onChange={(value: any) => handleChangeReportDateType(value)}
                defaultValue="paidDate"
              >
                {REPORT_DATE_TYPE.map((type) => (
                  <Select.Option key={type.key} value={type.key}>
                    {type.value}
                  </Select.Option>
                ))}
              </StyledSelect>
            </div>
          </div>
          <div className="my-auto">
            <button
              className="!border-none mt-4 lg:mt-[22px] rounded px-6 h-10 bg-main-2 text-sm leading-none text-white hover:bg-main-4"
              onClick={() => setRefreshDeliveryData(new Date().getTime())}
            >
              {t("filter.buttonTitle.View")}
            </button>
          </div>
        </div>
        <div className="relative grid grid-cols-12 gap-4 lg:gap-8">
          <div className="flex flex-col col-span-12 xl:col-span-5">
            <div className="grid grid-cols-2 xl:grid-cols-1 gap-4 lg:gap-6">
              <BlockBannerLG
                className="bg-[#7A3EB833] shadow-md shadow-[#d1d1d126]"
                bannerImage={bannerImage1}
                data={[
                  {
                    title: "Doanh thu vận chuyển",
                    value: overviewData?.deliveryValue,
                  },
                  {
                    title: "Giá trị vận chuyển trung bình",
                    value: overviewData?.averageDeliveryValue,
                  },
                ]}
              />
              <BlockBannerLG
                className="bg-[#FFD40033] shadow-md shadow-[#d1d1d126]"
                bannerImage={bannerImage2}
                data={[
                  {
                    title: "Tổng giá trị hàng hóa",
                    value: overviewData?.totalRevenue,
                  },
                  {
                    title: "Giá trị hàng hoá trung bình mỗi đơn",
                    value: overviewData?.averageRevenue,
                  },
                ]}
              />
            </div>
          </div>
          <div
            className="col-span-12 xl:col-span-7 flex w-full items-center min-h-[300px] lg:min-h-[400px] relative"
            ref={chartRef}
          >
            <div className="absolute h-[100px] w-full lg:h-full z-0">
              <img
                src={chartBg}
                className="object-fill w-full h-full"
                alt="chartBg"
              />
            </div>
            {overviewData?.chart && (
              <div>
                <Chart
                  options={
                    {
                      ...chartData.options,
                      labels: overviewData.chart.labels,
                    } as any
                  }
                  series={overviewData.chart.datasets}
                  type="line"
                  width={chartSize.width}
                  height={chartSize.height}
                />
              </div>
            )}
          </div>
        </div>
      </Section>
      <Section title={t("contentTitle.overview.CarrierspecificRevenueReport")}>
        <div className="max-w-[100%] overflow-x-auto shadow-lg">
          <div
            className="rounded-lg overflow-hidden bg-white lg:!min-w-none"
            style={{ minWidth: 160 * columns1.length + 32 }}
          >
            <div className="bg-gray-1 px-4 py-[10px] flex flex-row justify-between items-center md:text-sm text-sm font-semibold rounded-tl-lg rounded-tr-lg">
              {columns1.map((column, i) => (
                <div key={i} className="w-[150px]">
                  {column.title}
                </div>
              ))}
            </div>
            {deliveryReport?.map((data: any, index: number) => (
              <div
                key={data?.deliveryPartnerId}
                className="px-4 py-[10px] flex flex-row justify-between items-center text-sm border-b last:border-transparent border-gray-1"
              >
                <div className="w-[150px]">{data?.deliveryPartnerName}</div>
                <div className="w-[150px]">
                  {formatNumber(data?.totalOrder)}
                </div>
                <div className="w-[150px]">
                  {formatNumber(data?.totalDeliveryFee)}
                </div>
                <div className="w-[150px]">{formatNumber(data?.costPrice)}</div>
                <div className="w-[150px]">{formatNumber(data?.revenue)}</div>
              </div>
            ))}
          </div>
        </div>
      </Section>
      <Section title={t("contentTitle.overview.ShopspecificRevenueReport")}>
        <div className="flex flex-wrap justify-between lg:justify-normal gap-y-4 md:gap-y-1 rounded-lg gap-x-4 lg:gap-x-6 bg-white py-2 px-4 w-full shadow-md shadow-[#d1d1d126] mb-4">
          <div className="w-[47%] lg:w-[27%]">
            <p className="font-semibold text-sm leading-none mb-2 text-start">
              {t("filter.fieldTitle.From")}
            </p>
            <DatePicker
              defaultValue={dayjs(shopFrom, dateFormat)}
              format={dateFormat}
              className="w-full h-10 px-4"
              suffixIcon={
                <IoCalendarNumberOutline className="text-2xl text-black" />
              }
              onChange={(date, dateString) => {
                setShopFrom(dateString);
              }}
              disabledDate={(current) => {
                let customDate = dayjs(shopTo).format("YYYY-MM-DD");
                return current && current > dayjs(customDate, "YYYY-MM-DD");
              }}
              allowClear={false}
            />
          </div>
          <div className="w-[47%] lg:w-[27%]">
            <p className="font-semibold text-sm leading-none mb-2 text-start">
              {t("filter.fieldTitle.To")}
            </p>
            <DatePicker
              defaultValue={dayjs(shopTo, dateFormat)}
              format={dateFormat}
              className="w-full h-10 px-4"
              suffixIcon={
                <IoCalendarNumberOutline className="text-2xl text-black" />
              }
              onChange={(date, dateString) => {
                setShopTo(dateString);
              }}
              disabledDate={(current) => {
                let customDate = dayjs(shopFrom).format("YYYY-MM-DD");
                return current && current < dayjs(customDate, "YYYY-MM-DD");
              }}
              allowClear={false}
            />
          </div>
          <div className="w-[47%] lg:w-[27%] flex flex-col justify-start">
            <p className="font-semibold text-sm leading-none mb-2 text-start">
              Loại ngày
            </p>
            <Select
              className="rounded-lg w-full h-10 text-start"
              suffixIcon={<IoCaretDown className="text-lg" />}
              onChange={(value: any) => setShopDateType(value)}
              defaultValue="paidDate"
            >
              {REPORT_DATE_TYPE.map((type) => (
                <Select.Option key={type.key} value={type.key}>
                  {type.value}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="w-[47%] lg:w-[10%] my-auto text-left">
            <button
              className="!border-none mt-[22px] rounded px-6 h-10 bg-main-2 text-sm leading-none text-white hover:bg-main-4"
              onClick={() => setRefreshShopData(new Date().getTime())}
            >
              {t("filter.buttonTitle.View")}
            </button>
          </div>
        </div>
        <div className="bg-white rounded-lg p-2 lg:p-3 text-sm w-full shadow-lg mb-4">
          <div className="flex flex-wrap md:flex-nowrap gap-1 md:gap-3">
            <div className="flex gap-1">
              <p>Tổng số shop:</p>
              <p className="font-semibold">{shopReport?.length}</p>
            </div>
            <div className="flex gap-1">
              <p>Tổng số shop có đơn hàng:</p>
              <p className="font-semibold">{totalShop}</p>
            </div>
            <div className="flex gap-1">
              <p>Tổng số đơn hàng:</p>
              <p className="mr-2 font-semibold">{totalOrders}</p>
            </div>
          </div>
        </div>
        <div className="max-w-[100%] overflow-x-auto shadow-lg">
          <div
            className="rounded-lg overflow-hidden bg-white lg:!min-w-none list"
            style={{ minWidth: 160 * columns2.length + 32 }}
          >
            <div className="bg-gray-1 px-4 py-[10px] flex flex-row justify-between items-center text-sm font-semibold">
              {columns2.map((column, i) => (
                <div key={i} className="w-[150px]">
                  {column.title}
                </div>
              ))}
            </div>
            {shopReport?.length === 0 ? (
              <div className="col-span-12 xl:col-span-7 py-6 flex items-center justify-center">
                <img
                  src={NoDataIcon}
                  alt="no-data"
                  className="w-[180px] h-[162px] md:w-[250px] md:h-[225px]"
                />
              </div>
            ) : (
              shopReport
                ?.slice(PAGE_SIZE * (page - 1), PAGE_SIZE * page)
                ?.map((data: any, index: number) => (
                  <div
                    key={index}
                    className="px-4 py-[10px] flex flex-row justify-between items-center text-sm border-b last:border-transparent border-gray-1"
                  >
                    <Link
                      to={`/shop-management/revenue/${data?.shopId}`}
                      target="_blank"
                    >
                      <div className="w-[150px] text-blue-2">
                        {data?.userName}
                      </div>
                    </Link>
                    <div className="w-[150px]">{data?.shopName}</div>
                    <div className="w-[150px]">
                      {formatNumber(data?.totalOrder)}
                    </div>
                    <div className="w-[150px]">
                      {formatNumber(data?.totalDeliveryFee)}
                    </div>
                    <div className="w-[150px]">
                      {formatNumber(data?.costPrice)}
                    </div>
                    <div className="w-[150px]">
                      {formatNumber(data?.revenue)}
                    </div>
                  </div>
                ))
            )}
          </div>
        </div>
        <Pagination {...{ page, setPage, totalPage }} />
      </Section>
    </div>
  );
};

export default Overview;
