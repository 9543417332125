import { Section } from "@components/section/Section";
import { StyledTab } from "@pages/shop-management/styles";
import { useTranslation } from "react-i18next";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import ArrowForwardIcon from "@assets/image/shop-management/ArrowForward.svg";
import ShopManagementTabsItem from "./ShopManagementTabsItem";

const TAB_ITEMS = [
  { path: "details", label: "shopManagement.AccountDetails" },
  { path: "revenue", label: "shopManagement.ShopRevenueReport" },
];

const ShopManagementTabs = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;

  const detectTitle = () => {
    if (pathname.includes("details")) return t("shopManagement.ShopDetails");
    if (pathname.includes("revenue"))
      return t("shopManagement.ShopRevenueReport");
    return "";
  };

  return (
    <div className="flex flex-col gap-y-8 ">
      <Section title={detectTitle()}>
        <div className="flex gap-8 items-center w-full overflow-x-auto">
          <StyledTab className="min-width-[500px] md:w-full flex items-center bg-white rounded-lg gap-5 px-4 whitespace-nowrap">
            <div
              className="text-base text-black font-normal px-2 py-2 md:py-3 cursor-pointer border-b border-transparent hover:border-main-1"
              onClick={() => navigate("/shop-management")}
            >
              {t("shopManagement.ShopList")}
            </div>
            <img src={ArrowForwardIcon} className="w-[24px] h-[24px]" alt="" />
            {TAB_ITEMS.map(({ label, path }, index) => (
              <ShopManagementTabsItem path={path} label={t(label)} key={index} />
            ))}
          </StyledTab>
        </div>
        <Outlet />
      </Section>
    </div>
  );
};

export default ShopManagementTabs;
