import { Section } from "@components/section/Section";
import { DatePicker, Input, Select } from "antd";
import { PAGE_SIZE, REQUEST_STATUS } from "constants/constants";
import { IoCaretDown } from "react-icons/io5";
import { IoCalendarNumberOutline } from "react-icons/io5";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RequestBlock } from "@pages/requests-management/RequestBlock";
import Pagination from "@components/pagination";
import { WrapperShadowStyle } from "./styles";
import { getRequest, getRequestTypes } from "@services/request-services";
import FilterRequestTypes from "./FilterRequestTypes";
import CommentModal from "./CommentModal";
import RequestDetails from "./RequestDetails";
import { getTagList } from "@services/hashtag-services";
import { Tag } from "@models/TagModel";
import { HASHTAG_TYPE } from "constants/constants";
import { useLoading } from "provider/loading";
import NoDataIcon from "@assets/image/no-data.png";

const { REQUEST } = HASHTAG_TYPE;

export interface IRequestType {
  requestType: string;
  requestTypeCount: number;
}

const RequestsManagement: React.FC = () => {
  const dateFormat = "YYYY-MM-DD";
  const { t } = useTranslation();
  const { setLoading } = useLoading();

  const [isShowRequestDetails, setIsShowRequestDetails] =
    useState<boolean>(false);

  const [refreshData, setRefreshData] = useState<number>(new Date().getTime());

  const [requestTypes, setRequestTypes] = useState<IRequestType[]>([]);
  const [filterType, setFilterType] = useState<string>("total");
  const [requestList, setRequestList] = useState<any>([]);
  const [request, setRequest] = useState<any>({});
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [from, setFrom] = useState<any>(
    dayjs(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format(
      dateFormat
    )
  );
  const [to, setTo] = useState<any>(dayjs(new Date()).format(dateFormat));
  const [status, setStatus] = useState<number>(0);
  const [shopText, setShopText] = useState<string>("");
  const [orderText, setOrderText] = useState<string>("");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [hashtagList, setHashtagList] = useState<Tag[]>([]);

  const showModal = (idRequest: string) => {
    setIsModalOpen(true);
    const findRequest = requestList.find((req: any) => req.id === idRequest);
    setRequest(findRequest);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleChangeFilter = (value: string) => {
    setPage(1);
    setFilterType(value);
  };

  const handleCancelRequestDetails = () => {
    setIsShowRequestDetails(false);
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const payload = {
          page,
          limit: PAGE_SIZE,
          from,
          to,
          requestType: filterType === "total" ? "" : filterType,
          orderText,
          shopText,
          status: status === 0 ? "" : status,
        };
        const res = await getRequest(payload);
        if (res) {
          setRequestList(res.data);
          setPage(res.meta.page);
          setTotalPage(res.meta.pageCount || 1);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterType, refreshData, page]);

  useEffect(() => {
    (async () => {
      try {
        const payload = {
          from,
          to,
          orderText,
          shopText,
          status: status === 0 ? "" : status,
        };
        const res = await getRequestTypes(payload);
        if (res) {
          setRequestTypes(res);
        }
      } catch (error) {
        console.log(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData]);

  useEffect(() => {
    (async () => {
      try {
        const res = await getTagList({
          page: 1,
          limit: 50,
          type: REQUEST,
        });
        if (res) {
          const activeTagList = res.data?.filter((item: any) => item?.isActive);
          setHashtagList(activeTagList);
        }
      } catch (e) {}
    })();
  }, []);

  useEffect(() => {
    document.title = "Quản lý yêu cầu - Go24";
  }, []);

  return (
    <div className="flex flex-col">
      <Section title={t("contentTitle.requestsManagement.RequestsManagement")}>
        <WrapperShadowStyle className="rounded overflow-hidden mb-8 flex gap-4 bg-white py-2 px-4">
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4  justify-between w-full">
            <div className="w-full">
              <p className="font-semibold text-sm leading-none mb-2 text-start">
                {t("filter.fieldTitle.From")}
              </p>
              <DatePicker
                defaultValue={dayjs(from, dateFormat)}
                format={dateFormat}
                className="w-full h-10 px-4"
                suffixIcon={
                  <IoCalendarNumberOutline className="text-2xl text-black" />
                }
                onChange={(date, dateString: any) => {
                  setFrom(dateString);
                }}
                disabledDate={(current) => {
                  let customDate = dayjs(to).format("YYYY-MM-DD");
                  return current && current > dayjs(customDate, "YYYY-MM-DD");
                }}
                allowClear={false}
              />
            </div>
            <div className="w-full">
              <p className="font-semibold text-sm leading-none mb-2 text-start">
                {t("filter.fieldTitle.To")}
              </p>
              <DatePicker
                defaultValue={dayjs(to, dateFormat)}
                format={dateFormat}
                className="w-full h-10 px-4"
                suffixIcon={
                  <IoCalendarNumberOutline className="text-2xl text-black" />
                }
                onChange={(date, dateString: any) => {
                  setTo(dateString);
                }}
                disabledDate={(current) => {
                  let customDate = dayjs(from).format("YYYY-MM-DD");
                  return current && current < dayjs(customDate, "YYYY-MM-DD");
                }}
                allowClear={false}
              />
            </div>
            <div className="w-full">
              <p className="font-semibold text-sm leading-none mb-2 text-start">
                {t("filter.fieldTitle.State")}
              </p>
              <Select
                defaultValue={status}
                className="w-full h-10 text-start first:pr-4"
                suffixIcon={<IoCaretDown className="text-lg" />}
                options={Object.values(REQUEST_STATUS).map((status) => ({
                  value: status.code,
                  label: t(`RequestsManagement.${status.key}`),
                }))}
                onChange={(value: number) => setStatus(value)}
              />
            </div>
            <div className="w-full">
              <p className="font-semibold text-sm leading-none mb-2 text-start">
                {t("filter.fieldTitle.Shop")}
              </p>
              <Input
                className="w-full h-10 px-4"
                placeholder={t("filter.fieldContent.UsernameOrPhoneNumber")}
                onChange={(e) => setShopText(e.target.value)}
              />
            </div>
            <div className="w-full">
              <p className="font-semibold text-sm leading-none mb-2 text-start">
                {t("filter.fieldTitle.Keyword")}
              </p>
              <Input
                className="w-full h-10 px-4"
                placeholder={t("filter.fieldContent.OrderID-TrackingCode")}
                onChange={(e) => setOrderText(e.target.value)}
              />
            </div>
            <div className="md:hidden">
              <button
                className="!border-none rounded mt-[22px] px-6 h-10 bg-main-2 text-sm leading-none text-white hover:bg-main-4"
                onClick={() => {
                  setPage(1);
                  setRequest({});
                  setIsShowRequestDetails(false);
                  setRefreshData(new Date().getTime());
                  setFilterType("total");
                }}
              >
                {t("filter.buttonTitle.View")}
              </button>
            </div>
          </div>
          <div className="hidden md:block">
            <button
              className="!border-none rounded mt-[22px] px-6 h-10 bg-main-2 text-sm leading-none text-white hover:bg-main-4"
              onClick={() => {
                setPage(1);
                setRequest({});
                setIsShowRequestDetails(false);
                setRefreshData(new Date().getTime());
                setFilterType("total");
              }}
            >
              {t("filter.buttonTitle.View")}
            </button>
          </div>
        </WrapperShadowStyle>
      </Section>
      <FilterRequestTypes
        requestTypes={requestTypes}
        filterType={filterType}
        handleChangeFilter={handleChangeFilter}
      />
      <div className={`${isShowRequestDetails ? "mt-8" : "hidden"}`}>
        <RequestDetails
          request={request}
          handleCancel={handleCancelRequestDetails}
          setRefreshData={setRefreshData}
        />
      </div>

      <div className="mt-8">
        <div className="flex flex-col gap-y-4">
          {requestList?.length === 0 ? (
            <div className="w-full h-full py-6 flex items-center justify-center">
              <img
                src={NoDataIcon}
                alt="no-data"
                className="w-[180px] h-[162px] md:w-[250px] md:h-[225px]"
              />
            </div>
          ) : (
            requestList?.map((data: any) => (
              <RequestBlock
                key={data?.id}
                tagList={hashtagList}
                showDetailsFunc={() => {
                  setIsShowRequestDetails(true);
                  setRequest(data);
                }}
                data={data}
                commentFunc={showModal}
              />
            ))
          )}
        </div>
        <Pagination {...{ page, setPage }} totalPage={totalPage} />
      </div>
      <CommentModal
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalOpen={isModalOpen}
        request={request}
      />
    </div>
  );
};

export default RequestsManagement;
