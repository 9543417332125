import { message } from "antd";
import { api, go24Api } from "provider/api";

export const getOrderList = async (payload: any) => {
  try {
    const res = await api.get("order", { params: payload });
    if (res && res.data) {
      return {
        data: res.data.response.data,
        meta: res.data.response.meta,
      };
    }
    return undefined;
  } catch (e) {
    message.error("Lỗi lấy danh sách đơn hàng");
  }
};

export const getOrderDetails = async (id: any) => {
  try {
    const res = await api.get(`/order/${id}`);
    if (res && res.data) return res.data.response;

    return undefined;
  } catch (e) {}
};

export const getOrderTracking = async (payload: any) => {
  const res = await go24Api.get(`/shop/order/order-tracking`, {
    params: payload,
  });
  if (res && res.data) return res.data;
  return undefined;
};

export const getTracking = async (id: any) => {
  try {
    const res = await api.get(`/order/tracking/${id}`);
    if (res && res.data) return res.data.response;

    return undefined;
  } catch (e) {}
};

export const getOrderFromCODStatus = async (payload: any) => {
  try {
    const res = await api.get("order/get/cod-statuses", { params: payload });
    if (res && res.data) {
      return res.data.response;
    }
    return undefined;
  } catch (e) {}
};

export const getOrderFromDeliveryStatus = async ({
  page,
  limit,
  from,
  to,
  dateType,
  searchText,
  partnerDeliveryId,
  searchShop,
  feeType,
  printType,
  hashtag,
  addedService,
  deliveryArea,
  hasReturned,
}: any) => {
  try {
    const payload = {
      page,
      limit,
      from,
      to,
      dateType,
      searchText,
      partnerDeliveryId,
      searchShop,
      feeType,
      printType,
      hashtag,
      addedService,
      deliveryArea,
      hasReturned,
    };
    const res = await api.get("order/get/delivery-statuses", {
      params: payload,
    });
    if (res && res.data) {
      return res.data.response;
    }
    return undefined;
  } catch (e) {}
};

export const getOrderFromOrderStatus = async ({
  page,
  limit,
  from,
  to,
  dateType,
  searchText,
  partnerDeliveryId,
  searchShop,
  feeType,
  printType,
  hashtag,
  addedService,
  deliveryArea,
  hasReturned,
}: any) => {
  try {
    const payload = {
      page,
      limit,
      from,
      to,
      dateType,
      searchText,
      partnerDeliveryId,
      searchShop,
      feeType,
      printType,
      hashtag,
      addedService,
      deliveryArea,
      hasReturned,
    };
    const res = await api.get("order/get/order-statuses", { params: payload });
    if (res && res.data) {
      return res.data.response;
    }
    return undefined;
  } catch (e) {}
};

export const getOrderFromPaidStatus = async ({
  page,
  limit,
  from,
  to,
  dateType,
  searchText,
  partnerDeliveryId,
  searchShop,
  feeType,
  printType,
  hashtag,
  addedService,
  deliveryArea,
  hasReturned,
}: any) => {
  try {
    const payload = {
      page,
      limit,
      from,
      to,
      dateType,
      searchText,
      partnerDeliveryId,
      searchShop,
      feeType,
      printType,
      hashtag,
      addedService,
      deliveryArea,
      hasReturned,
    };
    const res = await api.get("order/get/paid-statuses", { params: payload });
    if (res && res.data) {
      return res.data.response;
    }
    return undefined;
  } catch (e) {}
};

export const getsAvailableShopLocations = async (userId: number) => {
  try {
    const res = await go24Api.get(
      `/shopLocation/gets-available-shop?orderUserId=${userId}`
    );
    if (res && res.data) {
      return res.data;
    }
    return undefined;
  } catch (e) {
    // message.error(e)
  }
};

export const getsByPhone = async (phoneNumber: string) => {
  try {
    const res = await go24Api.get(
      `/customer/gets-by-phone?phoneNumber=${phoneNumber}`
    );
    if (res && res.data) {
      return res.data;
    }
    return undefined;
  } catch (e) {
    // message.error(e)
  }
};

export const calculateOrderAmount = async (payload: any) => {
  try {
    const res = await go24Api.post("/order/calculate-order-amount", payload);
    if (res && res.data) {
      return res.data;
    }
    return undefined;
  } catch (e) {
    // message.error(e)
  }
};

export const getPriceDeliveryv2 = async (payload: any) => {
  try {
    const res = await go24Api.get("/v2/DeliveryPartner/get-price-delivery", {
      params: payload,
    });
    if (res && res.data) {
      return res.data;
    }
    return undefined;
  } catch (e) {
    // message.error(e)
  }
};

export const getPriceShopDelivery = async (payload: any) => {
  try {
    const res = await go24Api.get("/DeliveryPartner/get-price-shop-delivery", {
      params: payload,
    });
    if (res && res.data) {
      return res.data;
    }
    return undefined;
  } catch (e) {
    // message.error(e)
  }
};

export const getCODStatus = async (userId = 0) => {
  try {
    const res = await go24Api.get(`/order/get/cod-status?userId=${userId}`);
    if (res && res.data) {
      return res.data;
    }
    return undefined;
  } catch (e) {
    // message.error(e)
  }
};

export const searchItemToOrder = async (searchContent: string) => {
  try {
    const res = await go24Api.get(
      `/Item/get-all?searchContent=${searchContent}&pageSize=10&page=1`
    );
    if (res && res.data) {
      return res.data;
    }
    return undefined;
  } catch (e) {
    // message.error(e)
  }
};

///Voucher/gets-available
export const getVouchers = async () => {
  try {
    const res = await go24Api.get(`/Voucher/gets-available`);
    if (res && res.data) {
      return res.data;
    }
    return undefined;
  } catch (e) {
    // message.error(e)
  }
};

export const updateOrder = async (payload: any) => {
  const res = await go24Api.post("/order/update-info", {
    orderUpdate: payload,
  });
  return res;
};

export const renewOrder = async (payload: any) => {
  const res = await go24Api.post("/order/renew-order", {
    orderRenew: payload,
  });
  return res;
};

export const confirmOrder = async (orderId: any) => {
  const res = await go24Api.post("/order/confirm", { orderId });
  return res;
};

export const cancelOrder = async (orderId: any) => {
  const res = await go24Api.post("/order/cancel", { orderId });
  return res;
};
