import { Modal } from "antd";
import { useEffect, useMemo, useState } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import Messages from "./Comments";
import InputMessage, { IDataMessage } from "./InputComment";
import {
  getRequestById,
  updateCommentByIdRequest,
} from "@services/request-services";

interface ICommentModalProps {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  request: any;
}

const CommentModal = ({
  isModalOpen,
  handleCancel,
  handleOk,
  request,
}: ICommentModalProps) => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const sortComment = useMemo(
    () => (comments: any[]) => {
      if (comments && comments.length > 0) {
        return comments.sort(
          (a: any, b: any) =>
            new Date(b.createdDate).valueOf() -
            new Date(a.createdDate).valueOf()
        );
      }
      return [];
    },
    []
  );

  useEffect(() => {
    (async () => {
      try {
        if (request?.id) {
          setLoading(true);
          const res = await getRequestById(request.id, request?.tableName);
          if (res) {
            setData({
              ...res,
              comment: sortComment(res.comment),
            });
            setLoading(false);
          }
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [request]);

  const handleSendComment = async ({ inputStr }: IDataMessage) => {
    try {
      if (inputStr) {
        const payload = { content: inputStr, tableName: request?.tableName };
        const res = await updateCommentByIdRequest(data.id, payload);
        if (res) {
          const newComment = sortComment(res);
          const updateComment = {
            ...data,
            comment: newComment,
          };
          setData(updateComment);
        }
      }
    } catch (error) { }
  };

  return (
    <Modal
      centered
      title=""
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      classNames={{
        content: "!p-0",
      }}
      closeIcon={<IoCloseCircleOutline className="text-2xl text-white" />}
      width={900}
      footer={null}
    >
      <div className="text-white bg-blue-3 h-14 flex justify-center items-center text-base font-semibold rounded-tl-lg rounded-tr-lg">
        Bình luận
      </div>
      <div className="flex border-b flex-col md:flex-row">
        <div className="w-full md:w-1/2 border-r px-6 py-2">
          <div className="flex gap-2 md:justify-between">
            <p className="font-semibold">Mã đơn hàng:</p>
            <p>{request?.code}</p>
          </div>
          <div className="flex gap-2 md:justify-between">
            <p className="font-semibold">Mã vận đơn:</p>
            <p>{request?.deliveryCode}</p>
          </div>
          <div className="flex gap-2 md:justify-between">
            <p className="font-semibold">Mã ticket:</p>
            <p></p>
          </div>
          <div className="flex gap-2 md:justify-between">
            <p className="font-semibold">Mã ticket NVC:</p>
            <p></p>
          </div>
        </div>
        <div className="w-full md:w-1/2 px-6 py-2">
          <div className="flex gap-2 md:justify-between">
            <p className="font-semibold">Loại khiếu yêu cầu:</p>
            <p>{request?.requestType}</p>
          </div>
          <div className="flex gap-2 md:justify-between">
            <p className="font-semibold">Trạng thái:</p>
            <p>{request?.requestStatus}</p>
          </div>
        </div>
      </div>
      <Messages messages={data?.comment} loading={loading} />
      <InputMessage handleSendComment={handleSendComment} />
    </Modal>
  );
};

export default CommentModal;
