import { useTranslation } from "react-i18next";
import { Button, Checkbox, Input, Modal, Select, message } from "antd";
import { IoCaretDown } from "react-icons/io5";
import { StyledSelect } from "./styles";
import { Bank } from "@models/BankAccount";
import {
  createBankAccount,
  updateBankAccount,
} from "@services/bank-account-services";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

export interface IBankAccountForm {
  open: boolean;
  setOpen: any;
  selectedBankAccount: any;
  setRefreshData: any;
  setSelectedBankAccount: any;
  bankList: Bank[];
}

const BankAccountForm = ({
  open,
  setOpen,
  selectedBankAccount,
  setSelectedBankAccount,
  setRefreshData,
  bankList,
}: IBankAccountForm) => {
  const { t } = useTranslation();
  const params = useParams();
  const { id } = params;
  const [filterData, setFilterData] = useState(bankList || []);
  const [bankEntityId, setBankEntityId] = useState<number | undefined>(
    selectedBankAccount?.bankEntityId || undefined
  );
  const [shortName, setShortName] = useState("");
  const [accountName, setAccountName] = useState<string>(
    selectedBankAccount?.accountName || ""
  );
  const [accountNumber, setAccountNumber] = useState(
    selectedBankAccount?.accountNumber || ""
  );
  const [isDefault, setIsDefault] = useState(
    selectedBankAccount?.isDefault || false
  );

  const handleSearch = (newValue: string) => {
    const data = bankList.filter((ite) =>
      `${ite.bankShortName} - ${ite.bankName}`
        .toLowerCase()
        .includes(newValue.toLowerCase())
    );
    setFilterData(data);
  };

  const validate = () => {
    if(!bankEntityId) {
      message.error("Tên ngân hàng là bắt buộc");
      return false;
    }
    if(!accountName) {
      message.error("Tên chủ tài khoản là bắt buộc");
      return false;
    }
    if(!accountName) {
      message.error("Số tài khoản là bắt buộc");
      return false;
    }
    return true;
  }

  const onSave = async () => {
    if(!validate()) {
      return;
    }
    const payload = {
      bankEntityId,
      accountName,
      accountNumber,
      isDefault,
    };
    if (selectedBankAccount) {
      try {
        const res = await updateBankAccount({
          ...payload,
          id: selectedBankAccount?.id,
          shopId: Number(id),
        });
        if (res) {
          message.success(t("bank.UpdateBankAccountSuccessfully"));
          setOpen(false);
          setRefreshData(new Date().getTime());
        }
      } catch (e) {
        message.error(t("bank.UpdateBankAccountFailed"));
      }
      return;
    }
    if (id) {
      try {
        const res = await createBankAccount({ ...payload, shopId: Number(id) });
        if (res) {
          message.success(t("bank.CreateBankAccountSuccessfully"));
          setOpen(false);
          setRefreshData(new Date().getTime());
        }
      } catch (e) {
        message.error(t("bank.CreateBankAccountFailed"));
      }
    }
  };

  const onCancel = () => {
    setSelectedBankAccount();
    setOpen(false);
  };

  useEffect(() => {
    if (bankEntityId) {
      const check = bankList.find(
        (item) => item.id.toString() === bankEntityId.toString()
      );
      if (check) {
        setShortName(check.bankShortName);
      }
    }
  }, [bankEntityId, bankList]);

  return (
    <Modal
      title=""
      open={open}
      onCancel={onCancel}
      width={900}
      footer={null}
      centered
    >
      <div className="text-base font-semibold border-b pb-3">
        {selectedBankAccount?.id
          ? t("accountDetails.EditBankAccount")
          : t("accountDetails.AddBankAccount")}
      </div>
      <div className="mt-4 flex flex-col md:flex-row gap-4 md:gap-7">
        <div className="flex flex-col items-start gap-4 w-full md:w-1/2">
          <p className="text-sm font-medium text-black">
            Tên ngân hàng
            <span className="text-red"> *</span>
          </p>
          <StyledSelect
            className="rounded-lg w-full h-10"
            suffixIcon={<IoCaretDown className="text-lg" />}
            defaultValue={
              bankList.find((item) => item.id === bankEntityId)?.bankName
            }
            onChange={(e: any) => {
              setBankEntityId(e);
            }}
            placeholder="Nhập tên ngân hàng"
            showSearch
            onSearch={handleSearch}
            filterOption={false}
          >
            {filterData.map((bank) => (
              <Select.Option key={bank.id} value={bank.id.toString()}>
                {`${bank.bankShortName} - ${bank.bankName}`}
              </Select.Option>
            ))}
          </StyledSelect>
        </div>
        <div className="w-full md:w-1/2 flex flex-col items-start gap-4">
          <p className="text-sm font-medium text-black">
            {t("accountDetails.AccountOwnerName")}
            <span className="text-red"> *</span>
          </p>
          <Input
            className="rounded-lg w-full h-10 border-black border-[0.5px]"
            placeholder={t("accountDetails.placeholder.AccountName")}
            value={accountName}
            onChange={(e: any) => setAccountName(e.target.value)}
          />
        </div>
      </div>
      <div className="mt-4 flex flex-col md:flex-row gap-4 md:gap-7">
        <div className="w-full md:w-1/2 flex flex-col items-start gap-4">
          <p className="text-sm font-medium text-black">
            {t("accountDetails.AccountNumber")}
            <span className="text-red"> *</span>
          </p>
          <Input
            className="rounded-lg w-full h-10 border-black border-[0.5px]"
            placeholder={t("accountDetails.placeholder.AccountNumber")}
            value={accountNumber}
            onChange={(e: any) => setAccountNumber(e.target.value)}
          />
        </div>
        <div className="w-full md:w-1/2 flex items-end mb-[9px]">
          <div className="flex flex-col justify-end gap-4">
            <div className="flex items-center gap-4">
              <Checkbox
                value={isDefault}
                onChange={(e: any) => setIsDefault(e.target.checked)}
              />
              <div>{t("accountDetails.Default")}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4 flex justify-center">
        <button
          className="!border-none rounded px-6 py-2 bg-main-2 text-sm font-medium leading-[24px] text-white hover:bg-main-4 flex justify-center items-center"
          onClick={onSave}
        >
          {selectedBankAccount?.id
            ? t("accountDetails.Save")
            : t("button.AddAccount")}
        </button>
      </div>
    </Modal>
  );
};

export default BankAccountForm;
