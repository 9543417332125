import { geoApi } from "provider/api";

export const getProvince = async () => {
  try {
    const res = await geoApi.get("/province/gets");
    if (res && res.data) {
      return res.data?.data?.data;
    }
    return [];
  } catch (e) {}
};

export const getDistricts = async () => {
  try {
    const res = await geoApi.get("/district/gets");
    if (res && res.data) {
      return res.data?.data?.data;
    }
    return [];
  } catch (e) {}
};

export const getDistrictToProvince = async (provinceId: number) => {
  try {
    const res = await geoApi.get("/district/gets", { params: { provinceId } });
    if (res && res.data) {
      return res.data?.data?.data;
    }
    return [];
  } catch (e) {}
};

export const getWardToDistrict = async (
  provinceId: number,
  districtId: number
) => {
  try {
    const res = await geoApi.get("/ward/gets", {
      params: { provinceId, districtId },
    });
    if (res && res.data) {
      return res.data?.data?.data;
    }
    return [];
  } catch (e) {}
};

export const getAddressLevelFourToWard = async (
  provinceId: number,
  districtId: number,
  wardId: number
) => {
  try {
    const res = await geoApi.get("/address-level-four/gets", {
      params: { provinceId, districtId, wardId },
    });
    if (res && res.data) {
      return res.data?.data?.data;
    }
    return [];
  } catch (e) {}
};

export const getProvinceById = async (id: number) => {
  try {
    const res = await geoApi.get(`/province/get?provinceId=${id}`);
    if (res) {
      return res.data?.data?.name;
    }
    return undefined;
  } catch (e) {}
};

export const getDistrictById = async (id: number) => {
  try {
    const res = await geoApi.get(`/district/get?districtId=${id}`);
    if (res) {
      return res.data?.data?.name;
    }
    return undefined;
  } catch (e) {}
};

export const getWards = async () => {
  try {
    const res = await geoApi.get("/ward/gets");
    if (res && res.data) {
      return res.data?.data?.data;
    }
    return [];
  } catch (e) {}
};

export const getWardById = async (id: number) => {
  try {
    const res = await geoApi.get(`/ward/get?wardId=${id}`);
    if (res) {
      return res.data?.data?.name;
    }
    return undefined;
  } catch (e) {}
};

export const getAddressLevelFourById = async (id: number) => {
  try {
    const res = await geoApi.get(
      `/address-level-four/get?AddressLevelFourId=${id}`
    );
    if (res) {
      return res.data?.data?.name;
    }
    return undefined;
  } catch (e) {}
};
