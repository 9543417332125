import { Section } from "@components/section/Section";
import { Button, Checkbox, DatePicker, Input, Select, message } from "antd";
import {
  ADDED_SERVICE,
  COD_STATUS,
  DATE_TYPE,
  DELIVERY_AREA,
  ORDER_STATUS,
  ORDER_PAID_STATUS,
  PAYER,
  PRINT_TYPE,
  RETURN_TYPE,
  FILTER_DELIVERY_STATUS,
  HASHTAG_TYPE,
} from "constants/constants";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoCaretDown } from "react-icons/io5";
import { FaPlus, FaMinus } from "react-icons/fa6";
import { IoCalendarNumberOutline } from "react-icons/io5";
import { GoFile } from "react-icons/go";
import { OrderBlock } from "@pages/order-management/OrderBlock";
import { getTagList } from "@services/hashtag-services";
import { Tag } from "@models/TagModel";
import TagIcon from "@assets/svg/Tag";
import {
  getOrderFromCODStatus,
  getOrderFromDeliveryStatus,
  getOrderFromOrderStatus,
  getOrderFromPaidStatus,
  getOrderList,
} from "@services/order-service";
import Pagination from "@components/pagination";
import OrderDetails from "./OrderDetails";
import OrderTracking from "./OrderTracking";
import { getDeliveryList } from "@services/shop-services";
import moment from "moment";
import RequestModal from "./RequestModal";
import EditOrderModal from "./EditOrderModal";
import { exportDataToExcel, formatNumber } from "@helper/helper";
import { useLoading } from "provider/loading";
import { getDistricts, getProvince, getWards } from "@services/geo-services";
import NoDataIcon from "@assets/image/no-data.png";

const { ORDER } = HASHTAG_TYPE;

const OrdersManagement: React.FC = () => {
  const { t } = useTranslation();
  const { setLoading } = useLoading();
  const [orderStatusFilter, setOrderStatusFilter] = useState<
    string | undefined
  >(undefined);
  const dateFormat = "YYYY-MM-DD";
  const [orderPaidStatusFilter, setOrderPaidStatusFilter] = useState<
    string | undefined
  >(undefined);
  const [CODStatusFilter, setCODStatusFilter] = useState<string | undefined>(
    undefined
  );
  const [deliveryStatusFilter, setDeliveryStatusFilter] = useState<
    string | undefined
  >(undefined);
  const [numOfItems, setNumOfItems] = useState<number>(0);
  const [showMoreFilter, setShowMoreFilter] = useState<boolean>(false);
  const [hashtagList, setHashtagList] = useState<Tag[]>([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [from, setFrom] = useState<any>(
    dayjs(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format(
      dateFormat
    )
  );
  const [to, setTo] = useState<any>(dayjs(new Date()).format(dateFormat));
  const [dateType, setDateType] = useState("createdDate");
  const [searchText, setSearchText] = useState("");
  const [partnerDeliveryId, setPartnerDeliveryId] = useState(undefined);
  const [searchShop, setSearchShop] = useState("");
  const [feeType, setFeeType] = useState(undefined);
  const [printType, setPrintType] = useState(undefined);
  const [hashtag, setHashtag] = useState(undefined);
  const [addedService, setAddedService] = useState(undefined);
  const [deliveryArea, setDeliveryArea] = useState(undefined);
  const [hasReturned, setHasReturned] = useState(undefined);
  const [refreshData, setRefreshData] = useState(new Date().getTime());
  const [orderList, setOrderList] = useState<any>([]);
  const [codStatuses, setCODStatuses] = useState<any>([]);
  const [orderStatuses, setOrderStatuses] = useState<any>([]);
  const [paidStatus, setPaidStatus] = useState<any>([]);
  const [deliveryStatuses, setDeliveryStatuses] = useState<any>([]);
  const [selectedOrder, setSelectedOrder] = useState<any>();
  const [openOrderDetails, setOpenOrderDetais] = useState(false);
  const [openOrderTracking, setOpenOrderTracking] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [deliveries, setDeliveries] = useState<any[]>([]);
  const [checkedAllData, setCheckedAllData] = useState(false);
  const [selectedAllData, setSelectedAllData] = useState<any[]>([]);
  const [openRequest, setOpenRequest] = useState<any>("");
  const [openEditOrder, setOpenEditOrder] = useState(false);
  const [provinces, setProvinces] = useState<any[]>([]);
  const [districts, setDistricts] = useState<any[]>([]);
  const [wards, setWards] = useState<any[]>([]);

  const redStatus = [
    "Returned",
    "Canceled",
    "PartiallyReturned",
    "WeightChanged",
  ];
  const orangeStatus = [
    "WaitingReturn",
    "PartiallyReturning",
    "DelayPickup",
    "DelayDelivery",
  ];
  const greenStatus = ["Deliveried", "PartiallyDelivered"];

  const handleChangeOrderStatusFilter = (value: string) => {
    setOrderStatusFilter(() => {
      if (value === "total") return undefined;
      return value;
    });
    setPage(1);
  };
  const handleChangeOrderPaidStatusFilter = (value: string) => {
    setOrderPaidStatusFilter(() => {
      if (value === "total") return undefined;
      return value;
    });
    setPage(1);
  };
  const handleChangeCODStatusFilter = (value: string) => {
    setCODStatusFilter(() => {
      if (value === "total") return undefined;
      return value;
    });
    setPage(1);
  };
  const handleChangeDeliveryStatusFilter = (value: string) => {
    setDeliveryStatusFilter(() => {
      if (value === "total") return undefined;
      return value;
    });
    setPage(1);
  };

  const handleCheck = (data: any) => {
    if (selectedAllData.length === 0) {
      setSelectedAllData([data]);
      return;
    }
    if (selectedAllData.some(({ id }) => id === data?.id)) {
      let tempList = selectedAllData.filter(({ id }) => id !== data?.id);
      setSelectedAllData(tempList);
    } else {
      let tempList = [...selectedAllData, data];
      setSelectedAllData(tempList);
    }
  };
  const handleCheckAll = async (e: any) => {
    setCheckedAllData(e.target.checked);
    if (e.target.checked) {
      const payload = {
        page: 1,
        limit: numOfItems,
        from,
        to,
        dateType,
        searchText,
        partnerDeliveryId,
        searchShop,
        feeType,
        printType,
        hashtag,
        addedService,
        deliveryArea,
        hasReturned,
        statusId: orderStatusFilter,
        codStatusId: CODStatusFilter,
        deliveryStatus: deliveryStatusFilter,
        paidStatusId: orderPaidStatusFilter,
      };
      setLoading(true);
      const res = await getOrderList(payload);
      if (res) {
        setSelectedAllData(res.data);
        setLoading(false);
      }
    } else {
      setSelectedAllData([]);
      setLoading(false);
    }
  };

  const setColorfilterButtons = (key: string) => {
    if ("All".includes(key)) {
      return {
        active: "border-red-1",
        notActive: "border-red-2",
        norStatus: "!bg-main-2 !text-white",
      };
    }
    if (redStatus.includes(key))
      return {
        active: "border-red-1",
        notActive: "border-red-2",
        norStatus: "!bg-[#dd4b39] !text-white",
      };
    if (greenStatus.includes(key))
      return {
        active: "border-green-2",
        notActive: "border-green-1",
        norStatus: "!bg-green-3 !text-white",
      };
    if (orangeStatus.includes(key))
      return {
        active: "border-orange-1",
        notActive: "border-orange-2",
        norStatus: "!bg-[#f39c12] !text-white",
      };
    return {
      active: "bg-main-3 text-white border-main-3",
      notActive: "bg-gray-1",
      norStatus: "!bg-gray-1 !text-black-2",
    };
  };

  const handleFilterData = () => {
    setPage(1);
    setRefreshData(new Date().getTime());
    setSelectedAllData([]);
    setCheckedAllData(false);
  };

  const getProvinceNameById = (id: number | undefined) => {
    const find = provinces?.find((item) => item?.id === id);
    if (find) {
      return find?.name;
    } else return "";
  };

  const getDistrictNameById = (id: number | undefined) => {
    const find = districts?.find((item) => item?.id === id);
    if (find) {
      return find?.name;
    } else return "";
  };

  const getWardNameById = (id: number | undefined) => {
    const find = wards?.find((item) => item?.id === id);
    if (find) {
      return find?.name;
    } else return "";
  };

  const exportToExcel = () => {
    const data: any[] = [];
    selectedAllData.forEach((d) => {
      const item: any = {};
      const noData = "Không có";
      item["Mã shop"] = d?.shopEntityCode || noData;
      item["Username"] = d?.shopUsername || noData;
      item["Mã đơn shop"] = d?.shopCode || noData;
      item["Mã đơn hàng"] = d?.code || noData;
      item["Mã vận đơn"] = d?.deliveryCode || noData;
      item["Đối tác giao hàng"] = d?.deliveryName || noData;
      const shopLocation = d?.shopLocation || {};
      item["Tên kho lấy hàng"] = shopLocation?.name || noData;
      item["Người liên hệ"] = shopLocation?.contactPerson || noData;
      item["Số điện thoại liên hệ"] = shopLocation?.phoneNumber || noData;
      item["Địa chỉ lấy hàng"] = shopLocation?.address || noData;
      item["Người nhận"] = d?.fullName || noData;
      item["Số điện thoại"] = d?.phoneNumber || noData;
      item["Địa chỉ nhận"] = d?.address || noData;
      item["Tỉnh/Thành Phố"] = getProvinceNameById(d?.provinceId) || noData;
      item["Quyện Huyện"] = getDistrictNameById(d?.districtId) || noData;
      item["Phường Xã"] = getWardNameById(d?.wardId) || noData;
      item["Khối lượng"] = d?.weight || 0;
      item["Khối lượng tính cước"] = d?.partnerWeight
        ? d?.partnerWeight === 0
          ? d?.weight
          : d?.partnerWeight
        : 0;
      item["Dài"] = d?.length || 0;
      item["Rộng"] = d?.width || 0;
      item["Cao"] = d?.height || 0;
      item["Tiền thu hộ ban đầu"] = formatNumber(d?.initialCollectMoney) || 0;
      item["Tiền thu hộ"] = formatNumber(d?.collectMoney) || 0;
      item["Cước phí"] = formatNumber(d?.deliveryAmount) || 0;
      item["Phí vận chuyển gốc"] = formatNumber(d?.costOfGoods) || 0;
      item["Phí chuyển hoàn"] = 0;
      item["Phí bảo hiểm"] = formatNumber(d?.insurranceFee) || 0;
      let extraFee = 0;
      let extraFeeDetails = "";
      if (d.extraFees) {
        d?.extraFees?.forEach((item: any) => {
          extraFee += item.fee;
          extraFeeDetails += item.typeDescription + " - " + item.fee + "; ";
        });
      }
      item["Phí phụ thu"] = extraFee;
      item["Phí GH1P"] = formatNumber(d?.partiallyDeliveredAmount) || 0;
      item["Phí lưu kho"] = 0;
      item["Phí giao lại"] = 0;
      let incurredCost = 0;
      let incurredCostDetails = "";
      if (d.incurredCosts) {
        d?.incurredCosts?.forEach((item: any) => {
          incurredCost += item.cost;
          incurredCostDetails += item.reason + " - " + item.cost + "; ";
        });
      }
      item["Phí phát sinh"] = formatNumber(incurredCost) || 0;
      item["Giảm giá phí vận chuyển"] =
        d?.voucherAmount > formatNumber(d?.deliveryAmount)
          ? formatNumber(d?.deliveryAmount) || 0
          : formatNumber(d?.voucherAmount) || 0;
      item["Mã giảm giá"] = d?.voucherCode || noData;
      item["Giá trị bảo hiểm"] = formatNumber(d?.insurranceAmount) || 0;
      item["Chi tiết phí phụ thu"] = extraFeeDetails || noData;
      item["Chi tiết phí phát sinh"] =
        formatNumber(incurredCostDetails) || noData;
      item["Ứng tiền"] = d?.isMoneyCollection ? "Có" : "Không";
      item["Tổng thu người nhận"] =
        d.statusId === ORDER_STATUS.WaitingReturn ||
        d.statusId === ORDER_STATUS.Returned
          ? 0
          : formatNumber(d?.totalTakenCustomerAmount) || 0;
      item["Tổng phí dịch vụ giao hàng"] = formatNumber(
        (d?.deliveryAmount || 0) +
          (incurredCost || 0) +
          (d?.partiallyDeliveredAmount || 0) +
          (extraFee || 0) +
          (d?.insurranceFee || 0)
      );
      item["Phí ứng tiền"] = !d?.isMoneyCollection ? 0 : d.codFeeAmount || 0;
      item["Tiến ứng cho shop"] = !d?.isMoneyCollection
        ? 0
        : d?.shopAdvancePaymentAmount || 0;
      item["Thanh toán cho shop"] = formatNumber(d?.totalPaidShopAmount) || 0;
      item["Cho xem hàng"] = d?.isShowItem ? "Có" : "Không";
      let orderContent = "";
      if (d?.items) {
        d.items?.forEach((item: any) => {
          orderContent += `${item.Name} - SL: ${item.Quantity}; `;
        });
      }
      item["Nội dung hàng hóa"] = orderContent ? orderContent : noData;
      item["Thời gian lấy hàng"] = d?.pickupDate
        ? moment(d?.pickupDate).format("HH:mm:ss DD/MM/YYYY")
        : noData;
      item["Thời gian giao hàng"] = d?.deliveredDate
        ? moment(d?.deliveredDate).format("HH:mm:ss DD/MM/YYYY")
        : noData;
      item["Thời gian trả hàng"] = d?.returnedDate
        ? moment(d?.returnedDate).format("HH:mm:ss DD/MM/YYYY")
        : noData;
      item["Ngày thanh toán"] = d?.paidDate ? d?.paidDate : noData;
      item["Ngày ứng tiền"] = d?.codDate ? d?.codDate : noData;
      const status =
        Object.values(ORDER_STATUS).find(
          (p) => p.code === d.statusId.toString()
        )?.key || undefined;
      item["Trạng thái đơn hàng"] = status
        ? t(`orderStatus.${status}`)
        : noData;
      const payStatus =
        Object.values(ORDER_PAID_STATUS).find(
          (p) => p.code === d.paidStatusId.toString()
        )?.key || undefined;
      item["Trạng thái thanh toán"] = payStatus
        ? t(`orderPaidStatus.${payStatus}`)
        : noData;
      const codStatus =
        Object.values(COD_STATUS).find(
          (p) => p.code === d.codStatusId.toString()
        )?.key || undefined;
      item["Trạng thái ứng tiền"] = codStatus
        ? t(`CODStatus.${codStatus}`)
        : noData;
      item["Link tracking"] = deliveries.find(
        (item) => item.id === d.partnerDeliveryId
      )
        ? `${deliveries.find((item) => item.id === d.partnerDeliveryId)?.url}${
            d.deliveryCode
          }`
        : noData;
      data.push(item);
    });
    exportDataToExcel(data, "Orders");
  };

  useEffect(() => {
    (async () => {
      const res = await getProvince();
      if (res) setProvinces(res);
      else {
        message.error("Không thể kết nối đến data tỉnh thành");
      }
      const resDistricts = await getDistricts();
      if (resDistricts) {
        setDistricts(resDistricts);
      }
      const resWards = await getWards();
      if (resWards) {
        setWards(resWards);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const res = await getTagList({
          page: 1,
          limit: 50,
          type: ORDER,
        });
        if (res) {
          const activeTagList = res.data?.filter((item: any) => item?.isActive);
          setHashtagList(activeTagList);
        }
      } catch (e) {}
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const res = await getDeliveryList();
      setDeliveries(res);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const payload = {
        page,
        limit: 4,
        from,
        to,
        dateType,
        searchText,
        partnerDeliveryId,
        searchShop,
        feeType,
        printType,
        hashtag,
        addedService,
        deliveryArea,
        hasReturned,
        statusId: orderStatusFilter,
        codStatusId: CODStatusFilter,
        deliveryStatus: deliveryStatusFilter,
        paidStatusId: orderPaidStatusFilter,
      };
      setDataLoading(true);
      const res = await getOrderList(payload);
      if (res) {
        setOrderList(res.data);
        setTotalPage(res.meta?.pageCount || 1);
        setNumOfItems(res.meta?.total || 0);
      }
      setDataLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    orderStatusFilter,
    CODStatusFilter,
    deliveryStatusFilter,
    orderPaidStatusFilter,
    refreshData,
    page,
  ]);

  useEffect(() => {
    (async () => {
      const payload = {
        page,
        limit: 4,
        from,
        to,
        dateType,
        searchText,
        partnerDeliveryId,
        searchShop,
        feeType,
        printType,
        hashtag,
        addedService,
        deliveryArea,
        hasReturned,
      };
      setStatusLoading(true);
      const codStatus = await getOrderFromCODStatus(payload);
      if (codStatus) {
        const formatList = [];
        for (const item of Object.values(COD_STATUS)) {
          let check = false;
          for (const order of codStatus) {
            if (item.code === order.type) {
              check = true;
              formatList.push({ ...item, typeCount: order.typeCount });
            }
          }
          if (!check) {
            formatList.push({ ...item, typeCount: 0 });
          }
        }
        setCODStatuses(formatList);
      }
      const deliveryStatus = await getOrderFromDeliveryStatus(payload);
      if (deliveryStatus) {
        const formatList = [];
        for (const item of Object.values(FILTER_DELIVERY_STATUS)) {
          let check = false;
          for (const order of deliveryStatus) {
            if (item.code === order.type) {
              check = true;
              formatList.push({ ...item, typeCount: order.typeCount });
            }
          }
          if (!check) {
            formatList.push({ ...item, typeCount: 0 });
          }
        }
        setDeliveryStatuses(formatList);
      }
      const orderStatus = await getOrderFromOrderStatus(payload);
      if (orderStatus) {
        const formatList = [];
        for (const item of Object.values(ORDER_STATUS)) {
          let check = false;
          for (const order of orderStatus) {
            if (item.code === order.type) {
              check = true;
              formatList.push({ ...item, typeCount: order.typeCount });
            }
          }
          if (!check) {
            formatList.push({ ...item, typeCount: 0 });
          }
        }
        setOrderStatuses(formatList);
      }
      const paidStatus = await getOrderFromPaidStatus(payload);
      if (paidStatus) {
        const formatList = [];
        for (const item of Object.values(ORDER_PAID_STATUS)) {
          let check = false;
          for (const order of paidStatus) {
            if (item.code === order.type) {
              check = true;
              formatList.push({ ...item, typeCount: order.typeCount });
            }
          }
          if (!check) {
            formatList.push({ ...item, typeCount: 0 });
          }
        }
        setPaidStatus(formatList);
      }
      setStatusLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData]);

  useEffect(() => {
    if (!dataLoading && !statusLoading) setLoading(false);
    else setLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLoading, statusLoading]);

  useEffect(() => {
    document.title = "Quản lý đơn hàng - Go24";
  }, []);

  return (
    <div className="flex flex-col lg:gap-y-8">
      <Section title={t("contentTitle.ordersManagement.OrdersManagement")}>
        <div className="flex flex-col lg:flex-row rounded justify-between bg-white py-2 px-3 lg:px-4 w-full shadow-md shadow-[#d1d1d126] mb-4">
          <div className="flex flex-col gap-y-3 lg:gap-y-4 w-full mr-6">
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-x-2 gap-y-3 lg:gap-x-6">
              <div>
                <p className="font-semibold text-sm leading-none mb-2 text-start">
                  {t("filter.fieldTitle.From")}
                </p>
                <DatePicker
                  defaultValue={dayjs(from, dateFormat)}
                  format={dateFormat}
                  className="w-full h-10 px-4"
                  suffixIcon={
                    <IoCalendarNumberOutline className="text-2xl text-black" />
                  }
                  onChange={(date, dateString) => {
                    setFrom(dateString);
                  }}
                  disabledDate={(current) => {
                    let customDate = dayjs(to).format("YYYY-MM-DD");
                    return current && current > dayjs(customDate, "YYYY-MM-DD");
                  }}
                  allowClear={false}
                />
              </div>
              <div>
                <p className="font-semibold text-sm leading-none mb-2 text-start">
                  {t("filter.fieldTitle.To")}
                </p>
                <DatePicker
                  defaultValue={dayjs(to, dateFormat)}
                  format={dateFormat}
                  className="w-full h-10 px-4"
                  suffixIcon={
                    <IoCalendarNumberOutline className="text-2xl text-black" />
                  }
                  onChange={(date, dateString) => {
                    setTo(dateString);
                  }}
                  disabledDate={(current) => {
                    let customDate = dayjs(from).format("YYYY-MM-DD");
                    return current && current < dayjs(customDate, "YYYY-MM-DD");
                  }}
                  allowClear={false}
                />
              </div>
              <div>
                <p className="font-semibold text-sm leading-none mb-2 text-start">
                  {t("filter.fieldTitle.DateType")}
                </p>
                <Select
                  defaultValue={t("filter.fieldContent.createdDate")}
                  className="w-full h-10 text-start first:pr-4"
                  suffixIcon={<IoCaretDown className="text-lg" />}
                  onChange={(e) => setDateType(e)}
                >
                  {DATE_TYPE.map((type, i) => (
                    <Select.Option key={i} value={type}>
                      {t(`filter.fieldContent.${type}`)}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div>
                <p className="font-semibold text-sm leading-none mb-2 text-start">
                  {t("filter.fieldTitle.Keyword")}
                </p>
                <Input
                  className="w-full h-10 px-4"
                  placeholder={t(
                    "filter.fieldContent.PhoneNumber-ShopOrderCode-ShippingCode"
                  )}
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-x-2 gap-y-3 lg:gap-x-6">
              <div>
                <p className="font-semibold text-sm leading-none mb-2 text-start">
                  {t("filter.fieldTitle.TransportationCompany")}
                </p>
                <Select
                  defaultValue={t("filter.fieldContent.All")}
                  className="w-full h-10 text-start first:pr-4"
                  suffixIcon={<IoCaretDown className="text-lg" />}
                  onChange={(e: any) => setPartnerDeliveryId(e)}
                >
                  <Select.Option value={undefined}>
                    {t("filter.fieldContent.All")}
                  </Select.Option>
                  {deliveries.map((option: any) => (
                    <Select.Option key={option?.id} value={option.id}>
                      {option?.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div>
                <p className="font-semibold text-sm leading-none mb-2 text-start">
                  {t("filter.fieldTitle.Shop")}
                </p>
                <Input
                  className="w-full h-10 px-4"
                  placeholder={t("filter.fieldContent.UsernameOrPhoneNumber")}
                  value={searchShop}
                  onChange={(e) => setSearchShop(e.target.value)}
                />
              </div>
              <div>
                <p className="font-semibold text-sm leading-none mb-2 text-start">
                  {t("filter.fieldTitle.Payer")}
                </p>
                <Select
                  defaultValue={t("filter.fieldContent.All")}
                  className="w-full h-10 text-start first:pr-4"
                  suffixIcon={<IoCaretDown className="text-lg" />}
                  onChange={(e: any) => setFeeType(e)}
                >
                  <Select.Option value={undefined}>
                    {t("filter.fieldContent.All")}
                  </Select.Option>
                  {Object.values(PAYER).map((option, i) => (
                    <Select.Option key={i} value={option.code}>
                      {t(`filter.fieldContent.${option.key}`)}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div>
                <p className="font-semibold text-sm leading-none mb-2 text-start">
                  {t("filter.fieldTitle.PrintOrder")}
                </p>
                <Select
                  defaultValue={t("filter.fieldContent.All")}
                  className="w-full h-10 text-start first:pr-4"
                  suffixIcon={<IoCaretDown className="text-lg" />}
                  onChange={(e: any) => setPrintType(e)}
                >
                  <Select.Option value={undefined}>
                    {t("filter.fieldContent.All")}
                  </Select.Option>
                  {Object.values(PRINT_TYPE).map((option, i) => (
                    <Select.Option key={i} value={option.code}>
                      {t(`filter.fieldContent.${option.key}`)}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-x-2 gap-y-3 lg:gap-x-6">
              <div>
                <p className="font-semibold text-sm leading-none mb-2 text-start">
                  Hashtag
                </p>
                <Select
                  defaultValue={t("filter.fieldContent.All")}
                  className="w-full h-10 text-start first:pr-4"
                  suffixIcon={<IoCaretDown className="text-lg" />}
                  onChange={(e: any) => setHashtag(e)}
                >
                  <Select.Option value={undefined}>
                    {t("filter.fieldContent.All")}
                  </Select.Option>
                  {hashtagList.map((hashtag) => (
                    <Select.Option key={hashtag.id} value={hashtag.id}>
                      <div className="flex gap-2 items-center py-1">
                        <div>
                          <TagIcon
                            className="w-4 h-4"
                            style={{ color: hashtag.colorCode }}
                          />
                        </div>
                        <p className="truncate">{hashtag.hashtag}</p>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div>
                <p className="font-semibold text-sm leading-none mb-2 text-start">
                  {t("filter.fieldTitle.TypeOfOrder")}
                </p>
                <Select
                  defaultValue={t("filter.fieldContent.All")}
                  className="w-full h-10 text-start first:pr-4"
                  suffixIcon={<IoCaretDown className="text-lg" />}
                  onChange={(e: any) => setAddedService(e)}
                >
                  <Select.Option value={undefined}>
                    {t("filter.fieldContent.All")}
                  </Select.Option>
                  {Object.values(ADDED_SERVICE).map((option, i) => (
                    <Select.Option key={i} value={option.code}>
                      {t(`filter.fieldContent.${option.key}`)}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div>
                <p className="font-semibold text-sm leading-none mb-2 text-start">
                  {t("filter.fieldTitle.DeliveryArea")}
                </p>
                <Select
                  defaultValue={t("filter.fieldContent.All")}
                  className="w-full h-10 text-start first:pr-4"
                  suffixIcon={<IoCaretDown className="text-lg" />}
                  onChange={(e: any) => setDeliveryArea(e)}
                >
                  <Select.Option value={undefined}>
                    {t("filter.fieldContent.All")}
                  </Select.Option>
                  {Object.values(DELIVERY_AREA).map((option, i) => (
                    <Select.Option key={i} value={option.code}>
                      {t(`filter.fieldContent.${option.key}`)}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div>
                <p className="font-semibold text-sm leading-none mb-2 text-start">
                  {t("filter.fieldTitle.ConfirmationOfPayment")}
                </p>
                <Select
                  defaultValue={t("filter.fieldContent.All")}
                  className="w-full h-10 text-start first:pr-4"
                  suffixIcon={<IoCaretDown className="text-lg" />}
                  onChange={(e: any) => setHasReturned(e)}
                >
                  <Select.Option value={undefined}>
                    {t("filter.fieldContent.All")}
                  </Select.Option>
                  {Object.values(RETURN_TYPE).map((option, i) => (
                    <Select.Option key={i} value={option.code}>
                      {t(`filter.fieldContent.${option.key}`)}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <div>
            <Button
              className="!border-none rounded mt-[22px] px-6 h-10 bg-main-2 text-sm leading-none !text-white hover:!bg-main-4"
              onClick={handleFilterData}
            >
              {t("filter.buttonTitle.View")}
            </Button>
          </div>
        </div>
      </Section>
      <Section>
        <div className="flex flex-row rounded lg:bg-white p-0 lg:p-4 w-full lg:shadow-md lg:shadow-[#d1d1d126] mb-4">
          <div className="flex flex-row">
            <div
              className={`flex flex-col transition-all ease-linear duration-300 ${
                showMoreFilter ? "gap-y-3" : ""
              }`}
            >
              <div className="flex flex-row flex-wrap gap-x-2 gap-y-3 w-full">
                {orderStatuses.map((status: any) => (
                  <Button
                    key={status.key}
                    className={`rounded text-sm p-2 height-[auto] leading-none border-none ${
                      setColorfilterButtons(status.key).norStatus
                    } ${
                      orderStatusFilter === status.code ||
                      (orderStatusFilter === undefined && status.key === "All")
                        ? "opacity-50"
                        : "hover:opacity-85"
                    }`}
                    onClick={() => handleChangeOrderStatusFilter(status.code)}
                    disabled={
                      orderStatusFilter === status.code ||
                      (orderStatusFilter === undefined && status.key === "All")
                    }
                  >
                    {t(`filter.filterButtons.${status.key}`)}{" "}
                    <span className="ml-1">{`(${status?.typeCount})`}</span>
                  </Button>
                ))}
              </div>
              <div
                className={`w-full overflow-hidden transition-all ease-in-out duration-200 ${
                  showMoreFilter ? "h-fit opacity-100" : "h-[0px] opacity-0"
                }`}
              >
                <div className="flex flex-row flex-wrap gap-x-2 gap-y-3 w-full mb-3">
                  {paidStatus.map((status: any) => (
                    <Button
                      key={status.key}
                      className={`text-sm p-2 height-[auto] leading-none rounded border-none ${
                        status.key === "All"
                          ? "!bg-main-2 !text-white"
                          : status.key === "Done"
                          ? "!bg-green-3 !text-white"
                          : "!bg-gray-1 !text-black-2"
                      } ${
                        orderPaidStatusFilter === status.code ||
                        (orderPaidStatusFilter === undefined &&
                        status.key === "All"
                          ? "opacity-50"
                          : "hover:opacity-85")
                      }`}
                      onClick={() =>
                        handleChangeOrderPaidStatusFilter(status.code)
                      }
                      disabled={
                        orderPaidStatusFilter === status.code ||
                        (orderPaidStatusFilter === undefined &&
                          status.key === "All")
                      }
                    >
                      {t(`filter.filterButtons.${status.key}`)}
                      <span className="ml-1">{`(${status?.typeCount})`}</span>
                    </Button>
                  ))}
                </div>
                <div className="flex flex-row flex-wrap gap-x-2 gap-y-3 w-full">
                  {codStatuses.map((status: any) => (
                    <Button
                      key={status.key}
                      className={`border-none ${
                        status.key === "All"
                          ? "!bg-main-2 !text-white"
                          : status.key === "Done"
                          ? "!bg-green-3 !text-white"
                          : "!bg-gray-1 !text-black-2"
                      } ${
                        CODStatusFilter === status.code ||
                        (CODStatusFilter === undefined && status.key === "All"
                          ? "opacity-50"
                          : "hover:opacity-85")
                      } text-sm p-2 height-[auto] leading-none rounded`}
                      onClick={() => handleChangeCODStatusFilter(status.code)}
                      disabled={
                        CODStatusFilter === status.code ||
                        (CODStatusFilter === undefined && status.key === "All")
                      }
                    >
                      {t(`filter.filterButtons.${status.key}`)}
                      <span className="ml-1">{`(${status?.typeCount})`}</span>
                    </Button>
                  ))}
                </div>
              </div>
            </div>
            <div>
              <Button
                className="ml-2 p-1 border-2 border-yellow-400 text-yellow-400 rounded-lg"
                onClick={() => setShowMoreFilter(!showMoreFilter)}
              >
                {showMoreFilter ? <FaMinus /> : <FaPlus />}
              </Button>
            </div>
          </div>
        </div>
        <div className="flex flex-row flex-wrap gap-x-2 gap-y-3 rounded lg:bg-white p-0 lg:p-4 w-full lg:shadow-md lg:shadow-[#d1d1d126] mb-4">
          {deliveryStatuses.map((status: any) => (
            <Button
              key={status.key}
              className={`${setColorfilterButtons(status.key).norStatus} ${
                deliveryStatusFilter === status.code ||
                (deliveryStatusFilter === undefined && status.key === "All")
                  ? "opacity-50"
                  : "hover:opacity-85"
              } text-sm p-2 height-[auto] leading-none rounded border-none`}
              onClick={() => handleChangeDeliveryStatusFilter(status.code)}
              disabled={
                deliveryStatusFilter === status.code ||
                (deliveryStatusFilter === undefined && status.key === "All")
              }
            >
              {t(`filter.filterButtons.${status.key}`)}{" "}
              <span className="ml-1">{`(${status?.typeCount})`}</span>
            </Button>
          ))}
        </div>
        <div className="flex flex-row flex-wrap lg:justify-between lg:flex-nowrap rounded bg-white p-2 lg:p-4 w-full shadow-md shadow-[#d1d1d126] mb-4">
          <div className="w-full lg:w-fit flex items-center justify-between">
            <div className="flex items-center gap-1">
              <Checkbox
                checked={checkedAllData && numOfItems > 0}
                onClick={(e) => handleCheckAll(e)}
                disabled={numOfItems === 0}
                className="scale-[1.2] lg:scale-150 lg:ml-1"
              />
              <p className="ml-1 lg:ml-4 text-sm">Chọn tất cả</p>
            </div>
            <div className="md:hidden text-left text-sm leading-none">
              {t("filter.fieldTitle.SelectedOrders")}:{" "}
              <span className="text-red-1">{selectedAllData.length}</span>
            </div>
          </div>
          <div className="mt-2 lg:mt-0 flex flex-col lg:flex-row gap-y-2 gap-x-6 lg:items-center">
            <div className="hidden md:block text-left text-sm leading-none">
              {t("filter.fieldTitle.SelectedOrders")}:{" "}
              <span className="text-red-1">{selectedAllData.length}</span>
            </div>
            <Button
              className="flex items-center gap-2 bg-gray-2 px-3 py-1 rounded"
              onClick={exportToExcel}
              disabled={selectedAllData.length === 0}
            >
              <GoFile className="mb-[-2px]" />
              <span>{t("filter.buttonTitle.ExportToExcel")}</span>
            </Button>
          </div>
        </div>
      </Section>
      <Section>
        <div className="flex flex-col gap-y-4">
          {orderList.length === 0 ? (
            <div className="w-full py-6 flex items-center justify-center">
              <img
                src={NoDataIcon}
                alt="no-data"
                className="w-[180px] h-[162px] md:w-[250px] md:h-[225px]"
              />
            </div>
          ) : (
            orderList.map((data: any) => (
              <OrderBlock
                key={data?.id}
                isChecked={selectedAllData.some(
                  (item) => item?.id === data?.id
                )}
                onCheckFunc={() => handleCheck(data)}
                showDetailsFunc={() => {
                  setSelectedOrder(data);
                  setOpenOrderDetais(true);
                }}
                editOrderFunc={(isRenew = false) => {
                  setSelectedOrder({ ...data, isRenew });
                  setOpenEditOrder(true);
                }}
                trackingOrderFunc={() => {
                  setSelectedOrder(data);
                  setOpenOrderTracking(true);
                }}
                sendingRequest={() => {
                  setSelectedOrder(data);
                }}
                {...{
                  data,
                  hashtagList,
                  openRequest,
                  setOpenRequest,
                  deliveries,
                }}
              />
            ))
          )}
        </div>
        <Pagination {...{ page, setPage, totalPage }} />
        {openOrderDetails && (
          <OrderDetails
            open={openOrderDetails}
            onCancel={() => setOpenOrderDetais(false)}
            id={selectedOrder?.id}
          />
        )}
        {openOrderTracking && (
          <OrderTracking
            open={openOrderTracking}
            onCancel={() => setOpenOrderTracking(false)}
            order={selectedOrder}
          />
        )}
        {openRequest?.value && (
          <RequestModal
            type={openRequest}
            onCancel={() => setOpenRequest("")}
            order={selectedOrder}
          />
        )}
        {openEditOrder && (
          <EditOrderModal
            open={openEditOrder}
            onCancel={() => setOpenEditOrder(false)}
            order={selectedOrder}
            onRefreshData={() => setRefreshData(new Date().getTime())}
          />
        )}
      </Section>
    </div>
  );
};

export default OrdersManagement;
