import { getOrderDetails } from "@services/order-service";
import { getDeliveryList } from "@services/shop-services";
import { Modal, message } from "antd";
import { DATE_FORMAT, ORDER_TYPE } from "constants/constants";
import { useEffect, useState } from "react";
import { formatDate, formatNumber } from "@helper/helper";
import { Link } from "react-router-dom";
import { Geo } from "@models/Geo";
import NoDataIcon from "@assets/image/no-data.png";
import {
  getAddressLevelFourById,
  getDistrictById,
  getProvince,
  getWardById,
} from "@services/geo-services";
import { checkingGoodsDefault } from "./EditOrderModal";
import { RequestBlock } from "@pages/requests-management/RequestBlock";
import { getRequest } from "@services/request-services";
import dayjs from "dayjs";

const OrderDetails = ({ open, onCancel, id }: any) => {
  const [orderDetails, setOrderDetails] = useState<any>();
  const [deliveries, setDeliveries] = useState<any[]>([]);
  const [provinces, setProvinces] = useState<Geo[]>([]);
  const [shopDistrict, setShopDistrict] = useState<Geo>();
  const [shopWard, setShopWard] = useState<Geo>();
  const [shopLevelFour, setShopLevelFour] = useState<Geo>();
  const [district, setDistrict] = useState();
  const [ward, setWard] = useState();
  const [requestList, setRequestList] = useState<any[]>([]);
  const [levelFour, setLevelFour] = useState<Geo>();

  const getIncurredCost = (incurredCosts: any[]) => {
    const result = incurredCosts
      ?.map((c) => {
        return c.cost;
      })
      .reduce((a, b) => a + b, 0);
    return result ? result : 0;
  };

  const getDeliveryInstruction = (typeShowItem: number) => {
    const deliveryInstruction = checkingGoodsDefault.filter(
      (e) => e.id === typeShowItem
    )[0];
    if (deliveryInstruction === undefined) {
      return "";
    }
    return deliveryInstruction.title;
  };

  const getExtraFee = (extraFees: any[]) => {
    const result = extraFees
      .map((c) => {
        return c.fee;
      })
      .reduce((a, b) => a + b, 0);
    return result ? result : 0;
  };

  const getProvinceById = (id: number) => {
    const province = provinces.find((item) => item.id === id);
    if (province) {
      return province?.name;
    }
    return "";
  };

  useEffect(() => {
    (async () => {
      if (id && open) {
        const res = await getOrderDetails(id);
        if (res) {
          setOrderDetails(res);
        }
      }
    })();
  }, [id, open]);

  useEffect(() => {
    (async () => {
      const res = await getDeliveryList();
      setDeliveries(res);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const res = await getProvince();
      if (res) setProvinces(res);
      else {
        message.error("Không thể kết nối đến data tỉnh thành");
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (orderDetails?.code) {
        try {
          const from = dayjs(new Date(orderDetails?.createdDate)).format(
            "YYYY-MM-DD"
          );
          const to = dayjs(new Date()).format("YYYY-MM-DD");
          const payload = {
            page: 1,
            limit: 100,
            from,
            to,
            orderText: orderDetails?.code,
          };
          const res = await getRequest(payload);
          if (res) {
            setRequestList(res.data);
          }
        } catch (error) { }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetails?.code]);

  useEffect(() => {
    (async () => {
      if (orderDetails?.shopLocationDistrictId) {
        const res = await getDistrictById(orderDetails?.shopLocationDistrictId);
        if (res) setShopDistrict(res);
      }
    })();
  }, [orderDetails?.shopLocationDistrictId]);

  useEffect(() => {
    (async () => {
      if (orderDetails?.shopLocationWardId) {
        const res = await getWardById(orderDetails?.shopLocationWardId);
        if (res) setShopWard(res);
      }
    })();
  }, [orderDetails?.shopLocationWardId]);

  useEffect(() => {
    (async () => {
      if (orderDetails?.shopLocationLevelFourId) {
        const res = await getAddressLevelFourById(
          orderDetails?.shopLocationLevelFourId
        );
        if (res) setShopLevelFour(res);
      }
    })();
  }, [orderDetails?.shopLocationLevelFourId]);

  useEffect(() => {
    (async () => {
      if (orderDetails?.addressLevel4Id) {
        const res = await getAddressLevelFourById(
          orderDetails?.addressLevel4Id
        );
        if (res) setLevelFour(res);
      }
    })();
  }, [orderDetails?.addressLevel4Id]);

  useEffect(() => {
    (async () => {
      if (orderDetails?.districtId) {
        const res = await getDistrictById(orderDetails?.districtId);
        if (res) setDistrict(res);
      }
    })();
  }, [orderDetails?.districtId]);

  useEffect(() => {
    (async () => {
      if (orderDetails?.wardId) {
        const res = await getWardById(orderDetails?.wardId);
        if (res) setWard(res);
      }
    })();
  }, [orderDetails?.wardId]);

  return (
    <Modal
      title=""
      open={open}
      onCancel={onCancel}
      width={1200}
      footer={null}
      centered
    >
      <div className="text-base font-semibold border-b pb-3">
        Chi tiết đơn hàng
      </div>
      <div className="text-sm max-h-[500px] md:max-h-[700px] overflow-auto mb-4">
        <div className="flex flex-col lg:flex-row gap-4 mt-4">
          <div className="flex flex-col gap-1 w-full lg:w-1/2 lg:border-r border-gray-1">
            <p className="font-semibold text-black">
              Tên kho lấy hàng:{" "}
              <span className="font-normal">
                {orderDetails?.shopLocationName}
              </span>
            </p>
            <p className="font-semibold text-black">
              Số điện thoại kho lấy hàng:{" "}
              <span className="font-normal">
                {orderDetails?.shopLocationPhoneNumber}
              </span>
            </p>
            <p className="font-semibold text-black">
              Địa chỉ nhận hàng:{" "}
              <span className="font-normal">
                {`${orderDetails?.shopLocationAddress
                  }, ${shopLevelFour}, ${shopWard}, ${shopDistrict}, ${getProvinceById(
                    orderDetails?.shopLocationProvinceId
                  )}`}
              </span>
            </p>
          </div>
          <div className="flex flex-col gap-1 w-full lg:w-1/2">
            <p className="font-semibold text-black">
              Người nhận:{" "}
              <span className="font-normal">{orderDetails?.fullName}</span>
            </p>
            <p className="font-semibold text-black">
              Số điện thoại:{" "}
              <span className="font-normal">{orderDetails?.phoneNumber}</span>
            </p>
            <p className="font-semibold text-black">
              Địa chỉ giao hàng:{" "}
              <span className="font-normal">{`${orderDetails?.address
                }, ${levelFour}, ${ward}, ${district}, ${getProvinceById(
                  orderDetails?.provinceId
                )}`}</span>
            </p>
          </div>
        </div>
        <div className="mt-4 lg:mx-4 lg:border lg:border-gray-1 rounded-md">
          <div className="flex gap-1 items-center py-2 lg:py-0 lg:p-4 border-t">
            <p className="text-black font-semibold">Đơn vị vận chuyển:</p>
            <p>
              {deliveries?.find(
                (item: any) => item.id === orderDetails?.partnerDeliveryId
              )?.name || ""}
            </p>
            {orderDetails?.deliveryCode && (
              <p>
                -
                <span className="text-[#3c8dbc]">
                  {" "}
                  <Link
                    to={`${deliveries.find(
                      (item) => item.id === orderDetails.partnerDeliveryId
                    )?.url
                      }${orderDetails.deliveryCode}`}
                    target="_blank"
                  >
                    {orderDetails?.deliveryCode}
                  </Link>
                </span>
              </p>
            )}
          </div>
          <div className="flex flex-col lg:flex-row gap-2 lg:py-0 lg:p-4 lg:border-t border-gray-1">
            <div className="flex flex-col lg:flex-row gap-2 w-full lg:w-1/2 border-b lg:border-r border-gray-1">
              <div className="flex flex-col gap-1 w-full lg:w-1/2">
                <p className="font-semibold">
                  Trọng lượng:{" "}
                  <span className="font-normal">{`${orderDetails?.weight} kg`}</span>
                </p>
                <p className="font-semibold">
                  Ứng tiền thu hộ:{" "}
                  <span className="font-normal">
                    {orderDetails?.isMoneyCollection ? "Có" : "Không"}
                  </span>
                </p>
                <p className="font-semibold">
                  Khách hàng trả tiền phí:{" "}
                  <span className="font-normal">
                    {orderDetails?.isCustomerPaid ? "Có" : "Không"}
                  </span>
                </p>
                {orderDetails?.note && (
                  <p className="font-semibold mt-3">
                    Ghi chú:{" "}
                    <span className="font-normal">{orderDetails?.note}</span>
                  </p>
                )}
              </div>
              <div className="flex flex-col gap-1 w-full lg:w-1/2">
                <p className="font-semibold">
                  Kích thước:{" "}
                  <span className="font-normal">{`${orderDetails?.length},${orderDetails?.width},${orderDetails?.height} [KL quy đổi ${orderDetails?.weightConverted} kg]`}</span>
                </p>
                <p className="font-semibold">
                  Loại xem hàng:{" "}
                  <span className="font-normal">
                    {getDeliveryInstruction(orderDetails?.typeShowItem)}
                  </span>
                </p>
                <p className="font-semibold">
                  Loại đơn hàng:{" "}
                  <span className="font-normal">
                    {
                      ORDER_TYPE.find((item) => item.id === orderDetails?.type)
                        ?.name
                    }
                  </span>
                </p>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row gap-2 w-full lg:w-1/2">
              <div className="flex flex-col gap-1 w-full lg:w-1/2">
                <p className="font-semibold">
                  Tiền thu hộ:{" "}
                  <span className="font-normal">
                    {formatNumber(orderDetails?.collectMoney)} vnđ
                  </span>
                </p>
                <p className="font-semibold">
                  Mã voucher:{" "}
                  <span className="font-normal">
                    {orderDetails?.voucherCode
                      ? orderDetails?.voucherCode
                      : "(Không có)"}
                  </span>
                </p>
                <p className="font-semibold">
                  Giá trị bảo hiểm:{" "}
                  <span className="font-normal">
                    {formatNumber(orderDetails?.insurranceAmount)} vnđ
                  </span>
                </p>
                {orderDetails?.partiallyDeliveredAmount > 0 && (
                  <p className="font-semibold">
                    Phí giao hàng 1 phần:{" "}
                    <span className="font-normal">
                      {formatNumber(orderDetails?.partiallyDeliveredAmount)} vnđ
                    </span>
                  </p>
                )}
              </div>
              <div className="flex flex-col gap-1 w-full lg:w-1/2">
                <p className="font-semibold">
                  Phí vận chuyển:{" "}
                  <span className="font-normal">
                    {formatNumber(orderDetails?.deliveryAmount)} vnđ
                  </span>
                </p>
                <p className="font-semibold">
                  Phí bảo hiểm:{" "}
                  <span className="font-normal">
                    {formatNumber(orderDetails?.insurranceFee)} vnđ
                  </span>
                </p>
                <p className="font-semibold">
                  Giảm giá phí vận chuyển:{" "}
                  <span className="font-normal">
                    {formatNumber(orderDetails?.voucherAmount)} vnđ
                  </span>
                </p>
                {orderDetails?.partiallyDeliveredAmount > 0 && (
                  <p className="font-semibold">
                    Phí giao hàng 1 phần:{" "}
                    <span className="font-normal">
                      {formatNumber(orderDetails?.partiallyDeliveredAmount)} vnđ
                    </span>
                  </p>
                )}
                {orderDetails?.incurredCosts?.length > 0 && (
                  <p className="font-semibold">
                    Phí phát sinh:{" "}
                    <span className="font-normal">
                      {getIncurredCost(orderDetails?.incurredCosts)} vnđ
                    </span>
                  </p>
                )}
                {orderDetails?.extraFees?.length > 0 && (
                  <p className="font-semibold">
                    Phí phụ thu:{" "}
                    <span className="font-normal">
                      {getExtraFee(orderDetails?.extraFees)} vnđ
                    </span>
                  </p>
                )}
                <p className="font-semibold">
                  PVC gốc:{" "}
                  <span className="font-normal">
                    {formatNumber(orderDetails?.costOfGoods)} vnđ
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row gap-2 lg:gap-10  lg:items-center py-2 lg:py-0 lg:p-4 border-t border-gray-1">
            <div className="flex gap-1">
              <p className="text-black font-semibold">
                Tổng thu người nhận:{" "}
                <span className="font-normal">
                  {formatNumber(orderDetails?.totalTakenCustomerAmount)} vnđ
                </span>
              </p>
              <p></p>
            </div>
            <div className="flex gap-1">
              <p className="text-black font-semibold">
                Tiền thanh toán cho shop:{" "}
                <span className="font-normal">
                  {formatNumber(orderDetails?.totalPaidShopAmount)} vnđ
                </span>
              </p>
              <p></p>
            </div>
          </div>
          <div className="py-2 lg:py-0 lg:p-4 border-t border-gray-1 overflow-x-auto">
            <p className="font-semibold">Nội dung hàng hoá:</p>
            <div className="flex justify-between p-2  border-b border-gray-1 min-w-[700px]">
              <p className="font-semibold w-48">Mã sản phẩm</p>
              <p className="font-semibold w-48">Tên sản phẩm</p>
              <p className="font-semibold w-48">Số lượng</p>
              <p className="font-semibold w-48">Đơn giá</p>
              <p className="font-semibold w-48">Thành tiền</p>
            </div>
            {orderDetails?.item?.map((item: any) => (
              <div className="flex justify-between p-2 min-w-[700px]  border-b border-gray-1" key={item?.Code}>
                <p className="w-48">{item?.Code}</p>
                <p className="w-48">{item?.Name}</p>
                <p className="w-48">{item?.Quantity}</p>
                <p className="w-48">{formatNumber(item?.RetailPrice)} vnđ</p>
                <p className="w-48">
                  {formatNumber(item?.Quantity * item?.RetailPrice)} vnđ
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-4 pb-4">
          <p className="font-semibold">Lý do giao hàng thất bại</p>
          <div className="mt-4 mx-4 shadow rounded-t-lg overflow-x-auto lg:overflow-hidden">
            <div className="bg-gray-1 px-4 py-[10px] flex flex-row justify-between w-fit md:w-full items-center text-sm font-semibold" >
              <div>
                <div className="w-[50px] text-center">STT</div>
              </div>
              <div>
                <div className="min-w-[180px] w-auto text-center">Ngày giao hàng</div>
              </div>
              <div className="min-w-[300px] w-auto text-center">Lý do không giao được hàng</div>
            </div>
            {orderDetails?.failedReason?.length > 0 ? (
              orderDetails?.failedReason?.map((item: any, index: number) => (
                <div
                  key={item?.Id}
                  className={`px-4 py-[10px] flex flex-row justify-between items-center text-sm
              border-b ${orderDetails?.failedReason?.length - 1 === index
                      ? "border-transparent"
                      : "border-gray-1"
                    }`}
                >
                  <div>
                    <div className="w-[50px] text-center">{item?.Id}</div>
                  </div>
                  <div>
                    <div className="w-[180px] text-center">
                      {formatDate(item?.createdDate, DATE_FORMAT)}
                    </div>
                  </div>
                  <div className="w-full">{item?.Reason}</div>
                </div>
              ))
            ) : (
              <div className="w-full py-6 flex items-center justify-center">
                <img
                  src={NoDataIcon}
                  alt="no-data"
                  className="w-[180px] h-[162px] md:w-[250px] md:h-[225px]"
                />
              </div>
            )}
          </div>
        </div>
        {requestList.length > 0 && (
          <div className="">
            <p className="font-semibold">Lịch sử gửi yêu cầu</p>
            <div className="flex flex-col gap-y-4 p-4">
              {requestList.map((item) => (
                <RequestBlock key={item?.id} data={item} isRequest={false} />
              ))}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default OrderDetails;
