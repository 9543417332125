import { Header } from "@components/commons/Header";
import { Sidebar } from "@components/commons/Sidebar";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Outlet } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
type Props = {
  children?: React.ReactNode;
};

export const Layout: React.FC<Props> = ({ ...props }) => {
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' });
  const [isOpen, setIsOpen] = useState<boolean>(isDesktopOrLaptop ? true : false);
  const sidebarRef = useRef<HTMLDivElement | null>(null);
  const handleToogleOpenSideBar = () => {
    setIsOpen(!isOpen)
  };
  const handleCloseSidebar = useCallback(() => {
    setIsOpen(false);
  }, []);
  const handleClickOutside = useCallback((event: any) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      handleCloseSidebar();
    }
  }, [handleCloseSidebar]);
  useEffect(() => {
    if (!isDesktopOrLaptop && isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [isOpen, isDesktopOrLaptop, handleClickOutside])

  return (
    <div className="flex flex-row h-[100vh] w-full bg-bg-color relative lg:block">
      <div ref={sidebarRef}>
        <Sidebar
          isOpen={isOpen}
          handleToogleSidebar={handleToogleOpenSideBar}
          setIsOpen={setIsOpen}
        />
      </div>
      <div
        style={{
          width:
            isOpen && isDesktopOrLaptop
              ? "calc(100% - 230px)"
              : isDesktopOrLaptop
                ? "calc(100% - 90px)"
                : "100%",
        }}
        className={`overflow-hidden lg:absolute lg:right-0 ${!isDesktopOrLaptop && isOpen ? "bg-zinc-400" : ""
          }`}
      >
        <Header handleToogleSidebar={handleToogleOpenSideBar} />
        <div
          className={`relative w-full overflow-y-auto px-4 pt-4 lg:pt-6 pb-4 ${!isDesktopOrLaptop && isOpen ? "brightness-50" : ""
            }`}
          style={{ maxHeight: "calc(100vh - 64px)" }}
        >
          <Outlet />
          <div
            className={`absolute z-10 w-full h-full top-0 left-0 ${!isDesktopOrLaptop && isOpen ? "block" : "hidden"
              }`}
          ></div>
        </div>
      </div>
    </div>
  );
};
