import { useTranslation } from "react-i18next";
import { AutoComplete, Input, Modal, Select, message } from "antd";
import { useEffect, useState } from "react";
import {
  getAddressLevelFourToWard,
  getDistrictToProvince,
  getProvince,
  getWardToDistrict,
} from "@services/geo-services";
import { Geo } from "@models/Geo";
import { ShopLocation } from "@models/ShopLocation";
import { StyledSelect } from "./styles";
import { IoCaretDown } from "react-icons/io5";
import {
  createShopLocation,
  updateShopLocation,
} from "@services/shop-location-service";
import { useParams } from "react-router-dom";
import { detachAddress, searchSuggestAddress } from "@services/address-service";
import { useDebounce } from "use-debounce";
import { phoneRegExp } from "@helper/helper";

const ShopLocationForm = ({
  open,
  handleCancel,
  selectedShopLocation,
  setRefreshData,
}: {
  open: boolean;
  handleCancel: any;
  selectedShopLocation: ShopLocation | undefined;
  setRefreshData: any;
}) => {
  const { t } = useTranslation();
  const params = useParams();
  const { id } = params;
  const [provinces, setProvinces] = useState<Geo[]>([]);
  const [districts, setDistricts] = useState<Geo[]>([]);
  const [wards, setWards] = useState<Geo[]>([]);
  const [addressLevelFours, setAddressLevelFours] = useState<Geo[]>([]);
  const [selectedProvince, setSelectedProvince] = useState<any>(
    selectedShopLocation?.provinceId || undefined
  );
  const [selectedDistrict, setSelectedDistrict] = useState<any>(
    selectedShopLocation?.districtId || undefined
  );
  const [selectedWard, setSelectedWard] = useState<any>(
    selectedShopLocation?.wardId || undefined
  );
  const [selectedAddressLevelFour, setSelectedAddressLevelFour] = useState<any>(
    selectedShopLocation?.levelFourId || undefined
  );
  const [name, setName] = useState(selectedShopLocation?.name || "");
  const [phoneNumber, setPhoneNumber] = useState(
    selectedShopLocation?.phoneNumber || ""
  );
  const [address, setAddress] = useState(selectedShopLocation?.address || "");
  const [selectedSuggestAddress, setSelectedSuggestAddress] = useState("");
  const [addressDebounce] = useDebounce(address, 500);
  const [detachAddressList, setDetachAddressList] = useState<any[]>([]);

  const handleChangeProvince = async (id: number) => {
    setSelectedDistrict(undefined);
    setSelectedWard(undefined);
    setWards([]);
    setAddressLevelFours([]);
    setSelectedAddressLevelFour(undefined);
    setSelectedProvince(id);
    const res = await getDistrictToProvince(id);
    if (res) setDistricts(res);
  };

  const handleChangeDistrict = async (districtId: number) => {
    setSelectedAddressLevelFour(undefined);
    setSelectedDistrict(districtId);
    setSelectedWard(undefined);
    setAddressLevelFours([]);
    if (selectedProvince) {
      const res = await getWardToDistrict(selectedProvince, districtId);
      if (res) setWards(res);
    }
  };

  const handleChangeWard = async (wardId: number) => {
    setSelectedAddressLevelFour(undefined);
    setSelectedWard(wardId);
    if (selectedProvince && selectedDistrict) {
      const res = await getAddressLevelFourToWard(
        selectedProvince,
        selectedDistrict,
        wardId
      );
      if (res) setAddressLevelFours(res);
    }
  };

  const onSelectAddress = async (value: string) => {
    setAddress(address + " - " + value);
    setSelectedSuggestAddress(value);
    const res = await detachAddress(value);
    if (res) {
      const data = res?.data?.data;
      const resProvince = await getDistrictToProvince(data?.provinceId);
      if (resProvince) setDistricts(resProvince);
      const resDistrict = await getWardToDistrict(
        data?.provinceId,
        data?.districtId
      );
      if (resDistrict) setWards(resDistrict);
      const resWard = await getAddressLevelFourToWard(
        data?.provinceId,
        data?.districtId,
        data?.wardId
      );
      if (resWard) setAddressLevelFours(resWard);
      setSelectedProvince(data?.provinceId);
      setSelectedDistrict(data?.districtId);
      setSelectedWard(data?.wardId);
    }
  };

  const handleChangeAddressLevelFour = async (id: number) => {
    setSelectedAddressLevelFour(id);
  };

  const validate = () => {
    if (!name) {
      message.error(t("validate.PleaseInputStoreName"));
      return false;
    }
    if (!phoneNumber) {
      message.error(t("validate.PleaseInputPhoneNumber"));
      return false;
    }
    if (!phoneRegExp.test(phoneNumber)) {
      message.error(t("validate.ValidatePhoneNumber"));
      return false;
    }
    if (!address) {
      message.error(t("validate.PleaseInputAddress"));
      return false;
    }
    if (!selectedProvince) {
      message.error(t("validate.PleaseInputProvinces"));
      return false;
    }
    if (!selectedDistrict) {
      message.error(t("validate.PleaseInputDistrict"));
      return false;
    }
    if (!selectedWard) {
      message.error(t("validate.PleaseInputWard"));
      return false;
    }
    return true;
  };

  const onSave = async () => {
    if (!validate()) {
      return;
    }
    if (id) {
      const payload = {
        id: selectedShopLocation?.id,
        shopId: Number(id),
        name,
        phoneNumber,
        address,
        provinceId: Number(selectedProvince),
        districtId: Number(selectedDistrict),
        wardId: Number(selectedWard),
        levelFourId: Number(selectedAddressLevelFour),
      };
      try {
        if (selectedShopLocation?.id) {
          const res = await updateShopLocation(payload);
          if (res) {
            message.success(t("shopLocation.UpdateShopLocationSuccessfully"));
            handleCancel();
            setRefreshData(new Date().getTime());
          }
          return;
        }
        const res = await createShopLocation(payload);
        if (res) {
          message.success(t("shopLocation.CreateShopLocationSuccessfully"));
          handleCancel();
          setRefreshData(new Date().getTime());
        }
      } catch (e) {
        message.error(t("common.SomethingError"));
      }
    }
  };

  useEffect(() => {
    (async () => {
      const res = await getProvince();
      if (res) setProvinces(res);
      else {
        message.error("Không thể kết nối đến data tỉnh thành");
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (selectedShopLocation?.provinceId) {
        const res = await getDistrictToProvince(
          selectedShopLocation?.provinceId
        );
        if (res) setDistricts(res);
      }
    })();
  }, [selectedShopLocation?.provinceId]);

  useEffect(() => {
    (async () => {
      if (selectedShopLocation?.wardId) {
        const res = await getWardToDistrict(
          selectedShopLocation?.provinceId,
          selectedShopLocation?.districtId
        );
        if (res) setWards(res);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedShopLocation?.wardId]);

  useEffect(() => {
    (async () => {
      if (selectedShopLocation?.levelFourId) {
        const res = await getAddressLevelFourToWard(
          selectedShopLocation?.provinceId,
          selectedShopLocation?.districtId,
          selectedShopLocation?.wardId
        );
        if (res) setAddressLevelFours(res);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedShopLocation?.levelFourId]);

  useEffect(() => {
    (async () => {
      if (addressDebounce) {
        const res = await searchSuggestAddress(addressDebounce);
        if (res) {
          setDetachAddressList(
            res?.data?.map((item: any) => ({
              ...item,
              value: item?.fullName,
            })) || []
          );
        }
      }
    })();
  }, [addressDebounce]);

  return (
    <Modal
      title=""
      open={open}
      onCancel={handleCancel}
      width={900}
      footer={null}
    >
      <div className="text-base font-semibold border-b pb-3">
        {selectedShopLocation?.id
          ? t("accountDetails.EditAddressPickup")
          : t("accountDetails.AddAddressPickup")}
      </div>
      <div className="max-h-[400px] md:max-h-[700px] overflow-auto px-1 md:px-0">
        <div className="mt-4 grid md:grid-cols-2 gap-x-8 gap-y-4">
          <div className="">
            <p className="text-sm font-medium text-black">
              {t("accountDetails.PickupWarehouseName")}
              <span className="text-red"> *</span>
            </p>
            <Input
              className="mt-4 rounded-lg w-full h-10 border-black border-[0.5px]"
              placeholder={t("accountDetails.placeholder.PickupWarehouseName")}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="">
            <p className="text-sm font-medium text-black">
              {t("accountDetails.PhoneNumber")}
              <span className="text-red"> *</span>
            </p>
            <Input
              className="mt-4 rounded-lg w-full h-10 border-black border-[0.5px]"
              placeholder={t("accountDetails.placeholder.PhoneNumber")}
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>
          <div className="">
            <p className="text-sm font-medium text-black">
              Địa chỉ chi tiết<span className="text-red"> *</span>
            </p>
            <AutoComplete
              options={detachAddressList}
              onSelect={onSelectAddress}
              className="mt-4 rounded-lg w-full h-10"
              onSearch={(text) => setAddress(text)}
              placeholder="Nhập địa chỉ chi tiết"
              style={{ borderWidth: "0.5px", borderColor: "black" }}
              value={address}
            />
          </div>
          <div className="">
            <p className="text-sm font-medium text-black">
              Tỉnh / Thành phố
              <span className="text-red"> *</span>
            </p>
            <StyledSelect
              className="mt-4 rounded-lg w-full h-10"
              suffixIcon={<IoCaretDown className="text-lg" />}
              value={
                provinces?.find((item) => item.id === selectedProvince)?.name
              }
              onChange={(value: any) => handleChangeProvince(value)}
              placeholder="Nhập tên Tỉnh / Thành phố"
            >
              {provinces.map((province) => (
                <Select.Option key={province.id} value={province.id}>
                  {province.name}
                </Select.Option>
              ))}
            </StyledSelect>
          </div>
          <div className="">
            <p className="text-sm font-medium text-black">
              {t("accountDetails.District")}
              <span className="text-red"> *</span>
            </p>
            <StyledSelect
              className="mt-4 rounded-lg w-full h-10"
              suffixIcon={<IoCaretDown className="text-lg" />}
              onChange={(value: any) => handleChangeDistrict(value)}
              value={
                districts?.find((item) => item.id === selectedDistrict)?.name
              }
              placeholder="Nhập tên Quận / Huyện"
            >
              {districts.map((district) => (
                <Select.Option key={district.id} value={district.id}>
                  {district.name}
                </Select.Option>
              ))}
            </StyledSelect>
          </div>
          <div className="">
            <p className="text-sm font-medium text-black">
              {t("accountDetails.Ward")}
              <span className="text-red"> *</span>
            </p>
            <StyledSelect
              className="mt-4 rounded-lg w-full h-10"
              suffixIcon={<IoCaretDown className="text-lg" />}
              onChange={(e: any) => handleChangeWard(e)}
              value={wards?.find((item) => item.id === selectedWard)?.name}
              placeholder="Nhập tên Phường / Xã"
            >
              {wards.map((ward) => (
                <Select.Option key={ward.id} value={ward.id}>
                  {ward.name}
                </Select.Option>
              ))}
            </StyledSelect>
          </div>
          <div className="">
            <p className="text-sm font-medium text-black">
              {t("accountDetails.AddressLevelFour")}
            </p>
            <StyledSelect
              className="mt-4 rounded-lg w-full h-10"
              suffixIcon={<IoCaretDown className="text-lg" />}
              onChange={(e: any) => handleChangeAddressLevelFour(e)}
              value={
                addressLevelFours?.find(
                  (item) => item.id === selectedAddressLevelFour
                )?.name
              }
              placeholder="Nhập tên Địa chỉ cấp 4"
            >
              {addressLevelFours.map((addressLevelFour) => (
                <Select.Option
                  key={addressLevelFour.id}
                  value={addressLevelFour.id}
                >
                  {addressLevelFour.name}
                </Select.Option>
              ))}
            </StyledSelect>
          </div>
        </div>
        <div className="mt-6 flex justify-center">
          <button
            className="px-10 py-2 bg-blue-3 rounded text-white hover:bg-main-4"
            onClick={onSave}
          >
            {selectedShopLocation?.id
              ? t("accountDetails.Save")
              : t("accountDetails.AddAddressPickup")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ShopLocationForm;
