import { Input, Modal, Select, message } from "antd";
import { IoCaretDown } from "react-icons/io5";
import { addOrderRequest } from "@services/send-request";
import { useState } from "react";

const REQUEST_TYPE = [
  {
    text: "Giao lại",
    value: "re-delivery",
  },
  {
    text: "Khiếu nại",
    value: "complain",
  },
  {
    text: "Hẹn ngày giao",
    value: "reschedule",
  },
  {
    text: "Duyệt hoàn",
    value: "return",
  },
];

const SendRequestModal = ({ open, onCancel, order }: any) => {
  const [request, setRequest] = useState<any>();
  const [note, setNote] = useState("");

  const handleSubmitRequest = async () => {
    try {
      const payload = {
        requestType: request,
        orderCode: order?.orderCode,
        orderId: order?.orderId,
        requestMessage: note,
      };
      const res: any = await addOrderRequest(payload);
      if (res && res?.code !== "Fail") {
        message.success(
          "Đã gửi yêu cầu tới CSKH thành công, xin chờ trong ít phút"
        );
        onCancel();
      } else {
        message.error(res?.errorMessage);
      }
    } catch (e: any) {
      message.error(e?.errorMessage);
    }
  };

  return (
    <Modal
      title=""
      open={open}
      onCancel={onCancel}
      width={600}
      footer={null}
      centered
    >
      <div className="text-base font-semibold border-b pb-3">Gửi yêu cầu</div>
      <div className="my-4">
        <div className="flex flex-col gap-3">
          <div>
            <p className="mb-2">Loại yêu cầu</p>
            <Select
              className="w-full h-10 text-start first:pr-4"
              suffixIcon={<IoCaretDown className="text-lg" />}
              placeholder="Nhập loại"
              onChange={(e) => setRequest(e)}
            >
              {REQUEST_TYPE.map((type) => (
                <Select.Option key={type.value} value={type.value}>
                  {type.text}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
      </div>
      <div>
        <p className="mb-2">Ghi chú</p>
        <Input.TextArea
          rows={3}
          onChange={(e) => setNote(e.target.value)}
        ></Input.TextArea>
      </div>
      <div className="flex justify-end">
        <button
          className="mt-2 !border-none rounded px-3 py-1 bg-main-2 text-sm font-medium leading-[24px] text-white hover:bg-main-4 flex justify-center items-center"
          onClick={handleSubmitRequest}
        >
          Xác nhận
        </button>
      </div>
    </Modal>
  );
};

export default SendRequestModal;
