import { TFunction } from "i18next";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StyleSwitch } from "./styles";
import { Switch } from "antd";
import TrashRegular from "@assets/image/account-details/TrashRegular.svg";
import SolarPenIcon from "@assets/image/account-details/solar_pen-bold.svg";

interface ITableCol {
  width: number;
  minWidth?: number;
  title: string;
  dataIndex: string;
  key: string;
}

interface ITable {
  column: (t: TFunction<"translation", undefined>) => ITableCol[];
  row: any[];
  vatIsEveryMonth?: any;
  onEditAction?: any;
  onDeleteAction?: any;
  onActiveAction?: any;
  disableAction?: any;
}

const Table = ({
  column,
  row,
  onEditAction,
  onDeleteAction,
  onActiveAction,
  vatIsEveryMonth,
  disableAction,
}: ITable) => {
  const { t } = useTranslation();

  const calculateMinWidthTable = useMemo(() => {
    return (
      column(t).reduce((minWidth, col) => minWidth + Number(col.minWidth), 0) +
      column(t).length * 8 +
      16
    );
  }, []);

  return (
    <div className="mt-4 max-w-[100%] overflow-x-auto rounded-lg shadow-lg">
      <div
        className="bg-white w-full"
        style={{
          minWidth: `${calculateMinWidthTable}px`,
        }}
      >
        <div className="bg-gray-1 px-4 py-[10px] flex flex-row justify-between items-center gap-2 text-sm font-semibold">
          {column(t).map((column, i) => (
            <div
              key={i}
              style={{
                width: `${column.width}%`,
                minWidth: `${column.minWidth}px`,
              }}
            >
              {column.title}
            </div>
          ))}
        </div>
        <div className="px-4 text-sm font-normal">
          {row.map((row: any, index) => (
            <div
              className="flex justify-between items-center w-full gap-2 border-b last:border-none py-3"
              key={index}
            >
              {column(t).map((column, i) => {
                if (column.dataIndex === "action") {
                  return (
                    <div
                      key={i}
                      style={{
                        width: `${column.width}%`,
                        minWidth: `${column.minWidth}px`,
                      }}
                    >
                      <div className="flex items-center justify-around">
                        <StyleSwitch>
                          <Switch
                            className={row.isUsed ? "!bg-main-1" : "!bg-gray-1"}
                            onChange={() => onActiveAction(row)}
                            checked={row.isUsed}
                            disabled={disableAction}
                          ></Switch>
                        </StyleSwitch>
                        <button
                          disabled={disableAction}
                          onClick={() => onDeleteAction(row)}
                        >
                          <img src={TrashRegular} alt="" className="w-5 h-5" />
                        </button>
                        <button
                          disabled={disableAction}
                          onClick={() => onEditAction(row)}
                        >
                          <img src={SolarPenIcon} alt="" className="w-6 h-6" />
                        </button>
                      </div>
                    </div>
                  );
                } else if (column.dataIndex === "vatIsEveryMonth") {
                  return (
                    <div
                      key={i}
                      style={{
                        width: `${column.width}%`,
                        minWidth: `${column.minWidth}px`,
                      }}
                    >
                      {vatIsEveryMonth}
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={i}
                      style={{
                        width: `${column.width}%`,
                        minWidth: `${column.minWidth}px`,
                      }}
                    >
                      {row[column.dataIndex]}
                    </div>
                  );
                }
              })}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Table;
