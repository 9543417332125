import {
  StyleBankInfoListWrapper,
  StylePickupAddressWrapper,
  StyleTaxCodeListWrapper,
} from "./styles";
import PickupAddressList from "./PickupAddressList";
import TaxCodeList from "./TaxCodeList";
import BankInfoList from "./BankInfoList";
import AccountInfo from "./AccountInfo";
import RegisterCODProfile from "./RegisterCODProfile";
import CODTransporters from "./CODTransporters";
import SettingCODTransporters from "./SettingCODTransporters";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ShopAccount } from "@models/ShopAccount";
import { getShopDetails } from "@services/shop-services";

export const AccountDetails: React.FC = () => {
  const params = useParams();
  const { id } = params;
  const navigate = useNavigate();
  const [shopInfo, setShopInfo] = useState<ShopAccount>();

  useEffect(() => {
    (async () => {
      if (id) {
        try {
          const res = await getShopDetails(id);
          if (res) {
            setShopInfo(res);
          } else {
            navigate("/shop-management");
          }
        } catch (e) {
          navigate("/shop-management");
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    document.title = "Thông tin chi tiết shop - Go24";
  }, []);

  return (
    <div className="mt-4">
      <AccountInfo shopInfo={shopInfo} />
      <div className="mt-4">
        <div className="px-4 md:px-6 py-2 md:py-4 rounded-lg shadow-lg bg-white">
          <PickupAddressList />
        </div>
      </div>
      <div className="mt-4">
        <div className="px-4 md:px-6 py-2 md:py-4 rounded-lg shadow-lg bg-white">
          <TaxCodeList shopInfo={shopInfo} />
        </div>
      </div>
      <div className="mt-4">
        <div className="px-4 md:px-6 py-2 md:py-4 rounded-lg shadow-lg bg-white">
          <BankInfoList />
        </div>
      </div>
      <div className="mt-4">
        <RegisterCODProfile shopInfo={shopInfo} />
      </div>
      <div className="mt-4">
        <CODTransporters shopInfo={shopInfo}/>
      </div>
      <div className="mt-4">
        <SettingCODTransporters shopInfo={shopInfo} />
      </div>
    </div>
  );
};

export default AccountDetails;
