import { useLocalStorage } from "@hooks/useLocalStorage";
import axios from "axios";

const TOKEN = "token";
const GO24_TOKEN = "go24-token";

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use((config) => {
  const { getItem } = useLocalStorage();
  let token = getItem(TOKEN);
  if (token && !config.headers.Authorization) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const { removeItem, getItem, setItem } = useLocalStorage();
    const originalRequest = error.config;
    if (
      error?.response?.data?.statusCode === 401 &&
      window.location.pathname !== "/login"
    ) {
      originalRequest._retry = true;
      const refresh = getItem("refresh");
      const config = {
        headers: {
          Authorization: `Bearer ${refresh}`,
        },
      };
      return axios
        .post(`${process.env.REACT_APP_API_URL}/auth/refresh`, {}, config)
        .then((response) => {
          const { accessToken } = response?.data;
          setItem(TOKEN, accessToken);
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          return axios(originalRequest);
        })
        .catch((error) => {
          removeItem(TOKEN);
          window.location.pathname = "/login";
        });
    }
    return Promise.reject(error);
  }
);

export const geoApi = axios.create({
  baseURL: process.env.REACT_APP_GEO_API,
  headers: {
    "Content-Type": "application/json",
    "ngrok-skip-browser-warning": "true",
  },
});

export const go24Api = axios.create({
  baseURL: `${process.env.REACT_APP_GO24_API}/api`,
  headers: {
    "Content-Type": "application/json",
  },
});

go24Api.interceptors.request.use((config) => {
  const { getItem } = useLocalStorage();
  let token = getItem(GO24_TOKEN);
  if (token && !config.headers.Authorization) {
    config.headers.Accesstoken = token;
  }
  return config;
});

go24Api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response.status === 401 &&
      window.location.pathname !== "/login"
    ) {
      localStorage.removeItem(GO24_TOKEN);
      window.location.pathname = "/login";
    }
    return Promise.reject(error);
  }
);
