import { useTranslation } from "react-i18next";
import { SectionAccount } from "./SectionAccount";
import { Input } from "antd";
import { formatNumber } from "@helper/helper";
import { STATUS_COD } from "constants/constants";

const RegisterCODProfile = ({ shopInfo }: { shopInfo: any }) => {
  const { t } = useTranslation();

  return (
    <SectionAccount title={t("accountDetails.RegisterCODProfile")}>
      <div className="bg-white rounded-lg shadow-lg">
        <div className="px-4 md:px-6 py-2 md:py-4">
          <div className="mt-4 md:mt-6 flex flex-col md:flex-row gap-4 md:gap-7">
            <div className="w-full md:w-1/2">
              <div className="flex flex-col items-start gap-4">
                <p className="text-sm font-medium text-black">
                  {t("accountDetails.TotalCODAdvanceLimit")}
                </p>
                <Input
                  disabled
                  className="rounded-lg w-full h-10 border-black border-[0.5px]"
                  placeholder="Không có dữ liệu"
                  value={formatNumber(shopInfo?.codLimit)}
                />
              </div>
              <div className="mt-6 flex flex-col items-start gap-4">
                <p className="text-sm font-medium text-black">
                  {t("accountDetails.OrderCODAdvanceLimit")}
                </p>
                <Input
                  disabled
                  className="rounded-lg w-full h-10 border-black border-[0.5px]"
                  placeholder="Không có dữ liệu"
                  value={formatNumber(shopInfo?.codLimitPerOrder)}
                />
              </div>
              <div className="mt-6 flex flex-col items-start gap-4">
                <p className="text-sm font-medium text-black">
                  {t("accountDetails.AllowableRefundRate")}
                </p>
                <Input
                  disabled
                  className="rounded-lg w-full h-10 border-black border-[0.5px]"
                  placeholder="Không có dữ liệu"
                  value={`${shopInfo?.allowableReturnRate}%`}
                />
              </div>
              <div className="mt-6 flex flex-col items-start gap-4">
                <p className="text-sm font-medium text-black">
                  Trạng thái hồ sơ ứng COD
                </p>
                <Input
                  disabled
                  className="rounded-lg w-full h-10 border-black border-[0.5px]"
                  placeholder="Không có dữ liệu"
                  value={
                    STATUS_COD.find((item) => item?.id === shopInfo?.codStatus)
                      ?.value
                  }
                />
              </div>
            </div>
            <div className="w-full md:w-1/2">
              <div className="flex flex-col items-start gap-4">
                <p className="text-sm font-medium text-black">
                  {t("accountDetails.DailyCODAdvanceLimit")}
                </p>
                <Input
                  disabled
                  className="rounded-lg w-full h-10 border-black border-[0.5px]"
                  placeholder="Không có dữ liệu"
                  value={formatNumber(shopInfo?.codMaxAmountPerDay)}
                />
              </div>
              <div className="mt-6 flex flex-col items-start gap-4">
                <p className="text-sm font-medium text-black">
                  {t("accountDetails.OrderAdvancePercent")}
                </p>
                <Input
                  disabled
                  className="rounded-lg w-full h-10 border-black border-[0.5px]"
                  placeholder="Không có dữ liệu"
                  value={formatNumber(shopInfo?.codMaxPercentPerOrder)}
                />
              </div>
              <div className="mt-6 flex flex-col items-start gap-4">
                <p className="text-sm font-medium text-black">
                  {t("accountDetails.AttachPicture")}
                </p>
                <div className="overflow-auto flex gap-2 px-4 flex-nowrap items-center border bbg-[#0000000a] border-[#d9d9d9] rounded-lg w-full h-[156px]">
                  {shopInfo?.codAttachment?.map((item: string) => (
                    <div key={item}>
                      <div className="!w-[120px] !h-[120px]">
                        <img
                          className="w-[120px] h-[120px]"
                          src={`${process.env.REACT_APP_GO24_API}${item}`}
                          alt="img"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SectionAccount>
  );
};

export default RegisterCODProfile;
