import { Section } from "@components/section/Section";
import { useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";

const TAB_ITEMS = [
  { label: "Thông tin tài khoản nhân viên", path: "/sub-account" },
  { label: "Cài đặt nhóm quyền", path: "/sub-account/role-setting" },
];

const SubAccount = () => {
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    document.title = "Nhân viên - Go24";
  }, []);

  return (
    <div className="flex flex-col gap-y-8">
      <Section title="Cài đặt tài khoản nhân viên">
        <div className="flex gap-4 md:gap-8 items-center">
          <div className="flex items-center whitespace-nowrap flex-nowrap overflow-auto bg-white w-full rounded-lg gap-4 md:gap-5 px-2 md:px-4 shadow-md">
            {TAB_ITEMS.map(({ label, path }) => (
              <Link to={path} key={path}>
                <div
                  className={`text-sm md:text-base text-black font-normal px-1 md:px-2 py-4 border-b hover:border-main-1 cursor-pointer ${
                    pathname === path ? "border-main-1" : "border-transparent"
                  }`}
                  key={label}
                >
                  {label}
                </div>
              </Link>
            ))}
          </div>
        </div>
        <Outlet />
      </Section>
    </div>
  );
};

export default SubAccount;
