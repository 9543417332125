import { resetPasswordForShop } from "@services/shop-services";
import { Button, Input, Modal, message } from "antd";
import { useState } from "react";

const ResetPasswordModal = ({
  open,
  onCancel,
  id,
}: {
  open: boolean;
  onCancel: any;
  id: number | undefined;
}) => {
  const [newPassword, setNewPassword] = useState("");
  const [renewPassword, setRenewPassword] = useState("");

  const validateUpdateForm = () => {
    if (!newPassword) {
      message.error("Mật khẩu mới là bắt buộc");
      return false;
    }
    if (renewPassword.length < 8) {
      message.error("Mật khẩu tối thiểu 8 ký tự");
      return false;
    }
    if (!renewPassword) {
      message.error("Nhập lại mật khẩu mới là bắt buộc");
      return false;
    }
    if (newPassword !== renewPassword) {
      message.error("Nhập lại mật khẩu không trùng khớp");
      return false;
    }
    return true;
  };

  const onSave = async () => {
    if (!validateUpdateForm()) return;
    try {
      const payload = {
        newPassword,
        confirmPassword: newPassword,
      };
      if (id) {
        const res = await resetPasswordForShop(id, payload);
        if (res) {
          message.success("Cập nhật thành công");
          onCancel();
        }
      }
    } catch (e) {
      message.error("Cập nhật thất bại");
    }
  };

  return (
    <Modal
      title=""
      open={open}
      onCancel={onCancel}
      width={600}
      footer={null}
      centered
    >
      <div className="text-base font-semibold border-b pb-3">
        Thay đổi mật khẩu
      </div>
      <div className="mt-4 flex flex-col gap-6">
        <div className="flex flex-col items-start gap-3">
          <p className="text-sm font-medium text-black">
            Mật khẩu mới<span className="text-red"> *</span>
          </p>
          <Input.Password
            className="rounded-lg w-full h-10 border-black border-[0.5px]"
            placeholder="Nhập mật khẩu mới"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <div className="flex flex-col items-start gap-3">
          <p className="text-sm font-medium text-black">
            Nhập lại mật khẩu mới<span className="text-red"> *</span>
          </p>
          <Input.Password
            className="rounded-lg w-full h-10 border-black border-[0.5px]"
            placeholder="Nhập lại mật khẩu mới"
            value={renewPassword}
            onChange={(e) => setRenewPassword(e.target.value)}
          />
        </div>
      </div>
      <div className="mt-4 flex justify-center">
        <button
          className="!border-none rounded px-6 py-2 bg-main-2 text-sm font-medium leading-[24px] text-white hover:bg-main-4 flex justify-center items-center"
          onClick={onSave}
        >
          Cập nhật
        </button>
      </div>
    </Modal>
  );
};

export default ResetPasswordModal;
