import { useTranslation } from "react-i18next";
import { SectionAccount } from "./SectionAccount";
import CheckedCircle from "@assets/image/account-details/checkedCircle.svg";
import Circle from "@assets/image/account-details/tick-circle.svg";
import { useEffect, useState } from "react";

const CODTransporters = ({ shopInfo }: any) => {
  const { t } = useTranslation();
  const [shopCod, setShopCod] = useState<any[]>([]);

  useEffect(() => {
    if (shopInfo?.shopCodDetails?.length > 0) {
      const listCodPartner = [];
      for (const partner of shopInfo?.shopCodDetails || []) {
        listCodPartner.push({
          isActive: partner?.isActive,
          deliveryPartnerCode: partner?.deliveryPartnerCode,
          maximumLimit: partner?.maximumLimit,
        });
      }
      setShopCod(listCodPartner);
    }
  }, [shopInfo?.shopCodDetails]);

  return (
    <SectionAccount title={t("accountDetails.CODAdvanceLimitByTransporters")}>
      <div className="p-4 md:p-6 bg-white rounded-lg shadow-lg">
        {shopCod?.length === 0 ? (
          <div>Không có dữ liệu</div>
        ) : (
          <div className="max-w-[100%] overflow-x-auto text-sm shadow-lg">
            <div className="min-w-[1300px] rounded-tl-lg rounded-tr-lg bg-gray-1 px-4 py-[10px] flex flex-row justify-between items-center font-semibold">
              <div className="w-[250px]"></div>
              {shopCod.map((column) => (
                <div key={column?.deliveryPartnerCode} className="w-[150px]">
                  {column?.deliveryPartnerCode}
                </div>
              ))}
            </div>
            <div
              className={`min-w-[1300px] px-4 py-[10px] flex flex-row justify-between items-center border-b`}
            >
              <div className="w-[250px]">Active NVC</div>
              {shopCod.map((column) => (
                <div className="w-[150px]" key={column?.deliveryPartnerCode}>
                  <img
                    src={column?.isActive ? CheckedCircle : Circle}
                    alt=""
                    className="m-auto"
                  />
                </div>
              ))}
            </div>
            <div
              className={`min-w-[1300px] px-4 py-[10px] flex flex-row justify-between items-center border-b`}
            >
              <div className="w-[250px]">Hạn mức ứng tối đa</div>
              {shopCod.map((column) => (
                <div className="w-[150px]" key={column?.deliveryPartnerCode}>
                  {column?.maximumLimit || 0}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </SectionAccount>
  );
};

export default CODTransporters;
