import * as React from "react"
const TagIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={200}
    height={200}
    viewBox="0 0 200 200"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17.691 106.8c2.392 8.359 8.833 14.792 21.708 27.667l15.25 15.25c22.417 22.425 33.617 33.617 47.534 33.617 13.925 0 25.125-11.2 47.533-33.609 22.417-22.416 33.617-33.616 33.617-47.541 0-13.917-11.2-25.126-33.609-47.534l-15.25-15.25c-12.883-12.875-19.316-19.316-27.675-21.708-8.358-2.4-17.233-.35-34.975 3.75L61.591 23.8c-14.933 3.442-22.4 5.167-27.517 10.275-5.108 5.117-6.833 12.583-10.275 27.517l-2.366 10.233c-4.092 17.75-6.134 26.617-3.742 34.975ZM84.35 60.592a16.807 16.807 0 0 1-11.82 28.906 16.807 16.807 0 0 1-11.733-28.693 16.808 16.808 0 0 1 23.553-.213Zm74.4 39.833-58.158 58.167a6.25 6.25 0 0 1-8.833-8.842l58.15-58.166a6.25 6.25 0 0 1 8.841 0 6.25 6.25 0 0 1 0 8.841Z"
      clipRule="evenodd"
    />
  </svg>
)
export default TagIcon
