import axios from "axios";
import { api } from "provider/api";

export const searchSuggestAddress = async (search: string) => {
  try {
    const res = await api.get(`/partner/suggest-address?search=${search}`);
    if (res) {
      return res.data.response;
    }
  } catch (e) {}
};

export const detachAddress = async (address: string) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_GO24_API}/api/detachaddress/detach-address?address=${address}`
    );
    if (res) {
      return res;
    }
  } catch (e) {}
};
