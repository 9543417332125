import { useTranslation } from "react-i18next";
import { bankInfoColumn } from "./dataSources";
import { IoAdd } from "react-icons/io5";
import Table from "./Table";
import React, { useEffect, useState } from "react";
import { Bank, BankAccount } from "@models/BankAccount";
import {
  deleteBankAccount,
  getBankAccountList,
  getBankList,
  updateBankAccount,
} from "@services/bank-account-services";
import { useParams } from "react-router-dom";
import BankAccountForm from "./BankAccountForm";
import { message } from "antd";
import { useAuth } from "provider/auth";
import { AUTHORIZE } from "constants/constants";
import { FaPlus } from "react-icons/fa6";

const { EDIT } = AUTHORIZE;

export interface IActionBankInfoComponent {
  handleOnClickEdit: any;
}

const BankInfoList = () => {
  const { t } = useTranslation();
  const { organizations } = useAuth();
  const params = useParams();
  const { id } = params;
  const [open, setOpen] = useState<boolean>(false);
  const [bankList, setBankList] = useState<Bank[]>([]);
  const [bankAccountList, setBankAccountList] = useState<BankAccount[]>([]);
  const [refreshData, setRefreshData] = useState(new Date().getTime());
  const [selectedBankAccount, setSelectedBankAccount] = useState<BankAccount>();

  const showModal = () => {
    setOpen(true);
  };

  const onEditAction = (item: any) => {
    setSelectedBankAccount(item);
    setOpen(true);
  };

  const onDeleteAction = async (item: any) => {
    try {
      const res = await deleteBankAccount(item.id);
      if (res) {
        message.success(t("bank.DeleteBankAccountSuccessfully"));
        setRefreshData(new Date().getTime());
      }
    } catch (e) {
      message.error(t("bank.DeleteBankAccountFailed"));
    }
  };

  const onActiveAction = async (item: any) => {
    try {
      const res = await updateBankAccount({
        ...item,
        isUsed: item?.isUsed ? false : true,
      });
      if (res) {
        message.success(t("bank.UpdateBankAccountSuccessfully"));
        setRefreshData(new Date().getTime());
      }
    } catch (e) {
      message.error(t("bank.UpdateBankAccountFailed"));
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await getBankList();
        if (res) {
          setBankList(res);
        }
      } catch (e) {}
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (id) {
        try {
          const payload = {
            page: 1,
            limit: 10,
            shopId: Number(id),
          };
          const res = await getBankAccountList(payload);
          if (res) {
            const formatBankList = res.data.map((item: Bank, i: number) => ({
              ...item,
              stt: i + 1,
            }));
            setBankAccountList(formatBankList);
          }
        } catch (e) {}
      }
    })();
  }, [id, refreshData]);

  return (
    <div>
      <div className="flex gap-7 items-center justify-between">
        <div className="md:w-1/2 flex justify-start font-medium">
          {t("accountDetails.BankInfo")}
        </div>
        {/* <div className="md:w-1/2 flex justify-end">
          {organizations["manageShop"] === EDIT && (
            <button
              className="p-2 md:p-0 md:w-[180px] md:h-10 bg-blue-3 text-white flex justify-center items-center gap-4 rounded hover:bg-main-4"
              onClick={showModal}
            >
              <FaPlus className="text-xl md:text-2xl" />
              <span className="hidden md:block">{t("button.AddBankInfo")}</span>
            </button>
          )}
        </div> */}
      </div>
      {bankAccountList.length > 0 ? (
        <Table
          column={bankInfoColumn}
          row={bankAccountList}
          onEditAction={onEditAction}
          onDeleteAction={onDeleteAction}
          onActiveAction={onActiveAction}
          disableAction={organizations["manageShop"] !== EDIT}
        />
      ) : (
        <p className="mt-4">{t("common.NoData")}</p>
      )}
      {open && (
        <BankAccountForm
          {...{
            open,
            setOpen,
            selectedBankAccount,
            setSelectedBankAccount,
            setRefreshData,
            bankList,
          }}
        />
      )}
    </div>
  );
};

export default BankInfoList;
