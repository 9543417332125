import {
  COD_STATUS,
  DELIVERY_STATUS,
  DeliveryPartners,
  ORDER_PAID_STATUS,
  ORDER_STATUS,
  REQUEST_STATUS,
  REQUEST_TYPE,
} from "constants/constants";
import moment, * as momentTimeZone from "moment-timezone";
import * as XLSX from "xlsx";

export const formatNumber = (
  value: any,
  includeDecimal: boolean = true,
  decimal: number = 2
) => {
  if (!value) return "0";
  if (includeDecimal) {
    value = parseFloat(value).toFixed(decimal);
  }
  value += "";
  const list = value.split(".");
  const prefix = list[0].charAt(0) === "-" ? "-" : "";
  let num = prefix ? list[0].slice(1) : list[0];
  let result = "";
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  if (includeDecimal && parseInt(list[1]) > 0) {
    return `${prefix}${result},${list[1] ? `${list[1]}` : ""}`;
  }

  return `${prefix}${result}`;
};

export const formatDate = (time: any, format: string = "HH:mm DD-MM-YYYY") => {
  return momentTimeZone.tz(time, "Asia/Bangkok").format(format);
};

export const phoneRegExp = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
export const validateEmail = (email: String) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
export const weightRegExp = /^(?:[1-9]\d{0,2}(?:,\d{3})*|0)(?:\.\d+)?$/;

export const createQueryParams = (params: any) => {
  return Object.keys(params)
    .map((param) => {
      if (params[param]?.length > 0) {
        return params[param].map((p: any) => param + "=" + p);
      } else if (params[param] || params[param] === false) {
        return param + "=" + params[param];
      } else {
        return null;
      }
    })
    .filter((param) => param !== undefined && param !== null)
    .join("&");
};
export const parseObjectArrayToParam = (obj: Record<string, any>): string => {
  let path = "";
  Object.keys(obj).map((key) => {
    if ((obj[key] !== undefined || obj[key] === 0) && obj[key] !== null) {
      if (!Array.isArray(obj[key])) {
        path += `&${key}=${encodeURIComponent(obj[key])}`;
      } else {
        if (obj[key].length === 1) {
          path += `&${key}=${encodeURIComponent(obj[key][0])}`;
        } else if (obj[key].length > 1) {
          obj[key].map((ob: any) => {
            path += `&${key}=${encodeURIComponent(ob)}`;
          });
        }
      }
    }
  });
  path = path.substring(1);
  return path;
};
export const toBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const renderOrderStatus = (statusId: string) => {
  let status = "";
  switch (statusId) {
    case ORDER_STATUS.ReimbursementDelivery.code:
      status = ORDER_STATUS.ReimbursementDelivery.key;
      break;
    case ORDER_STATUS.Reimbursement.code:
      status = ORDER_STATUS.Reimbursement.key;
      break;
    case ORDER_STATUS.Canceled.code:
      status = ORDER_STATUS.Canceled.key;
      break;
    case ORDER_STATUS.WaitingConfirm.code:
      status = ORDER_STATUS.WaitingConfirm.key;
      break;
    case ORDER_STATUS.WaitingTakeOrder?.code:
      status = ORDER_STATUS.WaitingTakeOrder?.key;
      break;
    case ORDER_STATUS.Delivering.code:
      status = ORDER_STATUS.Delivering.key;
      break;
    case ORDER_STATUS.Deliveried.code:
      status = ORDER_STATUS.Deliveried.key;
      break;
    case ORDER_STATUS.WaitingReturn.code:
      status = ORDER_STATUS.WaitingReturn.key;
      break;
    case ORDER_STATUS.Returned.code:
      status = ORDER_STATUS.Returned.key;
      break;
    case ORDER_STATUS.DeliveryFailed.code:
      status = ORDER_STATUS.DeliveryFailed.key;
      break;
    case ORDER_STATUS.Incident.code:
      status = ORDER_STATUS.Incident.key;
      break;
    case ORDER_STATUS.PickedUp.code:
      status = ORDER_STATUS.PickedUp.key;
      break;
    case ORDER_STATUS.Transporting.code:
      status = ORDER_STATUS.Transporting.key;
      break;
    case ORDER_STATUS.Stored.code:
      status = ORDER_STATUS.Stored.key;
      break;
    case ORDER_STATUS.PartiallyReturning.code:
      status = ORDER_STATUS.PartiallyReturning.key;
      break;
    case ORDER_STATUS.PartiallyDelivered.code:
      status = ORDER_STATUS.PartiallyDelivered.key;
      break;
    case ORDER_STATUS.PartiallyReturned.code:
      status = ORDER_STATUS.PartiallyReturned.key;
      break;
  }
  return status;
};

export const renderDeliveryStatus = (statusId: string) => {
  let status = "";
  switch (statusId) {
    case DELIVERY_STATUS.All.code:
      status = DELIVERY_STATUS.All.key;
      break;
    case DELIVERY_STATUS.DelayPickup.code:
      status = DELIVERY_STATUS.DelayPickup.key;
      break;
    case DELIVERY_STATUS.DelayDelivery.code:
      status = DELIVERY_STATUS.DelayDelivery.key;
      break;
    case DELIVERY_STATUS.WeightChanged.code:
      status = DELIVERY_STATUS.WeightChanged.key;
      break;
    case DELIVERY_STATUS.ReturnPartPackage.code:
      status = DELIVERY_STATUS.ReturnPartPackage.key;
      break;
    case DELIVERY_STATUS.RetryDelivery.code:
      status = DELIVERY_STATUS.RetryDelivery.key;
      break;
    case DELIVERY_STATUS.Delivered.code:
      status = DELIVERY_STATUS.Delivered.key;
      break;
  }
  return status;
};

export const renderOrderPaidStatus = (statusId: string) => {
  let status = "";
  switch (statusId) {
    case ORDER_PAID_STATUS.None.code:
      status = ORDER_PAID_STATUS.None.key;
      break;
    case ORDER_PAID_STATUS.NotPaid.code:
      status = ORDER_PAID_STATUS.NotPaid.key;
      break;
    case ORDER_PAID_STATUS.WaitingPaid.code:
      status = ORDER_PAID_STATUS.WaitingPaid.key;
      break;
    case ORDER_PAID_STATUS.Done.code:
      status = ORDER_PAID_STATUS.Done.key;
      break;
  }
  return status;
};

export const renderCODStatus = (statusId: string) => {
  let status = "";
  switch (statusId) {
    case COD_STATUS.None.code:
      status = COD_STATUS.None.key;
      break;
    case COD_STATUS.WaittingCOD.code:
      status = COD_STATUS.WaittingCOD.key;
      break;
    case COD_STATUS.Done.code:
      status = COD_STATUS.Done.key;
      break;
  }
  return status;
};

export const renderRequestStatus = (idStatus: number) => {
  let status = "";
  switch (idStatus) {
    case REQUEST_STATUS.All.code:
      status = REQUEST_STATUS.All.key;
      break;
    case REQUEST_STATUS.Accept.code:
      status = REQUEST_STATUS.Accept.key;
      break;
    case REQUEST_STATUS.Rejected.code:
      status = REQUEST_STATUS.Rejected.key;
      break;
    case REQUEST_STATUS.WaitProgress.code:
      status = REQUEST_STATUS.WaitProgress.key;
      break;
    case REQUEST_STATUS.Progressing.code:
      status = REQUEST_STATUS.Progressing.key;
      break;
  }
  return status;
};

export const renderRequestType = (requestType: string) => {
  let status = "";
  switch (requestType) {
    case REQUEST_TYPE.Total.code:
      status = REQUEST_TYPE.Total.key;
      break;
    case REQUEST_TYPE.Renew.code:
      status = REQUEST_TYPE.Renew.key;
      break;
    case REQUEST_TYPE.Edit.code:
      status = REQUEST_TYPE.Edit.key;
      break;
    case REQUEST_TYPE.Pickup.code:
      status = REQUEST_TYPE.Pickup.key;
      break;
    case REQUEST_TYPE.Reschedule.code:
      status = REQUEST_TYPE.Reschedule.key;
      break;
    case REQUEST_TYPE.Delivery.code:
      status = REQUEST_TYPE.Delivery.key;
      break;
    case REQUEST_TYPE.PickupAppointment.code:
      status = REQUEST_TYPE.PickupAppointment.key;
      break;
    case REQUEST_TYPE.ReDelivery.code:
      status = REQUEST_TYPE.ReDelivery.key;
      break;
    case REQUEST_TYPE.Complain.code:
      status = REQUEST_TYPE.Complain.key;
      break;
    case REQUEST_TYPE.Return.code:
      status = REQUEST_TYPE.Return.key;
      break;
  }
  return status;
};

export const XLSX_EXTENSION = ".xlsx";

export const exportDataToExcel = (data: any[], fileName: string) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "data");
  XLSX.writeFile(
    workbook,
    `${fileName}_${moment(new Date()).format("YYYYMMDDHHmm")}${XLSX_EXTENSION}`
  );
};

export const removeVietnameseTones = (str: string) => {
  if (str === undefined) {
    str = "";
  }
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/\s/g, "");
  str = str.trim();
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  str = str.replace(
    /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
    " "
  );
  return str;
};

export const isOrderDeliveringStatus = (statusId: string) => {
  return (
    [
      ORDER_STATUS.PickedUp.code,
      ORDER_STATUS.Transporting.code,
      ORDER_STATUS.Stored.code,
      ORDER_STATUS.Deliveried.code,
      ORDER_STATUS.Delivering.code,
    ].indexOf(statusId) >= 0
  );
};

export const fieldOrderCheck = (fieldName: string, order: any) => {
  var allowStatuses = [];
  if (fieldName === "senderAddress") {
    switch (order?.partnerDeliveryId) {
      case DeliveryPartners.GHTK:
      case DeliveryPartners.GHTK2:
      case DeliveryPartners.GHTK3:
      case DeliveryPartners.GHN:
      case DeliveryPartners.GHN2:
      case DeliveryPartners.Ninjavan:
      case DeliveryPartners.BESTEXPRESS:
      case DeliveryPartners.JT:
        allowStatuses = [ORDER_STATUS.WaitingConfirm.code];

        if (!allowStatuses.includes(order?.statusId?.toString())) return false;
        break;

      default:
        break;
    }
  }

  if (fieldName === "phoneNumber") {
    switch (order?.partnerDeliveryId) {
      case DeliveryPartners.GHTK:
      case DeliveryPartners.GHTK2:
      case DeliveryPartners.GHTK3:
      case DeliveryPartners.GHN:
      case DeliveryPartners.GHN2:
      case DeliveryPartners.Ninjavan:
      case DeliveryPartners.BESTEXPRESS:
      case DeliveryPartners.JT:
        allowStatuses = [
          ORDER_STATUS.WaitingConfirm.code,
          ORDER_STATUS.WaitingTakeOrder.code,
          ORDER_STATUS.PickedUp.code,
          ORDER_STATUS.Transporting.code,
          ORDER_STATUS.Stored.code,
          ORDER_STATUS.Delivering.code,
          ORDER_STATUS.DeliveryFailed.code,
        ];

        if (!allowStatuses.includes(order?.statusId?.toString())) return false;
        break;

      default:
        break;
    }
  }

  if (fieldName === "customerName") {
    switch (order?.partnerDeliveryId) {
      case DeliveryPartners.GHTK:
      case DeliveryPartners.GHTK2:
      case DeliveryPartners.GHTK3:
      case DeliveryPartners.GHN:
      case DeliveryPartners.GHN2:
      case DeliveryPartners.Ninjavan:
      case DeliveryPartners.BESTEXPRESS:
      case DeliveryPartners.JT:
        allowStatuses = [
          ORDER_STATUS.WaitingConfirm.code,
          ORDER_STATUS.WaitingTakeOrder.code,
          ORDER_STATUS.PickedUp.code,
          ORDER_STATUS.Transporting.code,
          ORDER_STATUS.Stored.code,
          ORDER_STATUS.Delivering.code,
          ORDER_STATUS.DeliveryFailed.code,
        ];

        if (!allowStatuses.includes(order?.statusId?.toString())) return false;
        break;

      default:
        break;
    }
  }

  if (fieldName === "receiverAddress") {
    switch (order?.partnerDeliveryId) {
      case DeliveryPartners.GHTK:
      case DeliveryPartners.GHTK2:
      case DeliveryPartners.GHTK3:
      case DeliveryPartners.GHN:
      case DeliveryPartners.GHN2:
      case DeliveryPartners.Ninjavan:
      case DeliveryPartners.BESTEXPRESS:
      case DeliveryPartners.JT:
        allowStatuses = [
          ORDER_STATUS.WaitingConfirm.code,
          ORDER_STATUS.WaitingTakeOrder.code,
          ORDER_STATUS.PickedUp.code,
          ORDER_STATUS.Transporting.code,
          ORDER_STATUS.Stored.code,
          ORDER_STATUS.Delivering.code,
          ORDER_STATUS.DeliveryFailed.code,
        ];

        if (!allowStatuses.includes(order?.statusId?.toString())) return false;
        break;

      default:
        break;
    }
  }

  if (fieldName === "province") {
    switch (order?.partnerDeliveryId) {
      case DeliveryPartners.GHN:
      case DeliveryPartners.GHN2:
        allowStatuses = [
          ORDER_STATUS.WaitingConfirm.code,
          ORDER_STATUS.WaitingTakeOrder.code,
          ORDER_STATUS.PickedUp.code,
          ORDER_STATUS.Transporting.code,
          ORDER_STATUS.Stored.code,
          ORDER_STATUS.Delivering.code,
          ORDER_STATUS.DeliveryFailed.code,
        ];

        if (!allowStatuses.includes(order?.statusId?.toString())) return false;
        break;
      case DeliveryPartners.GHTK:
      case DeliveryPartners.GHTK2:
      case DeliveryPartners.GHTK3:
      case DeliveryPartners.Ninjavan:
      case DeliveryPartners.BESTEXPRESS:
      case DeliveryPartners.JT:
        allowStatuses = [
          ORDER_STATUS.WaitingConfirm.code,
          ORDER_STATUS.WaitingTakeOrder.code,
        ];

        if (!allowStatuses.includes(order?.statusId?.toString())) return false;
        break;
      default:
        break;
    }
  }

  if (fieldName === "district") {
    switch (order?.partnerDeliveryId) {
      case DeliveryPartners.GHTK:
      case DeliveryPartners.GHTK2:
      case DeliveryPartners.GHTK3:
      case DeliveryPartners.GHN:
      case DeliveryPartners.GHN2:
      case DeliveryPartners.Ninjavan:
      case DeliveryPartners.BESTEXPRESS:
      case DeliveryPartners.JT:
        allowStatuses = [
          ORDER_STATUS.WaitingConfirm.code,
          ORDER_STATUS.WaitingTakeOrder.code,
          ORDER_STATUS.PickedUp.code,
          ORDER_STATUS.Transporting.code,
          ORDER_STATUS.Stored.code,
          ORDER_STATUS.Delivering.code,
          ORDER_STATUS.DeliveryFailed.code,
        ];

        if (!allowStatuses.includes(order?.statusId?.toString())) return false;
        break;

      default:
        break;
    }
  }

  if (fieldName === "ward") {
    switch (order?.partnerDeliveryId) {
      case DeliveryPartners.GHTK:
      case DeliveryPartners.GHTK2:
      case DeliveryPartners.GHTK3:
      case DeliveryPartners.GHN:
      case DeliveryPartners.GHN2:
      case DeliveryPartners.Ninjavan:
      case DeliveryPartners.BESTEXPRESS:
      case DeliveryPartners.JT:
        allowStatuses = [
          ORDER_STATUS.WaitingConfirm.code,
          ORDER_STATUS.WaitingTakeOrder.code,
          ORDER_STATUS.PickedUp.code,
          ORDER_STATUS.Transporting.code,
          ORDER_STATUS.Stored.code,
          ORDER_STATUS.Delivering.code,
          ORDER_STATUS.DeliveryFailed.code,
        ];

        if (!allowStatuses.includes(order?.statusId?.toString())) return false;
        break;

      default:
        break;
    }
  }

  if (fieldName === "l4Address") {
    switch (order?.partnerDeliveryId) {
      case DeliveryPartners.GHTK:
      case DeliveryPartners.GHTK2:
      case DeliveryPartners.GHTK3:
      case DeliveryPartners.GHN:
      case DeliveryPartners.GHN2:
      case DeliveryPartners.Ninjavan:
      case DeliveryPartners.BESTEXPRESS:
      case DeliveryPartners.JT:
        allowStatuses = [
          ORDER_STATUS.WaitingConfirm.code,
          ORDER_STATUS.WaitingTakeOrder.code,
          ORDER_STATUS.PickedUp.code,
          ORDER_STATUS.Transporting.code,
          ORDER_STATUS.Stored.code,
          ORDER_STATUS.Delivering.code,
          ORDER_STATUS.DeliveryFailed.code,
        ];

        if (!allowStatuses.includes(order?.statusId?.toString())) return false;
        break;

      default:
        break;
    }
  }

  if (fieldName === "product") {
    switch (order?.partnerDeliveryId) {
      case DeliveryPartners.GHN:
      case DeliveryPartners.GHN2:
        allowStatuses = [
          ORDER_STATUS.WaitingConfirm.code,
          ORDER_STATUS.WaitingTakeOrder.code,
        ];

        if (!allowStatuses.includes(order?.statusId?.toString())) return false;
        break;

      case DeliveryPartners.GHTK:
      case DeliveryPartners.GHTK2:
      case DeliveryPartners.GHTK3:
      case DeliveryPartners.Ninjavan:
      case DeliveryPartners.BESTEXPRESS:
      case DeliveryPartners.JT:
        allowStatuses = [ORDER_STATUS.WaitingConfirm];

        if (!allowStatuses.includes(order?.statusId?.toString())) return false;
        break;

      default:
        break;
    }
  }

  if (fieldName === "collectMoney") {
    switch (order?.partnerDeliveryId) {
      case DeliveryPartners.GHTK:
      case DeliveryPartners.GHTK2:
      case DeliveryPartners.GHTK3:
      case DeliveryPartners.GHN:
      case DeliveryPartners.GHN2:
      case DeliveryPartners.Ninjavan:
      case DeliveryPartners.BESTEXPRESS:
      case DeliveryPartners.JT:
        allowStatuses = [
          ORDER_STATUS.WaitingConfirm.code,
          ORDER_STATUS.WaitingTakeOrder.code,
          ORDER_STATUS.PickedUp.code,
          ORDER_STATUS.Transporting.code,
          ORDER_STATUS.Stored.code,
          ORDER_STATUS.Delivering.code,
        ];

        if (!allowStatuses.includes(order?.statusId?.toString())) return false;
        break;

      default:
        break;
    }
  }

  if (fieldName === "insurranceAmount") {
    switch (order?.partnerDeliveryId) {
      case DeliveryPartners.GHTK:
      case DeliveryPartners.GHTK2:
      case DeliveryPartners.GHTK3:
      case DeliveryPartners.GHN:
      case DeliveryPartners.GHN2:
      case DeliveryPartners.Ninjavan:
      case DeliveryPartners.BESTEXPRESS:
      case DeliveryPartners.JT:
        allowStatuses = [
          ORDER_STATUS.WaitingConfirm.code,
          ORDER_STATUS.WaitingTakeOrder.code,
        ];

        if (!allowStatuses.includes(order?.statusId?.toString())) return false;
        break;

      default:
        break;
    }
  }

  if (
    fieldName === "weight" ||
    fieldName === "size" ||
    fieldName === "length" ||
    fieldName === "width" ||
    fieldName === "height"
  ) {
    switch (order?.partnerDeliveryId) {
      case DeliveryPartners.GHTK:
      case DeliveryPartners.GHTK2:
      case DeliveryPartners.GHTK3:
      case DeliveryPartners.GHN:
      case DeliveryPartners.GHN2:
      case DeliveryPartners.Ninjavan:
      case DeliveryPartners.BESTEXPRESS:
      case DeliveryPartners.JT:
        allowStatuses = [
          ORDER_STATUS.WaitingConfirm.code,
          ORDER_STATUS.WaitingTakeOrder.code,
        ];

        if (!allowStatuses.includes(order?.statusId?.toString())) return false;
        break;

      default:
        break;
    }
  }

  if (fieldName === "shopCode") {
    switch (order?.partnerDeliveryId) {
      case DeliveryPartners.GHTK:
      case DeliveryPartners.GHTK2:
      case DeliveryPartners.GHTK3:
      case DeliveryPartners.GHN:
      case DeliveryPartners.GHN2:
      case DeliveryPartners.Ninjavan:
      case DeliveryPartners.BESTEXPRESS:
      case DeliveryPartners.JT:
        allowStatuses = [
          ORDER_STATUS.WaitingConfirm.code,
          ORDER_STATUS.WaitingTakeOrder.code,
          ORDER_STATUS.PickedUp.code,
          ORDER_STATUS.Transporting.code,
          ORDER_STATUS.Stored.code,
          ORDER_STATUS.Delivering.code,
        ];

        if (!allowStatuses.includes(order?.statusId?.toString())) return false;
        break;

      default:
        break;
    }
  }

  if (fieldName === "note") {
    switch (order?.partnerDeliveryId) {
      case DeliveryPartners.GHTK:
      case DeliveryPartners.GHTK2:
      case DeliveryPartners.GHTK3:
      case DeliveryPartners.GHN:
      case DeliveryPartners.GHN2:
      case DeliveryPartners.Ninjavan:
      case DeliveryPartners.BESTEXPRESS:
      case DeliveryPartners.JT:
        allowStatuses = [
          ORDER_STATUS.WaitingConfirm.code,
          ORDER_STATUS.WaitingTakeOrder.code,
          ORDER_STATUS.PickedUp.code,
          ORDER_STATUS.Transporting.code,
          ORDER_STATUS.Stored.code,
          ORDER_STATUS.Delivering.code,
        ];

        if (!allowStatuses.includes(order?.statusId?.toString())) return false;
        break;

      default:
        break;
    }
  }

  if (fieldName === "voucher") {
    switch (order?.partnerDeliveryId) {
      case DeliveryPartners.GHTK:
      case DeliveryPartners.GHTK2:
      case DeliveryPartners.GHTK3:
      case DeliveryPartners.GHN:
      case DeliveryPartners.GHN2:
      case DeliveryPartners.Ninjavan:
      case DeliveryPartners.BESTEXPRESS:
      case DeliveryPartners.JT:
        allowStatuses = [ORDER_STATUS.WaitingConfirm.code];

        if (!allowStatuses.includes(order?.statusId?.toString())) return false;
        break;

      default:
        break;
    }
  }

  if (fieldName === "deliveryPartner") {
    switch (order?.partnerDeliveryId) {
      case DeliveryPartners.GHTK:
      case DeliveryPartners.GHTK2:
      case DeliveryPartners.GHTK3:
      case DeliveryPartners.GHN:
      case DeliveryPartners.GHN2:
      case DeliveryPartners.Ninjavan:
      case DeliveryPartners.BESTEXPRESS:
      case DeliveryPartners.JT:
        allowStatuses = [ORDER_STATUS.WaitingConfirm];

        if (!allowStatuses.includes(order?.statusId?.toString())) return false;
        break;

      default:
        break;
    }
  }

  if (fieldName === "deliveryInstruction") {
    switch (order?.partnerDeliveryId) {
      case DeliveryPartners.GHTK:
      case DeliveryPartners.GHTK2:
      case DeliveryPartners.GHTK3:
      case DeliveryPartners.GHN:
      case DeliveryPartners.GHN2:
      case DeliveryPartners.Ninjavan:
      case DeliveryPartners.BESTEXPRESS:
      case DeliveryPartners.JT:
        allowStatuses = [
          ORDER_STATUS.WaitingConfirm.code,
          ORDER_STATUS.WaitingTakeOrder.code,
          ORDER_STATUS.PickedUp.code,
          ORDER_STATUS.Transporting.code,
          ORDER_STATUS.Stored.code,
          ORDER_STATUS.Delivering.code,
        ];

        if (!allowStatuses.includes(order?.statusId?.toString())) return false;
        break;

      default:
        break;
    }
  }

  if (fieldName === "isCustomerPaid") {
    switch (order?.partnerDeliveryId) {
      case DeliveryPartners.GHTK:
      case DeliveryPartners.GHTK2:
      case DeliveryPartners.GHTK3:
      case DeliveryPartners.GHN:
      case DeliveryPartners.GHN2:
      case DeliveryPartners.Ninjavan:
      case DeliveryPartners.BESTEXPRESS:
      case DeliveryPartners.JT:
        allowStatuses = [
          ORDER_STATUS.WaitingConfirm.code,
          ORDER_STATUS.WaitingTakeOrder.code,
          ORDER_STATUS.PickedUp.code,
          ORDER_STATUS.Transporting.code,
          ORDER_STATUS.Stored.code,
          ORDER_STATUS.Delivering.code,
        ];

        if (!allowStatuses.includes(order?.statusId?.toString())) return false;
        break;

      default:
        break;
    }
  }

  if (
    fieldName === "partialDeliverySelection" ||
    fieldName === "partialDeliveryReturn" ||
    fieldName === "callbackShop" ||
    fieldName === "notDeliveredCollection"
  ) {
    switch (order?.partnerDeliveryId) {
      case DeliveryPartners.GHTK:
      case DeliveryPartners.GHTK2:
      case DeliveryPartners.GHTK3:
      case DeliveryPartners.GHN:
      case DeliveryPartners.GHN2:
      case DeliveryPartners.Ninjavan:
      case DeliveryPartners.BESTEXPRESS:
      case DeliveryPartners.JT:
        allowStatuses = [
          ORDER_STATUS.WaitingConfirm.code,
          ORDER_STATUS.WaitingTakeOrder.code,
          ORDER_STATUS.PickedUp.code,
          ORDER_STATUS.Transporting.code,
          ORDER_STATUS.Stored.code,
          ORDER_STATUS.Delivering.code,
        ];

        if (!allowStatuses.includes(order?.statusId?.toString())) return false;
        break;

      default:
        break;
    }
  }

  if (
    fieldName === "isFragileItem" ||
    fieldName === "isAgriculturalItem" ||
    fieldName === "isSealedProduct" ||
    fieldName === "isDocument"
  ) {
    switch (order?.partnerDeliveryId) {
      case DeliveryPartners.GHTK:
      case DeliveryPartners.GHTK2:
      case DeliveryPartners.GHTK3:
      case DeliveryPartners.GHN:
      case DeliveryPartners.GHN2:
      case DeliveryPartners.Ninjavan:
      case DeliveryPartners.BESTEXPRESS:
      case DeliveryPartners.JT:
        allowStatuses = [ORDER_STATUS.WaitingConfirm.code];

        if (!allowStatuses.includes(order?.statusId?.toString())) return false;
        break;

      default:
        break;
    }
  }

  return true;
};
