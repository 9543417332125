import {
  Checkbox,
  Divider,
  Input,
  Modal,
  Radio,
  Select,
  Spin,
  message,
} from "antd";
// import { NumericFormat } from "react-number-format";
import { IoCaretDown } from "react-icons/io5";
import { FaRegTrashCan } from "react-icons/fa6";
import {
  fieldOrderCheck,
  formatNumber,
  isOrderDeliveringStatus,
  phoneRegExp,
  removeVietnameseTones,
} from "@helper/helper";
import { StyledSelect } from "./styles";
import { useEffect, useState } from "react";
import {
  getCODStatus,
  getOrderDetails,
  getPriceDeliveryv2,
  getPriceShopDelivery,
  getsAvailableShopLocations,
  getsByPhone,
  renewOrder,
  searchItemToOrder,
  updateOrder,
} from "@services/order-service";
import { useOutsideClick } from "@hooks/useOutsideClick";
import { useDebounce } from "use-debounce";
import {
  getAddressLevelFourToWard,
  getDistrictToProvince,
  getProvince,
  getWardToDistrict,
} from "@services/geo-services";
import { getCalculateOrderAmount } from "@services/send-request";
import {
  DeliveryPartners,
  ORDER_STATUS,
  ResultCode,
} from "constants/constants";
import VoucherCodeModal from "./VoucherCodeModal";
import noProduct from "@assets/image/shop-management/camera.svg";

export const checkingGoodsDefault = [
  { id: 1, title: "Không cho xem hàng" },
  { id: 2, title: "Cho xem hàng không thử" },
  { id: 3, title: "Cho thử hàng" },
];

const EditOrderModal = ({ open, onCancel, order, onRefreshData }: any) => {
  const [loading, setLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState<any>();
  const [shopLocations, setShopLocations] = useState<any[]>([]);
  const [openPhoneNumber, setOpenPhoneNumber] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberDebounce] = useDebounce(phoneNumber, 700);
  const [customerFilters, setcustomerFilters] = useState<any[]>([]);
  const [provinces, setProvinces] = useState<any[]>([]);
  const [districts, setDistricts] = useState<any[]>([]);
  const [wards, setWards] = useState<any[]>([]);
  const [addressLevelFours, setAddressLevelFours] = useState<any[]>([]);
  const [productList, setProductList] = useState<any[]>([]);
  const [searchProduct, setSearchProduct] = useState("");
  const [searchProductDebounce] = useDebounce(searchProduct, 700);
  const [openProductList, setOpenProductList] = useState(false);
  const [deliveryPartner, setDeliveryPartner] = useState<any[]>([]);
  const [shopDeliveryPartner, setShopDeliveryPartner] = useState<any[]>([]);
  const [isPriceLoading, setIsPriceLoading] = useState(false);
  const [codStatus, setcodStatus] = useState<any>();
  const [calculateOrderAmount, setCalculateOrderAmount] = useState<any>();
  const [isInitialize, setIsInitialize] = useState(false);
  const [deliveryPartnerMappingView, setDeliveryPartnerMappingView] =
    useState("");
  const [isChanged, setIsChanged] = useState(false);
  const [openGetVoucherCode, setOpenGetVoucherCode] = useState(false);
  const [previousNotDeliveredFee, setPreviousNotDeliveredFee] = useState(0);
  const [enableControl, setEnableControl] = useState<any>({
    senderAddress: undefined,
    phoneNumber: undefined,
    customerName: undefined,
    receiverAddress: undefined,
    province: undefined,
    district: undefined,
    ward: undefined,
    l4Address: undefined,
    product: undefined,
    productType: undefined,
    collectMoney: undefined,
    weight: undefined,
    size: undefined,
    length: 10,
    width: 10,
    height: 10,
    insurranceAmount: undefined,
    shopCode: undefined,
    note: undefined,
    voucher: undefined,
    deliveryPartner: undefined,
    deliveryInstruction: undefined,
    isCustomerPaid: undefined,
    partialDeliverySelection: undefined,
    partialDeliveryReturn: undefined,
    callbackShop: undefined,
    notDeliveredCollection: undefined,
    isFragileItem: undefined,
    isAgriculturalItem: undefined,
    isSealedProduct: undefined,
    isDocument: undefined,
    updateButton: undefined,
  });

  const handleClickOutside = () => {
    setOpenPhoneNumber(false);
  };
  const ref: any = useOutsideClick(handleClickOutside);

  const handleClickOutsideProduct = () => {
    setOpenProductList(false);
  };
  const refProduct: any = useOutsideClick(handleClickOutsideProduct);

  const choosingCustomer = async (item: any) => {
    const phoneNumber = item.phoneNumber.trim();
    const { fullName, email, address, provinceId, districtId, wardId } = item;
    setOrderDetails({
      ...orderDetails,
      phoneNumber,
      fullName,
      email,
      address,
      provinceId,
      districtId,
      wardId,
    });
    setOpenPhoneNumber(false);
  };

  const onChangeOrderDetails = (name: string, value: any) => {
    setOrderDetails({ ...orderDetails, [name]: value });
  };

  const getPriceByPartner = () => {
    const price = (
      orderDetails?.deliveryOwner === 1
        ? deliveryPartner
        : shopDeliveryPartner || []
    ).find((p) => p.id === orderDetails?.partnerDeliveryId);
    if (price === undefined) {
      return {};
    } else {
      return price;
    }
  };

  const processCalculateOrderAmount = async (order = orderDetails) => {
    if (order?.partnerDeliveryId > 0) {
      const deliveryAmount = getPriceByPartner()?.deliveryPrice || 0;
      setOrderDetails({ ...order, deliveryAmount });
      const res = await getCalculateOrderAmount(order);
      if (res && res?.data?.result === ResultCode.Success) {
        setCalculateOrderAmount(res?.data?.data);
      } else {
        message.error(res?.data?.errorMessage);
      }
    }
  };

  const getPriceDelivery = async (
    shopLocationId: any,
    shopLocationList = shopLocations
  ) => {
    setIsPriceLoading(true);
    const location = shopLocationList.find((l) => l.id === shopLocationId);
    let order = { ...orderDetails };
    if (location) {
      const shopLocationName = location.name;
      const shopLocationAddress = location.address;
      const shopLocationProvinceId = location.provinceId;
      const shopLocationProvinceName = location.provinceName;
      const shopLocationDistrictId = location.districtId;
      const shopLocationDistrictName = location.districtName;
      const shopLocationWardId = location.wardId;
      const shopLocationWardName = location.wardName;
      const shopLocationPhoneNumber = location.phoneNumber;
      order = {
        ...orderDetails,
        shopLocationId,
        shopLocationName,
        shopLocationAddress,
        shopLocationProvinceId,
        shopLocationProvinceName,
        shopLocationDistrictId,
        shopLocationDistrictName,
        shopLocationWardId,
        shopLocationWardName,
        shopLocationPhoneNumber,
      };
    }
    if (!orderDetails?.weight || location === undefined) {
      setIsPriceLoading(false);
      return 0;
    }
    let deliveryPartner: any[] = [];
    let shopDeliveryPartner: any[] = [];
    const priceDelivery = await getPriceDeliveryv2({
      fromProvinceId: location.provinceId,
      fromDistrictId: location.districtId,
      toProvinceId: orderDetails?.provinceId,
      toDistrictId: orderDetails?.districtId,
      weight: orderDetails?.weight,
      isCustomerPaid: orderDetails?.isCustomerPaid,
      collectingMoney: orderDetails?.collectMoney,
      length: orderDetails?.length,
      width: orderDetails?.width,
      height: orderDetails?.height,
      insurranceAmount: orderDetails?.insurranceAmount,
      userId: orderDetails?.userId || 0,
      orderType: null,
      modifyOrderDeliveryPartnerId:
        orderDetails?.statusId?.toString() === ORDER_STATUS.WaitingConfirm.code
          ? 0
          : orderDetails?.partnerDeliveryId,
    });
    if (priceDelivery && priceDelivery?.code === "Success") {
      deliveryPartner = priceDelivery?.data;
    } else {
      message.error(priceDelivery.errorMessage);
    }
    const priceShopDelivery = await getPriceShopDelivery({
      fromProvinceId: location.provinceId,
      fromDistrictId: location.districtId,
      toProvinceId: orderDetails?.provinceId,
      toDistrictId: orderDetails?.districtId,
      weight: orderDetails?.weight,
      isCustomerPaid: orderDetails?.isCustomerPaid,
      collectingMoney: orderDetails?.collectMoney,
      length: orderDetails?.length,
      width: orderDetails?.width,
      height: orderDetails?.height,
      insurranceAmount: orderDetails?.insurranceAmount,
      userId: orderDetails?.userId || 0,
    });
    if (priceShopDelivery && priceShopDelivery?.code === "Success") {
      shopDeliveryPartner = priceShopDelivery?.data;
    } else {
      message.error(priceShopDelivery.errorMessage);
    }
    if (
      enableControl.deliveryPartner !== undefined &&
      orderDetails?.partnerDeliveryId !== undefined &&
      orderDetails?.partnerDeliveryId > 0
    ) {
      deliveryPartner = deliveryPartner.filter(
        (e) => e.id === orderDetails?.partnerDeliveryId
      );
    }
    if (
      enableControl.deliveryPartner !== undefined &&
      orderDetails?.partnerDeliveryId !== undefined &&
      orderDetails?.partnerDeliveryId > 0
    ) {
      shopDeliveryPartner = shopDeliveryPartner.filter(
        (e) => e.id === orderDetails?.partnerDeliveryId
      );
    }
    setDeliveryPartner(deliveryPartner);
    setShopDeliveryPartner(shopDeliveryPartner);
    const isMoneyCollection =
      codStatus?.restCOD >= getPriceByPartner()?.shopPay &&
      orderDetails?.partnerDeliveryId &&
      orderDetails?.deliveryOwner === 1 &&
      orderDetails?.isMoneyCollection;
    order = {
      ...order,
      isMoneyCollection,
    };
    processCalculateOrderAmount(order);
    setIsPriceLoading(false);
  };

  const onShopLocationChange = (shopLocationId: any) => {
    const location = shopLocations.find((l) => l.id === shopLocationId);
    if (location) {
      getPriceDelivery(shopLocationId);
    }
  };

  const handleChangeProvince = (e: any) => {
    setOrderDetails({
      ...orderDetails,
      provinceId: e,
      districtId: undefined,
      wardId: undefined,
      addressLevel4Id: undefined,
    });
  };

  const handleChangeDistrict = (e: any) => {
    setOrderDetails({
      ...orderDetails,
      districtId: e,
      wardId: undefined,
      addressLevel4Id: undefined,
    });
  };

  const handleChangeWard = (e: any) => {
    setOrderDetails({
      ...orderDetails,
      wardId: e,
      addressLevel4Id: undefined,
    });
  };

  const handleChangeAddressLevelFour = (e: any) => {
    const address = addressLevelFours.find((item) => item?.id === e);
    setOrderDetails({
      ...orderDetails,
      addressLevel4Id: address?.id,
    });
  };

  const choosingProduct = (product: any) => {
    const checkItem = orderDetails?.items?.find(
      (item: any) => item.Id === product.id
    );
    if (checkItem) {
      const increase = orderDetails?.items?.map((item: any) => {
        if (item.Id === product.id)
          return {
            ...item,
            Quantity: Number(item.Quantity) + 1,
          };
        return item;
      });
      setOrderDetails({ ...orderDetails, items: increase });
    } else {
      setOrderDetails({
        ...orderDetails,
        items: [
          ...orderDetails?.items,
          { ...product, Id: product.id, Name: product.name, Quantity: 1 },
        ],
      });
    }
    setOpenProductList(false);
  };

  const handleChangeQuantityOfProduct = (product: any, value: any) => {
    const increase = orderDetails?.items?.map((item: any) => {
      if (item.Id === product.Id) return { ...item, Quantity: value };
      return item;
    });
    setOrderDetails({ ...orderDetails, items: increase });
  };

  const handleRemoveProduct = (product: any) => {
    const increase = orderDetails?.items?.filter(
      (item: any) => item.Id !== product.Id
    );
    setOrderDetails({ ...orderDetails, items: increase });
  };

  const onWeightChange = () => {
    if (!orderDetails?.weight) {
      return;
    }
    getPriceDelivery(orderDetails?.shopLocationId);
  };

  const onSizeChange = () => {
    if (
      !orderDetails?.height ||
      !orderDetails?.length ||
      !orderDetails?.width
    ) {
      return;
    }
    getPriceDelivery(orderDetails?.shopLocationId);
  };

  const onInsuranceAmountChanged = () => {
    if (!isInitialize || orderDetails?.insurranceAmount) {
      processCalculateOrderAmount();
    }
  };

  const onShopCodeKeyPress = (event: KeyboardEvent) => {
    const regExp = new RegExp("^[a-zA-Z0-9+]*$");
    if (!regExp.test(event.key)) {
      event.preventDefault();
      return;
    }
  };

  const onShopCodeValueChanged = () => {
    const shopCode = removeVietnameseTones(orderDetails?.shopCode);
    setOrderDetails({ ...orderDetails, shopCode });
  };

  const onOpenChoosingVoucher = () => {
    if (orderDetails?.deliveryOwner !== 1) {
      return;
    }
    setOpenGetVoucherCode(true);
  };

  const onChoosingVoucher = (voucher: any) => {
    const order = {
      ...orderDetails,
      voucherCode: voucher?.code,
      voucherData: {
        voucher,
      },
    };
    setOrderDetails(order);
    processCalculateOrderAmount(order);
    setOpenGetVoucherCode(false);
  };

  const getPriceVoucher = () => {
    if (
      !orderDetails?.voucherData ||
      !orderDetails?.voucherData?.voucher ||
      orderDetails?.partnerDeliveryId === 5
    ) {
      return 0;
    } else if (orderDetails?.voucherData?.voucher.typeValue === 1) {
      return (
        (orderDetails?.deliveryAmount -
          orderDetails?.voucherData.voucher.value <
        0
          ? orderDetails?.deliveryAmount
          : orderDetails?.voucherData.voucher.value) * -1
      );
    } else if (orderDetails?.voucherData.voucher.typeValue === 2) {
      return (
        ((orderDetails?.deliveryAmount / 100) *
          orderDetails?.voucherData.voucher.value >
        orderDetails?.voucherData.voucher.maximum
          ? orderDetails?.voucherData.voucher.maximum
          : (orderDetails?.deliveryAmount / 100) *
            orderDetails?.voucherData.voucher.value) * -1
      );
    }
  };

  const changedPartnerDelivery = (event: any) => {
    const partnerDeliveryId = event.partnerDeliveryId;
    const deliveryOwner = event.deliveryOwner;
    const changeDeliveryPartner = (deliveryPartner || []).find(
      (x) => x.id === partnerDeliveryId
    );
    const deliveryAmount =
      deliveryOwner === 1 && changeDeliveryPartner
        ? changeDeliveryPartner?.deliveryPrice
        : 0;
    let voucherData;
    let voucherCode;
    const isMoneyCollection =
      codStatus.restCOD >= getPriceByPartner()?.shopPay &&
      deliveryOwner === 1 &&
      orderDetails?.isMoneyCollection;
    if (
      partnerDeliveryId === 5 ||
      deliveryOwner !== 1
    ) {
      voucherData = { voucher: undefined };
      voucherCode = "";
    }
    const order = {
      ...orderDetails,
      partnerDeliveryId,
      deliveryOwner,
      deliveryAmount,
      isMoneyCollection,
      voucherCode,
      voucherData,
    };
    setOrderDetails(order);
    processCalculateOrderAmount(order);
  };

  const onTypeShowItemChanged = (value: any) => {
    if (!isInitialize) {
      const order = { ...orderDetails, typeShowItem: value };
      processCalculateOrderAmount(order);
    }
  };

  const addNote = (value: string, newNote = "") => {
    if (!orderDetails?.note?.includes(value)) {
      return newNote
        ? newNote + ", " + value
        : orderDetails?.note
        ? orderDetails?.note + ", " + value
        : value;
    }
    return orderDetails?.note;
  };

  const removeNote = (value: string) => {
    let note = orderDetails?.note;
    if (orderDetails?.note?.includes(value)) {
      note = orderDetails?.note?.replace(", " + value, "");
      note = note?.replace(value, "");
      note = note?.replace(new RegExp("^[" + "," + "]*"), "");
      note = note?.replace(new RegExp("[" + "," + "]*$"), "");
      note = note?.trim();
    }
    return note;
  };

  const onCustomerPaidChanged = (value: any) => {
    if (!isInitialize) {
      const order = { ...orderDetails, isCustomerPaid: value };
      processCalculateOrderAmount(order);
    }
  };

  const onPartialDeliverySelectionChanged = (value: any) => {
    let note = "";
    if (!value) {
      note = removeNote("Giao hàng 1 phần chọn sản phẩm");
    }
    if (
      value &&
      orderDetails?.partnerDeliveryId !== DeliveryPartners.GHTK &&
      orderDetails?.partnerDeliveryId !== DeliveryPartners.GHTK2 &&
      orderDetails?.partnerDeliveryId !== DeliveryPartners.GHTK3
    ) {
      note = addNote("Giao hàng 1 phần chọn sản phẩm");
    }
    setOrderDetails({
      ...orderDetails,
      partialDeliverySelection: value,
      note,
    });
  };

  const onPartialDeliveryReturnChanged = (value: any) => {
    let note = "";
    if (!value) {
      note = removeNote("Giao hàng 1 phần đối trả hàng");
    }
    if (
      value &&
      orderDetails?.partnerDeliveryId !== DeliveryPartners.GHTK &&
      orderDetails?.partnerDeliveryId !== DeliveryPartners.GHTK2 &&
      orderDetails?.partnerDeliveryId !== DeliveryPartners.GHTK3
    ) {
      note = addNote("Giao hàng 1 phần đối trả hàng");
    }
    setOrderDetails({
      ...orderDetails,
      partialDeliveryReturn: value,
      note,
    });
  };

  const onCallbackShopChanged = (value: any) => {
    let note = "";
    if (!value) {
      note = removeNote(
        "Gọi shop khi khách không nhận hàng, không liên lạc được, sai thông tin"
      );
    }
    if (
      value &&
      orderDetails?.partnerDeliveryId !== DeliveryPartners.GHTK &&
      orderDetails?.partnerDeliveryId !== DeliveryPartners.GHTK2 &&
      orderDetails?.partnerDeliveryId !== DeliveryPartners.GHTK3
    ) {
      note = addNote(
        "Gọi shop khi khách không nhận hàng, không liên lạc được, sai thông tin"
      );
    }
    setOrderDetails({
      ...orderDetails,
      callbackShop: value,
      note,
    });
  };

  const onNotDeliveredCollectionChanged = (value: any) => {
    let note = "";
    if (!value) {
      note = removeNote(
        "Không giao được thu " + previousNotDeliveredFee.toString()
      );
    }
    if (
      value &&
      orderDetails?.partnerDeliveryId !== DeliveryPartners.GHTK &&
      orderDetails?.partnerDeliveryId !== DeliveryPartners.GHTK2 &&
      orderDetails?.partnerDeliveryId !== DeliveryPartners.GHTK3
    ) {
      note = addNote(
        "Không giao được thu " + previousNotDeliveredFee.toString()
      );
    }
    setOrderDetails({
      ...orderDetails,
      notDeliveredCollection: value,
      note,
    });
  };

  const onNotDeliveredFeeChanged = () => {
    let note = removeNote(
      "Không giao được thu " + previousNotDeliveredFee.toString()
    );
    if (
      orderDetails?.partnerDeliveryId !== DeliveryPartners.GHTK &&
      orderDetails?.partnerDeliveryId !== DeliveryPartners.GHTK2 &&
      orderDetails?.partnerDeliveryId !== DeliveryPartners.GHTK3
    ) {
      note = addNote(
        "Không giao được thu " + orderDetails?.notDeliveredFee.toString(),
        note
      );
    }
    setPreviousNotDeliveredFee(orderDetails?.notDeliveredFee);
    setOrderDetails({
      ...orderDetails,
      note,
    });
  };

  const onIsSealedProductChanged = (value: any) => {
    if (!isInitialize) {
      let note = "";
      if (!value) {
        note = removeNote("Hàng nguyên hộp");
      }
      if (
        value &&
        orderDetails?.partnerDeliveryId !== DeliveryPartners.GHTK &&
        orderDetails?.partnerDeliveryId !== DeliveryPartners.GHTK2 &&
        orderDetails?.partnerDeliveryId !== DeliveryPartners.GHTK3
      ) {
        note = addNote("Hàng nguyên hộp");
      }
      const order = { ...orderDetails, isSealedProduct: value, note };
      setOrderDetails(order);
      processCalculateOrderAmount(order);
    }
  };

  const onIsDocumentChanged = (value: any) => {
    let note = "";
    if (!value) {
      note = removeNote("Thư tín, tài liệu");
    }
    if (
      value &&
      orderDetails?.partnerDeliveryId !== DeliveryPartners.GHTK &&
      orderDetails?.partnerDeliveryId !== DeliveryPartners.GHTK2 &&
      orderDetails?.partnerDeliveryId !== DeliveryPartners.GHTK3
    ) {
      note = addNote("Thư tín, tài liệu");
    }
    const order = { ...orderDetails, isDocument: value, note };
    setOrderDetails(order);
  };

  const onIsFragileItemChanged = (value: any) => {
    if (!isInitialize) {
      let note = "";
      if (!value) {
        note = removeNote("Hàng hóa dễ vỡ");
      }
      if (
        value &&
        orderDetails?.partnerDeliveryId !== DeliveryPartners.GHTK &&
        orderDetails?.partnerDeliveryId !== DeliveryPartners.GHTK2 &&
        orderDetails?.partnerDeliveryId !== DeliveryPartners.GHTK3
      ) {
        note = addNote("Hàng hóa dễ vỡ");
      }
      const order = { ...orderDetails, isFragileItem: value, note };
      setOrderDetails(order);
      processCalculateOrderAmount(order);
    }
  };

  const onIsAgriculturalItemChanged = (value: any) => {
    if (!isInitialize) {
      let note = "";
      if (!value) {
        note = removeNote("Nông sản/ thực phẩm khô");
      }
      if (
        value &&
        orderDetails?.partnerDeliveryId !== DeliveryPartners.GHTK &&
        orderDetails?.partnerDeliveryId !== DeliveryPartners.GHTK2 &&
        orderDetails?.partnerDeliveryId !== DeliveryPartners.GHTK3
      ) {
        note = addNote("Nông sản/ thực phẩm khô");
      }
      const order = { ...orderDetails, isAgriculturalItem: value, note };
      setOrderDetails(order);
      processCalculateOrderAmount(order);
    }
  };

  const validation = () => {
    if (!phoneRegExp.test(orderDetails?.phoneNumber)) {
      message.error("Số điện thoại không đúng định nghĩa");
      return false;
    }
    if (orderDetails?.fullName === "") {
      message.error("Tên khách hàng không được bỏ trống");
      return false;
    }
    if (orderDetails?.address === "") {
      message.error("Địa chỉ không được bỏ trống");
      return false;
    }
    if (orderDetails?.provinceId === 0) {
      message.error("Tỉnh thành không được bỏ trống");
      return false;
    }
    if (orderDetails?.districtId === 0) {
      message.error("Quận huyện không được bỏ trống");
      return false;
    }
    if (orderDetails?.weight <= 0) {
      message.error("Khối lượng phải lớn hơn 0");
      return false;
    }

    if (orderDetails?.items?.length === 0) {
      message.error("Nội dung hàng hóa không được bỏ trống");
      return false;
    }
    if (orderDetails?.partnerDeliveryId === undefined) {
      message.error("Chưa chọn đối tác giao hàng");
      return false;
    }
    if (
      orderDetails?.partnerDeliveryId === 7 &&
      !orderDetails?.isCustomerPaid &&
      orderDetails?.collectMoney > 0 &&
      orderDetails?.collectMoney <= 5000
    ) {
      let msg =
        "Đối với đối tác Viettel Post, số tiền thu hộ phải lớn hơn 5,000";
      message.error(msg);
      return false;
    }

    if (
      orderDetails?.partnerDeliveryId !== 5 &&
      orderDetails?.voucherCode &&
      !orderDetails?.voucherData?.voucher
    ) {
      message.error("Mã giảm giá không hợp lệ");
      return false;
    }
    return true;
  };

  const processUpdateInfo = (rs: any) => {
    switch (rs.result) {
      case ResultCode.Success: {
        if (
          orderDetails?.statusId?.toString() ===
            ORDER_STATUS.WaitingTakeOrder.code ||
          isOrderDeliveringStatus(orderDetails?.statusId?.toString())
        ) {
          if (
            orderDetails?.partnerDeliveryId === DeliveryPartners.GHN ||
            orderDetails?.partnerDeliveryId === DeliveryPartners.GHN2
          ) {
            message.success("Cập nhật thành công");
          } else {
            message.success(
              ` Yêu Cầu Thay Đổi Thông Tin Đơn Hàng ${orderDetails?.code} CSKH Đã Tiếp Nhận, Vui Lòng Chờ Ít Phút`
            );
          }
        } else {
          message.success("Cập nhật thành công");
        }
        onRefreshData();
        onCancel();
        break;
      }
      case ResultCode.CODError: {
        message.error("Cập nhật COD bị lỗi: " + rs.errorMessage);
        onRefreshData();
        onCancel();
        break;
      }
      case ResultCode.CSError: {
        message.error(
          `Cập nhật chính sách giao hàng bị lỗi: ` + rs.errorMessage
        );
        onRefreshData();
        onCancel();
        break;
      }
      case ResultCode.CODAndCSError: {
        message.error(
          `Cập nhật COD & chính sách giao hàng bị lỗi: ` + rs.errorMessage
        );
        onRefreshData();
        onCancel();
        break;
      }
      case ResultCode.GHNUpdateError: {
        message.error(rs.errorMessage);
        onRefreshData();
        onCancel();
        break;
      }
      default: {
        message.error(rs.errorMessage);
        break;
      }
    }
  };

  const changeOrderInfo = async () => {
    if (!validation()) {
      return;
    }
    try {
      const res = order?.isRenew
        ? await renewOrder(orderDetails)
        : await updateOrder(orderDetails);
      if (res && res?.data) {
        processUpdateInfo(res.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getProvinceName = (id: number) => {
    const province = provinces.filter((e) => e.id === id)[0];
    if (province === undefined) {
      return "";
    }
    return province.name;
  };

  const getDistrictName = (id: number) => {
    const district = districts.filter((e) => e.id === id)[0];
    if (district === undefined) {
      return "";
    }
    return district.name;
  };

  const getWardName = (id: number) => {
    const ward = wards.find((e) => e.id === id);
    if (!ward) {
      return "";
    }
    return ward.name;
  };

  const getDeliveryInstruction = (id: number) => {
    const deliveryInstruction = checkingGoodsDefault.filter(
      (e) => e.id === id
    )[0];
    if (deliveryInstruction === undefined) {
      return "";
    }
    return deliveryInstruction.title;
  };

  const getAddressL4Name = (order: any) => {
    if (
      order.provinceId !== 0 &&
      order.districtId !== 0 &&
      order.wardId !== 0
    ) {
      return "Disable";
    }
    return undefined;
  };

  const getSenderAddress = (order: any) => {
    return `${order?.shopLocationName} (${order?.shopLocationAddress} - ${order?.shopLocationWardName}, ${order?.shopLocationDistrictName}, ${order?.shopLocationProvinceName})`;
  };

  const isGH1PEnable = (partnerId: number) => {
    let isEnable = false;
    if (
      partnerId === DeliveryPartners.GHN ||
      partnerId === DeliveryPartners.GHN2 ||
      partnerId === DeliveryPartners.Ninjavan ||
      partnerId === DeliveryPartners.JT
    ) {
      isEnable = true;
    }

    return isEnable;
  };

  const loadControl = (order: any) => {
    const control = { ...enableControl };
    if (order.statusId.toString() === ORDER_STATUS.WaitingConfirm.code) {
      return;
    }
    if (!fieldOrderCheck("senderAddress", order)) {
      control.senderAddress = getSenderAddress(order);
    }
    if (!fieldOrderCheck("phoneNumber", order)) {
      control.phoneNumber =
        order.phoneNumber === undefined ? "" : order.phoneNumber;
    }
    if (!fieldOrderCheck("customerName", order)) {
      control.customerName = order.fullName === undefined ? "" : order.fullName;
    }
    if (!fieldOrderCheck("receiverAddress", order)) {
      control.receiverAddress =
        order.address === undefined ? "" : order.address;
    }
    if (!fieldOrderCheck("province", order)) {
      control.province = getProvinceName(order.provinceId);
    }
    if (!fieldOrderCheck("district", order)) {
      control.district = getDistrictName(order.districtId);
    }
    if (!fieldOrderCheck("ward", order)) {
      control.ward = getWardName(order.wardId);
    }
    if (!fieldOrderCheck("l4Address", order)) {
      control.l4Address = getAddressL4Name(order);
    }
    if (!fieldOrderCheck("product", order)) {
      control.product = "Disabled";
    }
    if (!fieldOrderCheck("collectMoney", order)) {
      control.collectMoney = order.collectMoney;
    }
    if (!fieldOrderCheck("weight", order)) {
      control.weight = order.weight;
    }
    if (!fieldOrderCheck("size", order)) {
      control.size = "Disable";
      control.length = order.length;
      control.width = order.width;
      control.height = order.height;
    }
    if (!fieldOrderCheck("insurranceAmount", order)) {
      control.insurranceAmount = order.insurranceAmount;
    }
    if (!fieldOrderCheck("shopCode", order)) {
      control.shopCode = order.shopCode === undefined ? "" : order.shopCode;
    }
    if (!fieldOrderCheck("note", order)) {
      control.note = order.note === undefined ? "" : order.note;
    }
    if (!fieldOrderCheck("voucher", order)) {
      control.voucher =
        order.voucherCode === undefined ? "" : order.voucherCode;
    }
    if (!fieldOrderCheck("deliveryPartner", order)) {
      control.deliveryPartner = "Disable";
    }
    if (!fieldOrderCheck("deliveryInstruction", order)) {
      control.deliveryInstruction = getDeliveryInstruction(order.typeShowItem);
    }
    if (!fieldOrderCheck("isCustomerPaid", order)) {
      control.isCustomerPaid = "Disable";
    }
    if (!fieldOrderCheck("partialDeliverySelection", order)) {
      control.partialDeliverySelection = "Disable";
    }
    if (!fieldOrderCheck("partialDeliveryReturn", order)) {
      control.partialDeliveryReturn = "Disable";
    }
    if (!fieldOrderCheck("callbackShop", order)) {
      control.callbackShop = "Disable";
    }
    if (!fieldOrderCheck("notDeliveredCollection", order)) {
      control.notDeliveredCollection = "Disable";
    }
    if (!fieldOrderCheck("isFragileItem", order)) {
      control.isFragileItem = "Disable";
    }
    if (!fieldOrderCheck("isAgriculturalItem", order)) {
      control.isAgriculturalItem = "Disable";
    }
    if (!fieldOrderCheck("isSealedProduct", order)) {
      control.isSealedProduct = "Disable";
    }
    if (!fieldOrderCheck("isDocument", order)) {
      control.isDocument = "Disable";
    }
    setEnableControl(control);
  };

  useEffect(() => {
    (async () => {
      if (order?.id) {
        const res = await getOrderDetails(order?.id);
        if (res) {
          const order = {
            ...res,
            items: res?.item || [],
            paidAmount: Number(res?.paidAmount),
            voucherAmount: Number(res?.paidAmount),
          };
          delete order?.item;
          setOrderDetails(order);
          setDeliveryPartnerMappingView(
            `${res?.partnerDeliveryId}_${res?.deliveryOwner}`
          );
          setPreviousNotDeliveredFee(res?.notDeliveredFee);
          setPhoneNumber(res?.phoneNumber);
          loadControl(order);
        }
      }
    })();
  }, [order?.id, open]);

  useEffect(() => {
    (async () => {
      const res = await getProvince();
      if (res) setProvinces(res);
      else {
        message.error("Không thể kết nối đến data tỉnh thành");
      }
      const codStatus = await getCODStatus();
      if (codStatus) {
        setcodStatus(codStatus);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (orderDetails?.userId) {
        setLoading(true);
        const res: any = await getsAvailableShopLocations(orderDetails?.userId);
        if (res && res?.data) {
          setShopLocations(res.data);
          getPriceDelivery(orderDetails?.shopLocationId, res.data).then(
            (res) => {
              setLoading(false);
            }
          );
        } else {
          setLoading(false);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetails?.userId]);

  useEffect(() => {
    (async () => {
      if (orderDetails?.id) {
        const customers = await getsByPhone(phoneNumberDebounce);
        if (customers && customers?.data) {
          setcustomerFilters(customers.data);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumberDebounce, orderDetails?.id]);

  useEffect(() => {
    (async () => {
      if (orderDetails?.provinceId) {
        const res = await getDistrictToProvince(orderDetails?.provinceId);
        if (res) setDistricts(res);
      }
    })();
  }, [orderDetails?.provinceId]);

  useEffect(() => {
    (async () => {
      if (orderDetails?.provinceId && orderDetails?.districtId) {
        const resWards = await getWardToDistrict(
          orderDetails?.provinceId,
          orderDetails?.districtId
        );
        if (resWards) setWards(resWards);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetails?.districtId]);

  useEffect(() => {
    (async () => {
      if (
        orderDetails?.provinceId &&
        orderDetails?.districtId &&
        orderDetails?.wardId
      ) {
        const res = await getAddressLevelFourToWard(
          orderDetails?.provinceId,
          orderDetails?.districtId,
          orderDetails?.wardId
        );
        if (res) setAddressLevelFours(res);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetails?.wardId]);

  useEffect(() => {
    (async () => {
      if (searchProductDebounce) {
        const res = await searchItemToOrder(searchProductDebounce);
        if (res) {
          setProductList(res?.data?.dataSource);
        }
      }
    })();
  }, [searchProductDebounce]);

  return (
    <Modal
      title=""
      open={open}
      onCancel={onCancel}
      footer={null}
      centered
      width={1200}
    >
      <div className="relative">
        <div>
          <div className="text-base font-semibold border-b pb-3">
            Chỉnh sửa đơn hàng
          </div>
          <div className="pb-3 max-h-[500px] md:max-h-[600px] h-full overflow-auto">
            <div className="border-solid px-2 py-4 grid sm:grid-cols-3 grid-cols-1 gap-8 ">
              <div>
                <p className="uppercase font-semibold text-base">
                  Địa chỉ lấy hàng <span className="text-red">*</span>
                </p>
                <StyledSelect
                  className="mt-2 w-full h-10 text-start first:pr-4"
                  suffixIcon={<IoCaretDown className="text-lg" />}
                  placeholder="Nhập địa chỉ lấy hàng"
                  value={
                    shopLocations?.find(
                      (item) => item.id === orderDetails?.shopLocationId
                    )?.name
                  }
                  onChange={(e) => onShopLocationChange(e)}
                  disabled={order?.isRenew || enableControl.senderAddress}
                >
                  {shopLocations?.map((address) => (
                    <Select.Option key={address.id} value={address.id}>
                      <div className="flex gap-2 items-center py-1">
                        <p className="truncate">{`${address.name} (${address.address}, ${address.wardName}, ${address.districtName}, ${address.provinceName})`}</p>
                      </div>
                    </Select.Option>
                  ))}
                </StyledSelect>
                <Divider className="my-4" />
                <div className="flex flex-col">
                  <p className="uppercase font-semibold text-base mb-2">
                    Địa chỉ giao hàng
                  </p>
                  <p className="font-semibold">Số điện thoại</p>
                  <div className="relative w-full" ref={ref}>
                    <Input
                      className="rounded-md w-full h-10"
                      placeholder="Nhập số điện thoại"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      onFocus={() => setOpenPhoneNumber(true)}
                      disabled={enableControl?.phoneNumber}
                    />
                    {openPhoneNumber && (
                      <div className="absolute left-0 top-14 z-10 w-full max-h-[200px] overflow-auto rounded-lg bg-white shadow-lg border">
                        <div className="flex flex-col items-start">
                          {customerFilters?.map((item) => (
                            <div
                              key={item?.id}
                              className={`text-left py-2 px-2 text-sm cursor-pointer hover:bg-gray-2 w-full`}
                              onClick={() => choosingCustomer(item)}
                            >
                              {`${item?.fullName} (${item.phoneNumber})`}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                  <p className="mt-2 font-semibold">Họ tên khách hàng</p>
                  <Input
                    className="rounded-md w-full h-10"
                    placeholder="Nhập họ tên"
                    value={orderDetails?.fullName}
                    onChange={(e) =>
                      onChangeOrderDetails("fullName", e.target.value)
                    }
                    disabled={enableControl.customerName}
                  />
                  <p className="mt-2 font-semibold">
                    Địa chỉ chi tiết <span className="text-red">*</span>
                  </p>
                  <Input
                    className="rounded-md w-full h-10"
                    value={orderDetails?.address}
                    onChange={(e) =>
                      onChangeOrderDetails("address", e.target.value)
                    }
                    disabled={enableControl.receiverAddress}
                  />
                  <div className="mt-2 grid grid-cols-2 gap-x-7">
                    <div>
                      <p className="font-semibold">
                        Tỉnh thành <span className="text-red">*</span>
                      </p>
                      <StyledSelect
                        className="w-full h-10 text-start first:pr-4"
                        suffixIcon={<IoCaretDown className="text-lg" />}
                        placeholder="Nhập tỉnh thành"
                        value={
                          provinces?.find(
                            (item) => item.id === orderDetails?.provinceId
                          )?.name
                        }
                        onChange={(e) => handleChangeProvince(e)}
                        disabled={enableControl.province}
                      >
                        {provinces?.map((address) => (
                          <Select.Option key={address.id} value={address.id}>
                            <div className="flex gap-2 items-center py-1">
                              <p className="truncate">{address.name}</p>
                            </div>
                          </Select.Option>
                        ))}
                      </StyledSelect>
                    </div>
                    <div>
                      <p className="font-semibold">
                        Quận huyện <span className="text-red">*</span>
                      </p>
                      <StyledSelect
                        className="w-full h-10 text-start first:pr-4"
                        suffixIcon={<IoCaretDown className="text-lg" />}
                        placeholder="Nhập quận huyện"
                        value={
                          districts?.find(
                            (item) => item.id === orderDetails?.districtId
                          )?.name
                        }
                        onChange={(e) => handleChangeDistrict(e)}
                        disabled={enableControl.district}
                      >
                        {districts?.map((address) => (
                          <Select.Option key={address.id} value={address.id}>
                            <div className="flex gap-2 items-center py-1">
                              <p className="truncate">{address.name}</p>
                            </div>
                          </Select.Option>
                        ))}
                      </StyledSelect>
                    </div>
                  </div>
                  <p className="mt-2 font-semibold">
                    Phường xã <span className="text-red">*</span>
                  </p>
                  <StyledSelect
                    className="w-full h-10 text-start first:pr-4"
                    suffixIcon={<IoCaretDown className="text-lg" />}
                    placeholder="Nhập phường xã"
                    value={
                      wards?.find((item) => item.id === orderDetails?.wardId)
                        ?.name
                    }
                    onChange={(e) => handleChangeWard(e)}
                    disabled={enableControl.ward}
                  >
                    {wards.map((address) => (
                      <Select.Option key={address.id} value={address.id}>
                        <div className="flex gap-2 items-center py-1">
                          <p className="truncate">{address.name}</p>
                        </div>
                      </Select.Option>
                    ))}
                  </StyledSelect>
                  <p className="mt-2 font-semibold">Đường/Toà Nhà/Ngõ</p>
                  <StyledSelect
                    className="w-full h-10 text-start first:pr-4"
                    suffixIcon={<IoCaretDown className="text-lg" />}
                    placeholder="Nhập địa chỉ lấy hàng"
                    onChange={(e) => handleChangeAddressLevelFour(e)}
                    value={
                      addressLevelFours?.find(
                        (item) => item.id === orderDetails?.addressLevel4Id
                      )?.name
                    }
                    disabled={enableControl.l4Address}
                  >
                    {addressLevelFours?.map((address) => (
                      <Select.Option key={address.id} value={address.id}>
                        <div className="flex gap-2 items-center py-1">
                          <p className="truncate">{address.name}</p>
                        </div>
                      </Select.Option>
                    ))}
                  </StyledSelect>
                </div>
              </div>
              <div>
                <p className="uppercase font-semibold text-base mb-2">
                  Nội dung hàng hóa
                </p>
                <p className="font-semibold">
                  Sản phẩm <span className="text-red">*</span>
                </p>
                <div className="relative w-full" ref={refProduct}>
                  <Input
                    className="rounded-md w-full h-10"
                    placeholder="Nhập sản phẩm"
                    value={searchProduct}
                    onChange={(e) => setSearchProduct(e.target.value)}
                    onFocus={() => setOpenProductList(true)}
                    disabled={enableControl.product}
                  />
                  {openProductList && (
                    <div
                      className={`absolute left-0 top-12 z-10 w-full max-h-[200px] overflow-auto rounded-lg bg-white border`}
                    >
                      <div className="flex flex-col items-start">
                        {productList?.map((item) => (
                          <div
                            key={item?.id}
                            className={`flex gap-2 text-left py-2 text-sm cursor-pointer hover:bg-gray-2 w-full`}
                            onClick={() => choosingProduct(item)}
                          >
                            <div className="flex justify-center w-1/4">
                              <img
                                src={
                                  item?.imageUrl
                                    ? `${process.env.REACT_APP_GO24_API}${item?.imageUrl}`
                                    : noProduct
                                }
                                className="w-10 h-10 rounded-full"
                                alt="icon"
                              />
                            </div>
                            <div className="w-1/2">
                              <p>{item?.name}</p>
                              <p>{item?.code}</p>
                            </div>
                            <div className="w-1/4">{item?.retailPrice}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                {orderDetails?.items?.map((item: any) => (
                  <div className="mt-2 flex" key={item?.Id}>
                    <div className="h-10 rounded-l-[6px] border w-full p-2 truncate">
                      {item?.Name}
                    </div>
                    <div>
                      <Input
                        type="number"
                        min={0}
                        className="h-10 w-16 rounded-tl-none rounded-bl-none truncate"
                        value={item?.Quantity}
                        onChange={(e) =>
                          handleChangeQuantityOfProduct(item, e.target.value)
                        }
                        disabled={enableControl.product}
                      />
                    </div>
                    <div>
                      <button
                        className="bg-main-2 mb-2 rounded-md h-10 !w-10 flex justify-center items-center hover:bg-main-4"
                        onClick={() => handleRemoveProduct(item)}
                        disabled={enableControl.product}
                      >
                        <FaRegTrashCan className="text-white" />
                      </button>
                    </div>
                  </div>
                ))}
                <p className="font-semibold">Tiền thu hộ</p>
                <Input
                  value={orderDetails?.collectMoney}
                  placeholder="Nhập tiền thu hộ"
                  className="h-10 w-full"
                  onChange={(e) =>
                    onChangeOrderDetails("collectMoney", e.target.value)
                  }
                  onFocus={() => setIsChanged(true)}
                  onBlur={() => {
                    processCalculateOrderAmount();
                    setIsChanged(false);
                  }}
                  type="number"
                  min={0}
                  disabled={enableControl.collectMoney}
                />
                <div className="mt-2 flex gap-4">
                  <div className="w-1/3">
                    <p className="font-semibold">Khối lượng</p>
                    <Input
                      value={orderDetails?.weight}
                      onChange={(e) => {
                        onChangeOrderDetails("weight", e.target.value);
                      }}
                      type="number"
                      className="rounded-md w-full h-10 border border-[#d9d9d9] px-[11px] py-1 hover:border-[#4096ff] outline-none"
                      onFocus={() => setIsChanged(true)}
                      onBlur={() => {
                        onWeightChange();
                        setIsChanged(false);
                      }}
                      min={0}
                      disabled={enableControl.weight}
                    />
                  </div>
                  <div className="flex-1 w-2/3">
                    <p className="font-semibold">Kích thước (không bắt buộc)</p>
                    <div className=" grid grid-cols-3 gap-1">
                      <Input
                        type="number"
                        value={orderDetails?.length}
                        onChange={(e) => {
                          onChangeOrderDetails("length", e.target.value);
                        }}
                        onFocus={() => setIsChanged(true)}
                        onBlur={() => {
                          onSizeChange();
                          setIsChanged(false);
                        }}
                        className="rounded-md w-full h-10 border border-[#d9d9d9] px-[11px] py-1 hover:border-[#4096ff] outline-none"
                        min={0}
                        disabled={enableControl.size}
                      />
                      <Input
                        type="number"
                        value={orderDetails?.width}
                        onChange={(e) => {
                          onChangeOrderDetails("width", e.target.value);
                        }}
                        onFocus={() => setIsChanged(true)}
                        onBlur={() => {
                          onSizeChange();
                          setIsChanged(false);
                        }}
                        className="rounded-md w-full h-10 border border-[#d9d9d9] px-[11px] py-1 hover:border-[#4096ff] outline-none"
                        min={0}
                        disabled={enableControl.size}
                      />
                      <Input
                        type="number"
                        value={orderDetails?.height}
                        onChange={(e) => {
                          onChangeOrderDetails("height", e.target.value);
                        }}
                        onFocus={() => setIsChanged(true)}
                        onBlur={() => {
                          onSizeChange();
                          setIsChanged(false);
                        }}
                        className="rounded-md w-full h-10 border border-[#d9d9d9] px-[11px] py-1 hover:border-[#4096ff] outline-none"
                        min={0}
                        disabled={enableControl.size}
                      />
                    </div>
                  </div>
                </div>
                <p className="mt-2 font-semibold">
                  Giá trị khai giá bảo hiểm (không bắt buộc)
                </p>
                <Input
                  value={orderDetails?.insurranceAmount}
                  className="h-10 w-full"
                  placeholder="Nhập giá trị khai giá bảo hiểm"
                  onChange={(e) =>
                    onChangeOrderDetails("insurranceAmount", e.target.value)
                  }
                  onFocus={() => setIsChanged(true)}
                  onBlur={() => {
                    onInsuranceAmountChanged();
                    setIsChanged(false);
                  }}
                  type="number"
                  min={0}
                  disabled={enableControl.insurranceAmount}
                />
                <p className="mt-2 font-semibold">
                  Mã đơn riêng của shop (không bắt buộc)
                </p>
                <Input
                  className="rounded-md h-10"
                  placeholder="Nhập mã đơn của shop"
                  value={orderDetails?.shopCode}
                  onChange={(e) =>
                    onChangeOrderDetails("shopCode", e.target.value)
                  }
                  onKeyDown={(e: any) => onShopCodeKeyPress(e)}
                  onFocus={() => setIsChanged(true)}
                  onBlur={() => {
                    onShopCodeValueChanged();
                    setIsChanged(false);
                  }}
                  disabled={enableControl.shopCode}
                />
                <p className="mt-2 font-semibold">Ghi chú</p>
                <Input.TextArea
                  rows={3}
                  className="rounded-md w-full"
                  placeholder="Nhập ghi chú"
                  style={{ resize: "none" }}
                  value={orderDetails?.note}
                  onChange={(e) => onChangeOrderDetails("note", e.target.value)}
                  disabled={enableControl.note}
                />
              </div>
              <div>
                <p className="uppercase font-semibold text-base mb-2">
                  Đơn vị vận chuyển
                </p>
                <div className="grid grid-cols-2 gap-x-8">
                  <div>
                    <p className="font-semibold">Mã voucher</p>
                    <Input
                      className="rounded-md h-10"
                      disabled
                      value={orderDetails?.voucherCode}
                    />
                  </div>
                  <div className="flex items-end mb-1">
                    <button
                      className="bg-main-2 hover:bg-main-4 text-wrap text-white px-2 py-1 rounded-md"
                      onClick={onOpenChoosingVoucher}
                      disabled={
                        orderDetails?.deliveryOwner !== 1 ||
                        enableControl.voucher !== undefined || order?.partnerDeliveryId === 5
                      }
                    >
                      Lấy mã giảm giá
                    </button>
                  </div>
                </div>
                {orderDetails?.voucherData?.voucher &&
                  orderDetails?.partnerDeliveryId !== 5 &&
                  orderDetails?.deliveryOwner === 1 && (
                    <p className="font-semibold mt-1">
                      Giảm giá vận chuyển:{" "}
                      <span className="text-red">{getPriceVoucher()}</span>
                    </p>
                  )}
                {orderDetails?.voucherData?.voucher === null &&
                  orderDetails?.voucherCode !== "" &&
                  orderDetails?.voucherCode !== undefined && (
                    <p className="font-semibold text-red">
                      Mã giảm giá không hợp lệ.
                    </p>
                  )}
                <p className="text-red my-2">
                  Chú ý: Quý Khách Hàng không cần thanh toán trước tiền phí vận
                  chuyển trực tiếp cho tài xế.
                </p>
                {orderDetails?.shopLocationId &&
                  orderDetails?.provinceId !== 0 &&
                  orderDetails?.districtId !== 0 &&
                  orderDetails?.weight > 0 && (
                    <div>
                      <p className="font-semibold">Đối tác vận chuyển</p>
                      <div className="mt-2 w-full">
                        {isPriceLoading ? (
                          <div className="flex justify-center w-full py-2">
                            <Spin />
                          </div>
                        ) : (
                          <div>
                            {deliveryPartner?.map((item) => (
                              <div className="flex font-semibold">
                                <div className="flex gap-1 w-1/2">
                                  <Radio
                                    value={item.id.toString() + "_1"}
                                    className="flex"
                                    onChange={(e) => {
                                      setDeliveryPartnerMappingView(
                                        e.target.value
                                      );
                                      changedPartnerDelivery({
                                        partnerDeliveryId: item.id,
                                        deliveryOwner: 1,
                                      });
                                    }}
                                    checked={
                                      deliveryPartnerMappingView ===
                                      item.id.toString() + "_1"
                                    }
                                    disabled={enableControl.deliveryPartner}
                                  ></Radio>
                                  <p className="">{item?.name}</p>
                                </div>
                                <p className="text-red w-1/4">
                                  {item?.deliveryExpectedDate}
                                </p>
                                <p className="text-red w-1/4 text-left">{`${formatNumber(
                                  item?.deliveryPrice
                                )}đ`}</p>
                              </div>
                            ))}
                            {shopDeliveryPartner?.map((item) => (
                              <div className="flex font-semibold">
                                <div className="flex gap-1 w-1/2">
                                  <Radio
                                    value={item.id.toString() + "_2"}
                                    className="flex"
                                    onChange={(e) => {
                                      setDeliveryPartnerMappingView(
                                        e.target.value
                                      );
                                      changedPartnerDelivery({
                                        partnerDeliveryId: item.id,
                                        deliveryOwner: 2,
                                      });
                                    }}
                                    checked={
                                      deliveryPartnerMappingView ===
                                      item.id.toString() + "_2"
                                    }
                                  ></Radio>
                                  <p className="">{item?.name}</p>
                                </div>
                                <p className="text-red w-1/4">
                                  {item?.deliveryExpectedDate}
                                </p>
                                <p className="text-red w-1/4 text-left">{`${formatNumber(
                                  item?.deliveryPrice
                                )}đ`}</p>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                <StyledSelect
                  defaultValue={1}
                  className="my-2 w-full text-start h-10"
                  suffixIcon={<IoCaretDown className="text-lg" />}
                  onChange={(e) => {
                    onChangeOrderDetails("typeShowItem", e);
                    onTypeShowItemChanged(e);
                  }}
                  value={
                    checkingGoodsDefault?.find(
                      (item) => item.id === orderDetails?.typeShowItem
                    )?.title
                  }
                  disabled={enableControl.deliveryInstruction}
                >
                  {checkingGoodsDefault.map((allow) => (
                    <Select.Option key={allow.id} value={allow.id}>
                      <div className="flex gap-2 items-center py-1">
                        <p className="truncate">{allow.title}</p>
                      </div>
                    </Select.Option>
                  ))}
                </StyledSelect>
                {orderDetails?.typeShowItem === 3 &&
                  (orderDetails?.partnerDeliveryId === 4 ||
                    orderDetails?.partnerDeliveryId === 12 ||
                    orderDetails?.partnerDeliveryId === 14) && (
                    <p className="mb-1 text-red">
                      Phụ thu:{" "}
                      <span>
                        {formatNumber(calculateOrderAmount?.tryItemFeeAmount)}
                      </span>
                    </p>
                  )}
                <Checkbox
                  checked={orderDetails?.isCustomerPaid}
                  className="w-full"
                  disabled={enableControl.isCustomerPaid}
                  onChange={(e) => {
                    onChangeOrderDetails("isCustomerPaid", e.target.checked);
                    onCustomerPaidChanged(e.target.checked);
                  }}
                >
                  <p>Khách trả phí vận chuyển</p>
                </Checkbox>
                <Checkbox
                  checked={orderDetails?.partialDeliverySelection}
                  className="w-full"
                  disabled={enableControl.partialDeliverySelection}
                  onChange={(e) => {
                    onPartialDeliverySelectionChanged(e.target.checked);
                  }}
                >
                  <p>Giao hàng 1 phần chọn sản phẩm</p>
                </Checkbox>
                <Checkbox
                  checked={orderDetails?.partialDeliveryReturn}
                  className="w-full"
                  disabled={
                    enableControl.partialDeliveryReturn ||
                    isGH1PEnable(order.partnerDeliveryId)
                  }
                  onChange={(e) => {
                    onPartialDeliveryReturnChanged(e.target.checked);
                  }}
                >
                  <p>Giao hàng 1 phần đối trả hàng</p>
                </Checkbox>
                <Checkbox
                  checked={orderDetails?.callbackShop}
                  className="w-full"
                  disabled={enableControl.callbackShop}
                  onChange={(e) => {
                    onCallbackShopChanged(e.target.checked);
                  }}
                >
                  <p className="leading-4">
                    Gọi shop khi khách không nhận hàng, không liên lạc được, sai
                    thông tin
                  </p>
                </Checkbox>
                <Checkbox
                  checked={orderDetails?.notDeliveredCollection}
                  className="w-full h-10"
                  disabled={enableControl.notDeliveredCollection}
                  onChange={(e) => {
                    onNotDeliveredCollectionChanged(e.target.checked);
                  }}
                >
                  <div className="w-full h-10 flex gap-4 items-center">
                    <p
                      className={`${
                        orderDetails?.notDeliveredCollection ? "w-1/2" : ""
                      }`}
                    >
                      Không giao được thu
                    </p>
                    {orderDetails?.notDeliveredCollection && (
                      <Input
                        value={orderDetails?.notDeliveredFee}
                        className="rounded-md w-1/2 h-10 border border-[#d9d9d9] px-[11px] py-1 hover:border-[#4096ff] outline-none"
                        onFocus={() => setIsChanged(true)}
                        onBlur={() => {
                          onNotDeliveredFeeChanged();
                          setIsChanged(false);
                        }}
                        onChange={(e) =>
                          onChangeOrderDetails(
                            "notDeliveredFee",
                            e.target.value
                          )
                        }
                        type="number"
                        min={0}
                      />
                    )}
                  </div>
                </Checkbox>
                <Checkbox
                  checked={orderDetails?.isSealedProduct}
                  className="w-full"
                  disabled={enableControl.isSealedProduct}
                  onChange={(e) => {
                    onIsSealedProductChanged(e.target.checked);
                  }}
                >
                  <p>{`Hàng nguyên hộp (phụ thu ${formatNumber(
                    calculateOrderAmount?.sealedProductFeeAmount
                  )} VND)`}</p>
                </Checkbox>
                <Checkbox
                  checked={orderDetails?.isDocument}
                  className="w-full"
                  disabled={enableControl.isDocument}
                  onChange={(e) => {
                    onIsDocumentChanged(e.target.checked);
                  }}
                >
                  <p>Thư tín, tài liệu</p>
                </Checkbox>
                <Checkbox
                  checked={orderDetails?.isFragileItem}
                  className="w-full"
                  disabled={enableControl.isFragileItem}
                  onChange={(e) => {
                    onIsFragileItemChanged(e.target.checked);
                  }}
                >
                  <p>{`Hàng hóa dễ vỡ (phụ thu ${formatNumber(
                    calculateOrderAmount?.fragileItemFeeAmount
                  )} VND)`}</p>
                </Checkbox>
                <Checkbox
                  checked={orderDetails?.isAgriculturalItem}
                  className="w-full"
                  disabled={enableControl.isAgriculturalItem}
                  onChange={(e) => {
                    onIsAgriculturalItemChanged(e.target.checked);
                  }}
                >
                  <p>{`Nông sản/ thực phẩm khô (phụ thu ${formatNumber(
                    calculateOrderAmount?.agriculturalItemFeeAmount
                  )} VND)`}</p>
                </Checkbox>
                <div className="mt-2 flex flex-col gap-1">
                  <p>
                    <span className="font-semibold">TỔNG THU NGƯỜI NHẬN:</span>
                    {` ${formatNumber(
                      calculateOrderAmount?.totalTakenCustomerAmount
                    )} VND`}
                  </p>
                  <p>
                    <span className="font-semibold">PHÍ BẢO HIỂM:</span>
                    {` ${formatNumber(
                      calculateOrderAmount?.insuranceFeeAmount
                    )} VND`}
                  </p>
                  {calculateOrderAmount?.extraFeeAmount > 0 && (
                    <p>
                      <span className="font-semibold">PHÍ PHỤ THU:</span>
                      {` ${formatNumber(
                        calculateOrderAmount?.extraFeeAmount
                      )} VND`}
                    </p>
                  )}
                  {calculateOrderAmount?.incurredCost > 0 && (
                    <p>
                      <span className="font-semibold">PHÍ PHÁT SINH:</span>
                      {` ${formatNumber(
                        calculateOrderAmount?.incurredCost
                      )} VND`}
                    </p>
                  )}
                  <p>
                    <span className="font-semibold">THANH TOÁN CHO SHOP:</span>
                    {` ${formatNumber(
                      calculateOrderAmount?.totalPaidShopAmount
                    )} VND`}
                  </p>
                  {orderDetails?.isMoneyCollection && (
                    <p>
                      <span className="font-semibold">TIỀN ỨNG CHO SHOP:</span>
                      {` ${formatNumber(
                        calculateOrderAmount?.totalAdvancePaymentAmount
                      )} VND`}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="w-full flex justify-end">
              <button
                className="mr-2 h-10 bg-main-2 hover:bg-main-4 text-wrap text-white px-4 py-1 rounded-md"
                disabled={enableControl.updateButton === undefined && isChanged}
                onClick={changeOrderInfo}
              >
                {`${!order?.isRenew ? "Cập nhật" : "Tạo đơn mới"}`}
              </button>
            </div>
          </div>
        </div>
        {loading && (
          <div className="absolute left-0 top-0 flex justify-center items-center w-full h-full">
            <div className="loader z-[9999999px]"></div>
          </div>
        )}
      </div>
      {openGetVoucherCode && (
        <VoucherCodeModal
          open={openGetVoucherCode}
          onCancel={() => setOpenGetVoucherCode(false)}
          onChoosingVoucher={onChoosingVoucher}
        />
      )}
    </Modal>
  );
};

export default EditOrderModal;
