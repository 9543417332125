import { formatNumber } from "@helper/helper";
import React from "react";

type Dataset = {
  title: string;
  value: number;
};
type BlockBannerLGProps = {
  data: Dataset[];
  bannerImage: string;
  className?: string;
};
export const BlockBannerLG: React.FC<BlockBannerLGProps> = ({
  bannerImage,
  data,
  className,
  ...props
}) => {
  return (
    <div
      className={`flex flex-col md:flex-row justify-between items-center rounded-xl px-3 lg:px-4 md:px-8 py-3 lg:py-6 gap-x-4 md:gap-x-6 overflow-hidden ${className}`}
      {...props}
    >
      <div className="w-3/4 md:w-1/2 flex justify-center items-center">
        <img src={bannerImage} alt="banner" />
      </div>
      <div className="md:flex flex-col w-full md:w-1/2 gap-y-3 md:gap-y-4">
        {data.length > 0 &&
          data.map((data, i) => (
            <div key={i} className="w-full">
              <p className="text-sm md:text-base leading-none text-center mb-2 md:mb-4">
                {data.title}
              </p>
              <h4 className="font-bold text-main-1 text-lg md:text-2xl leading-none text-center mb-3 md:mb-1 truncate">
                {formatNumber(data?.value)} VND
              </h4>
            </div>
          ))}
      </div>
    </div>
  );
};
