import { Link, useLocation, useParams } from "react-router-dom";

interface IShopManagementTabsItem {
  path: string;
  label: string;
}

const ShopManagementTabsItem = ({ path, label }: IShopManagementTabsItem) => {
  const location = useLocation();
  const { pathname } = location;
  const params = useParams();
  const { id } = params;

  return (
    <Link to={`${path}/${id}`}>
      <div
        className={`text-base text-black font-normal px-2 py-4 border-b hover:border-main-1 cursor-pointer ${
          pathname.includes(path) ? "border-main-1" : "border-transparent"
        }`}
      >
        {label}
      </div>
    </Link>
  );
};

export default ShopManagementTabsItem;
