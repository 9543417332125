import React from "react";

type SectionProps = {
  title?: string;
  children?: React.ReactNode;
};
export const SectionAccount: React.FC<SectionProps> = ({ title, ...props }) => {
  return (
    <div>
      {title && (
        <h4 className="font-semibold text-sm md:text-base leading-none mb-4 text-start">
          {title}
        </h4>
      )}
      {props.children}
    </div>
  );
};
