import { DatePicker, Input, Modal, Select, message } from "antd";
import { StyledSelect } from "./styles";
import { IoCalendarNumberOutline, IoCaretDown } from "react-icons/io5";
import {
  addOrderRequest,
  calculateRedeliveryFee,
} from "@services/send-request";
import { useEffect, useState } from "react";
import moment from "moment-timezone";
import { formatNumber } from "@helper/helper";

const DATE_TYPE = [
  { code: "reschedule-pickup", name: "Hẹn ngày lấy" },
  { code: "reschedule-delivering", name: "Hẹn ngày giao" },
  { code: "reschedule-return", name: "Hẹn ngày trả" },
];

const PUSH_TYPE = [
  { code: "push-pickup", name: "Push lấy hàng" },
  { code: "push-delivering", name: "Push giao hàng" },
  { code: "push-return", name: "Push trả hàng" },
];

const COMPLAIN_TYPE = [
  "Lấy hàng",
  "Giao hàng",
  "Trả hàng",
  "Mất hàng",
  "Vỡ hàng",
  "Vấn đề khác",
];

const RequestModal = ({ type, onCancel, order }: any) => {
  const [complainType, setComplainType] = useState<any>();
  const [pushType, setPushType] = useState<any>("");
  const [rescheduleType, setRescheduleType] = useState();
  const [rescheduleDate, setRescheduleDate] = useState<any>();
  const [note, setNote] = useState("");
  const [redeliveryFee, setRedeliveryFee] = useState(0);

  const getPushType = (pushType: string) => {
    switch (pushType) {
      case "push-pickup":
        return "push lấy hàng";
      case "push-delivering":
        return "push giao hàng";
      case "push-return":
        return "push trả hàng";
      default:
        break;
    }
  };

  const getRescheduleType = (rescheduleType: string) => {
    switch (rescheduleType) {
      case "reschedule-pickup":
        return "hẹn ngày lấy";
      case "reschedule-delivering":
        return "hẹn ngày giao";
      case "reschedule-return":
        return "hẹn ngày trả";
      default:
        break;
    }
  };

  const handleSubmitRequest = async () => {
    let requestMessage = "";
    let request = type?.value;
    switch (type?.value) {
      case "re-delivery": {
        requestMessage = "Shop gửi yêu cầu giao lại: ";
        break;
      }
      case "complain": {
        if (!complainType) {
          message.error("Vui lòng chọn loại khiếu nại");
          return;
        }
        requestMessage = `Shop gửi yêu cầu khiếu nại: ${complainType} - ${note}`;
        break;
      }
      case "return": {
        requestMessage = `Shop gửi yêu cầu hoàn hàng: ${note}`;
        break;
      }
      case "order-push": {
        if (!pushType) {
          message.error("Vui lòng chọn loại push");
          return;
        }
        request = pushType;
        requestMessage = `Shop gửi yêu cầu ${getPushType(pushType)}: ${note}`;
        break;
      }
      case "order-reschedule": {
        if (!rescheduleType) {
          message.error("Vui lòng chọn loại ngày hẹn");
          return;
        }
        if (!rescheduleDate) {
          message.error("Vui lòng chọn ngày hẹn");
          return;
        }
        request = rescheduleType;
        requestMessage = `Shop gửi yêu cầu ${getRescheduleType(
          rescheduleType
        )}: Thời gian ${moment(rescheduleDate).format(
          "DD-MM-YYYY HH:mm:ss"
        )} - ${note}`;
        break;
      }
      default:
        requestMessage = `Shop gửi yêu cầu: ${note}`;
        break;
    }
    try {
      const payload = {
        requestType: request,
        orderCode: order?.code,
        orderId: order?.id,
        requestMessage,
      };
      const res: any = await addOrderRequest(payload);
      if (res && res?.code !== "Fail") {
        message.success(
          "Đã gửi yêu cầu tới CSKH thành công, xin chờ trong ít phút"
        );
        onCancel();
      } else {
        message.error(res?.errorMessage);
      }
    } catch (e: any) {
      message.error(e?.errorMessage);
    }
  };

  useEffect(() => {
    (async () => {
      if (type?.value === "re-delivery") {
        try {
          const res = await calculateRedeliveryFee(order);
          if (res && res?.data) {
            setRedeliveryFee(res.data);
          }
        } catch (e) {}
      }
    })();
  }, [order, type?.value]);

  return (
    <Modal
      title=""
      open={type?.value}
      onCancel={onCancel}
      width={600}
      footer={null}
      centered
    >
      <div className="text-base font-semibold border-b pb-3">{type?.text}</div>
      <div className="my-4">
        {type?.value === "re-delivery" && (
          <div className="flex flex-col gap-3">
            <div>
              <p className="mb-2">
                Quý khách đang yêu cầu giao lại đơn hàng 1 lần
              </p>
              <p>
                Phí giao lại là:
                <span className="text-red font-semibold">
                  {" "}
                  {formatNumber(redeliveryFee)}{" "}
                </span>
                đồng
              </p>
            </div>
          </div>
        )}
        {type?.value === "return" && (
          <p>Quý khách đang yêu cầu duyệt hoàn đơn hàng</p>
        )}
        {type?.value === "order-reschedule" && (
          <div className="flex flex-col gap-3">
            <div>
              <p className="mb-2">Loại</p>
              <StyledSelect
                className="w-full h-10 text-start first:pr-4"
                suffixIcon={<IoCaretDown className="text-lg" />}
                onChange={(e: any) => setRescheduleType(e)}
                placeholder="Nhập loại"
              >
                {DATE_TYPE.map((type) => (
                  <Select.Option key={type.code} value={type?.code}>
                    {type?.name}
                  </Select.Option>
                ))}
              </StyledSelect>
            </div>
            <div>
              <p className="mb-2">Ngày hẹn</p>
              <DatePicker
                showTime
                className="w-full h-10 px-4"
                suffixIcon={
                  <IoCalendarNumberOutline className="text-2xl text-black" />
                }
                onChange={(date, dateString) => setRescheduleDate(dateString)}
              />
            </div>
          </div>
        )}
        {type?.value === "order-push" && (
          <div className="flex flex-col gap-3">
            <div>
              <p className="mb-2">Loại</p>
              <StyledSelect
                className="w-full h-10 text-start first:pr-4"
                suffixIcon={<IoCaretDown className="text-lg" />}
                placeholder="Nhập loại"
                onChange={(e) => setPushType(e)}
              >
                {PUSH_TYPE.map((type) => (
                  <Select.Option key={type.code} value={type?.code}>
                    {type?.name}
                  </Select.Option>
                ))}
              </StyledSelect>
            </div>
          </div>
        )}
        {type?.value === "complain" && (
          <div className="flex flex-col gap-3">
            <div>
              <p className="mb-2">Loại</p>
              <StyledSelect
                className="w-full h-10 text-start first:pr-4"
                suffixIcon={<IoCaretDown className="text-lg" />}
                placeholder="Nhập loại"
                onChange={(e) => setComplainType(e)}
              >
                {COMPLAIN_TYPE.map((type) => (
                  <Select.Option key={type} value={type}>
                    {type}
                  </Select.Option>
                ))}
              </StyledSelect>
            </div>
          </div>
        )}
      </div>
      <div>
        <p className="mb-2">Ghi chú</p>
        <Input.TextArea
          rows={3}
          onChange={(e) => setNote(e.target.value)}
        ></Input.TextArea>
      </div>
      <div className="flex justify-end">
        <button
          className="mt-2 !border-none rounded px-3 py-1 bg-main-2 text-sm font-medium leading-[24px] text-white hover:bg-main-4 flex justify-center items-center"
          onClick={handleSubmitRequest}
        >
          Xác nhận
        </button>
      </div>
    </Modal>
  );
};

export default RequestModal;
